import { Facebook, LinkedIn, Search } from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { BlogCard, CardTitle } from "../../components/custom";
import Hero from "../../components/custom/hero";
import { Pagination, Button, Input } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import "./style.scss";
import moment from "moment";

const BlogSingle = () => {
  const [state, setState] = useState({
    post: null,
    similar: [],
  });

  const { id } = useParams();

  const history = useHistory();

  const { t, lang } = useLanguageContext();

  const BlogPosts = t("BlogPosts");

  useEffect(() => {
    const news = BlogPosts.news.find((x) => x.link.endsWith(id));
    const advices = BlogPosts.advices.find((x) => x.link.endsWith(id));

    if (news) {
      setState({
        ...state,
        post: news,
        similar: BlogPosts.news
          .filter((x) => !x.link.endsWith(id))
          .filter((x, y) => y < 2),
      });
    } else if (advices) {
      setState({
        ...state,
        post: advices,
        similar: BlogPosts.advices
          .filter((x) => !x.link.endsWith(id))
          .filter((x, y) => y < 2),
      });
    } else {
      history.push("/public/blog");
    }
  }, [id, lang]);

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-blog.jpg" />
      <div className="cv-container cv-blog-main">
        <CardTitle
          title={
            <>
              <span className="secondary">{t("Blog")} </span> {t("i vijesti")}
            </>
          }
        />
        <div className="cv-blog-single-content">
          {state.post ? (
            <div className="cv-blog-single-content-left">
              <div className="cv-blog-single-content-left-image">
                <img src={state.post.image} alt="Blog post" />
              </div>
              <div className="cv-blog-single-content-left-data">
                <div className="cv-blog-single-content-left-date">
                  {moment(state.post.date).format("DD.MM.YYYY")}
                </div>
                <h2 className="cv-blog-single-content-left-title">
                  {state.post.title}
                </h2>
                <div className="cv-blog-single-content-left-description">
                  {state.post.description?.map((x, y) => (
                    <div
                      className="cv-blog-single-content-left-description-row"
                      key={y}
                    >
                      {x}
                    </div>
                  ))}
                </div>
                <div className="cv-blog-single-content-left-source">
                  {state.post.source}
                </div>
              </div>
              <div className="cv-blog-single-content-left-share">
                <div
                  className="cv-blog-single-content-left-share-back"
                  onClick={goBack}
                >
                  {t("NAZAD")}
                </div>
                <div className="cv-blog-single-content-left-share-all">
                  <span>{t("Podjelite na:")} </span>
                  <div className="cv-blog-single-content-left-share-socials">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    >
                      <Facebook />
                    </a>
                    <a
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                    >
                      <LinkedIn />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {state.similar.length ? (
            <div className="cv-blog-single-content-right">
              <div className="cv-blog-single-content-similar">
                {state.post.category === 1 ? t`Slični savjeti` : null}
                {state.post.category === 2 ? t`Slične vijesti` : null}
              </div>
              <div className="cv-blog-single-content-right-posts">
                {state.similar.map((x, y) => (
                  <BlogCard
                    className="cv-blog-single-content-right-single"
                    key={y}
                    fixedRatio
                    type={x.category}
                    direction="vertical"
                    created_at={x.date}
                    image={x.image}
                    title={x.title}
                    description={x.short_description}
                    to={x.link}
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default BlogSingle;
