import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal } from '../../components/custom';
import { v4 as uuidv4 } from "uuid";

const defaultValues = {
  modals: []
};

const ModalContext = createContext(defaultValues);

// USING CONTEXT EXPORT
export const useModalContext = () => useContext(ModalContext);

// CONTEXT PROVIDER
export const ModalContextProvider = ({ children }) => {
  const [state, setState] = useState({
    modals: [],
  });

  // UPDATE REFERENCE
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const close = (id) => {
    setState({
      ...stateRef.current,
      modals: stateRef.current.modals.filter(x => x.id !== id),
    });
  }

  const push = ({ref, title, body, actions = [], size}) => {
    const id = ref || uuidv4();
    setState({
      ...stateRef.current,
      modals: [
        ...stateRef.current.modals,
        {
          id,
          dom: (
            <Modal
              open={true}
              onClose={() => close(id)}
              title={title}
              actions={actions}
              size={size}
            >
              {body}
            </Modal>
          ),
        },
      ],
    });
  }

  return (
    <ModalContext.Provider value={{ ...state, push, close }}>
      {children}
      {state.modals.length ? state.modals[0].dom : null}
    </ModalContext.Provider>
  );
};
