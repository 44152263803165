import React, { Fragment, useEffect, useState } from 'react';
import { AdCard, CardTitle, JobCard } from '../../../components/custom';
import { Pagination, Select } from '../../../components/ui';
import Hero from '../../../components/custom/hero';
import { useLanguageContext } from '../../../context/language';
import {
  getCurrentParams,
  injectQueryParams,
  removeFalsy
} from '../../../utils/misc';
import { Search } from './features';
import './style.scss';
import { JobAPI } from '../../../api/v2';
import { toQueryParams } from '../../../utils/converter';
import EmptyJobs from '../../../assets/svg/jobs/emptyjobs';
import Spinner from '../../../components/custom/spinner';

const Jobs = () => {
  const params = getCurrentParams();

  const [detailed, setDetailed] = useState(false);

  const [state, setState] = useState({
    ads: [],
    total: 0
  });


  const [isLoadingAds, setIsLoadingAds] = useState(false);
  const [values, setValues] = useState({
    companies: [],
    work_time_id: '',
    job_type_id: '',
    country_id: '',
    city_id: '',
    types_of_work: [],
    limit: 9,
    created_before: '',
    ...params
  });

  const [page, setPage] = useState(1);

  const { t } = useLanguageContext();

  const getJobs = async ({ offset = 0, params = {} }) => {
    const filteredValues = removeFalsy({ ...values, offset, ...params });
    const queryParams = toQueryParams(filteredValues);
    injectQueryParams(queryParams);
    try {
      setIsLoadingAds(true);
      const { data, meta } = await JobAPI.publicJobSearch(filteredValues);
      setState({ ...state, total: meta.total, ads: data });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingAds(false);
    }
  };

  useEffect(() => {
    getJobs({
      offset: (page - 1) * values.limit
    });
  }, [page]);

  const selectSpecific = (created_before) => {
    setValues({ ...values, created_before });
    getJobs({
      offset: (page - 1) * values.limit,
      params: { created_before }
    });
  };

  const changeStyle = (e, name) => {
    let temp = false;
    if (name === 'grid') {
      temp = true;
    }

    setState({
      ...state,
      active_style: temp
    });
  };

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-jobs.jpg" />
      <div className="cv-container cv-jobs-main">
        <Search
          values={values}
          setValues={setValues}
          onFilter={() =>
            getJobs({
              offset: (page - 1) * values.limit,
            })
          }
          detailed={detailed}
          setDetailed={setDetailed}
        />
        <div className="cv-jobs-row">
          <div className="cv-jobs-list">
            <div className="cv-jobs-list-title-flex">
              <CardTitle
                postTitle={`${state.total} rezultata`}
                title={
                  <>
                    <span className="secondary">{t("Lista")}</span>{" "}
                    {t("poslova")}
                  </>
                }
              />

              <div className="jobs-items-group">
                <div className="jobs-btn-group">
                  <button
                    onClick={(e) => changeStyle(e, "list")}
                    className={`view-toggle-btn  ${
                      state.active_style ? " " : " active "
                    }`}
                  >
                    <svg height="18px" width="18px" viewBox="0 0 448 512">
                      <path
                        fill="currrentColor"
                        d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                      ></path>
                    </svg>
                  </button>

                  <button
                    onClick={(e) => changeStyle(e, "grid")}
                    className={`view-toggle-btn ${
                      !state.active_style ? " " : " active "
                    }`}
                  >
                    <svg height="18px" width="18px" viewBox="0 0 512 512">
                      <path
                        fill="currrentColor"
                        d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z"
                      ></path>
                    </svg>
                  </button>
                </div>

                <Select
                  className="cv-jobs-list-search-filter-form"
                  value={values.created_before}
                  onChange={(created_before) => selectSpecific(created_before)}
                  placeholder={t`Najnoviji oglasi`}
                  options={[
                    { value: "0", label: t`Postavljeni danas` },
                    { value: "3", label: t`Postavljeni prije 3 dana` },
                    { value: "7", label: t`Postavljeni prije 7 dana` },
                    { value: "10", label: t`Postavljeni prije 10 dana` },
                  ]}
                />
              </div>
            </div>
            {/* <div className="cv-jobs-all"> */}
            {!!isLoadingAds ? (
              <Spinner />
            ) : (
              <div
                className={`cv-jobs-all ${
                  state.active_style ? "view-grid" : "view-list"
                } `}
              >
                {state.ads.map((x, y) =>
                  // y % 5 === 4 ? (
                  y % 5 === 2 ? (
                    <Fragment key={x.id}>
                      <JobCard
                        companySlug={x.company.slug}
                        slug={x.slug}
                        id={x.id}
                        company={x.company}
                        title={x.title}
                        description={x.short_description}
                        city={x.city}
                        country={x.country}
                        created_at={x.start_date}
                        category={x.type_of_work}
                      />
                      <AdCard variant="big_horizontal" />
                    </Fragment>
                  ) : (
                    <JobCard
                      companySlug={x.company.slug}
                      slug={x.slug}
                      id={x.id}
                      company={x.company}
                      title={x.title}
                      description={x.short_description}
                      city={x.city}
                      country={x.country}
                      created_at={x.start_date}
                      category={x.type_of_work}
                      key={x.id}
                    />
                  )
                )}
                {!state.ads.length ? (
                  <div className="cv-jobs-empty">
                    <div className="cv-jobs-empty-icon">
                      <EmptyJobs />
                    </div>
                    <span>{t("Trenutno nema oglasa")}</span>
                  </div>
                ) : null}
              </div>
            )}
            <div className="cv-pagination-holder cv-pagination-holder-right">
              <Pagination
                count={Math.ceil(state.total / values.limit)}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
          <div className="cv-jobs-ads">
            <AdCard variant="big_vertical" />
            <AdCard variant="small_vertical" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Jobs;
