const Advice5 = () => (
  <svg viewBox="0 0 109 107">
    <path
      d="M89.14 84.24C78.31 84.24 69.56 75.48 69.56 64.66C69.56 53.83 78.31 45.07 89.14 45.07C99.97 45.07 108.72 53.83 108.72 64.66C108.72 75.48 99.97 84.24 89.14 84.24Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M40.83 106.93C31.55 106.93 24.05 99.43 24.05 90.15C24.05 80.87 31.55 73.37 40.83 73.37C50.11 73.37 57.61 80.87 57.61 90.15C57.61 99.43 50.11 106.93 40.83 106.93Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M28.33 55.91C12.87 55.91 0.380005 43.41 0.380005 27.95C0.380005 12.5 12.87 0 28.33 0C43.79 0 56.29 12.5 56.29 27.95C56.29 43.41 43.79 55.91 28.33 55.91Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M78.3 97.51H93.67C96.74 97.51 99.27 95.01 99.27 91.9V69.55H78.3V97.51Z"
      fill="white"
    ></path>
    <path
      d="M15.4 22.01C12.33 22.01 9.8 24.51 9.8 27.62V49.97L29.38 69.55H78.3V22.01H15.4Z"
      fill="white"
    ></path>
    <path d="M9.83 49.97V69.55H29.38L9.83 49.97Z" fill="white"></path>
    <path d="M78.3 41.56H99.27V55.54H78.3V41.56Z" fill="white"></path>
    <path d="M78.3 55.57H99.27V69.55H78.3V55.57Z" fill="white"></path>
    <path
      d="M93.7 22.01H78.34V41.6H99.3V27.62C99.3 24.51 96.77 22.01 93.7 22.01Z"
      fill="white"
    ></path>
    <path d="M29.38 69.55L57.34 97.47V69.55H29.38Z" fill="white"></path>
    <path d="M57.34 69.55V97.47V97.51H78.3V69.55H57.34Z" fill="white"></path>
    <path
      d="M43.36 83.53L29.38 69.55H9.8V91.9C9.8 94.97 12.3 97.51 15.4 97.51H23.78V83.53H43.36Z"
      fill="white"
    ></path>
    <path d="M43.36 83.53H23.81V97.51H57.34L43.36 83.53Z" fill="white"></path>
    <path
      d="M99.3 91.9V76.54H85.33C84.55 76.54 83.94 77.18 83.94 77.92V97.51H93.73C96.77 97.51 99.3 95.01 99.3 91.9Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M78.3 45.78H21.01C20.23 45.78 19.62 46.42 19.62 47.17V59.76L29.41 69.55H78.34V45.78H78.3Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M16.82 65.94V69.55H23.81L19.22 64.96C18.31 64.05 16.82 64.69 16.82 65.94Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M99.3 48.55H90.93C90.15 48.55 89.55 49.19 89.55 49.93V55.54H99.34V48.55H99.3Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M99.3 59.76H86.71C85.93 59.76 85.33 60.4 85.33 61.14V69.52H99.3V59.76Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M99.3 29H86.71C85.93 29 85.33 29.64 85.33 30.39V41.56H99.3V29Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M47.17 78.9L57.37 89.1V76.54H48.19C46.9 76.54 46.26 78.03 47.17 78.9Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M78.3 82.11H67.13C66.35 82.11 65.74 82.75 65.74 83.49V97.47H78.34V82.11H78.3Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M23.81 83.53H43.39L36.4 76.54H18.24C17.46 76.54 16.85 77.18 16.85 77.92V97.51H23.84V83.53H23.81Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M50.35 90.52H36.37C35.59 90.52 34.99 91.16 34.99 91.9V97.51H57.34L50.35 90.52Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.7 98.55H78.34C77.76 98.55 77.29 98.08 77.29 97.51V69.55C77.29 68.98 77.76 68.5 78.34 68.5H99.3C99.88 68.5 100.35 68.98 100.35 69.55V91.9C100.35 95.55 97.35 98.55 93.7 98.55ZM79.35 96.46H93.67C96.16 96.46 98.22 94.43 98.22 91.9V70.6H79.35V96.46Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.3 70.6H29.38C29.11 70.6 28.84 70.5 28.64 70.29L9.06 50.71C8.85 50.51 8.75 50.24 8.75 49.97V27.62C8.75 23.97 11.72 20.97 15.4 20.97H78.3C78.88 20.97 79.35 21.44 79.35 22.01V69.55C79.35 70.12 78.91 70.6 78.3 70.6ZM29.82 68.5H77.26V23.06H15.4C12.9 23.06 10.85 25.09 10.85 27.62V49.56L29.82 68.5Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.38 70.6H9.8C9.23 70.6 8.75 70.12 8.75 69.55V49.97C8.75 49.53 9.02 49.16 9.39 48.99C9.8 48.82 10.24 48.92 10.54 49.23L30.12 68.81C30.43 69.11 30.53 69.55 30.36 69.96C30.19 70.33 29.82 70.6 29.38 70.6ZM10.88 68.5H26.88L10.88 52.5V68.5Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3 56.62H78.34C77.76 56.62 77.29 56.15 77.29 55.57V41.6C77.29 41.02 77.76 40.55 78.34 40.55H99.3C99.88 40.55 100.35 41.02 100.35 41.6V55.57C100.35 56.15 99.88 56.62 99.3 56.62ZM79.35 54.49H98.22V42.61H79.35V54.49Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3 70.6H78.34C77.76 70.6 77.29 70.12 77.29 69.55V55.57C77.29 55 77.76 54.53 78.34 54.53H99.3C99.88 54.53 100.35 55 100.35 55.57V69.55C100.35 70.12 99.88 70.6 99.3 70.6ZM79.35 68.5H98.22V56.62H79.35V68.5Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.3 42.64H78.34C77.76 42.64 77.29 42.17 77.29 41.6V22.01C77.29 21.44 77.76 20.97 78.34 20.97H93.7C97.35 20.97 100.35 23.94 100.35 27.62V41.6C100.35 42.17 99.88 42.64 99.3 42.64ZM79.35 40.51H98.22V27.58C98.22 25.09 96.2 23.03 93.67 23.03H79.35V40.51Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.34 98.55C57.07 98.55 56.8 98.45 56.59 98.25L28.64 70.29C28.33 69.99 28.23 69.55 28.4 69.15C28.57 68.74 28.94 68.5 29.38 68.5H57.34C57.91 68.5 58.38 68.98 58.38 69.55V97.51C58.38 97.95 58.11 98.32 57.74 98.49C57.61 98.52 57.47 98.55 57.34 98.55ZM31.91 70.6L56.29 94.97V70.6H31.91Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.3 98.55H57.34C57.07 98.55 56.8 98.45 56.59 98.25C56.39 98.05 56.29 97.78 56.29 97.51V69.55C56.29 68.98 56.76 68.5 57.34 68.5H78.3C78.88 68.5 79.35 68.98 79.35 69.55V97.51C79.35 98.08 78.91 98.55 78.3 98.55ZM58.38 96.46H77.26V70.6H58.38V96.46Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.81 98.55H15.44C11.79 98.55 8.79001 95.58 8.79001 91.9V69.55C8.79001 68.98 9.26001 68.5 9.83001 68.5H29.41C29.68 68.5 29.95 68.61 30.16 68.81L44.14 82.79C44.44 83.09 44.54 83.53 44.37 83.93C44.2 84.34 43.83 84.58 43.39 84.58H24.86V97.51C24.86 98.08 24.38 98.55 23.81 98.55ZM10.88 70.6V91.9C10.88 94.4 12.9 96.46 15.44 96.46H22.76V83.53C22.76 82.95 23.24 82.48 23.81 82.48H40.86L28.98 70.6H10.88Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.34 98.55H23.78C23.2 98.55 22.73 98.08 22.73 97.51V83.53C22.73 82.95 23.2 82.48 23.78 82.48H43.36C43.63 82.48 43.9 82.58 44.1 82.79L58.08 96.76C58.38 97.07 58.48 97.51 58.32 97.91C58.15 98.28 57.78 98.55 57.34 98.55ZM24.86 96.46H54.84L42.95 84.58H24.86V96.46Z"
      fill="#21365F"
    ></path>
    <path
      d="M64.33 15.8C64.16 15.63 63.95 15.43 63.78 15.26C54.16 5.6 38.53 5.64 28.91 15.29L28.37 15.83C18.51 25.73 18.54 41.73 28.4 51.59L46.36 69.55L64.29 51.62C74.18 41.73 74.18 25.69 64.33 15.8Z"
      fill="white"
    ></path>
    <path
      d="M64.29 51.62C72.73 43.18 73.95 30.25 67.97 20.49C65.54 19.82 62.97 19.41 60.34 19.41C44.91 19.41 32.39 31.94 32.39 47.37C32.39 50.91 33.06 54.32 34.28 57.43L46.36 69.52L64.29 51.62Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.4 70.6C46.13 70.6 45.86 70.5 45.65 70.29L27.69 52.3C17.43 42.03 17.43 25.36 27.66 15.06L28.2 14.52C33.06 9.66001 39.51 6.95001 46.36 6.95001C53.22 6.95001 59.67 9.62001 64.53 14.48L65.07 15.02C75.33 25.32 75.33 42.03 65.03 52.33L47.11 70.26C46.94 70.5 46.67 70.6 46.4 70.6ZM46.36 9.08001C40.05 9.08001 34.11 11.55 29.65 16.04L29.11 16.58C19.69 26.03 19.73 41.43 29.14 50.85L46.36 68.06L63.55 50.88C73 41.43 73.04 26 63.58 16.54L63.04 16C58.59 11.55 52.68 9.08001 46.36 9.08001Z"
      fill="#21365F"
    ></path>
    <path
      d="M46.36 47.37C38.63 47.37 32.39 41.12 32.39 33.39C32.39 25.66 38.63 19.41 46.36 19.41C54.09 19.41 60.34 25.66 60.34 33.39C60.34 41.12 54.09 47.37 46.36 47.37Z"
      fill="white"
    ></path>
    <path
      d="M60.34 33.39C60.34 31.4 59.9 29.51 59.16 27.79C57.44 27.04 55.55 26.6 53.55 26.6C45.82 26.6 39.58 32.85 39.58 40.58C39.58 42.57 40.02 44.47 40.76 46.19C42.48 46.93 44.37 47.37 46.36 47.37C54.1 47.37 60.34 41.12 60.34 33.39Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.36 48.42C38.09 48.42 31.34 41.66 31.34 33.39C31.34 25.12 38.09 18.37 46.36 18.37C54.64 18.37 61.39 25.12 61.39 33.39C61.39 41.66 54.64 48.42 46.36 48.42ZM46.36 20.46C39.24 20.46 33.43 26.27 33.43 33.39C33.43 40.51 39.24 46.32 46.36 46.32C53.49 46.32 59.29 40.51 59.29 33.39C59.29 26.27 53.49 20.46 46.36 20.46Z"
      fill="#21365F"
    ></path>
    <path
      d="M50.35 53.55C49.88 53.55 49.44 53.21 49.33 52.7C49.23 52.13 49.57 51.59 50.15 51.45C55.38 50.37 59.87 47.06 62.5 42.41C62.77 41.9 63.41 41.73 63.92 42C64.43 42.27 64.6 42.91 64.33 43.42C61.39 48.62 56.39 52.3 50.58 53.51C50.48 53.55 50.42 53.55 50.35 53.55Z"
      fill="#21365F"
    ></path>
    <path
      d="M65.51 38.22C65.44 38.22 65.37 38.22 65.3 38.19C64.73 38.08 64.36 37.51 64.49 36.97C64.73 35.79 64.83 34.61 64.83 33.39C64.83 32.82 65.3 32.34 65.88 32.34C66.45 32.34 66.92 32.82 66.92 33.39C66.92 34.74 66.79 36.06 66.55 37.37C66.45 37.88 66.01 38.22 65.51 38.22Z"
      fill="#21365F"
    ></path>
    <path
      d="M15.4 48.21C14.83 48.21 14.36 47.74 14.36 47.17V37.68C14.36 37.1 14.83 36.63 15.4 36.63C15.98 36.63 16.45 37.1 16.45 37.68V47.17C16.45 47.74 16.01 48.21 15.4 48.21Z"
      fill="#21365F"
    ></path>
    <path
      d="M15.4 33.15C14.83 33.15 14.36 32.68 14.36 32.11V29C14.36 28.43 14.83 27.95 15.4 27.95C15.98 27.95 16.45 28.43 16.45 29V32.11C16.45 32.68 16.01 33.15 15.4 33.15Z"
      fill="#21365F"
    ></path>
    <path
      d="M96.5 17.45C95.93 17.45 95.45 16.98 95.45 16.41V10.8C95.45 10.23 95.93 9.76001 96.5 9.76001C97.07 9.76001 97.55 10.23 97.55 10.8V16.41C97.55 16.98 97.07 17.45 96.5 17.45Z"
      fill="#21365F"
    ></path>
    <path
      d="M99.3 14.69H93.7C93.12 14.69 92.65 14.21 92.65 13.64C92.65 13.07 93.12 12.59 93.7 12.59H99.3C99.88 12.59 100.35 13.07 100.35 13.64C100.35 14.21 99.88 14.69 99.3 14.69Z"
      fill="#21365F"
    ></path>
    <path
      d="M88.09 14.65C87.52 14.65 87.05 14.19 87.05 13.61C87.05 13.03 87.52 12.56 88.09 12.56C88.67 12.56 89.14 13.03 89.14 13.61C89.14 14.19 88.67 14.65 88.09 14.65Z"
      fill="#21365F"
    ></path>
    <path
      d="M83.91 14.65C83.33 14.65 82.86 14.19 82.86 13.61C82.86 13.03 83.33 12.56 83.91 12.56C84.49 12.56 84.95 13.03 84.95 13.61C84.95 14.19 84.49 14.65 83.91 14.65Z"
      fill="#21365F"
    ></path>
    <path
      d="M78.3 14.65C77.72 14.65 77.26 14.19 77.26 13.61C77.26 13.03 77.72 12.56 78.3 12.56C78.88 12.56 79.35 13.03 79.35 13.61C79.35 14.19 78.88 14.65 78.3 14.65Z"
      fill="#21365F"
    ></path>
    <path
      d="M69.93 14.65C69.35 14.65 68.88 14.19 68.88 13.61C68.88 13.03 69.35 12.56 69.93 12.56C70.51 12.56 70.98 13.03 70.98 13.61C70.98 14.19 70.51 14.65 69.93 14.65Z"
      fill="#21365F"
    ></path>
    <path
      d="M21.01 14.69H15.4C14.83 14.69 14.36 14.21 14.36 13.64C14.36 13.07 14.83 12.59 15.4 12.59H21.01C21.58 12.59 22.05 13.07 22.05 13.64C22.05 14.21 21.58 14.69 21.01 14.69Z"
      fill="#21365F"
    ></path>
    <path
      d="M18.21 17.45C17.63 17.45 17.16 16.98 17.16 16.41V10.8C17.16 10.23 17.63 9.76001 18.21 9.76001C18.78 9.76001 19.25 10.23 19.25 10.8V16.41C19.25 16.98 18.78 17.45 18.21 17.45Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Advice5;