import { Swipe as SwipeIcon } from "@mui/icons-material";
import React from "react";
import { SimpleCard } from "../../ui";
import "./style.scss";

const Swipe = ({ options = [] }) => {
  return (
    <div className="cv-swipe-main">
      {options.length ? <SimpleCard className="cv-swipe-card">
        {options.map((x, y) => (
          <div className="cv-swipe-option">{x}</div>
        ))}
      </SimpleCard> : null}
      <div className="cv-swipe-help">
        <span>
          <SwipeIcon /> SWIPE
        </span>
      </div>
    </div>
  );
};

export default Swipe;
