import React from "react";
import "./style.scss";

const monthsArray = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAJ",
  "JUN",
  "JUL",
  "AVG",
  "SEP",
  "OKT",
  "NOV",
  "DEC",
];

const Bar = ({ value = 0, maxValue = 0, month = 0 }) => {
  return (
    <div className="cv-bar-main">
      <div className="cv-bar-value">{value}</div>
      <div className="cv-bar-display">
        <div
          className={`cv-bar-display-graph ${(value === maxValue && maxValue !== 0) ? 'cv-bar-display-graph-special' : ''}`}
          style={{ height: `${(value / maxValue) * 100}%` }}
        ></div>
        <span>{monthsArray[month]}</span>
      </div>
    </div>
  );
};

const Statistic = ({ data = [] }) => {
  const maxValue = Math.max(...data);

  return (
    <div className="cv-statistic-bars">
      {data.map((x, y) => (
        <Bar value={x} maxValue={maxValue} month={y} />
      ))}
    </div>
  );
};

export default Statistic;
