const BlogPosts = {
  advices: [
    {
      title: "10 loših stavki u CV-u koje vas mogu koštati posla",
      category: "SAVJETI",
      date: "2021-07-27T22:00:00.000Z",
      name: "10 loših stavki u CV-u koje vas mogu koštati posla",
      short_description:
        "Bivši HR stručnjak iz Google-a ističe greške u CV-u koje će drugim kandidatima dati prednost u odnosu na vas.",
      description: [
        "Bivši HR stručnjak iz Google-a ističe greške u CV-u koje će drugim kandidatima dati prednost u odnosu na vas.",
        "Google je jedan od najvećih američkih poslodavaca. U vrijeme kada je Scott Bacon radio u odsjeku za regrutovanje (tokom 2013. i 2014. godine), kompanija je zaprimila više od tri miliona biografija.",
        "„Google prima raznolike prijave, od velikih profesionalaca do ljudi koji ne mogu složiti jednu rečenicu“, kaže Bacon, koji je 'odgovoran za regrutiranje inženjera, ali i drugih stručnjaka'.",
        "S tolikom bazom CV-jeva i HR stručnjaci traže i najmanji razlog da nekoj prijavi kažu 'ne'. Bacon donosi 10 jednostavnih razloga zbog kojih vaš CV može završiti u smeću:",
        "1) Loš format",
        "Možda zvuči smiješno da će CV biti diskvalificiran radi lošeg formata, posebno ako vizual ne odgovara poziciji za koju ste se prijavili. Ipak, to se događa.",
        "„Formatiranje je važnije nego što mislite. Poslodavac vaš CV u prosjeku gleda svega šest sekundi, stoga je ključno zadržati preglednost informacija i pažnju onoga ko CV čita“, kaže Lydia Dishman iz Fast Company's.",
        "2) Isticanje vlastitih ambicija",
        "Bacon kaže da je ovo 'gubitak prostora': „Govorite o onome što vi želite, umjesto da govorite o onome što možete ponuditi poslodavcu.",
        "3) Insistiranje na jednoj radnoj poziciji",
        "Ponekad ovo ima smisla, ali u velikim kompanijama, gdje postoji na desetke otvorenih pozicija, dobro je napomenuti da ste zainteresirani i za alternativu. Istaknete li da ste zainteresirani isključivo za jednu poziciju, poslodavcu uskraćujete mogućnost da vašu prijavu uzme u obzir za radno mjesto koje smatra prikladnijim.",
        "4) Nedostatak fokusa",
        "Čak i ako imate bogato radno iskustvo, ne morate navoditi svaki posao koji ste do sada radili, preporučuje Bacon. Takođe, nemojte navoditi poslove koje ste radili prije 10 ili više godina. Ukoliko insistirate na navođenju svakog posla koji ste radili, za starije radne pozicije navedite samo kompaniju, radno mjesto i datum, bez detalja.",
        "„Fokusirajte se samo na poslove koji su relevantni za radnu poziciju na koju se prijavljujete“, savjetuje Bacon.",
        "5) Jedan CV – puno različitih prijava",
        "Lako je koristiti jedan te isti CV prilikom svake prijave na oglas. Jednako tako, lako je nikada ne pronaći posao. Umjesto toga, CV i propratno pismo treba prilagođavati svakoj radnoj poziciji na koju se prijavljujete, kaže Bacon.",
        "Jedini izuzetak je moguć kod kandidata koji su tek završili školovanje i još nemaju radnog iskustva te se prijavljuju na svoj prvi posao. U tom slučaju i poslodavci će imati više razumijevanja jer shvataju da u takvoj situaciji CV nije moguće prilagođavati.",
        "6) Nabrajanje zaduženja umjesto opisa dostignuća",
        "Ovo je najčešća greška u CV-jevima. Ljudi navode zadatke za koje su 'bili zaduženi', ali ne navode ono najvažnije – konkretne rezultate koje su ostvarili.",
        "„Dobro je poštovati sljedeće pravilo“, objašnjava Bacon: „Na svaki red u kojem navodite zaduženje, moraju doći dva reda vaših dostignuća.“",
        "7) 'Preporuke dostupne na zahtjev'",
        "Biste li odbili datu preporuku ukoliko bi je poslodavac zatražio? Naravno da ne. Stoga, priložite je odmah u svojoj prijavi.",
        "8) 'Izvrsno poznavanje Microsoft Officea'",
        "Živimo u 2021. godini. Ljudi pretpostavljaju da znate koristiti Word i Power Point. Zapravo, pretjerano isticanje MS Officea umjesto savladavanja korištenja novih alata pokazuje da niste u toku.",
        "9) Žargon i fraze",
        "„Dvije riječi koje me tjeraju da bacim CV u smeće su 'sinergija' i 'dinamičan'“, priznaje Bacon. Te riječi jednostavno zvuče previše korporativno. Žargon ili fraze prihvatljivi su jedino u slučaju kada upotrebljavate riječi navedene u oglasu za posao kako biste pokazali da udovoljavate traženim kvalifikacijama.",
        "10) Nedostatak prijateljskog tona",
        "I ljudi koji pregledavaju vaše prijave imaju osjećaje, a gotovo je zapanjujuće koliko kandidata nema svijest o tome.",
        "„Jedna od najboljih stvari koju možete napraviti u ovoj fazi potrage za poslom je stvoriti prijateljsku vezu s poslodavcem ili stručnjakom zaduženim za selekcijski postupak“, kaže Lydia Dishman iz Fast Company's. „Naravno, morate ispunjavati uslove navedene u oglasu. Međutim, ako pokažete interes i kontaktirate kompaniju kako biste se informirali o detaljima konkursa i šta možete učiniti kako biste što kvalitetnije pripremili svoju dokumentaciju, rado će vam pomoći.“",
      ],
      link: "/public/blog/post/10-losih-stavki-u-cv-u-koje-vas-mogu-kostati-posla",
      image: "/assets/images/blog/saveti za CV.jpg",
      source: "Izvor: mojposao.ba",
    },

    {
      title: "Top tri pitanja koja možete postaviti poslodavcu",
      category: "SAVJETI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Top tri pitanja koja možete postaviti poslodavcu",
      short_description:
        "Odradili ste veći dio razgovora za posao, prošli ste i ugodna i ona manje ugodna pitanja, i na kraju ste stigli do onog dijela u kojem vas budući poslodavci pitaju: Imate li vi kakvih pitanja? Što sada? Ako vam je prvi instinkt jednostavno reći ne, rukovati se sa smiješkom i otići iz prostorije, razmislite ponovo jer biste upravo na tom dijelu mogli izgubiti posao. Pitanja na intervjuu za posao nekada su čak i važnija od ostatka razgovora, piše women in adria.",
      description: [
        "Odradili ste veći dio razgovora za posao, prošli ste i ugodna i ona manje ugodna pitanja, i na kraju ste stigli do onog dijela u kojem vas budući poslodavci pitaju: Imate li vi kakvih pitanja? Što sada? Ako vam je prvi instinkt jednostavno reći ne, rukovati se sa smiješkom i otići iz prostorije, razmislite ponovo jer biste upravo na tom dijelu mogli izgubiti posao. Pitanja na intervjuu za posao nekada su čak i važnija od ostatka razgovora, piše women in adria.",
        "Ovo je korak u kojem mnogi griješe. Iako se može činiti potpuno nebitan, dio u kojem imate mogućnost postaviti pitanja budućim poslodavcima može mnogo reći o ozbiljnosti vaših namjera u toj firmi i opštem nivou vašeg angažmana. Manageri za ljudske resurse na LinkedInu priskočili su u pomoć i otkrili koja su najbolja pitanja koja su do sada čuli, ali i koja bi voljeli čuti ozbiljnih kandidata.",
        "Njihovi su odgovori objedinjeni u top 3 najbolja pitanja:",
        "Za kog kandidata biste rekli da je najbolji kog ste ikada zaposlili i zašto?",
        "Imate li trenutno ikakvih zabrinutosti oko odabira mene za to radno mjesto?",
        "Kako se vaša organizacija nosi s nestabilnostima na tržištu?",
        "Pokažite stvarni interes",
        "Osim toga, jedna HR managerka je rekla da joj je uvijek drago kada se neko raspituje oko potrebne za tim radnim mjestom.",
        "“Ovakav uvid vrlo je vrijedan za budućeg kandidata jer će mu odgovor reći da li je riječ o novonastalom radnom mjestu ili je ono upravo oslobođeno. Te će mu informacije pomoći da bolje razumije poslovanje firme i širi kontekst u kojem će raditi ako prihvati poziciju”, rekla je Chelsea Assimes koja regrutuje zaposlene za Facebook i Instagram.",
        "Ako se radi o potpuno novoj poziciji, to je pokazatelj da firma raste i razvija se. Ako je neko s te pozicije unapređen, to je takođe pokazatelj unutrašnjeg razvoja firme i prilika za napredovanje iznutra. S druge strane, ako je neko otišao zbog problema s upravom ili svojom ulogom, to je takođe informacija koja je vrijedna za vas kao kandidate, smatra Assimes.",
        "“HR manageri vole strateške razgovore – one u kojima kandidati pokušavaju razumijeti kako se stvari trenutno rade, putanju firme, što su izazovi koji su pred HR manadžerima – i kada dođu pripremljeni s primjerima prethodnih radnih iskustva kroz koja pokazuju da mogu dodati određenu vrijednost”, otkrila je Najat Bensalem, regrut za Discover Financial Services.",
        "Ipak postoje i glupa pitanja",
        "Pitanja su svakako poželjna, ono što nije poželjno je pitati nešto samo da bi se pitalo. U tom smislu, dobro razmislite što ćete pitati. Nikada nemojte postavljati pitanja koja traže odgovore o kojima se već pričalo prilikom intervjua. Ovo pokazuje da niste dovoljno dobro pazili i da će vam poslodavci više puta morati objašnjavati radne zadatke.",
        "Poželjno je doći s unaprijed pripremljenim pitanjima koja proizlaze iz vašeg interesa i istraživanja kompanije i radnog mjesta. Ovo je dio razgovora za posao koji nikako ne biste smjeli zanemariti, jer dobra i kvalitetna pitanja automatski demonstriraju kvalitete koje ste naveli u CV-ju, poput vašeg stvarnog interesa, pažljivosti, znatiželjnosti i razumijevanja.",
      ],
      link: "/public/blog/post/top-tri-pitanja-koja-mozete-postaviti-poslodavcu",
      image: "/assets/images/blog/pitanja za poslodavca.jpg",
      source: "Izvor: women in adria",
    },

    {
      title: "Uz 10 sjajnih trikova vratite volju za traženjem posla",
      category: "SAVJETI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Uz 10 sjajnih trikova vratite volju za traženjem posla",
      short_description:
        'Niste sigurni koja pozicija je za vas ili nema takvih otvorenih pozicija, a većina poslodavaca vam ne odgovara na poslate molbe. Došli ste na intervju, uvjereni ste da ste "razvalili", a na kraju je ipak odabran neko drugi?',
      description: [
        'Niste sigurni koja pozicija je za vas ili nema takvih otvorenih pozicija, a većina poslodavaca vam ne odgovara na poslate molbe. Došli ste na intervju, uvjereni ste da ste "razvalili", a na kraju je ipak odabran neko drugi?',
        "Bez obzira u kojoj ste fazi procesa, na koliko ste se mjesta prijavili, koliko ste testiranja prošli - jedna stvar je sigurna: potraga za poslom zaista može biti iscrpljujuća!",
        "Nemojte odustati, provjerite ovih 10 savjeta koji će vam pomoći da prebrodite krize.",
        "1. Identifikujte problem",
        "Dugotrajna potraga za poslom obično rezultira psihičkom i fizičkom isrpljenošću. Kada umor 'uzme maha', naše tijelo i um daju nam signale koje moramo prepoznati.",
        "Gubite volju jer vam pozicije na koje se prijavljujete nisu uzbudljive? Fizički ste iscrpljeni jer krećete u potragu tek nakon dugog radnog dana? Obeshrabreni ste jer na vaše prijave ne dobijete nikakvu povratnu informaciju?",
        "Pronađete pravi uzrok zašto vam teško pada potraga za poslom i tek tada možete početi razmišljati o daljim koracima i strategijama!",
        "Uzmite slobodan dan za potragu za poslom, potrudite se napisati kreativan CV ili pronaći neke nove kanale za traženje otvorenih pozicija. Kada znate problem, lakše ćete identifikovati potencijalna rješenja!",
        "2. Uzmite predah",
        "Kada nemate rezultate, prirodno je ubrzati i pojačati napore, pa se prijavljujete na sve više konkursa i pozicija. Ipak, to može biti izrazito kontraproduktivno. Uvijek je bolje predahnuti i uzeti kratku pauzu da 'napunite baterije'.",
        "Zaista, ništa strašno se neće dogoditi ako uzmete par dana odmora.",
        "Kada dođete sebi, korak po korak prođite svoju strategiju i analizirajte što možete popraviti. Ako niste sigurni što radite pogrešno, razmislite o savjetovanju s osobama koje su uspješno prošle vaš put. Pregledajte i savjete profesionalaca koje često besplatno daju na internet stranicama. Iskoristite mišljenja drugih i usavršite svoju strategiju!",
        "3. Pitajte za odgovor",
        "Potraga za poslom ima svoje uspone i padove. Uobičajeno je osjećati se stresno i depresivno tokom procesa. Identifikujte situacije i ljude koji vam pogoršavaju raspoloženje kako biste mogli lakše izaći na kraj s istima i suočite se s njima.",
        "Uzmite za primjer situaciju da dugo čekate odgovor potencijalnog poslodavca nakon razgovora za posao. Kako biste izbjegli beskrajno živciranje, pokušajte direktno pitati intervjuera da vam istakne vaše jake, ali i slabe strane te da vam da kvalitetnu povratnu informaciju na intervju. Tako ćete dobiti korisne informacije za ubuduće, a ujedno možete pretpostaviti kako je razgovor protekao.",
        "4. Upoznajte ljude uživo",
        "Odmaknite se malo od zamornog pretraživanja internet oglasa tako da se krećete među ljudima, stičete nova poznanstva i širite mrežu kontakata. Istraživanja kažu da 70% osoba svoj posao iz snova pronađe preko poznanstva i kontakata. Stoga, ugasite računar i krenite.",
        "Sajmovi poslova, predavanja, konferencije, razni sastanci, različita događanja unutar industrije su izvrsne mogućnosti za upoznavanje osoba koje će vas inspirisati, pomoći vam u potrazi, ili možda biti vaš kontakt za posao iz snova.",
        "5. Pronađite privremeni posao",
        "Umor i frustriranost mogu pojačati loš učinak ako se zamarate i brigom o tome kako platiti tekuće račune i obveze. Privremeni honorarni posao može vam pomoći prebroditi ovu krizu.",
        "Povremeno obavljanje konobarskog posla, prevođenje tekstova, čišćenje stanova ili šetanje pasa vam vjerovatno ne zvuči preglamurozno, ali će vam sigurno skinuti dio tereta s leđa, a i novčanika!",
        "6. Volontirajte",
        "Odvojite nekoliko sati sedmično za volontiranje. Ne samo da ćete se osjećati bolje nakon toga, već ćete imati i relevantne reference za CV,  proširićete mrežu profesionalnih kontakata, te upoznati ljude koji vas mogu spojiti s potencijalnim poslodavcem.",
        "Prema istraživanju LinkedIn-a, 1 od 5 poslodavaca zaposlilo je određenog kandidata na temelju njegovog volonterskog iskustva. Naime, time potvrđujete da ste vrijeme 'bez posla' iskoristili da pomognete drugima i ojačali svoje vještine u području koje vas zanima.",
        "7. Pronađite hobi",
        "Nekada je najbolje zaokupiti misli drugim stvarima. Definitivno vam savjetujemo da izađete izvan svoje svakodnevne rutine i pronađete se u nekom hobiju. Bio to sport ili neka zabavna aktivnost, važno je da krenete te da se mentalno opustite i zabavite.",
        "Time ćete u opuštenoj atmosferi upoznavati nove ljude i graditi mrežu poznanstava, bez da imate osjećaj da to uopšte radite. A da ne spominjemo veliki broj ljudi kojima je slučajno otkriće hobija bio temelj za posao snova!",
        "8. Pokrenite vlastiti projekat",
        "Jedna od najboljih stvari koje možete napraviti u predahu od traženja posla je pokrenuti neki vlastiti projekat. Razmislite o vlastitom blogu, web stranici, udruženju, društvenom klubu ili nečem sličnom čemu želite posvetiti svoje vrijeme.",
        "Pomoći će vam da se osjećate korisnije, pobudiće dozu kreativnosti i samouvjerenosti, te ujedno napuniti baterije za daljnje poduhvate. Takođe, vlastiti projekat može biti i vaš lični 'online portfolio' koji je izvrstan dodatak CV-ju!",
        "9. Razveselite nekoga",
        "Možda vam ovo zvuči malo čudno, ali psihološka istraživanja dokazuju da je najbolji način da podignete svoje raspoloženje, usrećiti nekog drugog.",
        "Dajte kompliment dragoj osobi, pomozite potpunom strancu ili nekim malim dobrim djelom uljepšajte nekom dan. Osjećat ćete se bolje, usrećiti druge ljude i dobiti nalet pozitivne energije koji vam je potreban.",
        "10. Promijenite lozinku",
        "Da, stvarno to mislimo, promijenite lozinku! Ne nekoliko slova, ne dodavanjem brojeva ili promjenom redoslijeda. Lozinku kreirajte prema svom cilju ili po nečemu što vas motiviše.",
        'Na primjer: "UnapređenjeUIduća3Mjeseca", "PronaćiĆuPosao!" ili "UNovePobjede!".',
        "Lozinka je nešto što vjerovatno utipkate nekoliko puta dnevno kako biste se prijavili na računar ili svoje ostale korisničke račune. Sjajno je započeti dan ukucavanjem rečenice koja vas motiviše, te tu istu radnju ponavljati nekoliko puta dnevno!",
      ],
      link: "/public/blog/post/uz-10-sjajnih-trikova-vratite-volju-za-trazenjem-posla",
      image: "/assets/images/blog/trazenje posla.jpg",
      source: "Izvor: themuse.com",
    },
  ],
  news: [
    {
      title: "Rad na daljinu i dalje dominantan",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Rad na daljinu i dalje dominantan",
      short_description:
        "Rad na daljinu radnicima omogućava veću fleksibilnost u određivanju vremena i mjesta rada, veću autonomiju, poboljšanu ravnotežu radnog vremena i smanjeno vrijeme putovanja na posao. S druge strane, ovaj način rada često rezultira većim intenzitetom i dužim radnim vremenom, što dovodi do osjećaja izolacije i negativno utiče na mentalno zdravlje radnika. Takođe, postoji sve veća upotreba mrežnog nadzora čime se postavlja pitanje privatnosti, a nedostatak prostora za rad i ergonomske opreme dovodi do povećanja zdravstvenih rizika za radnike na daljinu.",
      link: "/public/blog/post/rad-na-daljinu-i-dalje-dominantan",
      image: "/assets/images/blog/rad na daljinu.jpg",
      description: [
        "Rad na daljinu radnicima omogućava veću fleksibilnost u određivanju vremena i mjesta rada, veću autonomiju, poboljšanu ravnotežu radnog vremena i smanjeno vrijeme putovanja na posao. S druge strane, ovaj način rada često rezultira većim intenzitetom i dužim radnim vremenom, što dovodi do osjećaja izolacije i negativno utiče na mentalno zdravlje radnika. Takođe, postoji sve veća upotreba mrežnog nadzora čime se postavlja pitanje privatnosti, a nedostatak prostora za rad i ergonomske opreme dovodi do povećanja zdravstvenih rizika za radnike na daljinu.",
        "Ovo su glavni zaključci studije koje je zatražio Odbor za zapošljavanje i socijalna pitanja (EMPL) Evropskog parlamenta.",
        "Studiju je za Parlament sproveo Sektor za ekonomske, naučne i politike kvaliteta života na temelju opsežnog pregleda literature, web anketa, intervjua i pet studija slučaja u Finskoj, Njemačkoj, Irskoj, Italiji i Rumuniji.",
        "Iako rad na daljinu i digitalni rad nisu novi fenomeni, pandemija je pogurala digitalizaciju i rad na daljinu u područjima u kojima se prethodno malo koristila. Prema podacima Eurofounda, 36,5% radnika počelo je raditi od kuće tokom pandemije, upoređujući s 15,8% prije izbijanja pandemije.",
        "Iako se proširio na mnoga zanimanja i sektore, rad na daljinu i dalje je dominantan među visokoobrazovanim radnicima koji imaju vrlo razvijene digitalne vještine.",
        "Takođe, pokazalo se da, iako postoje mnogi potencijali smanjivanja diskriminacije u društvu, rad na daljinu propustio je otvoriti tržište rada za društveno ugrožene kategorije stanovništva.",
        '"Pandemija je dovela do ogromnog porasta rada na daljinu. Moramo se pripremiti na novu stvarnost. Rad na daljinu bi mogao pomoći osobama s invaliditetom u pronalasku posla. Zabrinuta sam zbog fragmentacije i individualizacije radne snage i poticanja novih nejednakosti na tržištu rada unutar onih koji mogu raditi na daljinu i onih koji ne mogu", izjavila je poslanica u Evropskom parlamentu Sindi Fransen (EPP).',
        "Rezultati studije pokazuju da je u većini zemalja Evropske unije veći udio žena od muškaraca među radnicima od kuće, vjerovatno zbog vrlo visoke učestalosti skraćenog radnog vremena među ženama (75,2%).",
        "Jaz među polovima u radu od kuće među zaposlenima bio je najveći u Francuskoj, Hrvatskoj, Malti, Poljskoj, Rumuniji i Sloveniji, gdje je učestalost među ženama bila dvostruko ili više od one zabilježene kod muškaraca.",
      ],
      source: "Izvor: Jutarnji list",
    },
    {
      title: "Google otkrio kakvog šefa svi vole",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      short_description:
        "Dobar šef mora znati stvoriti sigurno radno okruženje u kojem se niko ne boji da će biti ismijan ili imati posljedice zbog prijedloga rješenja. Dobar menadžer zna podstaći radnike pohvalom, ali i konstruktivnom kritikom. Takođe, mora imati jasno definisanu strategiju timskog rada te znati preuzeti odgovornost za odluke.",
      link: "/public/blog/post/google-otkrio-kakvog-sefa-svi-vole",
      image: "/assets/images/blog/sef koga vole.jpg",
      name: "Google otkrio kakvog šefa svi vole",
      description: [
        "Dobar šef mora znati stvoriti sigurno radno okruženje u kojem se niko ne boji da će biti ismijan ili imati posljedice zbog prijedloga rješenja. Dobar menadžer zna podstaći radnike pohvalom, ali i konstruktivnom kritikom. Takođe, mora imati jasno definisanu strategiju timskog rada te znati preuzeti odgovornost za odluke.",
        "Ako je šef nesposoban, badava sva sredstva koja ulažete u regrutovanje kadrova, ljudi će tražiti prvu priliku da odu. Ako je šef dobar, to podstiče vjernost radnika kompaniji, pokazuje 10-godišnje istraživanje Google-a pod nazivom “Project Oxygen”. Cilj je bio utvrditi što čini dobrog menadžera. Pokazalo se da su tehničke vještine manje važne.",
        "Važnije su one povezane s emocionalnom inteligencijom: da zna slušati i dijeliti informacije i znanje s kolegama.",
      ],
      source: "Izvor: 24sata.hr",
    },
    {
      title:
        "PKCG u Poslovnom savjetu Kine i zemalja centralne i istočne Evrope",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "PKCG u Poslovnom savjetu Kine i zemalja centralne i istočne Evrope",
      short_description:
        "Privredna komora Crne Gore (PKCG) pridružila se Poslovnom savjetu Kine i zemalja Centralne i Istočne Evrope (CIE).",
      description: [
        "Privredna komora Crne Gore (PKCG) pridružila se Poslovnom savjetu Kine i zemalja Centralne i Istočne Evrope (CIE).",
        "Iz PKCG je saopšteno da je riječ o neprofitnoj platformi koja je, od uspostavljanja 2014. godine, ključni dio Mehanizma za saradnju Kine i zemalja CIE i ima cilj da ojača veze poslovnih udruženja, te ekonomske, trgovinske i investicione aktivnosti.",
        "Za sada Poslovni savjet čini 17 organizacija iz 13 zemalja CIE.",
        "Potpredsjednik PKCG Pavle D. Radovanović je kazao da je uvjeren da će kineske i CIE privredne komore doprinijeti jačanju ekonomske, trgovinske i investicione saradnje.",
      ],
      link: "/public/blog/post/pkcg-u-poslovnom-svijetu-kine-i-zemalja-centralne-i-istocne-evrope",
      image: "/assets/images/blog/PKCG Foto PKCG.jpg",
      source: "Izvor: Mina business",
    },

    {
      title: "Smanjuju se paušali turističkih taksi u Hrvatskoj",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Smanjuju se paušali turističkih taksi u Hrvatskoj",
      short_description:
        "Hrvatsko Ministarstvo turizma i sporta (MINTS) donijelo je tri pravilnika kojima će se rasteretiti turistički sistem za više od 118 miliona kuna uz smanjenje paušalne stope turističke takse i članarine, a samim tim i ublažiti posljedice pandemije, izvijestili su u četvrtak iz MINTS-a, ističući da su pravilnici stupili na snagu, prenosi poslovni.hr.",
      description: [
        "Hrvatsko Ministarstvo turizma i sporta (MINTS) donijelo je tri pravilnika kojima će se rasteretiti turistički sistem za više od 118 miliona kuna uz smanjenje paušalne stope turističke takse i članarine, a samim tim i ublažiti posljedice pandemije, izvijestili su u četvrtak iz MINTS-a, ističući da su pravilnici stupili na snagu, prenosi poslovni.hr.",
        "Riječ je o pravilnicima o oslobađanju od plaćanja boravišnih taksi u 2021., o oslobađanju od plaćanja turističke takse za osobe koje koriste uslugu noćenja na plovnom objektu nautičkog turizma (nautičari) te o dopuni Pravilnika o godišnjem paušalnom iznosu članarine za osobe koje pružaju ugostiteljske usluge u domaćinstvu i na porodičnom poljoprivrednom gazdinstvu i o obrascima turističkih zajednica za plaćanje članarine turističkoj zajednici.",
        "Cilj održati sistem stabilnim i otpornijim",
        "Pravilnici su objavljeni u Narodnim novinama, a ministarka turizma i sporta Nikolina Brnjac poručila je da je “cilj održati turistički sistem stabilnim, spremnim za nastavak ove godine, ali i što otpornijim za budućnost”.",
        "Vjeruje da će rasterećenje turističkog sistema s više od 118 miliona kuna “sasvim sigurno doprinijeti lakšem poslovanju hotela, kampova, privatnih iznajmljivača, OPG-ova, kao i nautičkog sektora”.",
        "Oslobađanje plaćanja pola i cijelog godišnjeg ukupnog iznosa takse",
        "Pravilnikom o oslobađanju od plaćanja turističke takse u 2021., osobe koje pružaju ugostiteljske usluge u domaćinstvu ili na OPG-u oslobođene su plaćanja pola godišnjeg paušalnog iznosa turističke takse za glavni krevet, smještajnu jedinicu u kampu i kamp odmorištu te prema kapacitetu u objektu za robinzonski smještaj te u cijelosti za pomoćni krevet za 2021. godinu.",
        "Osobe kojima je do stupanja pravilnika na snagu utvrđen prestanak pružanja ugostiteljskih usluga u domaćinstvu ili na porodičnom poljoprivrednom gazdinstvu ili smanjenje kapaciteta smještajnog objekta, a u 2021. nisu imale evidentirano nijedno noćenje prema podacima sistema eVisitor, oslobađaju se plaćanja godišnjeg paušalnog iznosa turističke takse za 2021. godinu.",
        "Plaćanja godišnjeg paušalnog iznosa turističke takse za 2021. oslobađaju se i osobe čiji su smještajni objekti zbog potresa u 2020. označeni kao neupotrebljivi (crvena oznaka) ili privremeno neupotrebljivi (žuta oznaka), uz uslov da nemaju ranije nepodmirenih obveza po osnovi turističke odnosno boravišne takse.",
        "Od dijela turističke takse oslobađaju se i osobe koje koriste uslugu noćenja u smještajnom objektu u kojem se obavlja ugostiteljska djelatnost, prema prijedlogu kojeg su dale regionalne turističke zajednice za područja svojih županija.",
        "“Procjenjuje se kako će ukupno oslobođenje po osnovi turističke takse za objekte u domaćinstvu i OPG-ove koji pružaju usluge smještaja iznositi oko 105 miliona kuna”, naglašavaju iz MINTS-a.",
        "Za noćenje na plovilima 20 posto manji paušal turističke takse.",
        "Pravilnikom o oslobađanju od plaćanja turističke takse za osobe koje koriste uslugu noćenja na plovnom objektu nautičkog turizma (nautičari), od plaćanja dijela turističke takse oslobađaju se vlasnici plovila i osobe koje koriste uslugu noćenja na plovnom objektu nautičkog turizma (nautičari) i to 20 posto paušalnog iznosa turističke takse koja se plaća prema dužini plovila i vremenskom razdoblju boravka na plovilu.",
        "Godišnji paušal turističke članarine TZ manji 50 posto",
        "Pravilnikom o dopuni Pravilnika o godišnjem paušalnom iznosu članarine za osobe koje pružaju ugostiteljske usluge u domaćinstvu i na porodičnom poljoprivrednom gazdinstvu i o obrascima TZ za plaćanje članarine turističkoj zajednici, u 2021. osobama koje pružaju ugostiteljske usluge u domaćinstvu ili na porodičnom poljoprivrednom gazdinstvu, visina godišnjeg paušalnog iznosa članarine turističkoj zajednici umanjuje se za 50 posto po krevetu odnosno smještajnoj jedinici, dok se za pomoćne krevete oslobađa od plaćanja članarine.",
        "Iznos turističke članarine koja se plaća po registriranom ležaju iznosi 45,00 kuna, uz navedeno oslobođenje od 50 posto, u 2021. taj se iznos umanjuje na 22,50 kuna po ležaju.",
        "Ukupno oslobođenje po osnovi turističke članarine za objekte u domaćinstvu i OPG-ove koji pružaju usluge smještaja iznosiće više od 13 miliona kuna, zaključuju iz MINTS-a.",
      ],
      link: "/public/blog/post/smanjuju-se-pausali-turistickih-taksi-u-hrvatskoj",
      image: "/assets/images/blog/takse hrvatska.jpg",
      source: "Izvor: poslovni.hr",
    },

    {
      title:
        "U BiH najtraženiji informatičari, ekonomisti i mašinski i građevinski inženjeri",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "U BiH najtraženiji informatičari, ekonomisti i mašinski i građevinski inženjeri",
      short_description:
        "Na osnovu uvida u evidencije nezaposlenih u bazi Federalnog zavoda za zapošljavanje Bosne i Hercegovine (FBiH), ali i istraživanja realizovanog kroz tehničku podršku Zavodu kroz projekat “Unaprjeđenje istraživanja tržišta rada” koji finansira Evropska unija, može se reći da su najtraženija zanimanja visoke stručne spreme informatičar, ekonomista, građevinski inženjer, IT developer i mašinski inženjer, saopšteno je iz FBiH.",
      description: [
        "Na osnovu uvida u evidencije nezaposlenih u bazi Federalnog zavoda za zapošljavanje Bosne i Hercegovine (FBiH), ali i istraživanja realizovanog kroz tehničku podršku Zavodu kroz projekat “Unaprjeđenje istraživanja tržišta rada” koji finansira Evropska unija, može se reći da su najtraženija zanimanja visoke stručne spreme informatičar, ekonomista, građevinski inženjer, IT developer i mašinski inženjer, saopšteno je iz FBiH.",
        "S ciljem adekvatnog razumijevanja tržišta rada i planiranja intervencija u okviru ovog projekta, Zavod je realizovao brojne aktivnosti, poput stručne podrške u prikupljanju i analizi podataka, te u povećanju vidljivosti rezultata rada svih zavoda i službi.",
        "“Istraživanje tržišta rada 2020/2021” ponudilo je mnoge druge korisne informacije o kretanjima na tržištu te o potrebama poslodavaca a isto je dostupno na web portalu Zavoda. Posebno zanimljiv dio izvještaja je Pregled broja učenika u srednjim školama u Federaciji BiH u školskoj 2020/2021. godini po smjerovima, gdje se maturanti mogu informisati o stanju u školama u njihovim lokalnim zajednicama, za šta je interes pokazao veliki broj maturanata osnovnih škola.",
        "Ekonomska situacija u zemlji opterećuje mlade ljude koji se žele školovati za zanimanja koja ih zanimaju ali i koja su tražena na tržištu. Zbog toga je važno savjetovati se i donositi informirane odluke.",
        "– Naša preporuka maturantima i maturantkinjama i njihovim roditeljima jeste da se o odabiru zanimanja savjetuju sa našim kolegama i koleginicama koji u kantonalnim službama za zapošljavanje raspolažu sa preciznijim informacijama, te predstavljaju sponu između tražitelja posla na jednoj i poslodavaca na drugoj strani – rekla je direktorica Federalnog zavoda za zapošljavanje Helena Lončar.",
        "U proteklom periodu sprovedene su brojne aktivnosti kojima se Zavod zajedno sa kantonalnim službama posvetio kako bi se kontinuirano, uprkos i posljedicama “Covid-19” pandemije, pomoglo mladim ljudima ali i njihovim roditeljima da dođu do pravih informacija. Posjete školama, prezentacije, razgovori sa direktorima, pedagozima, objavljivanje brošura i publikacija, ažurirane internet stranice, medijske kampanje samo su neki od uobičajenih načina kako se dolazi do mladih ljudi sa informacijama koje ih zanimaju u okviru karijernog savjetovanja.",
      ],
      link: "/public/blog/post/u-bih-najtrazeniji-informaticari-ekonomisti-i-masinski-i-gradjevinski-inzenjeri",
      image: "/assets/images/blog/Trazena zanimanja u BiH.jpg",
      source: "Izvor: poslovi.ba",
    },

    {
      title: "Ilon Mask ulazi u novi biznis - otvara lanac restorana Tesla",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Ilon Mask ulazi u novi biznis - otvara lanac restorana Tesla",
      short_description:
        'Izvršni direktor kompanije "Tesla", Ilon Mask, na korak je od otvaranja lanca restorana, javlja "Blumberg".',
      description: [
        'Izvršni direktor kompanije "Tesla", Ilon Mask, na korak je od otvaranja lanca restorana, javlja "Blumberg".',
        "Ilon Mask ulazi u novi biznis - otvara lanac restorana Tesla",
        'Kako navodi njujorška agencija, "Tesla" je 27. maja podneo zahjtev američkom Zavodu za patente i žigove za upotrebu svog logotipa "T" i još dve verzije stilizovanog logotipa "Tesla" u prehrambenoj industriji.',
        'Sva tri zahtjeva se odnose na primjenu logotipa za "usluge restorana, pop-ap restoranske usluge (služenje hrane sa tezge na točkovima), samouslužne restoranske usluge i usluge hrane za ponijeti".',
        '"Blumberg" podsjeća da Mask već godinama priča o otvaranju restorana u južnoj Kaliforniji, te da je 2018. godine tvitovao da planira da pokrene "drive in" uslugu prodaje hrane, kao i roler i rok restoran na jednoj od novih lokacija "Tesline" Superpunionice za električna vozila u Los Anđelesu.',
        'Ovog aprila je potvrdio napredak u realizaciji te ideje tvitom:"Glavna nova stanica za punjenje stiže uskoro u Santa Moniku! Nadamo se da ćemo otvoriti i restoran iz 50-ih i pustiti 100 najboljih filmskih klipova". Prije toga je sa entuzijazmom najavio da će tamo u ponudi biti kokice zajedno sa "spoljnim ekranom na kojem će se vrtjeti 100 najboljih scena u istoriji filma".',
        'Mask, dakle, namjerava da na svojim stanicama za punjenje električnih automobila ponudi "popratne sadržaje" vozačima da nešto prezalogaje prije, u toku, ili nakon punjenja vozila, i da prekrate vrijeme gledajući najbolje filmske kadrove iz svjetske kinematografije.',
        'Maskov brat Kimbel je, inače, vlasnik kompanije Kičen Restoran Grupa, koja upravlja restoranima u mnogom američkim saveznim državama, uključujući Kolorado, Ilinois i Tenesi, napominje "Blumberg".',
      ],

      link: "/public/blog/post/ilon-mask-ulazi-u-novi-biznis-otvara-lanac-restorana-tesla",
      image: "/assets/images/blog/Ilon Mask.jpg",
      source: "Izvor: Bloomberg.com",
    },

    {
      title: "Ekskluzivni lanac hotela dolazi u Srbiju",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Ekskluzivni lanac hotela dolazi u Srbiju",
      short_description: "Lanac hotela Viceroy Hotels & Resorts dolazi u Srbiju.",
      description: [
        "Lanac hotela Viceroy Hotels & Resorts dolazi u Srbiju.",
        "To je najavio ambasador Srbije u SAD Marko Đurić, nakon razgovora sa direktorkom globalne prodaje tog lanca Sem Torgerson.",
        "- Srećan sam što mogu da najavim da Viceroy Hotels & Resorts dolazi u Srbiju. Velika zahvalnost Sem Torgerson na dobrim vijestima - napisao je Ðurić na svom nalogu na Tviteru.",
        "On je istakao da ta i slične investicije izdvajaju Srbiju kao povoljnu destinaciju za turizam i posao u ovom dijelu Evrope.",
        'Viceroy Hotels & Resorts, ugostiteljski brend koji potiče iz Los Anđelesa, objavio je ranije, kako su prenijeli mediji, da će 2021. otvoriti svoj prvi hotel sa pet zvezdica na evropskom kontinentu - luksuzni "Viceroy Kopaonik Srbija".',
        "Hotel će, prema navodima medija, imati 119 soba i penthaus, ski-bar, restoran, spa-centar, tri sale za sastanke, biblioteku, spoljni džakuzi, dječji klub i ski-servis.",
      ],
      link: "/public/blog/post/ekskluzivni-lanac-hotela-dolazi-u-srbiju",
      image: "/assets/images/blog/Viceroy hotel.jpg",
      source: "",
    },

    {
      title: "Flota Air Montenegra od juna pojačana sa dva aviona",
      category: "VIJESTI",
      date: "2021-07-27T22:00:00.000Z",
      name: "Flota Air Montenegra od juna pojačana sa dva aviona",
      short_description:
        "Air Montenegro će početi sa letovima u junu sa dva aviona. Iz kompanije To Montenegro su saopštili da su, kako bi spremno dočekali planirani saobraćaj i obiman red letjenja, te osigurali putnicima pouzdanost operacija, obezbijedili dodatni kapacitet u svojoj floti.",
      description: [
        "Air Montenegro će početi sa letovima u junu sa dva aviona. Iz kompanije To Montenegro su saopštili da su, kako bi spremno dočekali planirani saobraćaj i obiman red letjenja, te osigurali putnicima pouzdanost operacija, obezbijedili dodatni kapacitet u svojoj floti.",
        "“Pored pripadajućeg aviona registarske oznake 4O-AOA, drugi avion koji se pridružuje floti je iznajmljen od njemačke kompanije German Airways. Radi se o tipu aviona Embraer 190 – kapaciteta 100 sjedišta, koji će opslužiti letačke operacije Air Montenegra dok drugi pripadajući avion registarske oznake 4O – AOB ne uđe u saobraćaj”, naglašavaju.",
        "Iz menadžmenta kompanije poručuju da su posvećeni putnicima i da radu pristupaju veoma detaljno i odgovorno.",
        "“Uvođenje dodatnog aviona je primjer dobre prakse u vazduhoplovstvu da odmah na početku imamo dodatnu sigurnu podršku dok naša flota ne bude kompletirana. Najam aviona je u sklopu našeg planiranog budžeta i odgovara na trenutno interesovanje tržista i planirani red letenja u redovnom i charter saobraćaju”, zaključuju.",
      ],
      link: "/public/blog/post/flota-air-montenegra-od-juna-pojacana-sa-dva-aviona",
      image: "/assets/images/blog/air montenegro.jpg",
      source: "",
    },
  ],
};


export default BlogPosts;