import React from 'react';
import { Link } from "react-router-dom";

import { CandidateAPI } from '../../../api/v2';
import { useUserContext } from '../../../context/user';
import { useLanguageContext } from '../../../context/language';
import {
    Modal,
} from "../../../components/custom";
import { Button } from "../../../components/ui";
import { WarningIcon } from '../../../assets/svg';

import "./style.scss";

const VerificationWarningModal = ({ open, onClose, to, title, subtitle, ...parent }) => {

  const { t } = useLanguageContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="UPOZORENJE"
      size="medium"
      warning={true}
    >
      <div className="cv-dashboard-jobs-apply">
        <div className="cv-dashboard-jobs-apply-warning">
            <div>
                <WarningIcon className="cv-modal-warning-icon" />             
            </div>
            <div className="cv-modal-text-bold">
            {title}
            </div>
            <div className="cv-modal-text">
            {subtitle}
            </div>
        </div>

        <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          className="cv-mb-10"
          component={Link}
          to={to}
        >
          {t`VERIFIKUJTE`}
        </Button>
      </div>
    </Modal>
  );
};

export default VerificationWarningModal;