import React from 'react';
import { logoSource } from '../../assets/images';
import { SimpleCard } from '../../components/ui';
import './style.scss';

const Center = ({ children }) => (
  <div className="cv-centerlayout-main">
    <SimpleCard className="cv-centerlayout-inner">
      <div className="cv-centerlayout-logo">
        <img src={logoSource} alt="CVPRIČA" />
      </div>
      {children}
    </SimpleCard>
  </div>
);

export default Center;