import {
  LocationOn,
  Lock,
  Visibility,
  VisibilityOff,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { Chip, IconButton, ListItem, Paper } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AuthAPI, CandidateAPI, GlobalAPI } from "../../api/v2";
import {
  AdminAutocompleteMultiSelect,
  AdminAutocompleteSelect,
  AdminInput,
  AdminSelect,
  AdminSwitch,
} from "../../components/admin";
import {
  DashTitle,
  DataDisplay,
  UploadImage,
  Modal,
} from "../../components/custom";
import { SimpleCard, Button } from "../../components/ui";
import UserImage from '../../../src/assets/images/nouser5.png';
import { useUserContext } from "../../context/user";
import { delay } from "../../utils/misc";
import { CircularProgressWrapper } from "../../components/ui/icon-button/styles";
import "./style.scss";
import { useLanguageContext } from "../../context/language";

const Settings = () => {
  const { user, setUser } = useUserContext();

  const [state, setState] = useState({
    full_name: user.full_name,
    email: user.email,
    language_id: user.ui_language.id,
  });

  const { t, languages, setLanguage } = useLanguageContext();

  const [passwordModal, setPasswordModal] = useState(false);

  const [view, setView] = useState(0);

  // const [loading, setLoading] = useState(true);

  const onUpload = async (files) => {
    try {
      if (files[0].type.includes("image")) {
        const { profile_image } = await CandidateAPI.updateProfileImage(
          files[0]
        );
        if (profile_image) {
          setState({ ...state, profile_image });
          setUser({ ...user, profile_image });
        }
      } else {
        toast.error(t`Format slike nije podržan.`);
      }
      toast.success(t`Profilna slika je uspešno promenjena`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  const saveChanges = async () => {
    const { full_name, email, turn_notification, ui_language } =
      await CandidateAPI.updateOptionalFields(state);
    setUser({ ...user, full_name, email, ui_language, turn_notification });
    const selectedLanguage = languages.find(
      (lang) => lang.id === parseInt(state.language_id)
    );
    localStorage.setItem("lang", selectedLanguage.code);
    setLanguage(selectedLanguage);
    setView(0);
  };

  // const [uiLanguages, setUiLanguages] = useState([]);

  // const getUiLangs = async () => {
  //   const res = await GlobalAPI.uiLanguages();
  //   setUiLanguages(res.map((x) => ({ value: x.id, label: x.name })));
  //   setLoading(false);
  // };

  const [notificationsData, setNotificationsData] = useState({
    locations: user.city_notifications,
    categories: user.type_of_work_notifications,
    turn_notification: user.turn_notification ? true : false,
  });

  const [notificationsModal, setNotificationsModal] = useState(false);

 // Convert file to blob
 async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}
// End convert

const deleteProfileImage = async () => {
  try{
    const image = await getFileFromUrl(UserImage);
    const { profile_image } = await CandidateAPI.updateProfileImage(image);
    setState({ ...state, profile_image });
    setUser({ ...user, profile_image });
    toast.success(t`Profilna slika je uspešno izbrisana`);
  } catch {
    toast.error(t`Došlo je do greške`);
  }
}

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Podešavanja")}
          // subtitle="Izmjena avatara (maks. dim. 300x300)"
        />
        <div className="cv-dashboard-settings-content ">
          <SimpleCard
            withContent
            className="cv-dashboard-settings-content-side"
          >
            <div className="cv-dashboard-settings-avatar-wrap">
              <div>
                <UploadImage
                  src={user.profile_image}
                  name={user.full_name}
                  onUpload={onUpload}
                  onDelete={deleteProfileImage}
                  className="cv-dashboard-settings-upload"
                />
              </div>
              <div className="cv-data-display-label">
                {t("Izmjena avatara (maks. dim. 300x300)")}
              </div>
            </div>
            <AdminInput
              className="cv-mb-20"
              label={t("Ime korisnika")}
              value={view ? state.full_name : user.full_name}
              disabled={true}
              color="secondary"
              onChange={(full_name) => setState({ ...state, full_name })}
            />
            <AdminInput
              className="cv-mb-20"
              label={t("Email")}
              value={user.email}
              disabled={true}
              color="secondary"
              onChange={(email) => setState({ ...state, email })}
            />
            <AdminSelect
              className="cv-mb-20"
              label={t("Jezik")}
              value={view ? state.language_id : user.ui_language.id}
              disabled={!view}
              options={languages.map((lang) => ({
                value: lang.id,
                label: lang.name,
              }))}
              color="secondary"
              onChange={(language_id) => {
                setState({ ...state, language_id });
              }}
            />
            {!view ? (
              <Button
                className="cv-dashboard-settings-buttons"
                variant="contained"
                color="secondary"
                onClick={() => setView(1)}
                size="large"
              >
                {t("IZMJENI")}
              </Button>
            ) : (
              <Fragment>
                <Button
                  className="cv-mr-20 cv-dashboard-settings-buttons"
                  variant="contained"
                  color="primary"
                  onClick={saveChanges}
                  size="large"
                >
                  {t("SAČUVAJ")}
                </Button>
                <Button
                  className="cv-dashboard-settings-buttons"
                  variant="contained"
                  color="default"
                  onClick={() => setView(0)}
                  size="large"
                >
                  {t("OTKAŽI")}
                </Button>
              </Fragment>
            )}
          </SimpleCard>
          <SimpleCard
            withContent
            className="cv-dashboard-settings-content-side"
          >
            <DataDisplay
              label={t("Datum registracije")}
              value={moment(user.created_at).format("DD.MM.YYYY.")}
            />
            <DataDisplay
              label={t("Izmjeni lozinku")}
              value={"**********"}
              action={
                <IconButton onClick={() => setPasswordModal(true)}>
                  <Lock />
                </IconButton>
              }
            />
            <DataDisplay
              label={t("Notifikacije")}
              value={user.turn_notification ? "Uključene" : "Isključene"}
              action={
                <IconButton onClick={() => setNotificationsModal(true)}>
                  <SettingsIcon />
                </IconButton>
              }
            />
          </SimpleCard>
        </div>
      </div>
      <PasswordModal
        open={passwordModal}
        onClose={() => setPasswordModal(false)}
      />
      <NotificationsModal
        open={notificationsModal}
        onClose={() => setNotificationsModal(false)}
        notificationsData={notificationsData}
        setNotificationsData={setNotificationsData}
      />
    </Fragment>
  );
};

const PasswordModal = ({ open, onClose }) => {
  const [state, setState] = useState({
    old_password: "",
    new_password: "",
    repeat_new_password: "",
  });

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);

  const clearFields = () => {
    setState({
      old_password: "",
      new_password: "",
      repeat_new_password: "",
    });
  };

  const closeClear = () => {
    clearFields();
    onClose();
  };
  const { t } = useLanguageContext();
  const checkFields = () => {
    const { old_password, new_password, repeat_new_password } = state;
    if (!old_password) {
      toast.error(t`Popunite polje za staru lozinku`);
      return;
    }
    if (!new_password) {
      toast.error(t`Popunite polje za novu lozinku`);
      return;
    }
    if (new_password !== repeat_new_password) {
      toast.error(t`Nova i ponovljena nova lozinka nisu iste`);
      return;
    }
    if (
      old_password.length < 8 ||
      old_password.length > 20 ||
      new_password.length < 8 ||
      new_password.length > 20 ||
      repeat_new_password.length < 8 ||
      repeat_new_password.length > 20
    ) {
      toast.error(t`Lozinka mora biti između 8 i 20 karaktera`);
      return;
    }
    changePassword();
  };

  const changePassword = async () => {
    try {
      const response = await AuthAPI.changePassword(state);
      if (response.status === 200) {
        toast.success(`Uspešno promenjena lozinka.`);
      }
      if (response) {
        onClose();
      }
    } catch (e) {
      if (e.response && e.response?.data?.message.includes("Old password")) {
        toast.error(`Stara lozinka nije ispravna.`);
      } else {
        toast.error(`Došlo je do greške na serveru`);
      }
    }
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={closeClear}
        title={t("IZMJENA LOZINKE")}
        size="small"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={checkFields}
          >
            {t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <AdminInput
          className="cv-mb-20"
          label={t("Stara lozinka")}
          value={state.old_password}
          color="secondary"
          type={showOldPassword ? "text" : "password"}
          minLength="8"
          endAdornment={
            <IconButton onClick={() => setShowOldPassword(!showOldPassword)}>
              {showOldPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
          onChange={(old_password) => setState({ ...state, old_password })}
          required
        />
        <AdminInput
          className="cv-mb-20"
          label={t("Nova lozinka")}
          value={state.new_password}
          color="secondary"
          minLength="8"
          type={showNewPassword ? "text" : "password"}
          endAdornment={
            <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
              {showNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
          onChange={(new_password) => setState({ ...state, new_password })}
          required
        />
        <AdminInput
          className="cv-mb-20"
          label={t("Ponovite novu lozinku")}
          value={state.repeat_new_password}
          color="secondary"
          minLength="8"
          type={showRepeatNewPassword ? "text" : "password"}
          endAdornment={
            <IconButton
              onClick={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
            >
              {showRepeatNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          }
          onChange={(repeat_new_password) =>
            setState({ ...state, repeat_new_password })
          }
          required
        />
      </Modal>
    </Fragment>
  );
};

const NotificationsModal = ({ open, onClose, ...props }) => {
  const [state, setState] = useState({
    locations: [],
    categories: [],
    turn_notification: 0,
  });

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (open) {
      if (cities && categories) {
        setState({
          locations: props.notificationsData.locations.map((x) =>
            cities.find((y) => y.value === x.city_id)
          ),
          categories: props.notificationsData.categories.map((x) =>
            categories.find((y) => y.value === x.type_of_work_id)
          ),
          turn_notification: props.notificationsData.turn_notification,
        });
      }
    }
  }, [open, cities, categories]);

  useEffect(() => {
    // getUiLangs();
    setCities(allCities.map((x) => ({ label: x.name, value: x.id })));
    setCategories(type_of_works.map((x) => ({ label: x.name, value: x.id })));
  }, []);
  const { t } = useLanguageContext();
  const {
    user,
    setUser,
    cities: allCities,
    addings: { type_of_works },
  } = useUserContext();

  const handleNotifications = async (e, a) => {
    setState({ ...state, turn_notification: a ? true : false });
  };

  const saveChanges = async () => {
    const res = await CandidateAPI.updateOptionalFields({
      turn_notification: state.turn_notification ? 1 : 0,
      city_notifications_ids: state.locations.map((x) => x.value),
      type_of_work_notifications_ids: state.categories.map((x) => x.value),
    });
    setUser({
      ...user,
      ...res
    })
    props.setNotificationsData({
      ...props.notificationsData,
      locations: res.city_notifications,
      categories: res.type_of_work_notifications,
      turn_notification: res.turn_notification,
    });
    onClose();
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("NOTIFIKACIJE")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={saveChanges}
          >
            {t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <DataDisplay
          label={t("Notifikacije")}
          value={state.turn_notification ? "Uključene" : "Isključene"}
          action={
            <AdminSwitch
              color="secondary"
              checked={state.turn_notification}
              onChange={handleNotifications}
            />
          }
        />
        {user.role === "employee" ? (
          <Fragment>
            <DataDisplay
              label={t("Izaberite gradove u kojima želite da radite")}
              fullWidth
              value={
                <AdminAutocompleteMultiSelect
                  className="cv-mt-20"
                  color="secondary"
                  label="Lokacije"
                  onChange={(locations) =>
                    setState({
                      ...state,
                      locations,
                    })
                  }
                  value={state.locations}
                  options={cities}
                  fullWidth
                  tags
                />
              }
            />
            <DataDisplay
              label={t("Izaberite kategorije posla u kojima želite da radite")}
              fullWidth
              value={
                <AdminAutocompleteMultiSelect
                  className="cv-mt-20"
                  color="secondary"
                  label="Kategorije posla"
                  onChange={(categories) =>
                    setState({
                      ...state,
                      categories,
                    })
                  }
                  value={state.categories}
                  options={categories}
                  fullWidth
                  tags
                />
              }
            />
          </Fragment>
        ) : null}
      </Modal>
    </Fragment>
  );
};

export default Settings;
