import axios from "axios";
import { toast } from "react-toastify";
import { apiBaseUrl } from "../../../constants/project";

export const conversations = (params, user_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/chat/conversations`, {
      params: { ...params, user_id },
    })
    .then((x) => x.data);

export const conversation = (conversation_id, params, user_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/chat/conversations/${conversation_id}`, {
      params: { ...params, user_id },
    })
    .then((x) => x.data);

export const conversationSetSeen = (conversation_id, data, user_id) =>
  axios.patch(
    `${apiBaseUrl}/api/v2/chat/conversations/${conversation_id}/set-seen`,
    {
      ...data,
      user_id,
    }
  );

export const sendMessage = (reciever_id, data, user_id) =>
  axios
    .post(`${apiBaseUrl}/api/v2/chat/send-message/${reciever_id}`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data)
    .catch((e) => {
      console.error(e);
      toast.error("Neuspešno slanje poruke.");
    })

export const sendMessages = (to_user_ids, data, user_id) =>
  axios.post(`${apiBaseUrl}/api/v2/chat/send-messages`, {
    ...data,
    to_user_ids,
    user_id,
  });

const ChatAPI = {
  conversations,
  conversation,
  conversationSetSeen,
  sendMessage,
  sendMessages,
};

export default ChatAPI;
