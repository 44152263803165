import { AddTask, Assignment, Work } from "@mui/icons-material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NotificationAPI } from "../../api/v2";
import { DashTitle } from "../../components/custom";
import { Pagination, SimpleCard } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import { useUserContext } from "../../context/user";
import "./style.scss";

const Notifications = () => {
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
  });

  const [notifications, setNotifications] = useState([]);

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const getNotifications = async () => {
    const { data, meta } = await NotificationAPI.notifications(state);
    setNotifications(data);
    setTotal(meta.total);
  };

  useEffect(() => {
    getNotifications();
  }, [state]);

  const handlePage = (x) => {
    setPage(x);
    setState({ ...state, offset: (x - 1) * state.limit });
  };

  const { user, setUser } = useUserContext();

  const setSeen = (seen, id) => {
    if (!seen) {
      NotificationAPI.notificationSetSeen(id, 1);
      setUser({
        ...user,
        notifications: user.notifications.map((x) =>
          x.id === id ? { ...x, seen: 1 } : x
        ),
      });
    }
  };

  const { t } = useLanguageContext();

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Obaveštenja")}
          subtitle={t("Pogledajte svoja obaveštenja")}
        />
        <SimpleCard
          className="cv-dashboard-notifications-content"
          title={t("Lista svih obaveštenja")}
          withContent
        >
          <div className="cv-dashboard-notifications-all">
            {notifications.map((x) => (
              <Link
                onClick={() => setSeen(x.seen, x.id)}
                to={`${
                  x.type === "single_ad"
                    ? `/dashboard/jobs/job/${x.particular_id}`
                    : ""
                }${
                  x.type === "new_candidate_application"
                    ? `/dashboard/applications/application/${x.particular_id}`
                    : ""
                }${
                  x.type === "edit_profile_notification"
                    ? `/dashboard/cv-profile/edit`
                    : ""
                }`}
                className={`cv-dashboard-notification-single ${
                  !x.seen ? "cv-dashboard-notification-single-unread" : ""
                }`}
              >
                <div className="cv-dashboard-notification-single-left">
                  {x.type === "new_candidate_application" ? (
                    <Assignment />
                  ) : null}
                  {x.type === "single_ad" ? <Work /> : null}
                  {x.type === "edit_profile_notification" ? <AddTask/> : null}
                </div>
                <div className="cv-dashboard-notification-single-right">
                  <div className="cv-dashboard-notification-single-title">
                    {x.title}
                    <span>{moment(x.created_at).fromNow()}</span>
                  </div>
                  <div className="cv-dashboard-notification-single-text">
                    {x.text}
                  </div>
                </div>
              </Link>
            ))}
          </div>
          <div className="cv-dashboard-pagination-holder">
            <Pagination
              page={page}
              setPage={handlePage}
              count={Math.ceil(total / state.limit)}
            />
          </div>
        </SimpleCard>
      </div>
    </Fragment>
  );
};

export default Notifications;
