import React, { Fragment } from "react";
import { LocationHolder } from "../../assets/svg";
import { CardTitle } from "../../components/custom";
import Hero from "../../components/custom/hero";
import { useLanguageContext } from "../../context/language";
import './style.scss';

const Contact = () => {
  const { t, lang } = useLanguageContext();
  return (
    <Fragment>
      <Hero
        img="/assets/images/hero/hero-contact.jpg"
        heroStyle={{ objectPosition: "45% 50%" }}
      />
      <div className="cv-container cv-contact-inner">
        <div className="cv-contact-info">
          <CardTitle
            align="left"
            title={<span className="secondary">Kontakt</span>}
          />
          <div className="cv-contact-info-card">
            <div className="cv-contact-info-card-image">
              <LocationHolder />
              <img src={t`file/images/contact`} alt="Contact" />
            </div>
            <div className="cv-contact-info-text">
              <div className="cv-contact-info-title">
                {t("KONTAKT PODRŠKA")} { lang.name }
              </div>
              {/* <div className="cv-contact-info-description">
                <a href="tel:+382-00-123-456">+382 00 123 456</a>
                <a href="tel:+382-00-123-456">+382 00 123 456</a>
              </div> */}
              <div className="cv-contact-info-description">
                <a href={"mailto: " + t`info/mail`}>{t`info/mail`}</a>
                <a href="https://www.cvprica.com">www.cvprica.com</a>
              </div>
            </div>
          </div>
        </div>
        <div className="cv-contact-image">
          <div className="cv-contact-image-holder">
            <img src="/assets/images/contact/contact.png" alt="Contact" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
