import { FormControl, Select } from "@mui/material";
import { styled } from "@mui/system";

export const SelectWrapper = styled(Select)(() => ({
  width: "100%",
  fontSize: 16,
  borderRadius: 10,
  color: ({ value, disabled }) => (value && !disabled ? "#000" : "#9c9e9f"),
  "& > *": {
    color: "#000",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 10,
  },
}));

export const FormControlWrapper = styled(FormControl)(() => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 10,
  },
}));
