import React, {useRef, useState, useEffect} from "react";
import { toast } from "react-toastify";
import { Divider, Link } from "@mui/material";

import { Modal } from "../../../components/custom";
import { Button, Input } from "../../../components/ui";
import { logoSource } from "../../../assets/images";
import { AuthAPI } from "../../../api/v2";
import { DialogContentWrapper, DialogTitleWrapper } from "../../custom/modal/styles";
import { useLanguageContext } from '../../../context/language'
import { useUserContext } from "../../../context/user";
import "./style.scss";

const VerificationMobileModal = ({ open, onClose, phone, setProfileCompletely }) => {
  const [pin, setPin] = useState(["", "", "", "", "", ""]);

  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];
  const { t } = useLanguageContext();

  const { user, setUser } = useUserContext();

  const processVerification = async () => {
    if (!pin.filter(x => x === '').length) {
      try {
        const res = await AuthAPI.confirmOtp(pin.join(""));
        setUser({...user, ...res})
        setProfileCompletely(res.profile_completely)
        onClose();
      } catch {
      toast.error(t`Došlo je do greške`);
      }
    } else {
      toast.error(t`Popunite polja za verifikaciju`)
    }
  }

  const changeNumber = (v, number) => {
    setPin(pin.map((a, b) => (number === b ? v.slice(-1) : a)));
    if (number < 5 && v) {
      refs[number + 1].current.querySelector("input")?.focus();
    }
  }

  const requestNewCode = async () => {
    try {
      await AuthAPI.sendOtp();
      toast.success(t`Kod je uspešno poslat na broj vašeg telefona`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  useEffect(() => {
    if (open) {
      requestNewCode();
    }
  }, [open])

  return (
    <Modal
      className="cv-dashboard-verificaftion-model-wrapper"
      open={open}
      onClose={onClose}
      hideClose={true}
      custom={true}
      size="small"
    >
      <DialogTitleWrapper>
        <div className="cv-dashboard-verification-modal-content">
          <img src={logoSource} alt="CVPRIČA" />
        </div>
      </DialogTitleWrapper>
      <DialogContentWrapper className="cv-dashboard-verification-modal-removescroll">
        <div className="cv-dashboard-verification-modal-content">
          <div className="cv-dashboard-verification-modal-content-row">
            <p className="cv-dashboard-verification-modal-content-title">
              OTP Verifikacija
            </p>
            <p>{t`Verifikacioni kod je poslat kao SMS`}</p>
            <p>
              {t`na`} <strong>{phone}</strong>
            </p>
          </div>
          <div className="cv-dashboard-verification-modal-content-row">
            <p>
              <strong>{t`UNESITE KOD`}</strong>
            </p>
          </div>
          <div className="cv-dashboard-verification-modal-content-row cv-dashboard-pin">
            {[0, 1, 2, 3, 4, 5].map((x) => (
              <Input
                className="cv-dashboard-pin-single"
                ref={refs[x]}
                value={pin[x]}
                onChange={(v) => changeNumber(v, x)}
              />
            ))}
          </div>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            fullWidth
            className={
              pin.filter((x) => x.trim() === "").length ? "cv-disabled" : ""
            }
            onClick={processVerification}
          >
            {t`VERIFIKUJ`}
          </Button>
        </div>
      </DialogContentWrapper>
      <DialogContentWrapper className="cv-dashboard-verification-modal-removescroll">
        <div className="cv-dashboard-verification-modal-content">
          <p>
            {t`Niste primili kod`}?{" "}
            <Link
              href="#"
              onClick={requestNewCode}
              underline="hover"
              color="secondary"
            >
              {t`Pošalji opet`}!
            </Link>
          </p>
        </div>
      </DialogContentWrapper>
      <Divider />
      <DialogContentWrapper className="cv-dashboard-verification-modal-removescroll">
        <div className="cv-dashboard-verification-modal-content">
          <p>{t`I dalje imate poteškoća sa verifikacijom`}?</p>
          <p>
            {t`Kontaktirajte našu podršku na`}:{" "}
            <Link
              href="mailto:support@cvprica.com"
              underline="hover"
              color="secondary"
            >
              support@cvprica.com
            </Link>
          </p>
        </div>
      </DialogContentWrapper>
    </Modal>
  );
};

export default VerificationMobileModal;