export const genders = [
  { value: 1, label: "Muški" },
  { value: 2, label: "Ženski" },
];

export const statuses = [
  { id: 1, name: "Aktivni" },
  { id: 2, name: "Neaktivni" },
  { id: 3, name: "Arhivirani" },
  { id: 4, name: "Neobjavljeni" },
  { id: 5, name: "Nacrti" },
  { id: 6, name: "Istekli" },
];

export const statusObject = {
  1: "Aktivan",
  2: "Neaktivan",
  3: "Arhiviran",
  4: "Neobjavljen",
  5: "Nacrt",
  6: "Istekao",
};
