import { TextField } from "@mui/material";
import { styled } from "@mui/system";

export const InputWrapper = styled(TextField)(() => ({
  width: "100%",
  fontSize: 16,
  borderRadius: 10,
  "& .MuiOutlinedInput-root": {
    borderRadius: 10,
  },
}));
