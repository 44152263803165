import {
  BusinessCenter,
  Facebook,
  Instagram,
  KeyboardArrowUp,
  LinkedIn,
  PlayArrowRounded,
  RecordVoiceOver,
  YouTube,
} from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import { logoSource } from "../../../assets/images";
import { useLanguageContext } from "../../../context/language";
import "./style.scss";


const socials = [
  { icon: <Facebook />, to: "https://www.facebook.com/cvprica/" },
  { icon: <Instagram />, to: "https://www.instagram.com/cvpricacom/" },
  {
    icon: <YouTube />,
    to: "https://www.youtube.com/channel/UCed6GnH9YHk898zjlw4ASsQ",
  },
  { icon: <LinkedIn />, to: "https://www.linkedin.com/company/cv-prica/" },
];

const scrollTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Footer = () => {
  const { t } = useLanguageContext();

  const links = [
    {
      text: t`Objavite oglas`,
      to: "/dashboard/jobs/new",
      icon: <RecordVoiceOver />,
    },
    {
      text: t`Ispričajte svoju cv priču`,
      to: "/public/cv-story",
      icon: <PlayArrowRounded />,
    },
    { text: t`Pronađite posao`, to: "/public/jobs", icon: <BusinessCenter /> },
  ];

  // const navigation = [
  //   [
  //     { text: t`O nama`, to: "/public/about-us" },
  //     { text: t`Najčešća pitanja`, to: "/public/faq" },
  //     { text: t`Za medije`, to: "/public/for-media" },
  //     { text: t`Uslovi korišćenja`, to: "/public/terms" },
  //   ],
  //   [
  //     { text: t`Politika privatnosti`, to: "/public/privacy-policy" },
  //     { text: t`Kontakt`, to: "/public/contact" },
  //     { text: t`Pronađite posao pretragom`, to: "/public/jobs" },
  //     { text: t`Arhiva poslova`, to: "/public/jobs/archived" },
  //   ],
  //   [
  //     { text: t`Poslovi pute e-mail-a`, to: "/public/jobs/email" },
  //     { text: t`Za poslodavce`, to: "/public/for-company" },
  //     { text: t`Objavite oglas`, to: "/dashboard/jobs/new" },
  //     { text: t`Blog`, to: "/public/blog" },
  //   ],
  //   [
  //     { text: t`Ispričajte svoju CV priču`, to: "/public/cv-story" },
  //     { text: t`Poslodavci`, to: "/public/companies" },
  //     { text: t`Prijava kandidat`, to: "/public/login/employee" },
  //     { text: t`Prijava poslodavac`, to: "/public/login/company" },
  //   ],
  // ];

  const navigation = [
    [
      { text: t`Pretraga poslova`, to: "/public/jobs" },
      { text: t`Poslodavci`, to: "/public/companies" },
      { text: t`Ispričajte svoju cv priču`, to: "/public/cv-story" },
      { text: t`Prijava kandidat`, to: "/public/login/employee" },
    ],
    [
      { text: t`Za poslodavce`, to: "/public/for-companies" },
      { text: t`Objavite oglas`, to: "/dashboard/jobs/new" },
      { text: t`Prijava poslodavac`, to: "/public/login/company" },
    ],
    [
      { text: t`O nama`, to: "/public/about-us" },
      { text: t`Kontakt`, to: "/public/contact" },
      { text: t`Blog`, to: "/public/blog" },
      { text: t`Politika privatnosti`, to: "/public/privacy-policy" }
    ],
  ];

  return (
    <div className="cv-footer-main">
      <div className="cv-container cv-footer-inner">
        <div className="cv-footer-top">
          <div className="cv-footer-top-nav">
            {navigation.map((x, y) => (
              <div className="cv-footer-top-nav-col" key={y}>
                {x.map((a, b) => (
                  <Link className="cv-footer-top-nav-link" to={a.to} key={b}>
                    {a.text}
                  </Link>
                ))}
              </div>
            ))}
          </div>
          <div className="cv-footer-top-misc">
            <Link to="/" className="cv-footer-top-logo">
              <img src={logoSource} alt="CVPRIČA" />
            </Link>
            <div className="cv-footer-top-apps">
              <a
                className="cv-footer-top-app"
                href="https://play.google.com/store/apps/details?id=com.cvprica"
              >
                <div className="cv-footer-top-app-icon">
                  <img
                    src="/assets/images/apps/google-store.png"
                    alt="Google Store"
                  />
                </div>
                <div className="cv-footer-top-app-text">
                  Preuzmite sa <span>Google Store</span>
                </div>
              </a>
              <a className="cv-footer-top-app" href="https://apps.apple.com/app/cv-prica/id1599336108">
                <div className="cv-footer-top-app-icon">
                  <img
                    src="/assets/images/apps/apple-store.png"
                    alt="Apple Store"
                  />
                </div>
                <div className="cv-footer-top-app-text">
                  Preuzmite sa <span>Apple Store</span>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="cv-footer-center">
          {links.map((x, y) => (
            <Link className="cv-footer-center-link" to={x.to} key={y}>
              <div className="cv-footer-center-link-icon">{x.icon}</div>
              <div className="cv-footer-center-link-text">{x.text}</div>
            </Link>
          ))}
        </div>
        <div className="cv-footer-bottom">
          <div className="cv-footer-copyright">
            Copyright © {new Date().getFullYear()} CV Priča. All Rights
            Reserved. Powered by <a href="https://webcenter.me/">Web Centar.</a>
          </div>
          <div className="cv-footer-socials">
            {socials.map((x, y) => (
              <a
                className="cv-footer-social"
                href={x.to}
                target="_blank"
                key={y}
              >
                {x.icon}
              </a>
            ))}
            <div className="cv-footer-scroll-top" onClick={scrollTop}>
              <KeyboardArrowUp />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
