export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const tryParsing = (value) => {
  try {
    const parsed = JSON.parse(value);
    return parsed;
  } catch (e) {
    return value;
  }
};

export const getCurrentParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const newEntries = [...urlSearchParams.entries()].map((x) => [
    x[0],
    tryParsing(x[1])
  ]);
  const params = newEntries.reduce(
    (x, [first, second]) => ({ ...x, [first]: second }),
    {}
  );
  return params;
};

export const removeFalsy = (obj, arr = ["", null, undefined]) => {
  let newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (!arr.includes(obj[prop])) {
      if (Array.isArray(obj[prop])) {
        if (!obj[prop].length) {
          return;
        }
      }
      newObj[prop] = obj[prop];
    }
  });
  return newObj;
};

export const injectQueryParams = (params) => {
  if (window.history.pushState) {
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      params;
    window.history.replaceState({}, '', newurl);
  }
};
