import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useUserContext } from "../../context/user";
import { LoaderLayout } from "../../layouts";

const ProtectedRoute = ({ roles = ["guest"], redirectTo = "/", layout: Layout, ...props }) => {
  const userContext = useUserContext();

  return userContext.fetched ? (
    roles.includes(userContext.user?.role || "guest") ||
    roles.includes("all") ? (
      <Layout>
        <Route {...props} />
      </Layout>
    ) : (
      <Redirect to={redirectTo} />
    )
  ) : (
    <LoaderLayout/>
  );
};

export default ProtectedRoute;
