import axios from "axios";
import { apiBaseUrl } from "../../../constants/project";

export const login = (data) =>
  axios.post(`${apiBaseUrl}/api/v2/login`, data).then((x) => x.data);

export const register = (data) =>
  axios.post(`${apiBaseUrl}/api/v2/register`, data).then((x) => x.data);

export const resetPassword = (data) =>
  axios.post(`${apiBaseUrl}/api/v2/reset-password`, data);

export const logout = () => axios.post(`${apiBaseUrl}/api/v2/logout`);

export const changePassword = async (data) => {
  return axios.post(`${apiBaseUrl}/api/v2/change-password`, data);
};

export const verify = async () => {
  return axios.post(
    `${apiBaseUrl}/api/v2/verification/email/send-verification-email`
  );
};

export const sendOtp = async () => {
  return axios.post(`${apiBaseUrl}/api/v2/verification/phone/send-otp`);
};

export const confirmOtp = (code) =>
  axios
    .post(`${apiBaseUrl}/api/v2/verification/phone/confirm-otp`, { code })
    .then((x) => x.data.data);

export const googleAuth = (data) =>
  axios
    .post(`${apiBaseUrl}/api/v2/auth/google/verify-token`, data)
    .then((x) => x.data.data);

const AuthAPI = {
  login,
  register,
  resetPassword,
  logout,
  changePassword,
  verify,
  sendOtp,
  confirmOtp,
  googleAuth,
};

export default AuthAPI;
