import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
 
export const CircularProgressMainWrapper = styled('div')({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})

export const CircularProgressWrapper = styled(CircularProgress)(({ theme }) => ({
  "& .MuiCircularProgress-circle": {
    color: theme.palette.primary.dark,
  },
}));

export const CircularProgressLabel = styled('div')(({theme}) => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    color: theme.palette.secondary.main,
    fontSize: 32,
    fontWeight: 700
}))