const Advice1 = () => (
  <svg viewBox="0 0 105 103">
    <path
      d="M40.16 102.93C29.85 102.93 21.52 94.59 21.52 84.28C21.52 73.96 29.85 65.63 40.16 65.63C50.48 65.63 58.81 73.96 58.81 84.28C58.81 94.59 50.48 102.93 40.16 102.93Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M16.57 48.98C7.73999 48.98 0.609985 41.85 0.609985 33.02C0.609985 24.19 7.73999 17.05 16.57 17.05C25.4 17.05 32.54 24.19 32.54 33.02C32.54 41.85 25.4 48.98 16.57 48.98Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M78.37 54.25C63.64 54.25 51.74 42.35 51.74 27.62C51.74 12.89 63.64 0.98999 78.37 0.98999C93.09 0.98999 105 12.89 105 27.62C105 42.35 93.09 54.25 78.37 54.25Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M76.6199 8.10001H11.9799C11.4299 8.10001 11.0099 8.52001 11.0099 9.07001V92.29C11.0099 92.84 11.4299 93.26 11.9799 93.26H76.6199C77.1699 93.26 77.5899 92.84 77.5899 92.29V9.07001C77.5899 8.55001 77.1699 8.10001 76.6199 8.10001Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M69.29 8.07001H12.01C11.46 8.07001 11.04 8.49001 11.04 9.04001V87.93H68.32C68.87 87.93 69.29 87.51 69.29 86.96V8.07001Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.62 94.26H15.02C12.27 94.26 10.04 92.03 10.04 89.29V12.04C10.04 9.29 12.27 7.06 15.02 7.06H73.58C76.33 7.06 78.56 9.29 78.56 12.04V89.25C78.59 92.03 76.36 94.26 73.62 94.26ZM15.02 9.07C13.37 9.07 12.01 10.43 12.01 12.07V89.29C12.01 90.93 13.37 92.29 15.02 92.29H73.58C75.23 92.29 76.59 90.93 76.59 89.29V12.04C76.59 10.39 75.23 9.04 73.58 9.04H15.02V9.07Z"
      fill="#21365F"
    ></path>
    <path
      d="M61.63 8.10001H27.01V16.47C27.01 17.02 27.43 17.44 27.98 17.44H60.66C61.21 17.44 61.63 17.02 61.63 16.47V8.10001Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M57.62 8.10001H27.01V12.78C27.01 13.14 27.3 13.43 27.66 13.43H57.29C57.49 13.43 57.62 13.3 57.62 13.11V8.10001Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.6199 18.44H30.9899C28.2399 18.44 26.0099 16.21 26.0099 13.46V8.13C26.0099 7.58 26.4599 7.13 27.0099 7.13H61.63C62.18 7.13 62.63 7.58 62.63 8.13V13.46C62.63 16.18 60.3999 18.44 57.6199 18.44ZM28.0099 9.10001V13.43C28.0099 15.08 29.3699 16.44 31.0199 16.44H57.65C59.3 16.44 60.66 15.08 60.66 13.43V9.10001H28.0099Z"
      fill="#21365F"
    ></path>
    <path
      d="M19.71 33.37L26.4 33.34C26.75 33.34 27.04 33.05 27.04 32.69L27.01 26C27.01 25.65 26.72 25.36 26.36 25.36L19.67 25.39C19.32 25.39 19.03 25.68 19.03 26.04L19.06 32.73C19.06 33.08 19.35 33.37 19.71 33.37Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M19.06 30.69H23.04C23.78 30.69 24.36 30.08 24.36 29.36L24.33 25.39H21.71C20.22 25.39 19.06 26.59 19.06 28.07V30.69Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.06 30.72V28.07C18.06 27.1 18.41 26.17 19.12 25.49C19.8 24.81 20.74 24.42 21.71 24.39C21.71 24.39 24.36 24.39 24.39 24.39C26.4 24.39 28.05 26 28.05 28.04V30.69C28.05 31.66 27.69 32.6 26.98 33.28C26.3 33.95 25.36 34.34 24.39 34.37H21.71C19.71 34.37 18.06 32.76 18.06 30.72ZM24.36 26.36H21.68C21.23 26.36 20.81 26.52 20.51 26.84C20.19 27.17 20.03 27.59 20.03 28.01V30.66C20.03 31.56 20.77 32.31 21.68 32.31H24.33C24.78 32.31 25.2 32.14 25.49 31.82C25.81 31.5 25.98 31.08 25.98 30.66V28.01C26.01 27.1 25.27 26.36 24.36 26.36Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.62 82.27C60.59 82.27 49.99 71.67 49.99 58.65C49.99 45.62 60.59 35.02 73.62 35.02C86.64 35.02 97.24 45.62 97.24 58.65C97.24 71.67 86.64 82.27 73.62 82.27ZM73.62 37.03C61.69 37.03 51.99 46.72 51.99 58.65C51.99 70.57 61.69 80.27 73.62 80.27C85.54 80.27 95.24 70.57 95.24 58.65C95.24 46.72 85.54 37.03 73.62 37.03Z"
      fill="#21365F"
    ></path>
    <path
      d="M73.62 81.27C61.11 81.27 50.99 71.16 50.99 58.65C50.99 46.14 61.11 36.02 73.62 36.02C86.13 36.02 96.24 46.14 96.24 58.65C96.24 71.16 86.13 81.27 73.62 81.27Z"
      fill="white"
    ></path>
    <path
      d="M86.71 40.22C89.36 43.94 90.91 48.43 90.91 53.31C90.91 65.82 80.76 75.94 68.28 75.94C63.4 75.94 58.88 74.39 55.19 71.74C59.3 77.49 66.02 81.27 73.62 81.27C86.12 81.27 96.24 71.12 96.24 58.65C96.24 51.05 92.46 44.33 86.71 40.22Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.62 82.27C60.59 82.27 49.99 71.67 49.99 58.65C49.99 45.62 60.59 35.02 73.62 35.02C86.64 35.02 97.24 45.62 97.24 58.65C97.24 71.67 86.64 82.27 73.62 82.27ZM73.62 37.03C61.69 37.03 51.99 46.72 51.99 58.65C51.99 70.57 61.69 80.27 73.62 80.27C85.54 80.27 95.24 70.57 95.24 58.65C95.24 46.72 85.54 37.03 73.62 37.03Z"
      fill="#21365F"
    ></path>
    <path
      d="M73.62 43.39L78.14 52.54L88.26 54.03L80.92 61.17L82.67 71.25L73.62 66.47L64.57 71.25L66.28 61.17L58.98 54.03L69.09 52.54L73.62 43.39Z"
      fill="white"
    ></path>
    <path
      d="M80.92 61.17L88.26 54.03L82.92 53.25L77.66 58.36C76.82 59.16 76.46 60.33 76.65 61.49L77.88 68.73L82.67 71.25L80.92 61.17Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.57 72.25C64.37 72.25 64.15 72.19 63.99 72.06C63.66 71.83 63.53 71.45 63.6 71.09L65.25 61.52L58.3 54.77C58.04 54.51 57.94 54.09 58.04 53.73C58.17 53.38 58.46 53.12 58.85 53.06L68.45 51.67L72.74 42.97C73.07 42.29 74.2 42.29 74.52 42.97L78.82 51.67L88.42 53.06C88.81 53.12 89.1 53.38 89.23 53.73C89.36 54.09 89.26 54.48 88.97 54.77L82.02 61.52L83.67 71.09C83.73 71.48 83.57 71.83 83.28 72.06C82.99 72.29 82.57 72.32 82.21 72.12L73.62 67.6L65.02 72.12C64.89 72.19 64.73 72.25 64.57 72.25ZM73.62 65.47C73.78 65.47 73.94 65.5 74.07 65.6L81.34 69.41L79.95 61.33C79.89 61.01 80.02 60.68 80.24 60.46L86.12 54.74L78.01 53.57C77.69 53.54 77.4 53.31 77.27 53.02L73.65 45.65L69.96 52.99C69.8 53.28 69.54 53.48 69.22 53.54L61.11 54.7L66.99 60.42C67.22 60.65 67.35 60.97 67.28 61.3L65.89 69.38L73.16 65.56C73.29 65.53 73.46 65.47 73.62 65.47Z"
      fill="#21365F"
    ></path>
    <path
      d="M64.28 27.36H54.97C54.42 27.36 53.97 26.91 53.97 26.36C53.97 25.81 54.42 25.36 54.97 25.36H64.28C64.83 25.36 65.28 25.81 65.28 26.36C65.28 26.91 64.83 27.36 64.28 27.36Z"
      fill="#21365F"
    ></path>
    <path
      d="M49.63 27.36H32.34C31.79 27.36 31.34 26.91 31.34 26.36C31.34 25.81 31.79 25.36 32.34 25.36H49.63C50.18 25.36 50.64 25.81 50.64 26.36C50.64 26.91 50.18 27.36 49.63 27.36Z"
      fill="#21365F"
    ></path>
    <path
      d="M44.3 33.34H32.31C31.76 33.34 31.31 32.89 31.31 32.34C31.31 31.79 31.76 31.34 32.31 31.34H44.3C44.85 31.34 45.3 31.79 45.3 32.34C45.3 32.89 44.88 33.34 44.3 33.34Z"
      fill="#21365F"
    ></path>
    <path
      d="M19.71 49.34L26.4 49.31C26.75 49.31 27.04 49.02 27.04 48.66L27.01 41.97C27.01 41.61 26.72 41.32 26.36 41.32L19.67 41.36C19.32 41.36 19.03 41.65 19.03 42L19.06 48.69C19.06 49.05 19.35 49.34 19.71 49.34Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M19.06 46.66H23.04C23.78 46.66 24.36 46.04 24.36 45.33L24.33 41.36H21.71C20.22 41.36 19.06 42.55 19.06 44.04V46.66Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.06 46.69V44.04C18.06 43.07 18.41 42.13 19.12 41.45C19.8 40.74 20.74 40.39 21.71 40.35C21.71 40.35 24.36 40.35 24.39 40.35C26.4 40.35 28.05 41.97 28.05 44.01V46.66C28.05 47.63 27.69 48.56 26.98 49.24C26.3 49.92 25.36 50.31 24.39 50.34H21.71C19.71 50.34 18.06 48.72 18.06 46.69ZM24.36 42.33H21.68C21.23 42.33 20.81 42.52 20.51 42.81C20.19 43.13 20.03 43.55 20.03 43.97V46.62C20.03 47.53 20.77 48.27 21.68 48.27H24.33C24.78 48.27 25.2 48.08 25.49 47.79C25.81 47.46 25.98 47.04 25.98 46.62V43.97C26.01 43.07 25.27 42.33 24.36 42.33Z"
      fill="#21365F"
    ></path>
    <path
      d="M49.63 43.33H32.34C31.79 43.33 31.34 42.87 31.34 42.33C31.34 41.78 31.79 41.32 32.34 41.32H49.63C50.18 41.32 50.64 41.78 50.64 42.33C50.64 42.87 50.18 43.33 49.63 43.33Z"
      fill="#21365F"
    ></path>
    <path
      d="M41.65 49.34H32.34C31.79 49.34 31.34 48.89 31.34 48.34C31.34 47.79 31.79 47.34 32.34 47.34H41.65C42.2 47.34 42.65 47.79 42.65 48.34C42.65 48.89 42.2 49.34 41.65 49.34Z"
      fill="#21365F"
    ></path>
    <path
      d="M19.71 65.34L26.4 65.31C26.75 65.31 27.04 65.01 27.04 64.66L27.01 57.97C27.01 57.61 26.72 57.32 26.36 57.32L19.67 57.35C19.32 57.35 19.03 57.65 19.03 58L19.06 64.69C19.06 65.05 19.35 65.34 19.71 65.34Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M19.06 62.65H23.04C23.78 62.65 24.36 62.04 24.36 61.33L24.33 57.35H21.71C20.22 57.35 19.06 58.55 19.06 60.04V62.65Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.06 62.65V60C18.06 59.04 18.41 58.1 19.12 57.42C19.8 56.71 20.74 56.35 21.71 56.32C21.71 56.32 24.36 56.32 24.39 56.32C26.4 56.32 28.05 57.94 28.05 59.97V62.62C28.05 63.59 27.69 64.53 26.98 65.21C26.3 65.92 25.36 66.27 24.39 66.31H21.71C19.71 66.31 18.06 64.69 18.06 62.65ZM24.36 58.32H21.68C21.23 58.32 20.81 58.52 20.51 58.81C20.19 59.13 20.03 59.55 20.03 59.97V62.62C20.03 63.53 20.77 64.27 21.71 64.27H24.36C24.81 64.27 25.23 64.11 25.52 63.79C25.85 63.46 26.01 63.04 26.01 62.62V59.97C26.01 59.07 25.27 58.32 24.36 58.32Z"
      fill="#21365F"
    ></path>
    <path
      d="M45.66 59.33H32.34C31.79 59.33 31.34 58.87 31.34 58.32C31.34 57.77 31.79 57.32 32.34 57.32H45.66C46.21 57.32 46.66 57.77 46.66 58.32C46.66 58.87 46.21 59.33 45.66 59.33Z"
      fill="#21365F"
    ></path>
    <path
      d="M41.65 65.31H32.34C31.79 65.31 31.34 64.85 31.34 64.3C31.34 63.75 31.79 63.3 32.34 63.3H41.65C42.2 63.3 42.65 63.75 42.65 64.3C42.65 64.85 42.2 65.31 41.65 65.31Z"
      fill="#21365F"
    ></path>
    <path
      d="M19.71 81.3L26.4 81.27C26.75 81.27 27.04 80.98 27.04 80.63L27.01 73.93C27.01 73.58 26.72 73.29 26.36 73.29L19.67 73.32C19.32 73.32 19.03 73.61 19.03 73.97L19.06 80.66C19.06 81.01 19.35 81.3 19.71 81.3Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M19.06 78.62H23.04C23.78 78.62 24.36 78.01 24.36 77.3L24.33 73.32H21.71C20.22 73.32 19.06 74.52 19.06 76V78.62Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.06 78.65V76C18.06 75.03 18.41 74.1 19.12 73.42C19.8 72.74 20.74 72.35 21.71 72.32C21.71 72.32 24.36 72.32 24.39 72.32C26.4 72.32 28.05 73.93 28.05 75.97V78.62C28.05 79.59 27.69 80.53 26.98 81.21C26.3 81.89 25.36 82.27 24.39 82.31H21.71C19.71 82.31 18.06 80.69 18.06 78.65ZM24.36 74.29H21.68C21.23 74.29 20.81 74.45 20.51 74.77C20.19 75.1 20.03 75.52 20.03 75.94V78.59C20.03 79.49 20.77 80.24 21.68 80.24H24.33C24.78 80.24 25.2 80.08 25.49 79.75C25.81 79.43 25.98 79.01 25.98 78.59V75.94C26.01 75.03 25.27 74.29 24.36 74.29Z"
      fill="#21365F"
    ></path>
    <path
      d="M49.63 75.29H44.14C43.59 75.29 43.14 74.84 43.14 74.29C43.14 73.74 43.59 73.29 44.14 73.29H49.63C50.18 73.29 50.64 73.74 50.64 74.29C50.64 74.84 50.18 75.29 49.63 75.29Z"
      fill="#21365F"
    ></path>
    <path
      d="M38.84 75.29H32.34C31.79 75.29 31.34 74.84 31.34 74.29C31.34 73.74 31.79 73.29 32.34 73.29H38.84C39.39 73.29 39.84 73.74 39.84 74.29C39.84 74.84 39.39 75.29 38.84 75.29Z"
      fill="#21365F"
    ></path>
    <path
      d="M45.66 81.27H32.34C31.79 81.27 31.34 80.82 31.34 80.27C31.34 79.72 31.79 79.27 32.34 79.27H45.66C46.21 79.27 46.66 79.72 46.66 80.27C46.66 80.82 46.21 81.27 45.66 81.27Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.1 17.05H88.26C87.71 17.05 87.26 16.6 87.26 16.05C87.26 15.5 87.71 15.05 88.26 15.05H90.1C90.65 15.05 91.1 15.5 91.1 16.05C91.1 16.6 90.65 17.05 90.1 17.05Z"
      fill="#21365F"
    ></path>
    <path
      d="M96.24 17.05H94.4C93.85 17.05 93.4 16.6 93.4 16.05C93.4 15.5 93.85 15.05 94.4 15.05H96.24C96.79 15.05 97.24 15.5 97.24 16.05C97.24 16.6 96.79 17.05 96.24 17.05Z"
      fill="#21365F"
    ></path>
    <path
      d="M92.23 21.03C91.68 21.03 91.23 20.57 91.23 20.02V18.18C91.23 17.63 91.68 17.18 92.23 17.18C92.78 17.18 93.24 17.63 93.24 18.18V20.02C93.24 20.57 92.81 21.03 92.23 21.03Z"
      fill="#21365F"
    ></path>
    <path
      d="M92.23 14.89C91.68 14.89 91.23 14.43 91.23 13.88V12.04C91.23 11.49 91.68 11.04 92.23 11.04C92.78 11.04 93.24 11.49 93.24 12.04V13.88C93.24 14.43 92.81 14.89 92.23 14.89Z"
      fill="#21365F"
    ></path>
    <path
      d="M84.77 31.69H82.92C82.38 31.69 81.92 31.24 81.92 30.69C81.92 30.14 82.38 29.69 82.92 29.69H84.77C85.32 29.69 85.77 30.14 85.77 30.69C85.77 31.24 85.32 31.69 84.77 31.69Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.91 31.69H89.07C88.52 31.69 88.06 31.24 88.06 30.69C88.06 30.14 88.52 29.69 89.07 29.69H90.91C91.46 29.69 91.91 30.14 91.91 30.69C91.91 31.24 91.46 31.69 90.91 31.69Z"
      fill="#21365F"
    ></path>
    <path
      d="M86.93 35.67C86.38 35.67 85.93 35.22 85.93 34.67V32.82C85.93 32.27 86.38 31.82 86.93 31.82C87.48 31.82 87.93 32.27 87.93 32.82V34.67C87.93 35.25 87.48 35.67 86.93 35.67Z"
      fill="#21365F"
    ></path>
    <path
      d="M86.93 29.53C86.38 29.53 85.93 29.07 85.93 28.52V26.68C85.93 26.13 86.38 25.68 86.93 25.68C87.48 25.68 87.93 26.13 87.93 26.68V28.52C87.93 29.07 87.48 29.53 86.93 29.53Z"
      fill="#21365F"
    ></path>
    <path
      d="M88.77 86.28H86.93C86.38 86.28 85.93 85.83 85.93 85.28C85.93 84.73 86.38 84.28 86.93 84.28H88.77C89.32 84.28 89.78 84.73 89.78 85.28C89.78 85.83 89.32 86.28 88.77 86.28Z"
      fill="#21365F"
    ></path>
    <path
      d="M94.92 86.28H93.07C92.52 86.28 92.07 85.83 92.07 85.28C92.07 84.73 92.52 84.28 93.07 84.28H94.92C95.46 84.28 95.92 84.73 95.92 85.28C95.92 85.83 95.46 86.28 94.92 86.28Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.91 90.26C90.36 90.26 89.91 89.8 89.91 89.25V87.41C89.91 86.86 90.36 86.41 90.91 86.41C91.46 86.41 91.91 86.86 91.91 87.41V89.25C91.91 89.84 91.46 90.26 90.91 90.26Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.91 84.12C90.36 84.12 89.91 83.66 89.91 83.11V81.27C89.91 80.72 90.36 80.27 90.91 80.27C91.46 80.27 91.91 80.72 91.91 81.27V83.11C91.91 83.66 91.46 84.12 90.91 84.12Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Advice1;