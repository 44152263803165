import axios from "axios";
import { toFormData } from "../../../utils/converter";
import { apiBaseUrl } from "../../../constants/project";
import { toast } from "react-toastify";

export const companies = (params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/companies`, {
      params,
    })
    .then((x) => x.data.data);

export const dashboard = (year, params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/company/dashboard`, {
      params: { year, ...params },
    })
    .then((x) => x.data.data);

export const info = () =>
  axios.get(`${apiBaseUrl}/api/v2/company/info`).then((x) => x.data.data);

export const update = (data, user_id) =>
  axios
    .patch(`${apiBaseUrl}/api/v2/company/update`, { ...data, user_id })
    .then((x) => x.data.data);

export const updateProfileVideo = (video, user_id) =>
  axios
    .post(
      `${apiBaseUrl}/api/v2/company/update-video`,
      toFormData({
        video,
        user_id,
      })
    )
    .then((x) => x.data.data);

export const updateProfileImage = (image, user_id) =>
  axios
    .post(
      `${apiBaseUrl}/api/v2/company/fields/update-profile-image`,
      toFormData({
        image,
        user_id,
      })
    )
    .then((x) => x.data.data);

export const updateBackgroundImage = (image, user_id) =>
  axios
    .post(
      `${apiBaseUrl}/api/v2/company/fields/update-background-image`,
      toFormData({
        image,
        user_id,
      })
    )
    .then((x) => x.data.data);

export const deactivate = (user_id) =>
  axios.patch(`${apiBaseUrl}/api/v2/company/deactivate`, { user_id });

export const companyAdsList = (params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/company/jobs`, { params })
    .then((x) => x.data);

export const createCompanyAd = (data) =>
  axios
    .post(`${apiBaseUrl}/api/v2/company/jobs`, data)
    .then((x) => x.data.data);

export const updateCompanyAd = (ad_id, data) =>
  axios.patch(`${apiBaseUrl}/api/v2/company/jobs/${ad_id}`, data);

export const deleteCompanyAds = (ids) =>
  axios.delete(`${apiBaseUrl}/api/v2/company/jobs`, { data: { ids } });

export const archiveCompanyAds = (ids) =>
  axios.post(`${apiBaseUrl}/api/v2/company/jobs/archive`, {  ids });

export const unarchiveCompanyAds = (ids) =>
  axios.post(`${apiBaseUrl}/api/v2/company/jobs/reset`, { ids  });

export const companyAdApplicationsList = (ad_id, params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/company/jobs/${ad_id}/applications`, {
      params,
    })
    .then((x) => x.data);

export const companyAdSelectApplications = (ad_id, ids) =>
  axios
    .post(`${apiBaseUrl}/api/v2/company/jobs/${ad_id}/applications/select`, {
      ids,
    })
    .then((response) => {
      toast.success("Uspešno dodavanje kandidata.");
      return response;
    })
    .catch((e) => {
      console.error(e);
      toast.error("Došlo je do greške na serveru.")
    });

export const companyAdDeselectApplications = (ids) =>
  axios
    .post(`${apiBaseUrl}/api/v2/company/jobs/applications/deselect`, {
      ids,
    })
    .then((response) => {
      toast.success("Uspešno uklanjanje kandidata.");
      return response;
    })
    .catch((e) => {
      console.error(e);
      toast.error("Došlo je do greške na serveru.")
    });

export const companyApplicationSetEminent = (
  ad_id,
  application_id,
  is_eminent
) =>
  axios.patch(
    `${apiBaseUrl}/api/v2/company/jobs/${ad_id}/applications/${application_id}/set-eminent`,
    {
      is_eminent,
    }
  );

export const showJobs = (ids) =>
  axios.post(`${apiBaseUrl}/api/v2/company/jobs/show`, {
    ids,
  });

export const hideJobs = (ids) =>
  axios.post(`${apiBaseUrl}/api/v2/company/jobs/hide`, {
    ids,
  });

export const publicCompanySearch = (params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/companies`, { params })
    .then((x) => x.data);

export const selectedApplicationsList = (params) =>
  axios.get(`${apiBaseUrl}/api/v2/company/jobs/selected-applications`, {
    params,
  }).then((response) => response.data);

export const companyQuestions = () =>
  axios.get(`${apiBaseUrl}/api/v2/company/questions`);

export const companyQuestion = (question_id) =>
  axios.get(`${apiBaseUrl}/api/v2/company/questions/${question_id}`);

export const createCompanyQuestion = (question) =>
  axios.post(`${apiBaseUrl}/api/v2/company/questions`, { question });

export const deleteCompanyQuestions = (ids) =>
  axios.delete(`${apiBaseUrl}/api/v2/company/questions`, { data: { ids } });

export const updateCompanyQuestion = (question_id, question) =>
  axios.patch(`${apiBaseUrl}/api/v2/company/questions/${question_id}`, {
    question,
  });

export const companyInfo = (company_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/company/${company_id}/info`)
    .then((x) => x.data.data);

export const companySingleApplication = (ad_id, application_id) =>
  axios.get(
    `${apiBaseUrl}/api/v2/company/jobs/${ad_id}/applications/${application_id}`
  );

export const applicationShow = (application_id, user_id) => 
    axios.get(`${apiBaseUrl}/api/v2/company/applications/${application_id}`, {
      params: {
        user_id,
      },
    }).then(x => x.data.data);

export const companyInfoPublic = (company_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/company/${company_id}/info`)
    .then((x) => x.data.data);

export const setEminentStatus = (company_id, is_eminent) =>
  axios.post(`${apiBaseUrl}/api/v2/admin/companies/${company_id}/set-eminent`, {
    is_eminent,
  });

const CompanyAPI = {
  companies,
  dashboard,
  info,
  update,
  updateProfileVideo,
  updateProfileImage,
  updateBackgroundImage,
  deactivate,
  companyAdsList,
  deleteCompanyAds,
  archiveCompanyAds,
  unarchiveCompanyAds,
  companyAdApplicationsList,
  companyAdSelectApplications,
  companyApplicationSetEminent,
  showJobs,
  hideJobs,
  createCompanyAd,
  updateCompanyAd,
  selectedApplicationsList,
  publicCompanySearch,
  companyQuestions,
  companyQuestion,
  createCompanyQuestion,
  deleteCompanyQuestions,
  updateCompanyQuestion,
  companyInfo,
  companySingleApplication,
  companyInfoPublic,
  companyAdDeselectApplications,
  setEminentStatus,
  applicationShow,
};

export default CompanyAPI;
