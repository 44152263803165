import {
  Add,
  Edit,
  Favorite,
  Folder,
  Search,
  SendRounded,
  Tune,
  Visibility,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CandidateAPI, CompanyAPI, JobAPI } from "../../../api/v2";
import {
  AdminAutocompleteSelect,
  AdminSelect,
} from "../../../components/admin";
import {
  DashTitle,
  Table,
  Modal,
  Status,
  DisplayCard,
} from "../../../components/custom";
import Spinner from "../../../components/custom/spinner";
import {
  Button,
  Pagination,
  Select,
  Input,
  Switch,
} from "../../../components/ui";
import {
  statuses as allStatuses,
  statusObject,
} from "../../../constants/selects";
import { useEventContext } from "../../../context/event";
import { useLanguageContext } from "../../../context/language";
import { useUserContext } from "../../../context/user";
import { removeFalsy } from "../../../utils/misc";
import "./style.scss";
import { EmptyTableWrapper } from "../../../components/custom/table/styles";
import { SentimentVeryDissatisfied } from "@mui/icons-material";

const tableHead = [
  { key: "ad", id: 1, name: "Naslov oglasa" },
  { key: "type", id: 2, name: "Tip posla" },
  { key: "loc", id: 3, name: "Lokacija" },
  { key: "ends", id: 4, name: "Ističe" },
  { key: "status", id: 5, name: "Status" },
  { key: "number_of_applications", id: 6, name: "Prijava" },
  { key: "toggle", id: 7, name: "Prikaži" },
  { key: "edit", id: 8, name: "Izmeni" },
];

const PostedJobs = () => {
  const [state, setState] = useState({
    term: "",
    limit: 5,
    offset: 0,
    is_active: 1,
  });
  const [search, setSearch] = useState("")

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setState({...state, search});
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [search])
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [sort, setSort] = useState({
    order_by: "id",
    order_dir: "asc",
  });
  const [selection, setSelection] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({
    city_id: "",
    type_of_work_id: "",
  });

  const [application, setApplication] = useState(0);
  const [loading, setLoading] = useState(false);

  const requestSort = () => {};

  const updateToggle = async (a, b) => {
    if (a) {
      await CompanyAPI.showJobs([b]);
    } else {
      await CompanyAPI.hideJobs([b]);
    }
  };

  const getAllAds = async () => {
    setLoading(true);
    const { meta, data } = await CompanyAPI.companyAdsList(
      removeFalsy({
        ...state,
        ...modalState,
      })
    ).catch(err => {
      setLoading(false)
    });
    setTotal(meta.total);
    const formattedJobs = data.map(
      ({
        id,
        type_of_work,
        company,
        title,
        end_date,
        city,
        country,
        status,
        applied,
        visible,
        number_of_applications,
        ...x
      }) => ({
        id,
        to: `/dashboard/jobs/job/${id}`,
        image: company.profile_image,
        title,
        ad: (
          <Link
            to={`/dashboard/jobs/job/${id}`}
            className="cv-dashboard-jobs-job-single"
          >
            <div className="cv-dashboard-jobs-job-single-image">
              <img src={company.profile_image} />
            </div>
            <div className="cv-dashboard-jobs-job-single-info">
              <div className="cv-dashboard-jobs-job-single-info-title">
                {title}
              </div>
              <div className="cv-dashboard-jobs-job-single-info-company">
                {company.full_name}
              </div>
            </div>
          </Link>
        ),
        number_of_applications:
          number_of_applications > 0 ? (
            <Link
              to={`/dashboard/jobs/job/${id}/applications`}
              className="cv-dashboard-jobs-job-single-info-applications"
            >
              {number_of_applications}
            </Link>
          ) : (
            number_of_applications
          ),
        type: type_of_work.name,
        loc: `${city.name}, ${country.name}`,
        ends: moment(end_date).format("DD.MM.YYYY"),
        status: <Status status={status} />,
        mobileStatus: statusObject[status],
        toggle: (
          <Switch checked={visible} setChecked={(a) => updateToggle(a, id)} />
        ),
        edit: (
          <IconButton component={Link} to={`/dashboard/jobs/job/${id}/edit`}>
            <Edit />
          </IconButton>
        ),
        ...x,
      })
    );
    setJobs(formattedJobs);
    setLoading(false);
  };

  useEffect(() => {
    getAllAds();
  }, [state, modalState]);

  const handlePage = (x) => {
    setPage(x);
    setState({ ...state, offset: (x - 1) * state.limit });
  };

  useEffect(() => {
    if (page > 1 && jobs.length === 0) {
      handlePage(page - 1);
    }
  }, [jobs]);

  const { updateProfileCompleted } = useUserContext();

  useEffect(() => {
    updateProfileCompleted();
  }, []);

  const { width } = useEventContext();

  const archiveSelected = async () => {
    const ids = selection.map((x) => x.id);
    await CompanyAPI.archiveCompanyAds(ids);
    setSelection([]);
    getAllAds();
  };

  const { t } = useLanguageContext();

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Postavljeni oglasi")}
          subtitle={t("Sve informacije o postavljenim oglasima")}
          actions={[
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Add />}
              size="large"
              component={Link}
              to="/dashboard/jobs/new"
            >
              {t("DODAJTE NOVI OGLAS")}
            </Button>,
            <Button
              color="default"
              variant="contained"
              startIcon={<Folder />}
              size="large"
              className={!selection.length ? "cv-disabled" : ""}
              onClick={archiveSelected}
            >
              {t("ARHIVIRAJ SELEKTOVANO")} ({selection.length})
            </Button>,
          ]}
        />
        <div className="cv-dashboard-jobs-all-main cv-dashboard-order-content">
          <div className="cv-dashboard-filters-main">
            <Select
              disableDefault
              className="cv-dashboard-filters-limit"
              placeholder={t("Prikaži")}
              value={state.limit}
              onChange={(limit) => setState({ ...state, limit })}
              options={[
                { value: 5, label: t("Prikaži 5") },
                { value: 10, label: t("Prikaži 10") },
                { value: 25, label: t("Prikaži 25") },
                { value: 50, label: t("Prikaži 50") },
              ]}
            />
            <div className="cv-dashboard-filter-with-search">
              <Input
                className="cv-dashboard-filters-search"
                placeholder={t("Pronađi oglas") + "..."}
                value={search}
                onChange={(term) => setSearch(term)}
                endAdornment={<Search />}
              />
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className="cv-dashboard-filter-button"
                onClick={() => setModalOpen(true)}
              >
                <Tune />
              </Button>
            </div>
          </div>
          {width >= 900 ? (
            <Table
              head={tableHead}
              body={jobs}
              sort={sort}
              onRequestSort={requestSort}
              onSelection={setSelection}
              loading={loading}
            />
          ) : (  
            <div className="cv-dashboard-resposnive-table">
              {loading ? (
                <Spinner />
              ) : (
                jobs.map((x, y) => (
                  <DisplayCard
                    to={x.to}
                    key={y}
                    title={x.title}
                    image={x.image}
                    headActions={x.toggle}
                    infos={[
                      { value: x.company },
                      { title: t("Tip posla"), value: x.type },
                      { title: t("Lokacija"), value: x.loc },
                      { title: t("Status"), value: x.mobileStatus },
                      {
                        title: t("Broj prijava"),
                        value: x.number_of_applications,
                      },
                    ]}
                    bodyActions={[x.edit]}
                  />
                ))
              )}
            </div>
          )}
          <div className="cv-dashboard-pagination-holder">
            <Pagination
              page={page}
              setPage={handlePage}
              count={Math.ceil(total / state.limit)}
            />
          </div>
        </div>
      </div>
      <ApplicationModal
        application={application}
        open={application}
        onClose={() => setApplication(0)}
        jobs={jobs}
        setJobs={setJobs}
      />
      <FilterModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        modalState={modalState}
        setModalState={setModalState}
      />
    </Fragment>
  );
};

const ApplicationModal = ({ application, open, onClose, ...parent }) => {
  const sendApplication = async () => {
    const { ad_id } = await CandidateAPI.applicationStore({
      ad_id: application,
      applied: 1,
    });
    parent.setJobs(
      parent.jobs.map((x) => (x.id === ad_id ? { ...x, applied: 1 } : x))
    );
    onClose();
  };

  const { user } = useUserContext();
  const { t } = useLanguageContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("KONKURIŠI NA OGLAS")}
      size="medium"
      warning={user.profile_completely !== 100}
    >
      <div className="cv-dashboard-jobs-apply">
        {user.profile_completely !== 100 ? (
          <div className="cv-dashboard-jobs-apply-warning">
            {t("Vaš profil nije maskimalno popunjen" + "!")}
          </div>
        ) : null}
        <Button
          color="default"
          variant="contained"
          size="large"
          fullWidth
          component={Link}
          to={
            user.profile_completely !== 100
              ? "/dashboard/cv-profile/edit"
              : "/dashboard/cv-profile"
          }
          className="cv-mb-10"
        >
          {user.profile_completely !== 100
            ? t("DOPUNI PROFIL")
            : t("PREGLEDAJ PROFIL")}
        </Button>

        {user.profile_completely === 100 ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            className="cv-mb-10"
            onClick={sendApplication}
          >
            {t("PRIJAVI SE")}
          </Button>
        ) : null}

        <Button
          color="secondary"
          variant="text"
          size="large"
          fullWidth
          onClick={onClose}
        >
          {t("ODUSTANI")}
        </Button>
      </div>
    </Modal>
  );
};

const FilterModal = ({ open, onClose, modalState, setModalState }) => {
  const [state, setState] = useState(modalState);

  useEffect(() => {
    setState(modalState);
  }, [modalState]);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [typeOfWorks, setTypeOfWorks] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const {
    cities: allCities,
    countries: allCountries,
    addings: { type_of_works },
  } = useUserContext();

  useEffect(() => {
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
    setTypeOfWorks(type_of_works.map((x) => ({ value: x.id, label: x.name })));
    setCities(allCities.map((x) => ({ value: x.id, label: x.name })));
    setStatuses(
      allStatuses
        .filter((x) => [1, 6].includes(x.id))
        .map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const applyFilters = () => {
    setModalState(state);
    onClose();
  };

  const resetFilters = () => {
    setModalState({
      country_id: "",
      city_id: "",
      type_of_work_id: "",
      status: "",
    });
    onClose();
  };

  const { t } = useLanguageContext();

  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(state.country_id))
      .map((x) => ({
        value: x.id,
        label: x.name,
      }));
    setCities(filteredCities);
  }, [state.country_id]);

  const updateCountry = (x) => {
    setCountry(x);
    setCity(null);
    setState({ ...state, country_id: x?.value, city_id: "" });
  };

  const updateCity = (x) => {
    setCity(x);
    setState({ ...state, city_id: x?.value });
  };

  const updateCategory = (x) => {
    setCategory(x);
    setState({ ...state, type_of_work_id: x?.value });
  };

  const updateStatus = (x) => {
    setStatus(x);
    setState({ ...state, status: x?.value });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("FILTERI")}
      actions={
        <div className="cv-modal-buttons-group">
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={applyFilters}
          >
            {t("POTVRDI")}
          </Button>
          <Button
            color="secondary"
            variant="text"
            size="large"
            fullWidth
            onClick={resetFilters}
          >
            {t("RESETUJ FILTERE")}
          </Button>
        </div>
      }
      size="small"
    >
      <div>
        <AdminAutocompleteSelect
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={country}
          onChange={updateCountry}
          options={countries}
          label={t("Država")}
        />
        <AdminAutocompleteSelect
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={city}
          onChange={updateCity}
          options={cities}
          label={t("Grad")}
        />
        <AdminAutocompleteSelect
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={category}
          onChange={updateCategory}
          options={typeOfWorks}
          label={t("Kategorija posla")}
        />
        <AdminAutocompleteSelect
          color="secondary"
          variant="outlined"
          value={status}
          onChange={updateStatus}
          label={t("Status")}
          options={statuses}
        />
      </div>
    </Modal>
  );
};

export default PostedJobs;
