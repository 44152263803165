import React, { Fragment, useEffect, useRef } from "react";
import { Switch, useHistory, useLocation } from "react-router-dom";
import {
  AboutUs,
  Blog,
  Companies,
  CompanySingle,
  Contact,
  CvStory,
  ForCompanies,
  Home,
  Jobs,
  JobSingle,
  Login,
  Dashboard,
  Profile,
  AllJobs,
  FavoritesJobs,
  DoneApplications,
  PreparationApplications,
  Settings,
  DashboardCompanies,
  CompanyProfile,
  CvVideo,
  ProfileEdit,
  JobSingleCandidate,
  NewJob,
  PostedJobs,
  PreparationJobs,
  ArchivedJobs,
  Packages,
  ApplicationSingle,
  CompanySingleDashboard,
  CompanyProfileEdit,
  EmployeeSingle,
  ApplicationsList,
  Messages,
  Notifications,
  BlogSingle,
  Verify,
  NotFound,
} from "../pages";
import ProtectedRoute from "./protectedroute";
import { CenterLayout, DashboardLayout, PublicLayout } from "../layouts";
import PrivacyPolicy from "../pages/privacy-policy/index.js"

const PageRouter = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((x) => {
      if (!x.pathname.startsWith("/public/companies/")) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return (
    <Fragment>
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          component={Home}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/jobs"
          component={Jobs}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/jobs/:id/:companySlug/:slug"
          component={JobSingle}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/companies"
          component={Companies}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/companies/:id/:slug"
          component={CompanySingle}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/companies/:id/:slug/:subroute"
          component={CompanySingle}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/cv-story"
          component={CvStory}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/blog"
          component={Blog}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/blog/post/:id"
          component={BlogSingle}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/blog/:type"
          component={Blog}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/contact"
          component={Contact}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/for-companies"
          component={ForCompanies}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/about-us"
          component={AboutUs}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/privacy-policy"
          component={PrivacyPolicy}
          roles={["all"]}
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/public/login/:role"
          component={Login}
          roles={["guest"]}
          redirectTo="/dashboard"
          layout={PublicLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={Dashboard}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/cv-profile"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={Profile}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/cv-profile/edit"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={ProfileEdit}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/cv-profile/video"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={CvVideo}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/all"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={AllJobs}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/favorites"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={FavoritesJobs}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/applications/done"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={DoneApplications}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/job/:id"
          roles={["employee", "company"]}
          redirectTo="/public/login/employee"
          component={JobSingleCandidate}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/applications/preparation"
          roles={["employee"]}
          redirectTo="/public/login/employee"
          component={PreparationApplications}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/companies"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={DashboardCompanies}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/settings"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={Settings}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/new"
          roles={["company"]}
          redirectTo="/public/login/company"
          component={NewJob}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/job/:id/edit"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={NewJob}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/posted"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={PostedJobs}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/candidates"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={ApplicationsList}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/preparation"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={PreparationJobs}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/archived"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={ArchivedJobs}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/packages"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={Packages}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/profile"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={CompanyProfile}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/profile/edit"
          roles={["company"]}
          redirectTo="/public/login/employee"
          component={CompanyProfileEdit}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/applications/application/:id"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={ApplicationSingle}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/companies/:id/:companySlug"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={CompanySingleDashboard}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/companies/:id/:companySlug/:subroute"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={CompanySingleDashboard}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/employee/:id"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={EmployeeSingle}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/jobs/job/:id/applications"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={ApplicationsList}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/messages"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={Messages}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/notifications"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={Notifications}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/dashboard/messages/:id"
          roles={["company", "employee"]}
          redirectTo="/public/login/employee"
          component={Messages}
          layout={DashboardLayout}
        />
        <ProtectedRoute
          exact
          path="/verify/:token"
          roles={["all"]}
          redirectTo="/"
          component={Verify}
          layout={CenterLayout}
        />
        <ProtectedRoute
          exact
          path="*"
          roles={["all"]}
          redirectTo="/"
          component={NotFound}
          layout={CenterLayout}
        />
      </Switch>
    </Fragment>
  );
};

export default PageRouter;
