import React, { useState } from "react";
import { CircularProgressWrapper } from "../icon-button/styles";
import { ButtonWrapper } from "./styles";

const Button = ({ children, className, onClick = () => {}, ...props }) => {
  const [loading, setLoading] = useState(false);

  const processFunction = async (e) => {
    if (loading) return;
    else {
      if (onClick.constructor.name === "AsyncFunction") {
        setLoading(true);
        await onClick(e);
        setLoading(false);
      } else if (onClick.constructor.name === "Function") {
        onClick(e);
      }
    }
  };

  return (
    <ButtonWrapper onClick={processFunction} {...props} className={className}>
      {loading ? (
        <CircularProgressWrapper
          color="default"
          style={{ width: 21, height: 21 }}
        />
      ) : (
        children
      )}
    </ButtonWrapper>
  );
};

export default Button;
