import axios from "axios";
import { apiBaseUrl } from "../../../constants/project";

export const notifications = (params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/notifications`, { params })
    .then((x) => x.data);

export const notificationSetSeen = (notification_id, seen, user_id) =>
  axios.patch(
    `${apiBaseUrl}/api/v2/notifications/${notification_id}/set-seen`,
    {
      data: { seen, user_id },
    }
  );

const NotificationAPI = {
  notifications,
  notificationSetSeen,
};

export default NotificationAPI;
