import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Button,
  Box,
  TableSortLabel,
} from "@mui/material";
import { styled } from '@mui/system';

export const TableWrapper = styled(Table)(() => ({
  borderCollapse: "separate",
  borderSpacing: "0px 10px",
}));
export const TableRowWrapper = styled(TableRow)(() => ({
  marginBottom: 10,
}));
export const TableCellWrapper = styled(TableCell)(({ theme, center }) => ({
  padding: 10,
  fontFamily: "Rubik",
  width: center ? 60 : "auto",
  margin: center ? "0px auto" : "0px",
  [theme.breakpoints.down("sm")]: {
    padding: 3,
    height: "auto !important",
  },
  ...(center
    ? {
        "& > .MuiButton-root": {
          margin: "0 auto",
          display: "block",
        },
      }
    : {}),
}));
export const TableBodyWrapper = styled(TableBody)(() => ({
  "& .MuiTableRow-root": {
    background: "linear-gradient(to top, rgba(107, 107, 107, 0.1), white)",
    borderRadius: 10,
    boxShadow: "0 5px 10px rgb(0 0 0 / 20%)",
    "&:hover": {
      background: "linear-gradient(to top, rgba(40, 151, 251, 0.2), white)",
    },
    "& .MuiTableCell-root": {
      color: "#6b6b6b",
      fontSize: 13,
      height: 60,
      "&:not(:last-child)": {
        borderRight: "solid 1px rgba(0, 0, 0, 0.2)",
      },
      "&:first-child": {
        borderRadius: "10px 0px 0px 10px",
      },
      "&:last-child": {
        borderRadius: "0px 10px 10px 0px",
      },
    },
  },
}));
export const TableHeadWrapper = styled(TableHead)(() => ({
  "& .MuiTableRow-root": {
    background: "linear-gradient(to top, #04274f, #2897fb)",
    borderRadius: 10,
    boxShadow: "0 5px 10px rgb(0 0 0 / 20%)",
    "& .MuiTableCell-root": {
      color: "#d5eaff",
      textTransform: "uppercase",
      fontSize: 11,
      height: 40,
      fontWeight: 100,
      "&:not(:last-child)": {
        borderRight: "solid 1px rgba(255, 255, 255, 0.2)",
      },
      "&:first-child": {
        borderRadius: "10px 0px 0px 10px",
      },
      "&:last-child": {
        borderRadius: "0px 10px 10px 0px",
      },
    },
  },
}));

export const TableSortLabelWrapper = styled(TableSortLabel)(() => ({
  display: "flex",
  justifyContent: "space-between",
  color: "#d5eaff",
  "&.Mui-active, &.Mui-active svg": {
    color: "#fff !important",
  },
  "&:hover, &:hover .MuiTableSortLabel-icon": {
    color: "#fff",
  },
}));

export const TableSelectWrapper = styled(Button)(({ selected }) => ({
  width: 18,
  height: 18,
  padding: 0,
  borderRadius: "50%",
  minWidth: 18,
  border: "1px solid #626262",
  background: selected
    ? "linear-gradient(to top, rgb(21 21 21), rgb(129 129 129))"
    : "none",
}));

export const EmptyTableWrapper = styled(Box)(({theme}) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  background: "linear-gradient(to top, rgba(107, 107, 107, 0.1), white)",
  borderRadius: 10,
  boxShadow: "0 5px 10px rgb(0 0 0 / 20%)",
  padding: 15,
  minHeight: 300,
  "& svg": {
    width: 50,
    height: 50,
    color: "#2897fb",
  },
  "& h3": {
    color: "#00000080",
    fontWeight: 400
  },
}));