import React from "react";
import { AvatarWrapper } from "./styles";

const Avatar = ({ src, name = "", ...props }) => {
  return (
    <AvatarWrapper src={src} {...props}>
      {name[0] || "?"}
    </AvatarWrapper>
  );
};

export default Avatar;
