import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { Input } from "../";

const Autocomplete = ({
  options = [],
  label,
  value,
  onChange,
  placeholder,
  ...params
}) => {
  return (
    <MuiAutocomplete
      value={value}
      inputValue={value}
      onInputChange={(a, b) => onChange(b)}
      options={options.map((x) => ({ ...x, id: x.value, label: x.label }))}
      classes={{ paper: "cv-paper-radius" }}
      {...params}
      renderInput={(params) => (
        <Input {...params} placeholder={placeholder} label={label} />
      )}
    />
  );
};

export default Autocomplete;
