import {
  BusinessCenter,
  Close,
  Dashboard,
  EmailRounded,
  Filter,
  HistoryEduRounded,
  HomeRounded,
  Person,
  Search,
  ViewQuilt,
} from "@mui/icons-material";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import { logoSource } from "../../../assets/images";
import { useLanguageContext } from "../../../context/language";
import { useUserContext } from "../../../context/user";
import { Button } from "../../ui";
import "./style.scss";

const listItems = [
  { icon: <HomeRounded />, text: "Početna", to: "/" },
  { icon: <Search />, text: "Pretraga poslova", to: "/public/jobs" },
  { icon: <BusinessCenter />, text: "Poslodavci", to: "/public/companies" },
  {
    icon: <HistoryEduRounded />,
    text: "Ispričajte svoju cv priču",
    to: "/public/cv-story",
  },
  { icon: <ViewQuilt />, text: "Blog", to: "/public/blog" },
  { icon: <EmailRounded />, text: "Kontakt", to: "/public/contact" },
];

const MobileNavigation = ({ open, setOpen }) => {
  const onClose = () => {
    setOpen(false);
  };

  const { languages, lang, setLanguage } = useLanguageContext();
  const { user } = useUserContext();

  const choseLang = (x) => {
      setLanguage(x);
      onClose();
  }

  return (
    <Drawer
      className="cv-mobilenavigation-wrapper"
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <div className="cv-mobilenavigation-main">
        <div className="cv-mobilenavigation-top">
          <div className="cv-mobilenavigation-logo">
            <img src={logoSource} alt="CVPRIČA" />
          </div>
          <IconButton
            className="cv-mobilenavigation-close"
            size="large"
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </div>
        <List className="cv-mobilenavigation-items">
          {listItems.map((x, y) => (
            <ListItem
              exact
              button
              component={NavLink}
              to={x.to}
              className="cv-mobilenavigation-link"
              activeClassName="cv-mobilenavigation-link-active"
              onClick={onClose}
              key={y}
            >
              <ListItemIcon>{x.icon}</ListItemIcon>
              <ListItemText primary={x.text} />
            </ListItem>
          ))}
        </List>
        <div className="cv-mobilenavigation-buttons">
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/public/for-companies"
            onClick={onClose}
          >
            Za poslodavce
          </Button>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/dashboard/jobs/new"
            onClick={onClose}
          >
            Objavite oglas
          </Button>
        </div>
        <div className="cv-mobilenavigation-login">
          {!user ? (
            <Fragment>
              <div className="cv-mobilenavigation-login-title">
                <Person /> Prijavite se
              </div>
              <div className="cv-mobilenavigation-login-types">
                <Link
                  className="cv-mobilenavigation-login-type"
                  to="/public/login/employee"
                  onClick={onClose}
                >
                  Kandidat
                </Link>{" "}
                /
                <Link
                  className="cv-mobilenavigation-login-type"
                  to="/public/login/company"
                  onClick={onClose}
                >
                  Poslodavac
                </Link>
              </div>
            </Fragment>
          ) : (
            <div className="cv-mobilenavigation-login-dashboard">
              <Link
                to="/dashboard"
                className="cv-mobilenavigation-login-dashboard-link"
              >
                <Dashboard /> Dashboard
              </Link>
            </div>
          )}
        </div>
        <div className="cv-mobilenavigation-languages">
          {languages.map((x) => (
            <IconButton
              key={x.code}
              onClick={() => choseLang(x)}
              className={`cv-mobilenavigation-language ${
                x === lang ? "cv-mobilenavigation-language-active" : ""
              }`}
            >
              <span>{x.code}</span>
            </IconButton>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default MobileNavigation;
