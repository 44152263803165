import React from "react";
import { DatePickerWrapper } from "./styles";
import { AdminInput } from "../";

const DatePicker = ({ color, className, ...props }) => {
  return (
    <div className={className}>
      <DatePickerWrapper
        variant="inline"
        inputVariant="outlined"
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => <AdminInput color={color} {...params} {...props} />}
        {...props}
      />
    </div>
  );
};

export default DatePicker;
