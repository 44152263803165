import {
  AddCircle,
  Close,
  CloudUpload,
  Delete,
  Download,
  Edit,
  EditOutlined,
  Phone,
  Save,
} from "@mui/icons-material";
import {
  Alert,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { CandidateAPI } from "../../../api/v2";
import { logout } from "../../../api/v2/auth";
import {
  AdminDatepicker,
  AdminInput,
  AdminPhoneInput,
  AdminSelect,
  // AdminPhoneInput,
} from "../../../components/admin";
import PhoneInput from "react-phone-input-2";
import {
  DashTitle,
  DataDisplay,
  UploadImage,
  Modal,
  Progress,
} from "../../../components/custom";
import { SimpleCard, Button } from "../../../components/ui";
import LeavePageModal from "../../../components/ui/leave-page-modal";
import { genders } from "../../../constants/selects";
import { useLanguageContext } from "../../../context/language";
import { useUserContext } from "../../../context/user";
import jsFileDownload from "js-file-download";
import UserImage from '../../../assets/images/nouser5.png';
import mime from "mime-types";
import { v4 as uuidv4 } from "uuid";
import "./style.scss";
import "react-phone-input-2/lib/material.css";
import { removeFalsy } from "../../../utils/misc";

const Profile = () => {
  const [state, setState] = useState({
    full_name: "",
    gender_id: "",
    birth_year: null,
    email: "",
    education_level_id: "",
    country_id: "",
    city_id: "",
    address: "",
    phone: "",
    zip_code: "",
    profile_image: "",
    educations: [],
    work_experiences: [],
    languages: [],
    computer_skills: [],
    cv_document: null,
    cv_video: "",
    aditional_info: "",
    driver_licences: [],
  });

  const [errors, setErrors] = useState({
    full_name: false,
    gender_id: false,
    birth_year: false,
    email: false,
    education_level_id: false,
    country_id: false,
    city_id: false,
    address: false,
    phone: false,
    zip_code: false,
    educations: false,
  });

  const [open, setOpen] = useState(false);

  const getCandidateData = async () => {
    const candidate = await CandidateAPI.candidateInfo();
    setState({
      full_name: candidate.full_name,
      gender_id: candidate.gender?.id,
      birth_year: new Date(candidate.birth_year),
      email: candidate.email,
      education_level_id: candidate.education_level?.id,
      country_id: candidate.country?.id,
      city_id: candidate.city?.id,
      address: candidate.address || "",
      phone: candidate.phone ?? "",
      zip_code: candidate.zip_code,
      profile_image: candidate.profile_image,
      educations: candidate.educations,
      work_experiences: candidate.work_experiences,
      languages: candidate.languages,
      computer_skills: candidate.computer_skills,
      cv_document: candidate.cv_document,
      cv_video: candidate.cv_video?.video,
      aditional_info: candidate.aditional_info,
      driver_licences: candidate.driver_licences,
      profile_completely: candidate.profile_completely,
    });
  };

  const [educationLevels, setEducationLevels] = useState([]);
  const [promptOpen, setPromptOpen] = useState(false);

  const [documentUploading, setDocumentUploading] = useState(false);
  const [videoUploading, setVideoUploading] = useState(false);

  const { t } = useLanguageContext();

  const {
    addings,
    countries: allCountries,
    cities: allCities,
  } = useUserContext();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(state.country_id))
      .map((x) => ({ value: x.id, label: x.name }));
    setCities(filteredCities);
  }, [state.country_id]);

  useEffect(() => {
    getCandidateData();
    setEducationLevels(
      addings.education_levels.map((x) => ({ value: x.id, label: x.name }))
    );
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
  }, []);

  const { user, setUser, logout } = useUserContext();

  const deactivateProfile = async () => {
    try {
      await CandidateAPI.deactivate();
      await logout();
      setOpen(false);
      toast.success(t`Profil je deaktiviran`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  const uploadProfileImage = async (files) => {
    try {
      const { profile_image } = await CandidateAPI.updateProfileImage(files[0]);
      setState({ ...state, profile_image });
      setUser({ ...user, profile_image });
      toast.success(t`Profilna slika je uspešno promenjena`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  const getPercent = () => {
    const len = [
      state.full_name,
      state.gender_id,
      state.birth_year,
      state.education_level_id,
      state.email,
      state.country_id,
      state.city_id,
      state.address,
      state.phone,
      state.zip_code,
      state.educations,
      state.profile_image,
      user.is_email_verified ? true : null,
      user.is_phone_verified ? true : null,
    ];

    const fLen = len.filter((x) =>
      Array.isArray(x) ? x.length > 0 : ![null, undefined, ""].includes(x)
    ).length;
    return Math.round((fLen / len.length) * 80 + (state.cv_video ? 20 : 0));
  };

  const [educationOpen, setEducationOpen] = useState(false);
  const [workExpOpen, setWorkExpOpen] = useState(false);
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [computerOpen, setComputerOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [drivingOpen, setDrivingOpen] = useState(false);

  const handleUpload = async (e) => {
    setDocumentUploading(true);
    const { cv_document } = await CandidateAPI.updateCvDocument(
      e.target.files[0]
    );
    setDocumentUploading(false);
    setState({ ...state, cv_document });
  };

  const uploadRef = useRef();

  const handleVideoUpload = async (e) => {
    setVideoUploading(true);
    const { cv_video } = await CandidateAPI.updateProfileVideo(
      e.target.files[0]
    );
    setVideoUploading(false);
    setState({ ...state, cv_video: cv_video.video });
  };

  const uploadVideoRef = useRef();

  const deleteCvDocument = async () => {
    await CandidateAPI.deleteCvDocument();
    setState({ ...state, cv_document: null });
    setUser({ ...user, cv_document: null });
  };

  const deleteCvVideo = async () => {
    await CandidateAPI.deleteProfileVideo();
    setState({ ...state, cv_video: null });
    setUser({ ...user, cv_video: null });
  };

  const history = useHistory();

  const saveChanges = async () => {
    if (state.full_name.trim() === "") {
      return toast.error(t`Ime i prezime polje je obavezno`) 
    }
    if (state && !state.gender_id || state && state.gender_id && state.gender_id=== null) {
      return toast.error(t`Pol polje je obavezno`) 
    }
    if (state && !state.birth_year || state && state.birth_year && state.birth_year=== null) {
      return toast.error(t`Datum rođenja polje je obavezno`) 
    }
    if (state && !state.education_level_id || state && state.education_level_id && state.education_level_id=== null) {
      return toast.error(t`Nivo obrazovanja polje je obavezno`) 
    }
    if (state.email.trim() === "") {
      return toast.error(t`Email polje je obavezno`) 
    }
    if (state && !state.country_id || state && state.country_id && state.country_id=== null) {
      return toast.error(t`Država polje je obavezno`) 
    }
    if (state && !state.city_id || state && state.city_id && state.city_id=== null) {
      return toast.error(t`Grad polje je obavezno`) 
    }
    if (state.address.trim() === "") {
      return toast.error(t`Ulica i broj prebivališta polje je obavezno`) 
    }
    if (state && !state.phone || state && state.phone && state.phone=== null) {
      return toast.error(t`Broj telefona polje je obavezno`) 
    }
    if (state && !state.zip_code || state && state.zip_code && state.zip_code=== null) {
      return toast.error(t`Poštanski broj polje je obavezno`) 
    }
  
    try {
      const request_validated = await CandidateAPI.update({
        ...state,
        phone: state.phone.charAt(0) === "+" ? state.phone : `+${state.phone}`,
      });
      setUser({
        ...user,
        ...request_validated,
        is_email_verified:
          state.email !== user.email ? 0 : user.is_email_verified,
        is_phone_verified:
          state.phone !== user.phone ? 0 : user.is_phone_verified,
      });
      history.goBack();
      toast.success(t`Izmjene su uspešno sačuvane`);
    } catch (e) {
      if (e.response.data.message.includes("Phone must be unique")) {
        toast.error(t`Broj telefona već postoji`);
      } else {
        toast.error(t`Broj telefona polje je obavezno`);
      }
    }
  };

  const downloadVideo = async () => {
    const res = await CandidateAPI.downloadCvVideo();
    const contentType = res.headers["content-type"];
    const extension = mime.extension(contentType);
    jsFileDownload(res.data, `${uuidv4()}.${extension}`);
  };
  const downloadDocument = async () => {
    const res = await CandidateAPI.downloadCvDocument();
    const contentType = res.headers["content-type"];
    const extension = mime.extension(contentType);
    jsFileDownload(res.data, `${uuidv4()}.${extension}`);
  };

   // Convert file to blob
   async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }
  // End convert

  const deleteProfileImage = async () => {
    try {
      const image = await getFileFromUrl(UserImage);
      const { profile_image } = await CandidateAPI.updateProfileImage(image);
      setState({ ...state, profile_image });
      setUser({ ...user, profile_image });
      toast.success(t`Profilna slika je uspešno izbrisana`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  }

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Moj CV profil")}
          subtitle={t("Popunite sve informacije prije konkurisanja na oglase")}
          actions={[
            <Button
              color="error"
              variant="contained"
              startIcon={<Delete />}
              size="large"
              onClick={() => setOpen(true)}
            >
              {t("DEAKTIVIRAJ PROFIL")}
            </Button>,
          ]}
        />
        <div className="cv-dashboard-profile-main cv-dashboard-order-content">
          <SimpleCard
            className="cv-dashboard-profile-card"
            title={t("Kontakt informacije")}
            withContent
          >
            <div className="cv-dashboard-profile-grid">
              <AdminInput
                label={t("Ime i prezime")}
                value={state.full_name}
                className="cv-dashboard-profile-col-double"
                onChange={(full_name) => setState({ ...state, full_name })}
                color="secondary"
                variant="outlined"
                error={state && !state.full_name || state && state.full_name && state.full_name=== null}
                //onBlur={() => onBlur("full_name")}
                required
              />
              <AdminSelect
                label={t("Pol")}
                value={state.gender_id}
                onChange={(gender_id) => setState({ ...state, gender_id })}
                color="secondary"
                variant="outlined"
                options={genders}
                error={state && !state.gender_id || state && state.gender_id && state.gender_id=== null}
                //onBlur={() => onBlur("gender_id")}
                required
              />
              <AdminDatepicker
                label={t("Datum rođenja")}
                value={state.birth_year}
                onChange={(birth_year) => setState({ ...state, birth_year })}
                color="secondary"
                variant="outlined"
                //error={state && !state.birth_year || state && state.birth_year && state.birth_year=== null}
                //onBlur={() => onBlur("birth_year")}
                required
              />
              <AdminSelect
                label={t("Nivo obrazovanja")}
                value={state.education_level_id}
                onChange={(education_level_id) =>
                  setState({ ...state, education_level_id })
                }
                color="secondary"
                variant="outlined"
                options={educationLevels}
                error={state && !state.education_level_id || state && state.education_level_id && state.education_level_id=== null}
                //onBlur={() => onBlur("education_level_id")}
                required
              />
              <AdminInput
                label={t("E-mail")}
                value={state.email}
                onChange={(email) => setState({ ...state, email })}
                color="secondary"
                variant="outlined"
                error={state.email.trim() === ""}
                required
              />
              <AdminSelect
                label={t("Država")}
                value={state.country_id}
                onChange={(country_id) => setState({ ...state, country_id })}
                color="secondary"
                variant="outlined"
                options={countries}
                error={state && !state.country_id || state && state.country_id && state.country_id=== null}
                //onBlur={() => onBlur("country_id")}
                required
              />
              <AdminSelect
                label={t("Grad")}
                value={state.city_id}
                onChange={(city_id) => setState({ ...state, city_id })}
                color="secondary"
                variant="outlined"
                options={cities}
                error={state && !state.city_id || state && state.city_id && state.city_id=== null}
                //onBlur={() => onBlur("city_id")}
                required
              />
              <AdminInput
                className="cv-dashboard-profile-col-double"
                label={t("Ulica i broj prebivališta")}
                value={state.address}
                onChange={(address) => setState({ ...state, address })}
                color="secondary"
                variant="outlined"
                error={state && !state.address || state && state.address && state.address=== null}
                //onBlur={() => onBlur("address")}
                required
              />
              {/* { <PhoneInput
                value={state.phone}
                specialLabel={t("Broj telefona")}
                onChange={(phone) => setState({ ...state, phone })}
                regions={"europe"}
                country={"me"}
                priority={{ rs: 0 }}
                preferredCountries={["me", "rs", "hr", "ba", "si"]}
                placeholder="382 12 345 6789"
              /> } */}
              <AdminPhoneInput
                label={t("Broj telefona")}
                value={state.phone}
                onChange={(phone) => setState({ ...state, phone })}
                color="secondary"
                variant="outlined"
                error={state && state.phone && state.phone == '+387' || state && state.phone && state.phone == '+385' || state && state.phone && state.phone == '+382' || state && state.phone && state.phone == '+381' || !state.phone}
                placeholder="00-000-0000"
                required
              /> 
              <AdminInput
                label={t("Poštanski broj")}
                value={state.zip_code}
                onChange={(zip_code) => setState({ ...state, zip_code })}
                color="secondary"
                variant="outlined"
                error={state && !state.zip_code || state && state.zip_code && state.zip_code=== null}
                required
              />

              <DataDisplay
                className="cv-dashboard-profile-col-double"
                label={t("Popunjenost profila")}
                value={
                  <Progress
                    value={getPercent()}
                    className="cv-dashboard-dashboard-progress"
                    circleClassName="cv-dashboard-dashboard-progress-circle"
                    thickness={4}
                  />
                }
              />
            </div>
          </SimpleCard>
          <SimpleCard
            className="cv-dashboard-profile-card"
            title={t("Moja biografija")}
            withContent
          >
            <UploadImage
              src={state.profile_image}
              name={state.full_name}
              className="cv-dashboard-profile-upload"
              onUpload={uploadProfileImage}
              onDelete={deleteProfileImage}
            />
            <input
              type="file"
              hidden
              onChange={handleUpload}
              ref={uploadRef}
              accept="text/plain, application/pdf,  application/docx, application/doc"
            />
            <input
              type="file"
              hidden
              onChange={handleVideoUpload}
              ref={uploadVideoRef}
              accept="video/mp4,video/x-m4v,video/*"
            />
            {!state.profile_image ? (
              <div className="cv-mb-20">
                <Alert severity="info">
                  Kako bi dodatno kompletirali profil potrebno je dodati sledeća
                  polja:{" "}
                  <span className="cv-dashboard-profile-alert-bold">
                    profilnu sliku
                  </span>
                </Alert>
              </div>
            ) : null}
            <div className="cv-dashboard-profile-grid">
              <AdminInput
                label={t("Obrazovanje")}
                value={state.educations?.map((x) => x.title).join(", ")}
                className="cv-dashboard-profile-col-double"
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <IconButton onClick={() => setEducationOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                }
                // error={errors.educations}
                required
              />
              <AdminInput
                label={t("Radno iskustvo")}
                value={
                  state.work_experiences.length
                    ? state.work_experiences
                        ?.map((x) => x.company_name)
                        .join(", ")
                    : t("Bez radnog iskustva")
                }
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <IconButton onClick={() => setWorkExpOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                }
              />
              <AdminInput
                label={t("Strani jezici")}
                value={state.languages?.map((x) => x.languages_name).join(", ")}
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <IconButton onClick={() => setLanguagesOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                }
              />
              <AdminInput
                className="cv-dashboard-profile-col-double"
                label={t("Rad na računaru")}
                value={state.computer_skills
                  ?.map((x) => x.computer_skill_name.name)
                  .join(", ")}
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <IconButton onClick={() => setComputerOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                }
              />
              <AdminInput
                label={t("CV dokument")}
                className="cv-dashboard-profile-col-double"
                value={state.cv_document?.document_name || ""}
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <Fragment>
                    {state.cv_document ? (
                      <IconButton onClick={downloadDocument}>
                        <Download />
                      </IconButton>
                    ) : null}
                    <IconButton onClick={() => uploadRef.current.click()}>
                      {state.cv_document ? (
                        <CloudUpload />
                      ) : documentUploading ? (
                        <CircularProgress className="cv-profile-uploading-progress" />
                      ) : (
                        <AddCircle />
                      )}
                    </IconButton>
                    {state.cv_document ? (
                      <IconButton onClick={deleteCvDocument}>
                        <Delete />
                      </IconButton>
                    ) : null}
                  </Fragment>
                }
              />
              <AdminInput
                label={t("CV video")}
                className="cv-dashboard-profile-col-double"
                value={state.cv_video || ""}
                // required
                color="secondary"
                variant="outlined"
                disabled
                required
                endAdornment={
                  <Fragment>
                    {state.cv_video ? (
                      <IconButton onClick={downloadVideo}>
                        <Download />
                      </IconButton>
                    ) : null}
                    <IconButton onClick={() => uploadVideoRef.current.click()}>
                      {state.cv_video ? (
                        <CloudUpload />
                      ) : videoUploading ? (
                        <CircularProgress className="cv-profile-uploading-progress" />
                      ) : (
                        <AddCircle />
                      )}
                    </IconButton>
                    {state.cv_video ? (
                      <IconButton onClick={deleteCvVideo}>
                        <Delete />
                      </IconButton>
                    ) : null}
                  </Fragment>
                }
              />
              <AdminInput
                label={t("Ukratko o meni")}
                value={state.aditional_info}
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <IconButton onClick={() => setAboutOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                }
              />
              <AdminInput
                label={t("Vozačka dozvola")}
                value={state.driver_licences
                  ?.map((x) => x.drivers_licence_category.name)
                  .join(", ")}
                color="secondary"
                variant="outlined"
                disabled
                endAdornment={
                  <IconButton onClick={() => setDrivingOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                }
              />
              {/* <Button
                color="secondary"
                variant="contained"
                size="large"
                className="cv-dashboard-profile-button-right"
                component={Link}
                to="/dashboard/cv-profile/video"
              >
                VIDEO CV
              </Button> */}
            </div>
          </SimpleCard>
        </div>
        <div className="cv-dashboard-bottom-actions">
          <Button
            variant="contained"
            color="default"
            startIcon={<Close />}
            size="large"
            onClick={() => setPromptOpen(true)}
          >
            {t("OTKAŽITE")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Save />}
            size="large"
            onClick={saveChanges}
          >
            {t("SAČUVAJTE")}
          </Button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={t("DEAKTIVIRAJ")}
        actions={[
          <Button
            className="cv-dashboard-employee-edit-button"
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deactivateProfile}
          >
            {t("DA")}
          </Button>,
          <Button
            className="cv-dashboard-employee-edit-button"
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setOpen(false)}
          >
            {"NE"}
          </Button>,
        ]}
        size="medium"
      >
        <div className="cv-profile-modal-text">
          {t("Da li ste sigurni da želite deaktivirati nalog" + "?")}
        </div>
        <div className="cv-profile-modal-text-error">
          {t(
            "Pažnja: nakon potvrde deaktivacije, nalog će biti obrisan u roku od 30 dana. U slučaju da opet pristupite prije isteka roka, nalog će biti ponovo aktiviran" +
              "."
          )}
        </div>
      </Modal>
      {/* EDUCATION MODAL */}

      <EducationsModal
        edu={state.educations}
        open={educationOpen}
        onClose={() => setEducationOpen(false)}
        state={state}
        setState={setState}
      />
      <WorkExpModal
        workexps={state.work_experiences}
        open={workExpOpen}
        onClose={() => setWorkExpOpen(false)}
        state={state}
        setState={setState}
      />
      <LanguagesModal
        langs={state.languages}
        open={languagesOpen}
        onClose={() => setLanguagesOpen(false)}
        state={state}
        setState={setState}
      />
      <ComputerSkillsModal
        skills={state.computer_skills}
        open={computerOpen}
        onClose={() => setComputerOpen(false)}
        state={state}
        setState={setState}
      />
      <OtherModal
        desc={state.aditional_info}
        open={aboutOpen}
        onClose={() => setAboutOpen(false)}
        state={state}
        setState={setState}
      />
      <DrivingLicenceModal
        licence={state.driver_licences}
        open={drivingOpen}
        onClose={() => setDrivingOpen(false)}
        state={state}
        setState={setState}
      />
      <LeavePageModal
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
        onLeave={() => history.goBack()}
      />
      {/* <RouterPrompt when={true} open={promptOpen} onOK={() => false} onCancel={() => setPromptOpen(false)}/> */}
    </Fragment>
  );
};

const RouterPrompt = ({ open, when, onOK, onCancel }) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(open);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    setShowPrompt(open);
  }, [open]);

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return "true";
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return (
    <LeavePageModal
      open={showPrompt}
      onClose={handleCancel}
      onLeave={handleOK}
    />
  );
};

const EducationsModal = ({ edu, open, onClose, ...parent }) => {
  const [educations, setEducations] = useState(edu);
  const [state, setState] = useState({
    id: 0,
    city: "",
    education_level_id: "",
    education_area_id: "",
    institution: "",
    title: "",
    course: "",
    start_date: null,
    end_date: null,
    ongoing: 0,
    profile_completely: 0,
  });

  useEffect(() => {
    setEducations(edu);
  }, [edu]);

  const { addings } = useUserContext();

  const [educationLevels, setEducationLevels] = useState([]);
  const [educationAreas, setEducationAreas] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      city: "",
      education_level_id: "",
      education_area_id: "",
      institution: "",
      title: "",
      course: "",
      start_date: null,
      end_date: null,
      ongoing: 0,
    });
  };

  useEffect(() => {
    setEducationLevels(
      addings.education_levels.map((x) => ({ value: x.id, label: x.name }))
    );
    setEducationAreas(
      addings.education_areas.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const handleCheckbox = (e, a) => {
    setState({ ...state, ongoing: a ? 1 : 0 });
  };

  const [deleteOpen, setDeleteOpen] = useState(0);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadEdu = (x) => {
    setState({ ...x });
  };

  const addEdu = async () => {
    try {
      const { end_date, id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.educationStore({
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        });
        parent.setState({
          ...parent.state,
          educations: [...parent.state.educations, res],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.educationUpdate(id, {
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        });
        parent.setState({
          ...parent.state,
          educations: parent.state.educations.map((x) =>
            x.id !== id ? x : res
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteEdu = async () => {
    await CandidateAPI.educationDelete(deleteOpen);
    parent.setState({
      ...parent.state,
      educations: parent.state.educations.filter((x) => x.id !== deleteOpen),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(0);
  };

  const { t } = useLanguageContext();

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("OBRAZOVANJE")}
        size="large"
        actions={[
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={clearFields}
          >
            {t("OČISTI POLJA")}
          </Button>,
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={addEdu}
          >
            {state.id === 0 ? t("DODAJ NOVO OBRAZOVANJE") : t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {educations?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`${t("Obrazovanje")} ${y + 1}`}
                value={x.title}
                onClick={() => loadEdu(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid">
              <AdminSelect
                className="cv-dashboard-profile-col-double"
                label={t("Nivo obrazovanja")}
                value={state.education_level_id}
                onChange={(education_level_id) =>
                  setState({ ...state, education_level_id })
                }
                color="secondary"
                variant="outlined"
                options={educationLevels}
                required
              />
              <AdminSelect
                label={t("Oblast obrazovanja")}
                value={state.education_area_id}
                onChange={(education_area_id) =>
                  setState({ ...state, education_area_id })
                }
                color="secondary"
                variant="outlined"
                options={educationAreas}
                required
              />
              <AdminInput
                label={t("Stečeno zvanje")}
                value={state.title}
                onChange={(title) => setState({ ...state, title })}
                color="secondary"
                variant="outlined"
                required
              />
              <AdminInput
                label={t("Naziv institucije")}
                value={state.institution}
                onChange={(institution) => setState({ ...state, institution })}
                color="secondary"
                variant="outlined"
                required
              />
              <AdminInput
                label={t("Grad")}
                value={state.city}
                onChange={(city) => setState({ ...state, city })}
                color="secondary"
                variant="outlined"
                required
              />
              <AdminInput
                className="cv-dashboard-profile-col-double"
                label={t("Smjer")}
                value={state.course}
                onChange={(course) => setState({ ...state, course })}
                color="secondary"
                variant="outlined"
                required
              />
              <AdminDatepicker
                className="cv-dashboard-profile-col-double"
                label={t("Datum upisa")}
                value={state.start_date}
                onChange={(start_date) => setState({ ...state, start_date })}
                color="secondary"
                variant="outlined"
                required
              />
              {!state.ongoing ? (
                <AdminDatepicker
                  className="cv-dashboard-profile-col-double"
                  label={t("Datum diplomiranja")}
                  value={state.end_date}
                  onChange={(end_date) => setState({ ...state, end_date })}
                  color="secondary"
                  variant="outlined"
                  minDate={state.start_date}
                  required
                />
              ) : null}
              <div className="cv-dashboard-profile-col-double cv-dashboard-profile-end-date-in-process">
                <label>
                  <Checkbox
                    color="secondary"
                    onChange={handleCheckbox}
                    checked={state.ongoing ? true : false}
                  />{" "}
                  {t("Još uvijek u toku")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(0)}
        title={t("OBRIŠI")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteEdu}
          >
            {"OBRIŠI"}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(0)}
          >
            {t("OTKAŽI")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {"Da li ste sigurni da želite da obrišete obrazovanje" + "?"}
        </div>
      </Modal>
    </Fragment>
  );
};

const WorkExpModal = ({ workexps, open, onClose, ...parent }) => {
  const [workExps, setWorkExps] = useState(workexps);
  const [state, setState] = useState({
    id: 0,
    company_name: "",
    location: "",
    position: "",
    type_of_work_id: "",
    description: "",
    start_date: null,
    end_date: null,
    ongoing: 0,
  });

  useEffect(() => {
    setWorkExps(workexps);
  }, [workexps]);

  const { addings } = useUserContext();

  const [categories, setCategories] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      company_name: "",
      location: "",
      position: "",
      type_of_work_id: "",
      description: "",
      start_date: null,
      end_date: null,
      ongoing: 0,
    });
  };

  useEffect(() => {
    setCategories(
      addings.type_of_works.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const handleCheckbox = (e, a) => {
    setState({ ...state, ongoing: a ? 1 : 0 });
  };

  const [deleteOpen, setDeleteOpen] = useState(0);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadWorkExp = (x) => {
    setState({
      id: x.id,
      company_name: x.company_name || "",
      location: x.location || "",
      position: x.position || "",
      type_of_work_id: x.type_of_work_id || "",
      description: x.description || "",
      start_date: x.start_date || null,
      end_date: x.end_date || null,
      ongoing: x.ongoing || "",
    });
  };

  const addWorkExp = async () => {
    try {
      const { end_date, id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.workExperienceStore({
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        });
        parent.setState({
          ...parent.state,
          work_experiences: [...parent.state.work_experiences, res],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.workExperienceUpdate(id, {
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        });
        parent.setState({
          ...parent.state,
          work_experiences: parent.state.work_experiences.map((x) =>
            x.id !== id ? x : res
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteWorkExp = async () => {
    await CandidateAPI.workExperienceDelete(deleteOpen);
    parent.setState({
      ...parent.state,
      work_experiences: parent.state.work_experiences.filter(
        (x) => x.id !== deleteOpen
      ),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(0);
  };
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="RADNO ISKUSTVO"
        size="large"
        actions={[
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={clearFields}
          >
            {t("OČISTI POLJA")}
          </Button>,
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={addWorkExp}
          >
            {state.id === 0 ? t("DODAJ NOVO RADNO ISKUSTVO") : t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {workExps?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`${t("Kompanija")} ${y + 1}`}
                value={x.company_name}
                onClick={() => loadWorkExp(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid">
              <AdminInput
                label={t("Kompanija")}
                value={state.company_name}
                onChange={(company_name) =>
                  setState({ ...state, company_name })
                }
                color="secondary"
                variant="outlined"
                required
              />
              <AdminInput
                label={t("Lokacija")}
                value={state.location}
                onChange={(location) => setState({ ...state, location })}
                color="secondary"
                variant="outlined"
                required
              />
              <AdminInput
                label={t("Radna pozicija")}
                value={state.position}
                onChange={(position) => setState({ ...state, position })}
                color="secondary"
                variant="outlined"
                required
              />
              <AdminSelect
                label={t("Kategorija posla")}
                value={state.type_of_work_id}
                onChange={(type_of_work_id) =>
                  setState({ ...state, type_of_work_id })
                }
                color="secondary"
                variant="outlined"
                options={categories}
                required
              />
              <AdminDatepicker
                className="cv-dashboard-profile-col-double"
                label={t("Datum zaposlenja")}
                value={state.start_date}
                onChange={(start_date) => setState({ ...state, start_date })}
                color="secondary"
                variant="outlined"
                required
              />
              {!state.ongoing ? (
                <AdminDatepicker
                  className="cv-dashboard-profile-col-double"
                  label={t("Datum prestanka radnog odnosa")}
                  value={state.end_date}
                  onChange={(end_date) => setState({ ...state, end_date })}
                  color="secondary"
                  variant="outlined"
                  minDate={state.start_date}
                  required
                />
              ) : null}

              <AdminInput
                className="cv-dashboard-profile-col-double"
                label={t("Opis pozicije")}
                value={state.description}
                onChange={(description) => setState({ ...state, description })}
                color="secondary"
                variant="outlined"
                multiline
                maxRows={4}
                minRows={4}
                required
              />
              <div className="cv-dashboard-profile-col-double cv-dashboard-profile-end-date-in-process">
                <label>
                  <Checkbox
                    color="secondary"
                    onChange={handleCheckbox}
                    checked={state.ongoing ? true : false}
                  />{" "}
                  {t("Još uvijek u toku")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(0)}
        title={t("OBRIŠI")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteWorkExp}
          >
            {t("OBRIŠI")}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(0)}
          >
            {t("OTKAŽI")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {t("Da li ste sigurni da želite da obrišete radno iskustvo") + "?"}
        </div>
      </Modal>
    </Fragment>
  );
};

const LanguagesModal = ({ langs, open, onClose, ...parent }) => {
  const [languages, setLanguages] = useState(langs);
  const [state, setState] = useState({
    id: 0,
    language_reads_id: "",
    language_speaks_id: "",
    language_writes_id: "",
    languages_id: "",
  });

  useEffect(() => {
    setLanguages(langs);
  }, [langs]);

  const { addings } = useUserContext();

  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [reads, setReads] = useState([]);
  const [writes, setWrites] = useState([]);
  const [speaks, setSpeaks] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      language_reads_id: "",
      language_speaks_id: "",
      language_writes_id: "",
      languages_id: "",
    });
  };

  useEffect(() => {
    setReads(
      addings.languages_read.map((x) => ({ value: x.id, label: x.name }))
    );
    setWrites(
      addings.languages_write.map((x) => ({ value: x.id, label: x.name }))
    );
    setSpeaks(
      addings.languages_speak.map((x) => ({ value: x.id, label: x.name }))
    );
    setLanguagesOptions(
      addings.languages.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const handleCheckbox = (e, a) => {
    setState({ ...state, ongoing: a ? 1 : 0 });
  };

  const [deleteOpen, setDeleteOpen] = useState(0);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadLang = (x) => {
    setState(x);
  };

  const addLang = async () => {
    try {
      const { end_date, id, ...other } = state;
      if (id === 0) {
        const { languages: l, ...res } = await CandidateAPI.languageStore({
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        });
        parent.setState({
          ...parent.state,
          languages: [
            ...parent.state.languages,
            { languages_name: l.name, ...res },
          ],
        });
        clearFields();
      } else {
        const { languages: l, ...res } = await CandidateAPI.languageUpdate(id, {
          ...other,
          ...(!other.ongoing ? { end_date } : {}),
        });
        parent.setState({
          ...parent.state,
          languages: parent.state.languages.map((x) =>
            x.id !== id ? x : { languages_name: l.name, ...res }
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteLang = async () => {
    await CandidateAPI.languageDelete(deleteOpen);
    parent.setState({
      ...parent.state,
      languages: parent.state.languages.filter((x) => x.id !== deleteOpen),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(0);
  };
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("STRANI JEZICI")}
        size="large"
        actions={[
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={clearFields}
          >
            {t("OČISTI POLJA")}
          </Button>,
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={addLang}
          >
            {state.id === 0 ? t("DODAJ NOVI JEZIK") : t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {languages?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`${t("Jezik")} ${y + 1}`}
                value={x.languages_name}
                onClick={() => loadLang(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid-lang">
              <AdminSelect
                className="cv-dashboard-profile-col-triple"
                label={t("Jezik")}
                value={state.languages_id}
                onChange={(languages_id) =>
                  setState({ ...state, languages_id })
                }
                color="secondary"
                variant="outlined"
                options={languagesOptions}
                required
              />
              <AdminSelect
                label={t("Čitanje")}
                value={state.language_reads_id}
                onChange={(language_reads_id) =>
                  setState({ ...state, language_reads_id })
                }
                color="secondary"
                variant="outlined"
                options={reads}
                required
              />
              <AdminSelect
                label={t("Pisanje")}
                value={state.language_writes_id}
                onChange={(language_writes_id) =>
                  setState({ ...state, language_writes_id })
                }
                color="secondary"
                variant="outlined"
                options={writes}
                required
              />
              <AdminSelect
                label={t("Govor")}
                value={state.language_speaks_id}
                onChange={(language_speaks_id) =>
                  setState({ ...state, language_speaks_id })
                }
                color="secondary"
                variant="outlined"
                options={speaks}
                required
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(0)}
        title={t("OBRIŠI")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteLang}
          >
            {t("OBRIŠI")}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(0)}
          >
            {t("OTKAŽI")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {t("Da li ste sigurni da želite da obrišete strani jezik") + "?"}
        </div>
      </Modal>
    </Fragment>
  );
};

const ComputerSkillsModal = ({ skills, open, onClose, ...parent }) => {
  const [computerSkills, setComputerSkills] = useState(skills);
  const [state, setState] = useState({
    id: 0,
    computer_skill_name_id: "",
    computer_skill_knowledge_level_id: "",
  });

  useEffect(() => {
    setComputerSkills(skills);
  }, [skills]);

  const { addings } = useUserContext();

  const [computerSkillsOptions, setComputerSkillsOptions] = useState([]);
  const [skillLevels, setSkillLevels] = useState([]);

  const clearFields = () => {
    setState({
      id: 0,
      computer_skill_name_id: "",
      computer_skill_knowledge_level_id: "",
    });
  };

  useEffect(() => {
    setSkillLevels(
      addings.computer_skills_levels.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
    setComputerSkillsOptions(
      addings.computer_skills_names.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const [deleteOpen, setDeleteOpen] = useState(0);

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setDeleteOpen(id);
  };

  const loadSkill = (x) => {
    setState(x);
  };

  const addSkill = async () => {
    try {
      const { id, ...other } = state;
      if (id === 0) {
        const res = await CandidateAPI.computerSkillStore(other);
        parent.setState({
          ...parent.state,
          computer_skills: [...parent.state.computer_skills, res],
        });
        clearFields();
      } else {
        const res = await CandidateAPI.computerSkillUpdate(id, other);
        parent.setState({
          ...parent.state,
          computer_skills: parent.state.computer_skills.map((x) =>
            x.id !== id ? x : res
          ),
        });
        clearFields();
      }
    } catch (e) {}
  };

  const deleteSkill = async () => {
    await CandidateAPI.computerSkillDelete(deleteOpen);
    parent.setState({
      ...parent.state,
      languages: parent.state.computer_skills.filter(
        (x) => x.id !== deleteOpen
      ),
    });
    if (deleteOpen === state.id) {
      clearFields();
    }
    setDeleteOpen(0);
  };
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="RAD NA RAČUNARU"
        size="large"
        actions={[
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={clearFields}
          >
            {t("OČISTI POLJA")}
          </Button>,
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={addSkill}
          >
            {state.id === 0 ? t("DODAJ NOVU VJESTINU") : t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-edit-modal-side">
            {computerSkills?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className={`cv-dashboard-profile-edit-single ${
                  x.id === state.id ? "cv-dashboard-profile-edit-active" : ""
                }`}
                label={`${t("Vještina")} ${y + 1}`}
                value={x.computer_skill_name.name}
                onClick={() => loadSkill(x)}
                action={
                  <IconButton onClick={(e) => handleDelete(e, x.id)}>
                    <Delete />
                  </IconButton>
                }
              />
            ))}
          </div>
          <div className="cv-dashboard-profile-edit-modal-side">
            <div className="cv-dashboard-profile-edit-modal-grid">
              <AdminSelect
                className="cv-dashboard-profile-col-double"
                label={t("Vještina")}
                value={state.computer_skill_name_id}
                onChange={(computer_skill_name_id) =>
                  setState({ ...state, computer_skill_name_id })
                }
                color="secondary"
                variant="outlined"
                options={computerSkillsOptions}
                required
              />
              <AdminSelect
                className="cv-dashboard-profile-col-double"
                label={t("Nivo znanja")}
                value={state.computer_skill_knowledge_level_id}
                onChange={(computer_skill_knowledge_level_id) =>
                  setState({ ...state, computer_skill_knowledge_level_id })
                }
                color="secondary"
                variant="outlined"
                options={skillLevels}
                required
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={deleteOpen}
        onClose={() => setDeleteOpen(0)}
        title={t("OBRIŠI")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deleteSkill}
          >
            {t("OBRIŠI")}
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setDeleteOpen(0)}
          >
            {t("OTKAŽI")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {t("Da li ste sigurni da želite da obrišete vještinu?")}
        </div>
      </Modal>
    </Fragment>
  );
};

const OtherModal = ({ desc, open, onClose, ...parent }) => {
  const [state, setState] = useState(desc);

  useEffect(() => {
    setState(desc);
  }, [desc]);

  const saveOther = async () => {
    const { aditional_info } = await CandidateAPI.updateOptionalFields({
      aditional_info: state,
    });
    parent.setState({ ...parent.state, aditional_info });
    onClose();
  };
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("OSTALO")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={saveOther}
          >
            {"SAČUVAJ"}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <AdminInput
            label={t("Ukratko o meni")}
            value={state}
            onChange={(description) => setState(description)}
            color="secondary"
            variant="outlined"
            multiline
            maxRows={4}
            minRows={4}
            required
          />
        </div>
      </Modal>
    </Fragment>
  );
};

const DrivingLicenceModal = ({ licence, open, onClose, ...parent }) => {
  const [state, setState] = useState({
    own_vehicle: "",
    drivers_licence_category_id: "",
    additional_info: "",
  });

  const { addings } = useUserContext();

  const [drivingCategories, setDrivingCategories] = useState([]);

  useEffect(() => {
    setDrivingCategories(
      addings.drivers_licence_categories.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
  }, []);

  useEffect(() => {
    if (licence && licence[0]) setState({ ...state, ...licence[0] });
  }, [licence]);

  const saveLicences = async () => {
    const { driver_licences } = await CandidateAPI.updateDriverLicence(state);
    parent.setState({ ...parent.state, driver_licences });
    onClose();
  };
  const { t } = useLanguageContext();

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("VOZAČKA DOZVOLA")}
        size="medium"
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={saveLicences}
          >
            {t("SAČUVAJ")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-profile-edit-modal-main">
          <div className="cv-dashboard-profile-grid cv-dashboard-profile-driving-licence">
            <AdminSelect
              label={t("Vozačka dozvola")}
              value={state.drivers_licence_category_id}
              onChange={(drivers_licence_category_id) =>
                setState({ ...state, drivers_licence_category_id })
              }
              color="secondary"
              variant="outlined"
              options={drivingCategories}
              required
            />
            <AdminSelect
              label={t("Sopstveno vozilo")}
              value={state.own_vehicle}
              onChange={(own_vehicle) => setState({ ...state, own_vehicle })}
              color="secondary"
              variant="outlined"
              options={[
                {
                  value: "1",
                  label: t("Da"),
                },
                {
                  value: "0",
                  label: t("Ne"),
                },
              ]}
              required
            />
            <AdminInput
              className="cv-dashboard-profile-col-double"
              label={t("Dodatne informacije")}
              value={state.additional_info}
              onChange={(additional_info) =>
                setState({ ...state, additional_info })
              }
              color="secondary"
              variant="outlined"
              multiline
              maxRows={10}
              minRows={4}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Profile;
