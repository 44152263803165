import React, { Fragment } from "react";
import Hero from "../../components/custom/hero";
import { NotFound } from "../../components/custom";
import { useLanguageContext } from "../../context/language";
import './style.scss';

const NotFoundPage = () => {
  const { t } = useLanguageContext();
  return (
    <div className="cv-notfoundpage-main">
      <NotFound title={t("404")} description={t("Stranica nije pronađena")} />
    </div>
  );
};

export default NotFoundPage;
