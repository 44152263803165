import { Fragment } from "react"
import { Footer, Navigation } from "../../components/custom"

const PublicLayout = ({ children }) => (
  <Fragment>
    <Navigation />
    <div className="cv-page">{children}</div>
    <Footer />
  </Fragment>
);

export default PublicLayout;