import { InputWrapper } from "./styles";

const Input = ({
  color = "primary",
  variant = "outlined",
  onChange,
  inputComponent,
  endAdornment,
  startAdornment,
  ...props
}) => {
  const onValue = (e) => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <InputWrapper
      color={color}
      variant={variant}
      onChange={onValue}
      InputProps={{
        endAdornment,
        startAdornment,
        inputComponent,
      }}
      {...props}
    />
  );
};

export default Input;
