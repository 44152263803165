import { DashNavigation, Sidebar } from "../../components/custom";
import { SimpleCard } from "../../components/ui";
import './style.scss';

const DashboardLayout = ({ children }) => (
  <div className="cv-dashboard-layout">
    <SimpleCard className="cv-dashboard-card">
      <DashNavigation />
      <div className="cv-dashboard-layout-body">
        <Sidebar />
        <div className="cv-dashboard-layout-content">{children}</div>
      </div>
    </SimpleCard>
  </div>
);

export default DashboardLayout;
