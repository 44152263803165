import React, { useState } from "react";
import "./style.scss";

const SimpleCard = ({ children, className, title, actions, withContent, ...props }) => {

  const [animation, setAnimation] = useState(true);

  return (
    <div
      className={`cv-simplecard-main ${
        !animation ? "cv-simplecard-main-disable-animation" : ""
      } ${className}`}
      {...props}
      onAnimationEnd={() => setAnimation(false)}
    >
      {title ? (
        <div className="cv-simplecard-title">
          <div className="cv-simplecard-title-element">{title}</div>
          {actions ? (
            <div className="cv-simplecard-actions">{actions}</div>
          ) : null}
        </div>
      ) : null}
      {withContent !== undefined ? (
        <div className="cv-simplecard-content">{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default SimpleCard;
