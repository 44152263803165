import React from "react";
import { useModalContext } from "../../../context/modal";
// import { Button } from "../../ui";
import "./style.scss";

const Advice = ({ number, icon, title, description, long_description }) => {
  const { push, close } = useModalContext();

  const pushModal = () => {
    push({
      ref: "details",
      size: "medium",
      title: "Savjet",
      body: (
        <div className="cv-advice-modal">
          <div className="cv-advice-icon">
            <div className="cv-advice-number">{number}</div>
            {icon}
            {/* <img src={icon} /> */}
          </div>
          <div className="cv-advice-modal-title">{title}</div>
          <div className="cv-advice-modal-description">{long_description}</div>
        </div>
      ),
      actions: [
        // <Button
        //   fullWidth
        //   color="primary"
        //   variant="contained"
        //   onClick={() => close("details")}
        // >
        //   Zatvori
        // </Button>,
      ],
    });
  };

  return (
    <div className="cv-advice-wrapper" onClick={pushModal}>
      <div className="cv-advice-icon">
        <div className="cv-advice-number">{number}</div>
        {icon}
        {/* <img src={icon} /> */}
      </div>
      <div className="cv-advice-title">{title}</div>
      <div className="cv-advice-description">{description}</div>
    </div>
  );
};

export default Advice;
