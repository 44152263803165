import { CheckRounded, CloseRounded, CloudDownload, Delete, PlayArrowRounded } from "@mui/icons-material";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CandidateAPI } from "../../api/v2";
import { useLanguageContext } from "../../context/language";

import {
  DashTitle,
  DataDisplay,
  ModalVideo,
  Modal,
} from "../../components/custom";
import { SimpleCard, Button } from "../../components/ui";
import { useUserContext } from "../../context/user";
import VideoRecorder from "react-video-recorder";
import "./style.scss";
import { blobToFile } from "../../utils/converter";

const CvVideo = () => {
  const [state, setState] = useState({
    src: "",
    duration: 0,
    date: "",
    extension: "",
    isVideoUploading: false
  });

  const [videoBlob, setVideoBlob] = useState(null);

  const [open, setOpen] = useState(false);
  const [recorderOpen, setRecorderOpen] = useState(false);

  const { user, setUser } = useUserContext();

  useEffect(() => {
    if (user.cv_video) {
      setState({
        src: user.cv_video?.video,
        duration: 0,
        date: moment(user.cv_video?.created_at).format("DD.MM.YYYY"),
        extension: user.cv_video?.video.split(".").pop(),
      });
    }
  }, [user]);

  const onData = (e) => {
    const duration = moment.utc(e.target.duration * 1000).format("HH:mm:ss");
    setState({ ...state, duration });
  };

  const {t} = useLanguageContext();

  const deleteCvVideo = async () => {
    await CandidateAPI.deleteProfileVideo();
    setOpen(false);
    setState({ src: "", duration: 0, date: "", extension: "" });
    setUser({ ...user, cv_video: null });
  };

  const fileRef = useRef();


  const uploadVideo = async (e) => {
    setState({ ...state, isVideoUploading: true });
    try {
      const { cv_video } = await CandidateAPI.updateProfileVideo(
        e.target.files[0]
      );
      setState({ ...state, video: cv_video.video, isVideoUploading: false });
      setUser({ ...user, cv_video });
    } catch (error) {
      console.error(error);
    }
  };


  const saveRecording = async () => {
    const file = blobToFile(videoBlob, "recording.webm");

    const { cv_video } = await CandidateAPI.updateProfileVideo(file);
    setUser({ ...user, cv_video });
    setRecorderOpen(false);
    setVideoBlob(null);
  };

  const closeRecording = () => {
    setRecorderOpen(false);
    setVideoBlob(null);
  };

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Moj Video CV")}
          subtitle={t("Panel za upravljanje video biografijom")}
        />
        <SimpleCard
          className="cv-dashboard-cvvideo-content"
          title={t("VIDEO CV INFO")}
          withContent
        >
          <div className="cv-dashboard-cvvideo-preview">
            <div className="cv-dashboard-cvvideo-preview-video">
              {user.cv_video || state.isVideoUploading ? (
                <ModalVideo
                  src={state.src}
                  ondata={onData}
                  isUploading={state.isVideoUploading}
                />
              ) : (
                <div className="cv-dashboard-cvvideo-preview-empty">
                  <img src="/assets/images/holder/empty.png" alt="Empty" />
                </div>
              )}
            </div>
            <div className="cv-dashboard-cvvideo-info">
              {user.cv_video ? (
                <Fragment>
                  <DataDisplay
                    className="cv-mb-10"
                    label={t("Format video zapisa")}
                    value={state.extension}
                  />
                  <DataDisplay
                    className="cv-mb-10"
                    label={t("Trajanje video zapisa")}
                    value={state.duration}
                  />
                  <DataDisplay
                    className="cv-mb-10"
                    label={t("Datum otpremanja")}
                    value={state.date}
                  />
                  <Button
                    className="cv-dashboard-cvvideo-preview-button"
                    color="error"
                    variant="contained"
                    startIcon={<Delete />}
                    size="large"
                    fullWidth
                    onClick={() => setOpen(true)}
                  >
                    {t("OBRIŠI VIDEO CV")}
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <DataDisplay
                    className="cv-mb-10"
                    label={t("Napomena")}
                    value={
                      <div className="cv-dashboard-cvvideo-info-help">
                        <div className="cv-mb-20">
                          {t(
                            "Prilikom dodavanja video biografije morate imati spreman fajl na svom lokalnom uređaju. Maksimalna dozvoljena veličina fajla iznosi 20 MB, a podržani formati su mp4, wav."
                          )}
                        </div>
                        <div className="cv-mb-20">
                          {t(
                            "Prije početka snimanja video biografije predlažemo da se pripremite u skladu sa našim savjetima kao što su uvježbavanje govora, poslovno oblačenje i odabir lokacije. Detaljnije informacije i sve naše savjete možete naći na stranici Ispričajte svoju CV priču."
                          )}
                        </div>
                        <div className="cv-dashboard-cvvideo-info-help-images">
                          <div className="cv-dashboard-cvvideo-info-help-image">
                            <div className="cv-dashboard-cvvideo-info-help-image-1">
                              <img
                                src={
                                  "https://i0.wp.com/post.medicalnewstoday.com/wp-content/uploads/sites/3/2020/03/GettyImages-1092658864_hero-1024x575.jpg?w=1155&h=1528"
                                }
                                alt="Correct"
                              />
                            </div>
                            <div className="cv-dashboard-cvvideo-info-help-image-icon-1">
                              <CheckRounded />
                            </div>
                          </div>
                          <div className="cv-dashboard-cvvideo-info-help-image">
                            <div className="cv-dashboard-cvvideo-info-help-image-2">
                              <img
                                src={
                                  "https://i0.wp.com/post.medicalnewstoday.com/wp-content/uploads/sites/3/2020/03/GettyImages-1092658864_hero-1024x575.jpg?w=1155&h=1528"
                                }
                                alt="Wrong"
                              />
                            </div>
                            <div className="cv-dashboard-cvvideo-info-help-image-icon-2">
                              <CloseRounded />
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />
                  <input
                    type="file"
                    hidden
                    ref={fileRef}
                    onChange={uploadVideo}
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                  <div className="cv-dashboard-cvvideo-preview-buttons">
                    <Button
                      className="cv-dashboard-cvvideo-preview-button"
                      color="secondary"
                      variant="contained"
                      startIcon={<CloudDownload />}
                      size="medium"
                      fullWidth
                      onClick={() => fileRef.current.click()}
                    >
                      {t("DODAJTE NOVI VIDEO")}
                    </Button>
                    <Button
                      className="cv-dashboard-cvvideo-preview-button"
                      color="primary"
                      variant="contained"
                      startIcon={<PlayArrowRounded />}
                      size="medium"
                      fullWidth
                      onClick={() => setRecorderOpen(true)}
                    >
                      {t("KREIRAJTE NOVI VIDEO")}
                    </Button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </SimpleCard>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={t("OBRIŠI VIDEO CV")}
        size="medium"
        actions={[
          <Button
            variant="contained"
            color="secondary"
            onClick={deleteCvVideo}
            size="large"
            fullWidth
          >
            {t("DA")}
          </Button>,
          <Button
            variant="contained"
            color="default"
            onClick={() => setOpen(false)}
            size="large"
            fullWidth
          >
            {t("NE")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          {t("Da li ste sigurni da želite da obrišete video snimak?")}
        </div>
      </Modal>
      <Modal
        open={recorderOpen}
        onClose={closeRecording}
        title={t("SNIMI VIDEO CV")}
        size="medium"
        actions={[
          <Button
            variant="contained"
            color="secondary"
            onClick={saveRecording}
            size="large"
            fullWidth
            className={!videoBlob ? "cv-disabled" : null}
          >
            {t("SAČUVAJ")}
          </Button>,
          <Button
            variant="contained"
            color="default"
            onClick={closeRecording}
            size="large"
            fullWidth
          >
            {t("OTKAŽI")}
          </Button>,
        ]}
      >
        <div className="cv-dashboard-cvvideo-modal-content">
          <VideoRecorder
            mimeType="video/webm"
            isOnlyInitially={true}
            showReplayControls={true}
            videoControlsList="nodownload"
            disablepictureinpicture
            onRecordingComplete={(videoBlob) => {
              setVideoBlob(videoBlob);
            }}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default CvVideo;
