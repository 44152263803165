import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal } from "..";
import { Button } from "../../ui";
import { IconButton } from "@mui/material";
import { Close, PlayArrowRounded } from "@mui/icons-material";
import "./style.scss";
import { deviceType } from "../../../utils/device";
import { useEventContext } from "../../../context/event";

const ModalVideo = ({
  isUploading = false,
  src,
  title = "CV VIDEO",
  ondata = () => {},
}) => {
  const [videoOpen, setVideoOpen] = useState(false);

  const fullScreenVideoRef = useRef();

  const { fullscreen } = useEventContext();

  const openVideo = () => {
    const type = deviceType();
    if (type === "mobile") {
      fullScreenVideoRef.current?.requestFullscreen();
    } else {
      setVideoOpen(true);
    }
  };

  useEffect(() => {
    if (!fullscreen && fullScreenVideoRef.current) {
      fullScreenVideoRef.current.pause();
      fullScreenVideoRef.current.currentTime = 0;
    }
  },[fullscreen])

  return (
    <Fragment>
      <div
        className="cv-modalvideo-main"
        onClick={() => !isUploading && openVideo()}
      >
        {isUploading ? (
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-inner">
                <div className="overlay-content">
                  <span className="spinner"></span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <video src={src} onLoadedData={ondata}></video>
        )}

        <IconButton
          className="cv-modalvideo-button"
          color="primary"
          size="large"
        >
          <PlayArrowRounded />
        </IconButton>
      </div>
      <Modal
        open={videoOpen}
        onClose={() => setVideoOpen(false)}
        title={title}
        actions={[
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setVideoOpen(false)}
          >
            ZATVORI
          </Button>,
        ]}
        size="large"
      >
        <video
          className="cv-modalvideo-video-preview"
          src={src}
          controls
          autoPlay
        ></video>
      </Modal>
      <video
        src={src}
        onLoadedData={ondata}
        controls
        ref={fullScreenVideoRef}
        className="cv-modalvideo-video-preview-fullscreen"
      />
    </Fragment>
  );
};

export default ModalVideo;
