const Company2 = () => (
  <svg viewBox="0 0 118 118">
    <path
      d="M96.63 74.61C85.19 74.61 75.95 65.37 75.95 53.93C75.95 42.5 85.19 33.26 96.63 33.26C108.06 33.26 117.3 42.5 117.3 53.93C117.3 65.37 108.06 74.61 96.63 74.61Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M48.25 35.43C38.46 35.43 30.54 27.51 30.54 17.72C30.54 7.92 38.46 0 48.25 0C58.05 0 65.97 7.92 65.97 17.72C65.97 27.51 58.05 35.43 48.25 35.43Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M29.51 117.3C13.19 117.3 0 104.11 0 87.79C0 71.47 13.19 58.27 29.51 58.27C45.83 58.27 59.03 71.47 59.03 87.79C59.03 104.11 45.83 117.3 29.51 117.3Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M99.66 107.36H14.68C12.07 107.36 9.94 105.23 9.94 102.62V17.64C9.94 15.03 12.07 12.9 14.68 12.9H99.66C102.27 12.9 104.4 15.03 104.4 17.64V102.62C104.4 105.23 102.27 107.36 99.66 107.36Z"
      fill="white"
    ></path>
    <path
      d="M86.68 107.36H29.12C29.12 72.32 57.53 43.91 92.56 43.91H104.36V89.64C104.4 99.43 96.47 107.36 86.68 107.36Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.68 108.46H27.66C17.28 108.46 8.84 100.02 8.84 89.64V30.62C8.84 20.24 17.28 11.8 27.66 11.8H86.68C97.06 11.8 105.5 20.24 105.5 30.62V89.64C105.5 100.02 97.06 108.46 86.68 108.46ZM27.66 14.01C18.51 14.01 11.05 21.46 11.05 30.62V89.64C11.05 98.8 18.51 106.25 27.66 106.25H86.68C95.84 106.25 103.29 98.8 103.29 89.64V30.62C103.29 21.46 95.84 14.01 86.68 14.01H27.66Z"
      fill="#21365F"
    ></path>
    <path
      d="M67.18 76.08L73.1 70.16L79.01 76.08L73.09 81.99L67.18 76.08Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.53 85.22C74.25 85.22 73.98 85.1 73.74 84.91L65.69 76.86C65.26 76.42 65.26 75.71 65.69 75.28L72.32 68.65C72.76 68.22 73.47 68.22 73.9 68.65L81.95 76.7C82.38 77.13 82.38 77.84 81.95 78.28L75.32 84.91C75.08 85.1 74.81 85.22 74.53 85.22ZM68.02 76.07L74.53 82.58L79.62 77.49L73.11 70.98L68.02 76.07Z"
      fill="#21365F"
    ></path>
    <path
      d="M71.71 84.45L81.5 74.66L90.99 84.15L81.19 93.94L71.71 84.45Z"
      fill="white"
    ></path>
    <path
      d="M87.51 87.59L81.2 93.9L74.65 87.35L80.41 81.59C80.72 81.28 81.2 81.28 81.51 81.59L87.51 87.59Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.16 95.05C80.88 95.05 80.61 94.93 80.37 94.73L70.9 85.26C70.7 85.06 70.59 84.79 70.59 84.47C70.59 84.16 70.7 83.88 70.9 83.68L80.69 73.9C81.12 73.47 81.83 73.47 82.26 73.9L91.73 83.37C91.93 83.57 92.05 83.84 92.05 84.16C92.05 84.43 91.93 84.75 91.73 84.95L81.95 94.73C81.75 94.93 81.47 95.05 81.16 95.05ZM73.27 84.43L81.16 92.32L89.37 84.12L81.47 76.23L73.27 84.43Z"
      fill="#21365F"
    ></path>
    <path
      d="M92.72 92.4L89.44 95.68C87.63 97.49 84.71 97.49 82.93 95.68L81.2 93.94L90.98 84.16L92.72 85.89C94.49 87.71 94.49 90.63 92.72 92.4Z"
      fill="white"
    ></path>
    <path
      d="M91.42 91.53L92.48 92.6L92.68 92.4C94.49 90.59 94.49 87.67 92.68 85.89L90.94 84.16L81.16 93.94L82.9 95.68C83.09 95.88 83.29 96.03 83.53 96.19L88.18 91.58C89.09 90.63 90.55 90.63 91.42 91.53Z"
      fill="white"
    ></path>
    <path
      d="M89.44 95.68L92.52 92.6L91.46 91.53C90.55 90.63 89.09 90.63 88.18 91.53L83.53 96.15C85.34 97.45 87.83 97.3 89.44 95.68Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.17 98.12C84.71 98.12 83.25 97.57 82.15 96.47L80.41 94.73C79.98 94.3 79.98 93.59 80.41 93.15L90.19 83.37C90.63 82.93 91.34 82.93 91.77 83.37L93.51 85.1C95.72 87.35 95.72 90.94 93.51 93.19L90.23 96.47C89.13 97.57 87.63 98.12 86.17 98.12ZM82.74 93.94L83.68 94.93C85.07 96.31 87.27 96.31 88.66 94.93L91.93 91.65C93.31 90.27 93.31 88.06 91.93 86.68L90.98 85.74L82.74 93.94Z"
      fill="#21365F"
    ></path>
    <path
      d="M29.04 32.04C40.76 20.32 59.74 20.32 71.41 32.04C83.13 43.76 83.13 62.73 71.41 74.41C59.7 86.13 40.72 86.13 29.04 74.41C17.36 62.69 17.36 43.72 29.04 32.04Z"
      fill="white"
    ></path>
    <path
      d="M71.45 32.04C67.19 27.78 61.98 25.09 56.54 23.95C51.06 25.09 45.85 27.82 41.59 32.04C29.87 43.76 29.87 62.73 41.59 74.41C45.85 78.67 51.06 81.36 56.5 82.5C61.98 81.32 67.19 78.63 71.45 74.41C83.13 62.69 83.13 43.72 71.45 32.04Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M50.27 73.9C38.83 73.9 29.59 64.66 29.59 53.22C29.59 41.79 38.83 32.55 50.27 32.55C61.7 32.55 70.94 41.79 70.94 53.22C70.94 64.66 61.7 73.9 50.27 73.9Z"
      fill="white"
    ></path>
    <path
      d="M64.86 38.63C63.05 36.81 60.96 35.43 58.71 34.4C51.53 37.64 46.52 44.86 46.52 53.22C46.52 61.63 51.53 68.81 58.71 72.04C60.92 71.06 63.01 69.64 64.83 67.82C72.91 59.77 72.91 46.68 64.86 38.63Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.27 74.96C38.27 74.96 28.49 65.22 28.49 53.19C28.49 41.15 38.23 31.41 50.27 31.41C62.3 31.41 72.05 41.15 72.05 53.19C72.05 65.22 62.26 74.96 50.27 74.96ZM50.27 33.65C39.49 33.65 30.7 42.41 30.7 53.22C30.7 64 39.46 72.79 50.27 72.79C61.04 72.79 69.84 64.04 69.84 53.22C69.8 42.45 61.04 33.65 50.27 33.65Z"
      fill="#21365F"
    ></path>
    <path
      d="M82.26 29.16C80.63 29.16 79.31 27.83 79.31 26.2C79.31 24.56 80.63 23.24 82.26 23.24C83.9 23.24 85.22 24.56 85.22 26.2C85.22 27.83 83.9 29.16 82.26 29.16Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.26 30.26C80.01 30.26 78.2 28.45 78.2 26.2C78.2 23.95 80.02 22.13 82.26 22.13C84.51 22.13 86.33 23.95 86.33 26.2C86.33 28.45 84.47 30.26 82.26 30.26ZM82.26 24.34C81.24 24.34 80.41 25.17 80.41 26.2C80.41 27.22 81.24 28.05 82.26 28.05C83.29 28.05 84.12 27.22 84.12 26.2C84.08 25.17 83.25 24.34 82.26 24.34Z"
      fill="#21365F"
    ></path>
    <path
      d="M95.52 55.71C93.88 55.71 92.56 54.39 92.56 52.75C92.56 51.11 93.88 49.79 95.52 49.79C97.16 49.79 98.48 51.11 98.48 52.75C98.48 54.39 97.16 55.71 95.52 55.71Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.52 56.82C93.27 56.82 91.46 55 91.46 52.75C91.46 50.5 93.27 48.69 95.52 48.69C97.77 48.69 99.59 50.5 99.59 52.75C99.59 55 97.77 56.82 95.52 56.82ZM95.52 50.9C94.49 50.9 93.67 51.73 93.67 52.75C93.67 53.78 94.49 54.61 95.52 54.61C96.55 54.61 97.38 53.78 97.38 52.75C97.38 51.73 96.55 50.9 95.52 50.9Z"
      fill="#21365F"
    ></path>
    <path
      d="M91.1 70.47C89.47 70.47 88.14 69.14 88.14 67.51C88.14 65.87 89.47 64.55 91.1 64.55C92.74 64.55 94.06 65.87 94.06 67.51C94.06 69.14 92.74 70.47 91.1 70.47Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.1 71.57C88.85 71.57 87.04 69.76 87.04 67.51C87.04 65.26 88.85 63.44 91.1 63.44C93.35 63.44 95.17 65.26 95.17 67.51C95.17 69.76 93.35 71.57 91.1 71.57ZM91.1 65.65C90.08 65.65 89.25 66.48 89.25 67.51C89.25 68.53 90.08 69.36 91.1 69.36C92.13 69.36 92.96 68.53 92.96 67.51C92.96 66.48 92.13 65.65 91.1 65.65Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.27 84.31C33.14 84.31 19.18 70.39 19.18 53.22C19.18 36.1 33.1 22.13 50.27 22.13C67.39 22.13 81.36 36.06 81.36 53.22C81.32 70.35 67.39 84.31 50.27 84.31ZM50.27 24.34C34.37 24.34 21.38 37.28 21.38 53.22C21.38 69.16 34.33 82.11 50.27 82.11C66.21 82.11 79.15 69.16 79.15 53.22C79.15 37.28 66.17 24.34 50.27 24.34Z"
      fill="#21365F"
    ></path>
    <path
      d="M64.11 50.07L58.43 44.39L47.58 55.2L41.59 49.2L35.9 54.84L47.58 66.52L64.11 50.07Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M47.58 67.63C47.31 67.63 47.03 67.51 46.79 67.31L35.12 55.63C34.68 55.2 34.68 54.49 35.12 54.05L40.8 48.37C40.99 48.17 41.27 48.06 41.59 48.06C41.86 48.06 42.18 48.17 42.37 48.37L47.62 53.62L57.68 43.6C58.12 43.16 58.83 43.16 59.26 43.6L64.94 49.28C65.14 49.48 65.26 49.75 65.26 50.07C65.26 50.34 65.14 50.66 64.94 50.86L48.41 67.31C48.14 67.51 47.86 67.63 47.58 67.63ZM37.48 54.84L47.58 64.94L62.54 50.03L58.43 45.96L48.37 55.99C47.94 56.42 47.23 56.42 46.79 55.99L41.55 50.74L37.48 54.84Z"
      fill="#21365F"
    ></path>
    <path
      d="M72.32 102.54C71.73 102.54 71.22 102.03 71.22 101.44V95.52C71.22 94.93 71.73 94.42 72.32 94.42C72.91 94.42 73.43 94.93 73.43 95.52V101.44C73.43 102.07 72.95 102.54 72.32 102.54Z"
      fill="#21365F"
    ></path>
    <path
      d="M75.28 99.62H69.36C68.77 99.62 68.26 99.11 68.26 98.52C68.26 97.93 68.77 97.41 69.36 97.41H75.28C75.87 97.41 76.39 97.93 76.39 98.52C76.39 99.11 75.87 99.62 75.28 99.62Z"
      fill="#21365F"
    ></path>
    <path
      d="M63.48 99.62C62.87 99.62 62.38 99.13 62.38 98.52C62.38 97.91 62.87 97.41 63.48 97.41C64.09 97.41 64.59 97.91 64.59 98.52C64.59 99.13 64.09 99.62 63.48 99.62Z"
      fill="#21365F"
    ></path>
    <path
      d="M59.06 99.62C58.45 99.62 57.96 99.13 57.96 98.52C57.96 97.91 58.45 97.41 59.06 97.41C59.68 97.41 60.17 97.91 60.17 98.52C60.17 99.13 59.68 99.62 59.06 99.62Z"
      fill="#21365F"
    ></path>
    <path
      d="M53.15 99.62C52.54 99.62 52.04 99.13 52.04 98.52C52.04 97.91 52.54 97.41 53.15 97.41C53.76 97.41 54.25 97.91 54.25 98.52C54.25 99.13 53.76 99.62 53.15 99.62Z"
      fill="#21365F"
    ></path>
    <path
      d="M44.31 99.62C43.7 99.62 43.2 99.13 43.2 98.52C43.2 97.91 43.7 97.41 44.31 97.41C44.92 97.41 45.41 97.91 45.41 98.52C45.41 99.13 44.92 99.62 44.31 99.62Z"
      fill="#21365F"
    ></path>
    <path
      d="M25.49 95.17C24.9 95.17 24.38 94.65 24.38 94.06V88.14C24.38 87.55 24.9 87.04 25.49 87.04C26.08 87.04 26.59 87.55 26.59 88.14V94.06C26.59 94.69 26.08 95.17 25.49 95.17Z"
      fill="#21365F"
    ></path>
    <path
      d="M28.41 92.25H22.49C21.9 92.25 21.38 91.73 21.38 91.14C21.38 90.55 21.9 90.04 22.49 90.04H28.41C29 90.04 29.51 90.55 29.51 91.14C29.55 91.73 29.04 92.25 28.41 92.25Z"
      fill="#21365F"
    ></path>
    <path
      d="M34.33 92.21C33.72 92.21 33.22 91.71 33.22 91.1C33.22 90.49 33.72 90 34.33 90C34.94 90 35.43 90.49 35.43 91.1C35.43 91.71 34.94 92.21 34.33 92.21Z"
      fill="#21365F"
    ></path>
    <path
      d="M40.24 92.21C39.63 92.21 39.14 91.71 39.14 91.1C39.14 90.49 39.63 90 40.24 90C40.86 90 41.35 90.49 41.35 91.1C41.35 91.71 40.86 92.21 40.24 92.21Z"
      fill="#21365F"
    ></path>
    <path
      d="M47.62 92.21C47.01 92.21 46.52 91.71 46.52 91.1C46.52 90.49 47.01 90 47.62 90C48.23 90 48.73 90.49 48.73 91.1C48.73 91.71 48.23 92.21 47.62 92.21Z"
      fill="#21365F"
    ></path>
    <path
      d="M57.96 92.21C57.35 92.21 56.86 91.71 56.86 91.1C56.86 90.49 57.35 90 57.96 90C58.57 90 59.06 90.49 59.06 91.1C59.06 91.71 58.57 92.21 57.96 92.21Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Company2;