import React from 'react';

import './style.scss';

const Spinner = () => {

  return (
    <div className="spinner-custom-loading-wrap">
      <div className="spinner-custom-loading">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    </div>
  );
};

export default Spinner;