import { PaginationWrapper } from "./styles";


const Pagination = ({ count, page, setPage }) => {
    const onChange = (e, value) => {
        setPage(value)
    }
    return <PaginationWrapper count={count} page={page} onChange={onChange}/>
}

export default Pagination;