import {
  BusinessCenter,
  Dashboard,
  PersonRounded,
  RecordVoiceOver,
  SendRounded,
  Settings,
  StorageRounded,
} from "@mui/icons-material";
import React, { Fragment, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useLanguageContext } from "../../../context/language";
import { useUserContext } from "../../../context/user";
import "./style.scss";

export const NavDropdownLink = ({
  title,
  icon,
  to,
  toggle,
  setToggle,
  locationChange = () => {},
  items = [],
}) => {
  const { location } = useHistory();

  const toggleLocation = () => {
    setToggle(toggle === to ? window.location.pathname : to);
  };

  const { user } = useUserContext();

  return (
    <Fragment>
      <div
        className={`cv-dash-sidebar-item ${
          location.pathname.startsWith(to) || toggle === to
            ? "cv-dash-sidebar-item-active"
            : ""
        }`}
        onClick={toggleLocation}
      >
        {icon} {title}
      </div>
      {items.length ? (
        <div className="cv-dash-sidebar-item-list">
          {items.map((x) =>
            !x.role || x.role === user.role ? (
              <NavLink
                exact
                to={x.to}
                key={x.to}
                className="cv-dash-sidebar-item-list-item"
                activeClassName="cv-dash-sidebar-item-list-item-active"
                onClick={locationChange}
              >
                {x.name}
              </NavLink>
            ) : null
          )}
        </div>
      ) : null}
    </Fragment>
  );
};

const Sidebar = () => {
  const { t } = useLanguageContext();

  const { location } = useHistory();

  const [toggle, setToggle] = useState(location.pathname);

  const { user } = useUserContext();

  const items = [
    { name: t`Dashboard`, icon: <Dashboard />, to: "/dashboard" },
    {
      name: t`Moj CV profil`,
      icon: <PersonRounded />,
      to: "/dashboard/cv-profile",
      role: "employee",
    },
    {
      name: t`Profil`,
      icon: <PersonRounded />,
      to: "/dashboard/profile",
      role: "company",
    },
    {
      name: t`Oglasi`,
      icon: <RecordVoiceOver />,
      to: "/dashboard/jobs",
      items: [
        { name: t`Svi oglasi`, to: "/dashboard/jobs/all", role: "employee" },
        {
          name: t`Omiljeni oglasi`,
          to: "/dashboard/jobs/favorites",
          role: "employee",
        },
        {
          name: t`Dodaj novi oglas`,
          to: "/dashboard/jobs/new",
          role: "company",
        },
        {
          name: t`Postavljeni oglasi`,
          to: "/dashboard/jobs/posted",
          role: "company",
        },
        {
          name: t`Oglasi u pripremi`,
          to: "/dashboard/jobs/preparation",
          role: "company",
        },
        {
          name: t`Arhiva oglasa`,
          to: "/dashboard/jobs/archived",
          role: "company",
        },
      ],
    },
    {
      name: t`Paketi`,
      icon: <StorageRounded />,
      to: "/dashboard/packages",
      role: "company",
    },
    {
      name: t`Moji konkursi`,
      icon: <SendRounded />,
      to: "/dashboard/applications",
      role: "employee",
      items: [
        { name: t`Izvršene prijave`, to: "/dashboard/applications/done" },
        {
          name: t`Prijave u pripremi`,
          to: "/dashboard/applications/preparation",
        },
      ],
    },
    {
      name: t`Kompanije`,
      icon: <BusinessCenter />,
      to: "/dashboard/companies",
      role: "employee",
    },
    { name: t`Podešavanja`, icon: <Settings />, to: "/dashboard/settings" },
  ];

  return (
    <div className="cv-dash-sidebar">
      {items.map((x, y) =>
        !x.role || x.role === user.role ? (
          x.items ? (
            <NavDropdownLink
              icon={x.icon}
              title={x.name}
              toggle={toggle}
              setToggle={setToggle}
              items={x.items}
              to={x.to}
            >
              Dropdown
            </NavDropdownLink>
          ) : (
            <NavLink
              className="cv-dash-sidebar-item"
              activeClassName="cv-dash-sidebar-item-active"
              exact
              to={x.to}
              onClick={() => setToggle(x.to)}
            >
              {x.icon} {x.name}
            </NavLink>
          )
        ) : null
      )}
    </div>
  );
};

export default Sidebar;
