import React from 'react';

const CheckCircleIcon = ({ className }) => (
    <svg className={className} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" fill="#2999ff">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0.111 0.333 9.556 9.556">
            <path paint-order="stroke fill markers" fill-rule="evenodd" d="M4.889.333a4.778 4.778 0 1 0 0 9.556 4.778 4.778 0 0 0 0-9.556zm0 .925A3.851 3.851 0 0 1 8.742 5.11a3.851 3.851 0 0 1-3.853 3.853 3.85 3.85 0 0 1-3.853-3.853 3.851 3.851 0 0 1 3.853-3.853zm2.7 2.51l-.433-.438a.23.23 0 0 0-.327-.001l-2.723 2.7-1.152-1.16a.231.231 0 0 0-.327-.002l-.438.434a.232.232 0 0 0-.001.327l1.749 1.763c.09.091.236.092.327.002l3.325-3.299a.231.231 0 0 0 0-.326z"/>
        </svg>
    </svg>
);

export default CheckCircleIcon;
