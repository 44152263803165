import axios from "axios";
import { apiBaseUrl } from "../../../constants/project";
import { toFormData } from "../../../utils/converter";

export const jobCategories = () =>
  axios.get(`${apiBaseUrl}/api/v2/job-categories`);

export const jobTypes = () => axios.get(`${apiBaseUrl}/api/v2/job-types`);

export const typeOfWorks = () =>
  axios.get(`${apiBaseUrl}/api/v2/type-of-works`);

export const workTimes = () => axios.get(`${apiBaseUrl}/api/v2/work-times`);

export const jobs = (params, user_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/jobs`, { params: { ...params, user_id } })
    .then((x) => x.data);

export const job = (job_id) =>
  axios.get(`${apiBaseUrl}/api/v2/jobs/${job_id}`).then((x) => x.data.data);

export const publicJobSearch = (params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/job-search`, {
      params,
    })
    .then((x) => x.data);

export const publicSingleCompanyAds = (company_id, params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/company/${company_id}/jobs`, {
      params,
    })
    .then((x) => x.data);

export const publicAd = (company_id, ad_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/company/${company_id}/jobs/${ad_id}`)
    .then((x) => x.data.data);

export const jobSearchAllFilter = () =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/job-search/filters/all`)
    .then((x) => x.data);

export const jobSearchCompanyFilter = (term) =>
  axios.get(`${apiBaseUrl}/api/v2/public/job-search/filters/company`, {
    params: { term },
  });

export const jobSearchTypeOfWorkFilter = (term) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/job-search/filters/type-of-work`, {
      params: { term },
    })
    .then((x) => x.data);

export const jobSearchLocationFilter = (term) =>
  axios.get(`${apiBaseUrl}/api/v2/public/job-search/filters/location`, {
    params: { term },
  });

export const jobSearchTermFilter = (term, cancelToken) =>
  axios
    .get(`${apiBaseUrl}/api/v2/public/job-search/filters/term`, {
      params: { term },
      cancelToken,
    })
    .then((x) => x.data);

export const uploadQuestionVideo = (video) =>
  axios
    .post(`${apiBaseUrl}/api/v2/video`, toFormData({ video }, "video.webm"))
    .then((x) => x.data.data);

export const deleteQuestionVideo = (id) =>
  axios.delete(`${apiBaseUrl}/api/v2/video/${id}`).then((x) => x.data);

const JobAPI = {
  jobCategories,
  jobTypes,
  typeOfWorks,
  workTimes,
  jobs,
  job,
  publicJobSearch,
  publicSingleCompanyAds,
  publicAd,
  jobSearchAllFilter,
  jobSearchCompanyFilter,
  jobSearchTypeOfWorkFilter,
  jobSearchLocationFilter,
  jobSearchTermFilter,
  uploadQuestionVideo,
  deleteQuestionVideo,
};

export default JobAPI;
