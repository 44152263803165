import { Checkbox, ListItemText, MenuItem, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import {
  FormControlWrapper,
  InputLabelWrapper,
  SelectWrapper,
  OptionWrapper,
} from "./styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelect = ({
  id,
  value = [],
  label,
  placeholder,
  onChange = () => {},
  options = [],
  ...props
}) => {

  const [state, setState] = useState(value);

  const handleChange = (e) => {
    const newValue =
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value;
    setState(newValue);
    onChange(newValue);
  };

  return (
    <FormControlWrapper {...props} size="medium">
      {!state.length ? (
        <InputLabelWrapper id={id} shrink={false}>
          {placeholder}
        </InputLabelWrapper>
      ) : null}
      <SelectWrapper
        multiple
        labelId={id}
        value={state}
        onChange={handleChange}
        renderValue={(selected) =>
          selected.length ? `Selektovano (${selected.length})` : ''
        }
        MenuProps={MenuProps}
        displayEmpty
      >
        {options.map(({ label, ...x }, y) => (
          <MenuItem key={label} value={x.value}>
            <Checkbox checked={state.indexOf(x.value) > -1} />
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </SelectWrapper>
    </FormControlWrapper>
  );
};

export default MultiSelect;
