import { MenuItem } from "@mui/material";
import React from "react";
import {
  FormControlWrapper,
  InputLabelWrapper,
  SelectWrapper,
  OptionWrapper,
} from "./styles";

const Select = ({
  id,
  value,
  label,
  color = "secondary",
  placeholder,
  onChange = () => {},
  options = [],
  allowEmpty = true,
  disableDefault,
  ...props
}) => {
  return (
    <FormControlWrapper {...props}>
      {label ? <InputLabelWrapper id={id}>{label}</InputLabelWrapper> : null}
      <SelectWrapper
        labelId={id}
        value={value}
        placeholder={placeholder}
        label={label}
        color={color}
        onChange={(a) => onChange(a.target.value)}
        displayEmpty={allowEmpty}
        MenuProps={{
          classes: {
            paper: "cv-paper-radius",
          },
        }}
      >
        {placeholder ? (
          <OptionWrapper
            value=""
            style={disableDefault ? { display: "none" } : null}
          >
            {placeholder}
          </OptionWrapper>
        ) : null}
        {options.map((x, y) => (
          <OptionWrapper key={y} value={x.value}>
            {x.label}
          </OptionWrapper>
        ))}
      </SelectWrapper>
    </FormControlWrapper>
  );
};

export default Select;
