import React, { Fragment } from "react";
import { CardTitle } from "../../components/custom";
import Hero from "../../components/custom/hero";
import { useLanguageContext } from "../../context/language";
import "./style.scss";

const AboutUs = () => {
  const { t } = useLanguageContext();

  const description = [
    t`CV PRIČA d.o.o. je firma sa sjedištem u Podgorici, osnovana 2021. godine sa ciljem razvoja jedinstvene platforme za zapošljavanje, kako u Crnoj Gori, tako i u čitavom regionu. CV PRIČA je nastala u želji da se proces zapošljavanja unaprijedi i pojednostavi i kandidatima i poslodavcima. Tržište rada danas zahtijeva brzinu i preciznost i ne trpi greške i nedostatke u CV-ju, a ne prašta ni tremu pri prvom razgovoru za posao. Ipak, zahvaljujući savremenim tehnologijama, postizanje najboljih rezultata za kratko vrijeme je sada znatno olakšano.`,
    t`Koncept CV PRIČA jezasnovan na ideji da kandidati putem video snimka predstave svoju radnu biografiju, vještine i znanje na najbolji način i samim tim povećaju šanse za zaposlenje. Tako dobijaju mogućnost da npr. izuzetnim vještinama komunikacije nadomjeste eventualno kratko radno iskustvo ili neposjedovanje adekvatnog obrazovanja koje se zahtijeva u oglasu za posao.`,
    t`Poslodavcima je, pak, omogućeno da steknu bolju sliku o kandidatima i tako uštede vrijeme pri izboru budućih zaposlenih. Zahvaljujući video CV pričama, može se preskočiti prvi krug intervjuisanja kandidata, a mogućnost odgovaranja na pitanja poslodavca u video formatu može zamijeniti i drugi krug razgovora.`,
    t`Pored toga, platforma nudi brojne druge pogodnosti, kao što je postavljanje oglasa u različitim državama i gradovima, kao i mogućnost podjednako efikasnog postavljanja, praćenja i konkurisanja na oglase sa računara i sa mobilnih telefona. Savremen i atraktivan dizajn omogućava kvalitetnu promociju kandidata, ali i kompanija koje svoje poslovanje mogu predstaviti kreiranjem stranica poput pravih veb sajtova.`,
    t`I to nije sve. Naš cilj je da pronalaženjem inovativnih i originalnih rješenja nastavimo misiju unaprjeđenja ove platforme. Želimo da svojim korisnicima obezbijedimo kontinuirano pružanje kvalitetnih i naprednih usluga pri zapošljavanju i na taj način osiguramo ostvarivanje dugoročnih i pozitivnih rezultata.`,
  ];

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-about-us.jpg" />
      <div className="cv-container cv-about-us-main">
        <CardTitle
          align="center"
          title={<span className="secondary">{t("O nama")}</span>}
        />
        <div className="cv-about-us-description">
          {description.map((x, y) => (
            <p key={y}>{x}</p>
          ))}
        </div>
        <div className="cv-about-us-video">
          <video src={t`file/videos/hero`} controls></video>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutUs;
