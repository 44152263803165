import axios from "axios";
import { toFormData } from "../../../utils/converter";
import { apiBaseUrl } from "../../../constants/project";
import { toast } from "react-toastify";

export const get = (user_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/candidate/info`, {
      params: { user_id },
    })
    .then((x) => x.data.data);

export const update = (data, user_id) =>
  axios
    .patch(`${apiBaseUrl}/api/v2/candidate/update`, { user_id, ...data })
    .then((x) => x.data.data);

export const updateOptionalFields = (data, user_id) =>
  axios
    .patch(`${apiBaseUrl}/api/v2/candidate/fields/update`, {
      user_id,
      ...data,
    })
    .then((x) => x.data.data);

export const updateProfileImage = async (image, user_id) =>
  axios
    .post(
      `${apiBaseUrl}/api/v2/candidate/fields/update-profile-image`,
      toFormData({
        image,
        user_id,
      })
    )
    .then((x) => x.data.data);



export const updateCvDocument = (document, user_id) =>
  axios
    .post(
      `${apiBaseUrl}/api/v2/candidate/update-cv-document`,
      toFormData({
        document,
        user_id,
      })
    )
    .then((x) => x.data.data);

export const updateProfileVideo = (video, user_id) =>
  axios
    .post(
      `${apiBaseUrl}/api/v2/candidate/update-cv-video`,
      toFormData({
        video,
        user_id,
      })
    )
    .then((x) => x.data.data);

export const deleteProfileVideo = () =>
  axios.delete(`${apiBaseUrl}/api/v2/candidate/cv-video`);

export const updateDriverLicence = (data, user_id) =>
  axios
    .patch(`${apiBaseUrl}/api/v2/candidate/update-driver-licence`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data);

export const deactivate = (user_id) =>
  axios.patch(`${apiBaseUrl}/api/v2/candidate/deactivate`, { user_id });

export const educations = (user_id) =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/educations`, {
    params: user_id,
  });

export const educationStore = (data, user_id) =>
  axios
    .post(`${apiBaseUrl}/api/v2/candidate/educations`, { ...data, user_id })
    .then((x) => x.data.data);

export const educationUpdate = (education_id, data, user_id) =>
  axios
    .patch(`${apiBaseUrl}/api/v2/candidate/educations/${education_id}`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data);

export const educationDelete = (education_id, user_id) =>
  axios.delete(`${apiBaseUrl}/api/v2/candidate/educations/${education_id}`, {
    data: { user_id },
  });

export const workExperiences = (user_id) =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/work-experiences`, {
    params: { user_id },
  });

export const workExperienceStore = (data, user_id) =>
  axios
    .post(`${apiBaseUrl}/api/v2/candidate/work-experiences`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data);

export const workExperienceUpdate = (work_experience_id, data, user_id) =>
  axios
    .patch(
      `${apiBaseUrl}/api/v2/candidate/work-experiences/${work_experience_id}`,
      {
        ...data,
        user_id,
      }
    )
    .then((x) => x.data.data);

export const workExperienceDelete = (work_experience_id, user_id) =>
  axios.delete(
    `${apiBaseUrl}/api/v2/candidate/work-experiences/${work_experience_id}`,
    {
      data: {
        user_id,
      },
    }
  );

export const computerSkills = (user_id) =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/computer-skills`, {
    params: {
      user_id,
    },
  });

export const computerSkillStore = (data, user_id) =>
  axios
    .post(`${apiBaseUrl}/api/v2/candidate/computer-skills`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data);

export const computerSkillUpdate = (computer_skill_id, data, user_id) =>
  axios
    .patch(
      `${apiBaseUrl}/api/v2/candidate/computer-skills/${computer_skill_id}`,
      {
        ...data,
        user_id,
      }
    )
    .then((x) => x.data.data);

export const computerSkillDelete = (computer_skill_id, user_id) =>
  axios.delete(
    `${apiBaseUrl}/api/v2/candidate/computer-skills/${computer_skill_id}`,
    {
      data: {
        user_id,
      },
    }
  );

export const languages = (user_id) =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/languages`, {
    params: {
      user_id,
    },
  });

export const languageStore = (data, user_id) =>
  axios
    .post(`${apiBaseUrl}/api/v2/candidate/languages`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data);

export const languageUpdate = (language_id, data, user_id) =>
  axios
    .patch(`${apiBaseUrl}/api/v2/candidate/languages/${language_id}`, {
      ...data,
      user_id,
    })
    .then((x) => x.data.data);

export const languageDelete = (language_id, user_id) =>
  axios.delete(`${apiBaseUrl}/api/v2/candidate/languages/${language_id}`, {
    data: {
      user_id,
    },
  });

// APP

export const applications = (params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/candidate/applications`, {
      params,
    })
    .then((x) => x.data);

export const applicationShow = (application_id, user_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/candidate/applications/${application_id}`, {
      params: {
        user_id,
      },
    })
    .then((x) => x.data.data);

export const applicationStore = (data, user_id) =>
  axios.post(`${apiBaseUrl}/api/v2/candidate/applications`, {
    ...data,
    user_id,
  });

export const applicationUpdate = (application_id, data, user_id) =>
  axios.patch(`${apiBaseUrl}/api/v2/candidate/applications/${application_id}`, {
    ...data,
    user_id,
  });

export const applicationDelete = (application_id, user_id) =>
  axios.delete(
    `${apiBaseUrl}/api/v2/candidate/applications/${application_id}`,
    {
      data: {
        user_id,
      },
    }
  );

export const adSetFavorite = (ad_id, data, user_id) =>
  axios.patch(`${apiBaseUrl}/api/v2/candidate/jobs/${ad_id}/set-favorite`, {
    ...data,
    user_id,
  });

export const candidateInfo = (candidate_id) =>
  axios
    .get(
      `${apiBaseUrl}/api/v2/candidate/${
        candidate_id ? `${candidate_id}/` : ""
      }info`
    )
    .then((x) => x.data.data);

export const candidateNotifications = () =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/notifications/all`);

export const candidateCityNotifications = () =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/notifications/city`);

export const candidateCompanyNotifications = () =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/notifications/city`);

export const candidateTypeOfWorkNotifications = () =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/notifications/type-of-work`);

export const candidateSubscribeToCompany = (company_id) =>
  axios.post(
    `${apiBaseUrl}/api/v2/candidate/notifications/company/${company_id}/subscribe`
  );

export const candidateUnsubscribeToCompany = (company_id) =>
  axios.post(
    `${apiBaseUrl}/api/v2/candidate/notifications/company/${company_id}/unsubscribe`
  );

export const dashboard = (year, params) =>
  axios
    .get(`${apiBaseUrl}/api/v2/candidate/dashboard`, {
      params: { year, ...params },
    })
    .then((x) => x.data.data);

export const deleteCvDocument = () =>
  axios.delete(`${apiBaseUrl}/api/v2/candidate/cv-document`);

export const downloadCvVideo = () =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/download-cv-video`, {
    responseType: "blob",
  });

export const downloadCvDocument = () =>
  axios.get(`${apiBaseUrl}/api/v2/candidate/download-cv-document`, {
    responseType: "blob",
  });

const CandidateAPI = {
  get,
  update,
  updateOptionalFields,
  updateProfileImage,
  updateCvDocument,
  updateProfileVideo,
  updateDriverLicence,
  deactivate,
  educations,
  educationStore,
  educationUpdate,
  educationDelete,
  workExperiences,
  workExperienceStore,
  workExperienceUpdate,
  workExperienceDelete,
  computerSkills,
  computerSkillStore,
  computerSkillUpdate,
  computerSkillDelete,
  languages,
  languageStore,
  languageUpdate,
  languageDelete,
  applications,
  applicationShow,
  applicationStore,
  applicationUpdate,
  applicationDelete,
  adSetFavorite,
  deleteProfileVideo,
  candidateInfo,
  candidateNotifications,
  candidateCityNotifications,
  candidateCompanyNotifications,
  candidateTypeOfWorkNotifications,
  candidateSubscribeToCompany,
  candidateUnsubscribeToCompany,
  dashboard,
  deleteCvDocument,
  downloadCvVideo,
  downloadCvDocument,
};

export default CandidateAPI;
