const Advice6 = () => (
  <svg viewBox="0 0 129 112">
    <path
      d="M84.96 111.44C73.59 111.44 64.4 102.24 64.4 90.88C64.4 79.51 73.59 70.32 84.96 70.32C96.33 70.32 105.52 79.51 105.52 90.88C105.52 102.24 96.33 111.44 84.96 111.44Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M111.4 69.7C101.66 69.7 93.79 61.84 93.79 52.1C93.79 42.37 101.66 34.5 111.4 34.5C121.13 34.5 129 42.37 129 52.1C129 61.84 121.13 69.7 111.4 69.7Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M29.36 58.93C13.12 58.93 0 45.81 0 29.57C0 13.33 13.12 0.209999 29.36 0.209999C45.6 0.209999 58.72 13.33 58.72 29.57C58.72 45.81 45.6 58.93 29.36 58.93Z"
      fill="#E8EBEC"
    ></path>
    <path d="M44.96 90.88H83.12V99.68H44.96V90.88Z" fill="#BDC3D8"></path>
    <path
      d="M75.78 90.88H44.96V96.75H69.94C73.17 96.75 75.78 94.14 75.78 90.88Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.12 100.8H44.96C44.35 100.8 43.87 100.29 43.87 99.71V90.91C43.87 90.3 44.38 89.82 44.96 89.82H83.12C83.73 89.82 84.21 90.33 84.21 90.91V99.71C84.24 100.29 83.73 100.8 83.12 100.8ZM46.05 98.59H82.03V92H46.05V98.59Z"
      fill="#21365F"
    ></path>
    <path
      d="M111.02 82.07H17.06V11.15C17.06 10.57 17.5 10.13 18.08 10.13H110C110.58 10.13 111.02 10.57 111.02 11.15V82.07Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M98.92 76.19H17.06V11.15C17.06 10.57 17.5 10.13 18.08 10.13H103.68V71.44C103.68 74.05 101.58 76.19 98.92 76.19Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.02 83.16H17.06C16.45 83.16 15.97 82.65 15.97 82.07V14.52C15.97 11.49 18.45 9.00999 21.48 9.00999H106.64C109.66 9.00999 112.14 11.49 112.14 14.52V82.07C112.14 82.69 111.63 83.16 111.02 83.16ZM18.18 80.99H109.93V14.52C109.93 12.68 108.44 11.22 106.64 11.22H21.48C19.64 11.22 18.18 12.71 18.18 14.52V80.99Z"
      fill="#21365F"
    ></path>
    <path
      d="M110 90.87H18.08C17.5 90.87 17.06 90.43 17.06 89.86V82.07H111.02V89.86C111.02 90.43 110.58 90.87 110 90.87Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M96.34 87.95H17.06V82.07H102.22C102.22 85.3 99.6 87.95 96.34 87.95Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.64 92H21.48C18.45 92 15.97 89.52 15.97 86.49V82.07C15.97 81.46 16.48 80.99 17.06 80.99H111.02C111.63 80.99 112.11 81.5 112.11 82.07V86.49C112.14 89.52 109.66 92 106.64 92ZM18.18 83.16V86.46C18.18 88.29 19.68 89.75 21.48 89.75H106.64C108.47 89.75 109.93 88.26 109.93 86.46V83.16H18.18Z"
      fill="#21365F"
    ></path>
    <path
      d="M93.42 100.8H34.7C34.08 100.8 33.61 100.29 33.61 99.71C33.61 99.1 34.12 98.62 34.7 98.62H93.42C94.03 98.62 94.51 99.13 94.51 99.71C94.51 100.29 94.03 100.8 93.42 100.8Z"
      fill="#21365F"
    ></path>
    <path
      d="M64.06 87.58C63.46 87.58 62.97 87.09 62.97 86.49C62.97 85.89 63.46 85.4 64.06 85.4C64.66 85.4 65.15 85.89 65.15 86.49C65.15 87.09 64.66 87.58 64.06 87.58Z"
      fill="#21365F"
    ></path>
    <path
      d="M69.05 64.27L58.59 61.51H46.42V82.07H75.78V73C75.78 68.89 73.03 65.32 69.05 64.27Z"
      fill="white"
    ></path>
    <path
      d="M34.25 61.51L23.79 64.27C19.81 65.32 17.06 68.89 17.06 73V82.07H46.42V61.51H34.25Z"
      fill="white"
    ></path>
    <path
      d="M69.05 64.27L58.59 61.51H46.42V66.03H51.79L65.49 69.64C67.56 70.18 69.02 72.05 69.02 74.19V82.07H75.78V73C75.78 68.89 73.03 65.32 69.05 64.27Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.78 83.16H46.42C45.81 83.16 45.33 82.65 45.33 82.07V61.51C45.33 60.9 45.84 60.43 46.42 60.43H58.59C58.69 60.43 58.79 60.43 58.86 60.46L69.32 63.21C73.78 64.37 76.9 68.41 76.9 73.03V82.11C76.9 82.69 76.39 83.16 75.78 83.16ZM47.54 80.99H74.69V73.03C74.69 69.43 72.25 66.27 68.78 65.35L58.45 62.64H47.54V80.99Z"
      fill="#21365F"
    ></path>
    <path
      d="M34.25 61.51L26.78 63.49C27.8 68.79 34.32 73.88 37.65 76.19C38.91 77.04 39.62 78.47 39.62 80V82.11H46.39V61.55H34.25V61.51Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.42 83.16H17.06C16.45 83.16 15.97 82.65 15.97 82.07V73C15.97 68.38 19.1 64.37 23.55 63.18L34.02 60.43C34.12 60.39 34.19 60.39 34.29 60.39H46.45C47.07 60.39 47.54 60.9 47.54 61.48V82.04C47.54 82.69 47.03 83.16 46.42 83.16ZM18.15 80.99H45.3V62.64H34.39L24.06 65.35C20.56 66.27 18.15 69.43 18.15 73.03V80.99Z"
      fill="#21365F"
    ></path>
    <path
      d="M46.42 72.97C46.42 72.97 58.52 66.44 58.59 61.51C56.04 60.19 54.1 57.81 53.56 54.92C53.49 54.51 53.42 51.83 53.35 51.42H39.42C39.35 51.9 39.28 54.62 39.18 55.13C38.64 57.95 36.74 60.26 34.22 61.48C34.39 66.41 46.42 72.97 46.42 72.97Z"
      fill="white"
    ></path>
    <path
      d="M53.56 54.96C53.49 54.55 53.42 51.86 53.35 51.46H45.47C45.5 51.86 46.35 54.41 46.42 54.82C46.76 57.3 48.83 60.32 50.6 61.96C51.21 62.53 51.55 63.35 51.45 64.16C50.97 68.07 47.1 71.71 45.98 72.69C46.25 72.86 46.42 72.93 46.42 72.93C46.42 72.93 58.52 66.41 58.59 61.48C56.04 60.22 54.1 57.81 53.56 54.96Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.42 74.05C46.25 74.05 46.05 74.02 45.91 73.92C44.62 73.2 33.3 66.92 33.17 61.55C33.17 61.11 33.4 60.73 33.78 60.53C36.09 59.41 37.69 57.37 38.16 54.96C38.2 54.72 38.26 53.49 38.3 52.78C38.33 52.1 38.37 51.52 38.37 51.32C38.43 50.77 38.91 50.33 39.45 50.33H53.39C53.93 50.33 54.41 50.74 54.47 51.32C54.51 51.49 54.51 52.07 54.54 52.75C54.58 53.43 54.61 54.55 54.64 54.75C55.12 57.2 56.75 59.37 59.1 60.56C59.47 60.77 59.71 61.14 59.71 61.55C59.61 66.95 48.26 73.24 46.96 73.95C46.79 74.02 46.59 74.05 46.42 74.05ZM35.48 62.13C36.33 65.12 42.27 69.36 46.45 71.71C50.63 69.36 56.62 65.12 57.43 62.13C54.88 60.56 53.05 58.01 52.5 55.16C52.44 54.85 52.4 54.17 52.37 52.81C52.37 52.71 52.37 52.64 52.37 52.54H40.54C40.54 52.64 40.54 52.75 40.54 52.88C40.47 54.21 40.44 55.02 40.37 55.36C39.76 58.22 38.03 60.63 35.48 62.13Z"
      fill="#21365F"
    ></path>
    <path
      d="M60.56 40.95C60.56 31.61 54.24 26.27 46.45 26.27C38.67 26.27 32.35 31.61 32.35 40.95C32.35 50.3 38.67 57.91 46.45 57.91C54.24 57.91 60.56 50.33 60.56 40.95Z"
      fill="white"
    ></path>
    <path
      d="M49.82 26.65C50.91 28.75 51.52 31.27 51.52 34.19C51.52 43.54 45.2 51.15 37.41 51.15C36.6 51.15 35.78 51.05 35 50.88C37.55 55.13 41.73 57.91 46.45 57.91C54.24 57.91 60.56 50.33 60.56 40.95C60.56 33 55.97 27.97 49.82 26.65Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M46.42 37.49C40.81 34.94 36.5 34.57 33.3 35.04C32.66 36.77 32.28 38.74 32.28 40.95C32.28 50.3 38.6 57.91 46.39 57.91C54.17 57.91 60.49 50.33 60.49 40.95C60.49 39.8 60.39 38.71 60.22 37.69C56.48 39.15 51.62 39.87 46.42 37.49Z"
      fill="white"
    ></path>
    <path
      d="M60.25 37.69C58.93 38.2 57.43 38.61 55.87 38.85C54.88 47.07 49.04 53.39 41.9 53.39C39.52 53.39 37.31 52.68 35.34 51.46C37.92 55.4 41.93 57.91 46.42 57.91C54.2 57.91 60.52 50.33 60.52 40.95C60.56 39.8 60.46 38.71 60.25 37.69Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.42 59C38.03 59 31.2 50.91 31.2 40.95C31.2 38.71 31.57 36.57 32.25 34.67C32.39 34.29 32.73 34.02 33.13 33.95C37.18 33.34 41.83 34.19 46.86 36.5C50.94 38.37 55.32 38.44 59.84 36.67C60.15 36.57 60.49 36.57 60.8 36.74C61.07 36.91 61.27 37.18 61.34 37.52C61.54 38.64 61.64 39.8 61.64 40.99C61.64 50.91 54.81 59 46.42 59ZM34.12 36.03C33.64 37.56 33.4 39.19 33.4 40.95C33.4 49.69 39.25 56.79 46.42 56.79C53.59 56.79 59.44 49.69 59.44 40.95C59.44 40.34 59.4 39.76 59.33 39.19C54.68 40.68 50.19 40.44 45.94 38.51C41.59 36.5 37.62 35.65 34.12 36.03Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.42 59C38.03 59 31.2 50.91 31.2 40.95C31.2 31.68 37.45 25.19 46.42 25.19C55.39 25.19 61.64 31.68 61.64 40.95C61.64 50.91 54.81 59 46.42 59ZM46.42 27.36C38.64 27.36 33.4 32.83 33.4 40.95C33.4 49.69 39.25 56.79 46.42 56.79C53.59 56.79 59.44 49.69 59.44 40.95C59.44 32.83 54.2 27.36 46.42 27.36Z"
      fill="#21365F"
    ></path>
    <path
      d="M111.02 21.86H70.58C70.21 21.86 69.9 22.16 69.9 22.54V54.96C69.9 55.33 70.21 55.63 70.58 55.63H111.02V21.86Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M106.64 21.86H70.62C70.24 21.86 69.94 22.16 69.94 22.54V49.76H102.9C104.97 49.76 106.64 48.09 106.64 46.02V21.86Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.02 56.76H72.86C70.62 56.76 68.82 54.96 68.82 52.71V24.81C68.82 22.6 70.62 20.77 72.86 20.77H111.02C111.63 20.77 112.11 21.28 112.11 21.86V55.63C112.14 56.25 111.63 56.76 111.02 56.76ZM72.86 22.98C71.84 22.98 71.02 23.79 71.02 24.81V52.71C71.02 53.73 71.84 54.55 72.86 54.55H109.93V22.98H72.86Z"
      fill="#21365F"
    ></path>
    <path
      d="M101.78 47.48L96.55 46.12H90.46H84.38L79.15 47.48C77.18 47.99 75.78 49.79 75.78 51.86V55.67H105.14V51.86C105.18 49.79 103.78 47.99 101.78 47.48Z"
      fill="white"
    ></path>
    <path
      d="M105.18 51.86C105.18 49.82 103.78 48.02 101.81 47.48L101.17 47.31C100.93 51.62 98.18 52.88 93.83 55.63H105.18V51.86Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.18 56.76H75.82C75.2 56.76 74.73 56.25 74.73 55.67V51.86C74.73 49.31 76.46 47.07 78.91 46.43L84.14 45.07C84.24 45.03 84.31 45.03 84.41 45.03H96.58C96.68 45.03 96.78 45.03 96.85 45.07L102.08 46.43C104.57 47.07 106.26 49.31 106.26 51.86V55.67C106.26 56.25 105.75 56.76 105.18 56.76ZM76.9 54.55H104.06V51.86C104.06 50.3 103 48.94 101.51 48.57L96.41 47.24H84.55L79.45 48.57C77.96 48.97 76.9 50.33 76.9 51.86V54.55Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.46 50.37C90.46 50.37 96.51 48.57 96.55 46.12C95.25 45.47 94.3 44.25 94.03 42.82C94 42.62 93.96 41.26 93.93 41.06H86.96C86.93 41.29 86.89 42.65 86.86 42.92C86.59 44.35 85.64 45.47 84.38 46.12C84.45 48.57 90.46 50.37 90.46 50.37Z"
      fill="white"
    ></path>
    <path
      d="M96.55 46.09C95.25 45.44 94.3 44.22 94.03 42.79C94 42.59 93.96 41.23 93.93 41.02L90.6 41.63C90.63 41.94 90.67 43.88 90.73 44.18C91.11 46.15 92.4 47.82 94.1 48.8C95.42 48.09 96.55 47.17 96.55 46.09Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.46 51.46C90.36 51.46 90.26 51.46 90.16 51.42C89.04 51.08 83.36 49.25 83.29 46.15C83.29 45.71 83.53 45.34 83.9 45.13C84.89 44.66 85.6 43.77 85.81 42.72C85.84 42.59 85.84 42.11 85.87 41.74C85.87 41.4 85.91 41.06 85.91 40.95C85.98 40.41 86.45 39.97 87 39.97H93.96C94.54 39.97 94.98 40.38 95.05 40.95C95.05 41.06 95.08 41.36 95.08 41.7C95.08 42.08 95.12 42.52 95.12 42.65C95.32 43.67 96.04 44.62 97.06 45.13C97.43 45.34 97.67 45.71 97.67 46.12C97.63 49.21 91.92 51.08 90.8 51.42C90.7 51.42 90.6 51.46 90.46 51.46ZM85.74 46.6C86.42 47.48 88.53 48.57 90.5 49.21C92.5 48.57 94.61 47.48 95.25 46.6C94.06 45.71 93.25 44.45 92.98 43.03C92.94 42.92 92.94 42.55 92.91 42.18H88.05C88.02 42.59 88.02 42.99 87.98 43.13C87.68 44.52 86.89 45.75 85.74 46.6Z"
      fill="#21365F"
    ></path>
    <path
      d="M97.53 35.08C97.53 30.39 94.37 27.73 90.46 27.73C86.55 27.73 83.39 30.39 83.39 35.08C83.39 39.76 86.55 43.54 90.46 43.54C94.37 43.57 97.53 39.76 97.53 35.08Z"
      fill="white"
    ></path>
    <path
      d="M92.16 27.94C92.71 28.99 93.01 30.25 93.01 31.71C93.01 36.4 89.85 40.17 85.94 40.17C85.54 40.17 85.13 40.14 84.75 40.04C86.05 42.18 88.12 43.57 90.46 43.57C94.37 43.57 97.53 39.76 97.53 35.11C97.53 31.1 95.25 28.58 92.16 27.94Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.46 44.66C85.98 44.66 82.31 40.38 82.31 35.08C82.31 30.11 85.67 26.65 90.46 26.65C95.25 26.65 98.62 30.11 98.62 35.08C98.65 40.38 94.98 44.66 90.46 44.66ZM90.46 28.86C86.86 28.86 84.52 31.3 84.52 35.11C84.52 39.19 87.2 42.48 90.46 42.48C93.73 42.48 96.41 39.19 96.41 35.11C96.44 31.3 94.1 28.86 90.46 28.86Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.46 56.76C89.85 56.76 89.37 56.25 89.37 55.67V50.37C89.37 49.76 89.88 49.28 90.46 49.28C91.07 49.28 91.55 49.79 91.55 50.37V55.67C91.58 56.25 91.07 56.76 90.46 56.76Z"
      fill="#21365F"
    ></path>
    <path
      d="M70.72 14.52H57.4C57.02 14.52 56.72 14.21 56.72 13.84V6.39C56.72 6.02 57.02 5.71 57.4 5.71H70.72C71.09 5.71 71.4 6.02 71.4 6.39V13.84C71.4 14.21 71.09 14.52 70.72 14.52Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.44 15.64H59.64C57.4 15.64 55.6 13.84 55.6 11.59V8.67C55.6 6.43 57.4 4.63 59.64 4.63H68.44C70.68 4.63 72.49 6.43 72.49 8.67V11.59C72.49 13.84 70.68 15.64 68.44 15.64ZM59.64 6.83C58.62 6.83 57.8 7.65 57.8 8.67V11.59C57.8 12.61 58.62 13.43 59.64 13.43H68.44C69.46 13.43 70.28 12.61 70.28 11.59V8.67C70.28 7.65 69.46 6.83 68.44 6.83H59.64Z"
      fill="#21365F"
    ></path>
    <path
      d="M64.06 11.59C63.25 11.59 62.6 10.94 62.6 10.13C62.6 9.32 63.25 8.67 64.06 8.67C64.87 8.67 65.52 9.32 65.52 10.13C65.52 10.94 64.87 11.59 64.06 11.59Z"
      fill="#21365F"
    ></path>
    <path
      d="M121.32 30.32H115.44C114.83 30.32 114.35 29.81 114.35 29.23C114.35 28.62 114.86 28.14 115.44 28.14H121.32C121.93 28.14 122.41 28.65 122.41 29.23C122.41 29.81 121.93 30.32 121.32 30.32Z"
      fill="#21365F"
    ></path>
    <path
      d="M118.36 33.24C117.75 33.24 117.28 32.73 117.28 32.15V26.27C117.28 25.66 117.78 25.19 118.36 25.19C118.97 25.19 119.45 25.7 119.45 26.27V32.15C119.48 32.76 118.97 33.24 118.36 33.24Z"
      fill="#21365F"
    ></path>
    <path
      d="M118.36 39.12C117.76 39.12 117.28 38.63 117.28 38.03C117.28 37.43 117.76 36.94 118.36 36.94C118.96 36.94 119.45 37.43 119.45 38.03C119.45 38.63 118.96 39.12 118.36 39.12Z"
      fill="#21365F"
    ></path>
    <path
      d="M118.36 43.5C117.76 43.5 117.28 43.02 117.28 42.42C117.28 41.81 117.76 41.33 118.36 41.33C118.96 41.33 119.45 41.81 119.45 42.42C119.45 43.02 118.96 43.5 118.36 43.5Z"
      fill="#21365F"
    ></path>
    <path
      d="M118.36 49.38C117.76 49.38 117.28 48.9 117.28 48.29C117.28 47.69 117.76 47.21 118.36 47.21C118.96 47.21 119.45 47.69 119.45 48.29C119.45 48.9 118.96 49.38 118.36 49.38Z"
      fill="#21365F"
    ></path>
    <path
      d="M118.36 58.18C117.76 58.18 117.28 57.7 117.28 57.1C117.28 56.49 117.76 56.01 118.36 56.01C118.96 56.01 119.45 56.49 119.45 57.1C119.45 57.7 118.96 58.18 118.36 58.18Z"
      fill="#21365F"
    ></path>
    <path
      d="M12.64 78.74H6.80001C6.18001 78.74 5.71001 78.23 5.71001 77.66C5.71001 77.04 6.22001 76.57 6.80001 76.57H12.68C13.29 76.57 13.76 77.08 13.76 77.66C13.76 78.27 13.25 78.74 12.64 78.74Z"
      fill="#21365F"
    ></path>
    <path
      d="M9.72001 81.7C9.11001 81.7 8.63 81.19 8.63 80.61V74.73C8.63 74.12 9.14001 73.65 9.72001 73.65C10.33 73.65 10.81 74.16 10.81 74.73V80.61C10.81 81.19 10.33 81.7 9.72001 81.7Z"
      fill="#21365F"
    ></path>
    <path
      d="M9.72001 69.94C9.12001 69.94 8.63 69.46 8.63 68.85C8.63 68.25 9.12001 67.77 9.72001 67.77C10.32 67.77 10.81 68.25 10.81 68.85C10.81 69.46 10.32 69.94 9.72001 69.94Z"
      fill="#21365F"
    ></path>
    <path
      d="M9.72001 64.06C9.12001 64.06 8.63 63.58 8.63 62.98C8.63 62.37 9.12001 61.89 9.72001 61.89C10.32 61.89 10.81 62.37 10.81 62.98C10.81 63.58 10.32 64.06 9.72001 64.06Z"
      fill="#21365F"
    ></path>
    <path
      d="M9.72001 56.72C9.12001 56.72 8.63 56.24 8.63 55.63C8.63 55.03 9.12001 54.55 9.72001 54.55C10.32 54.55 10.81 55.03 10.81 55.63C10.81 56.24 10.32 56.72 9.72001 56.72Z"
      fill="#21365F"
    ></path>
    <path
      d="M9.72001 46.43C9.12001 46.43 8.63 45.94 8.63 45.34C8.63 44.74 9.12001 44.25 9.72001 44.25C10.32 44.25 10.81 44.74 10.81 45.34C10.81 45.94 10.32 46.43 9.72001 46.43Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Advice6;