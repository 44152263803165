import React, { Fragment, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Cancel, Check, Close, PlayArrowRounded } from "@mui/icons-material";
import { Image, Button } from "../../ui";
import { useEventContext } from "../../../context/event";
import { useLanguageContext } from "../../../context/language";
import simpleParallax from "simple-parallax-js";
import { deviceType } from "../../../utils/device";
import { Modal } from "..";

const Hero = ({ img, heroStyle = {}, video, data, ...props }) => {
  const [videoModal, setVideoModal] = useState(false);

  const heroRef = useRef();
  useEffect(() => {
    const device = deviceType();
    const updateStyle = () => {
      heroRef.current?.style.setProperty(
        "top",
        `${Math.round(window.scrollY / 2)}px`
      );
    };
    if (device === "desktop") {
      updateStyle();
      window.addEventListener("scroll", updateStyle);
    }
    return () => {
      if (device === "desktop") {
        window.removeEventListener("scroll", updateStyle);
      }
    };
  }, []);

  return (
    <Fragment>
      <div
        className={`cv-hero-main ${data ? "cv-hero-big" : "cv-hero-small"}`}
        {...props}
      >
        <div className="cv-hero-image">
          {img ? (
            <Image
              key={img}
              src={img}
              alt="Hero"
              className="cv-hero-images"
              ref={heroRef}
              style={{
                position: "absolute",
                ...heroStyle,
              }}
            />
          ) : null}
        </div>
        {data ? (
          <div className="cv-container cv-hero-inner-flex">
            <div className="cv-hero-inner">
              <div className="cv-hero-content">
                <div className="cv-hero-title">
                  {data.title?.map((x, y) => (
                    <x.tag key={y} className={x.class}>
                      {x.text}
                    </x.tag>
                  ))}
                </div>
                {data.description ? (
                  <div className="cv-hero-description">
                    {data.description?.map((x, y) => (
                      <p key={y} className="cv-hero-description-single">
                        <Check /> {x}
                      </p>
                    ))}
                  </div>
                ) : null}
                {data.actions ? (
                  <div className="cv-hero-actions">
                    {data.actions}
                    <Button
                      color="primary"
                      variant="contained"
                      className="cv-hero-action-play"
                      onClick={() => setVideoModal(true)}
                    >
                      <PlayArrowRounded />
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="cv-hero-play-content">
              <Button
                variant="contained"
                color="primary"
                className="cv-hero-play-video"
                onClick={() => setVideoModal(true)}
              >
                <PlayArrowRounded />
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      <VideoModal
        open={videoModal}
        onClose={() => setVideoModal(false)}
        video={video}
      />
    </Fragment>
  );
};

const VideoModal = ({ open, onClose, video }) => {
  return (
    <Modal open={open} onClose={onClose} custom>
      <div className="cv-hero-video-main">
        <Button
          className="cv-hero-video-main-close"
          color="primary"
          variant="contained"
        >
          <Close />
        </Button>
        <video autoPlay controls src={video} />
      </div>
    </Modal>
  );
};

export default Hero;
