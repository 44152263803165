import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { SimpleCard, Button } from '../../ui';
import EmailWarningIcon from '../../../assets/svg/icons/EmailWarningIcon';
import MobileWarningIcon from '../../../assets/svg/icons/MobileWarningIcon';

import "./style.scss";

const VerificationCard = ({ title, subtitle, onClick, duration, icon, buttonLabel, isButtonDisabled }) => {

  const [disableButton, setDisableButton] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const renderIcon = () => {
    if (icon == 'Email') {
      return <EmailWarningIcon />;
    }
    if (icon == 'Phone') {
      return <MobileWarningIcon />;
    }
  }

  useEffect(() => {
    let interval;
    let timeout;
    if (disableButton && !isButtonDisabled) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
      timeout = setTimeout(() => {
        clearInterval(interval);
        setSeconds(duration);
        setDisableButton(false);
      }, duration * 1000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    }

  }, [disableButton])

  useEffect(() => {
    setSeconds(duration);
  }, [duration]);

  useEffect(() => {
    setDisableButton(isButtonDisabled);
  }, [isButtonDisabled])

  return (
    <SimpleCard className="cv-dashboard-profile-verification-card" withContent>
      <div className="cv-dashboard-profile-verification-group">
        <div className="cv-dashboard-profile-verification-icon">
          {renderIcon()}
        </div>
        <div className="cv-dashboard-profile-verification-text">
          <div className="cv-dashboard-profile-verification-text-bold">
            {title}
          </div>
          <div>{subtitle}</div>
        </div>
      </div>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={`cv-dashboard-profile-button-right ${disableButton ? 'cv-disabled' : ''}`}
        onClick={(e) => {
          setDisableButton(true);
          onClick(e);
        }}
      >
        {disableButton && !isButtonDisabled ? seconds : buttonLabel}
      </Button>
    </SimpleCard>
  );
}

export default VerificationCard;
