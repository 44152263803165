import axios from "axios";
import { apiBaseUrl } from "../../../constants/project";

export const commonData = () =>
  axios.get(`${apiBaseUrl}/api/v2/common-data`).then((x) => x.data);

export const packages = () => axios.get(`${apiBaseUrl}/api/v2/packages`).then(x => x.data.data);

export const driverLicenceCategories = () =>
  axios.get(`${apiBaseUrl}/api/v2/drivers-licence-categories`);

export const genders = () => axios.get(`${apiBaseUrl}/api/v2/genders`);

export const cities = (country_id) =>
  axios
    .get(`${apiBaseUrl}/api/v2/cities`, { params: { country_id } })
    .then((x) => x.data.data);

export const countries = () =>
  axios.get(`${apiBaseUrl}/api/v2/countries`).then((x) => x.data.data);

export const educationAreas = () =>
  axios.get(`${apiBaseUrl}/api/v2/education-areas`);

export const educationLevels = () =>
  axios.get(`${apiBaseUrl}/api/v2/education-levels`);

export const educationTitles = () =>
  axios.get(`${apiBaseUrl}/api/v2/education-titles`);

export const uiLanguages = () =>
  axios.get(`${apiBaseUrl}/api/v2/ui-languages`).then((x) => x.data.data);

export const languages = () => axios.get(`${apiBaseUrl}/api/v2/languages`);

export const languageReads = () =>
  axios.get(`${apiBaseUrl}/api/v2/language-reads`);

export const languageSpeaks = () =>
  axios.get(`${apiBaseUrl}/api/v2/language-speaks`);

export const languageWrites = () =>
  axios.get(`${apiBaseUrl}/api/v2/language-writes`);

export const user = () =>
  axios.get(`${apiBaseUrl}/api/v2/me`).then((x) => x.data.data);

export const profileCompleted = () =>
  axios.get(`${apiBaseUrl}/api/v2/me/profile-completed`).then((x) => x.data.data);

const GlobalAPI = {
  commonData,
  packages,
  driverLicenceCategories,
  genders,
  cities,
  countries,
  educationAreas,
  educationLevels,
  educationTitles,
  uiLanguages,
  languages,
  languageReads,
  languageSpeaks,
  languageWrites,
  user,
  profileCompleted,
};

export default GlobalAPI;
