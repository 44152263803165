import React, { useState } from "react";
import {
  IconButtonWrapper,
  BadgeWrapper,
  CircularProgressWrapper,
  MenuWrapper,
} from "./styles";
import "./style.scss";
import { NavLink } from "react-router-dom";

const IconButton = ({
  children,
  icon,
  count = 0,
  dropdown,
  onClick = () => { },
  className,
  component: Tag,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {

    setAnchorEl(e?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const processFunction = async (e) => {
    if (dropdown) {
      handleClick(e);
    }
    if (onClick.constructor.name === "AsyncFunction") {
      setLoading(true);
      await onClick();
      setLoading(false);
    } else if (onClick.constructor.name === "Function") {
      onClick();
    }
  };

  const Component = Tag || "div";

  return (
    <Component className={`cv-icon-button-main ${className}`} {...props}>
      <IconButtonWrapper size="medium" onClick={processFunction}>
        {loading ? (
          <CircularProgressWrapper />
        ) : (
          <BadgeWrapper badgeContent={count} color="primary">
            {icon}
          </BadgeWrapper>
        )}
      </IconButtonWrapper>
      {dropdown ? (
        <MenuWrapper
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <div className="cv-icon-button-dropdown">
            <div className="cv-icon-button-dropdown-title">
              {dropdown.title}
            </div>
            <div className="cv-icon-button-dropdown-content">
              {typeof dropdown.content === "function"
                ? dropdown.content(handleClose)
                : dropdown.content}
            </div>
          </div>
        </MenuWrapper>
      ) : null}
    </Component>
  );
};

export default IconButton;
