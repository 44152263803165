import React from "react";
import { useHistory } from "react-router-dom";
import EmptyJobs from "../../../assets/svg/jobs/emptyjobs";
import { useLanguageContext } from "../../../context/language";
import { Button } from '../../ui';
import './style.scss';

const NotFound = ({ title = "404", description = "Not found" }) => {

  const { t } = useLanguageContext();
  const history = useHistory();

  return (
    <div className="cv-notfound-main">
      <div className="cv-notfound-icon">
        <EmptyJobs />
      </div>
      <h1>{title}</h1>
      <span>{description}</span>
      <Button
        color="secondary"
        variant="contained"
        fullWidth
        size="large"
        href="/"
      >{t`Nazad na početnu`}</Button>
    </div>
  );
};

export default NotFound;
