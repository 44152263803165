import React from 'react';

const EmailWarningIcon = ({ className }) => (
    <svg className={className} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="137 102 16 15">
        <svg width="16" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0.5 0 15 15" fill="#ff663d" x="137" y="102">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0.666 0.333 14.667 14.667">
            <path paint-order="stroke fill markers" fill-rule="evenodd" d="M8 12.281c-.47 0-.94-.146-1.344-.435L.666 7.52v6.105c0 .759.616 1.375 1.375 1.375h11.917c.76 0 1.375-.616 1.375-1.375V7.52l-5.99 4.326A2.284 2.284 0 0 1 8 12.281zm6.806-7.279c-.255-.197-.493-.386-.848-.653V3.083c0-.759-.616-1.375-1.375-1.375h-2.22l-.258-.189C9.621 1.17 8.665.325 8 .333 7.335.325 6.379 1.17 5.897 1.52l-.258.19H3.416c-.759 0-1.375.615-1.375 1.374v1.263c-.355.267-.595.456-.847.654a1.38 1.38 0 0 0-.528 1.082v.304l2.75 1.988v-5.29h9.167v5.29l2.75-1.988v-.304c0-.42-.195-.819-.527-1.08z" />
            </svg>
        </svg>
        <svg width="2" height="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0.3 2 6.4" fill="#ff663d" x="144" y="106">
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="7" viewBox="0.054 0.79 1.833 5.867">
            <path paint-order="stroke fill markers" fill-rule="evenodd" d="M1.888 5.74a.92.92 0 0 1-.917.917.92.92 0 0 1-.917-.917.92.92 0 0 1 .917-.916.92.92 0 0 1 .917.916zM.16 1.08l.154 3.116c.009.147.13.261.275.261h.76a.276.276 0 0 0 .274-.26L1.78 1.08a.275.275 0 0 0-.275-.29H.433a.274.274 0 0 0-.272.29z" />
            </svg>
        </svg>
    </svg>
);

export default EmailWarningIcon;