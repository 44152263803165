import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { useState } from "react";
import { AdminInput } from "../";

const AutocompleteSelect = ({
  options = [],
  label,
  color = "primary",
  value,
  onChange,
  disabled,
  ...params
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <MuiAutocomplete
      value={value}
      onChange={(a, b) => onChange(b)}
      inputValue={inputValue}
      onInputChange={(a, b) => setInputValue(b)}
      disabled={disabled}
      options={options}
      classes={{paper: "cv-paper-radius"}}
      {...params}
      renderInput={(params) => (
        <AdminInput color={color} {...params} label={label} />
      )}
    />
  );
};

export default AutocompleteSelect;
