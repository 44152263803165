import React, { Fragment, useEffect, useState } from "react";
import { Link } from "@mui/material";
import Hero from "../../../components/custom/hero";
import {
  AdCard,
  CardTitle,
  JobCard,
  ModalVideo,
  NotFound,
} from "../../../components/custom";
import { useLanguageContext } from "../../../context/language";
import "./style.scss";
import { CompanyAPI, JobAPI } from "../../../api/v2";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import {
  Facebook,
  HomeRounded,
  Instagram,
  Language,
  LocationOn,
  MailRounded,
  RecordVoiceOver,
} from "@mui/icons-material";
import { Pagination } from "../../../components/ui";
import EmptyJobs from "../../../assets/svg/jobs/emptyjobs";
import { setMetaData } from "../../../utils/meta";
import { tryParsing } from "../../../utils/misc";
import Spinner from "../../../components/custom/spinner";

const CompanySingle = () => {
  const [company, setCompany] = useState(null);
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { id, slug, subroute } = useParams();

  const getCompany = async () => {
    try {
      const res = await CompanyAPI.companyInfoPublic(id);
      setCompany(res);
      setMetaData({
        title: res.title,
        description: res.description,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAds = async () => {
    try {
      setIsLoading(true);
      const { data, meta } = await JobAPI.publicSingleCompanyAds(id, {
        limit: 5,
        offset: 5 * (page - 1),
      });
      setAds(data);
      setTotal(meta.total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompany();
  }, [subroute]);

  useEffect(() => {
    if (subroute === "jobs") {
      getAds(page);
    }
  }, [page, subroute]);

  const { t } = useLanguageContext();

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-companies.jpg" />
      <div className="cv-container cv-companysingle-main">
        <CardTitle
          align="center"
          postTitle="Pretražite profile poslodavaca i saznajte uslove rada i sve druge važne informacije o kompanijama koje vas zanimaju."
          title={
            <>
              <span className="secondary">{t("Upoznajte")}</span>{" "}
              {t("poslodavce")}
            </>
          }
        />
        {company ? (
          <Fragment>
            <div className="cv-company-single-holder">
              <div className="cv-company-single-bg">
                {company.background_image ? (
                  <img src={company.background_image} alt="Background" />
                ) : (
                  <img
                    src="/assets/images/card/gray_simple.jpeg"
                    alt="Background"
                  />
                )}
              </div>
              <div className="cv-company-single-profile">
                {company.profile_image ? (
                  <img src={company.profile_image} alt="Profile" />
                ) : null}
              </div>
              <div className="cv-company-single-text">
                <div className="cv-company-single-full">
                  <div className="cv-company-single-info-name">
                    {company.full_name}
                  </div>
                  <div className="cv-company-single-info-location">
                    <LocationOn />
                    {company.country.name}, {company.city.name}
                  </div>
                </div>
                <div className="cv-company-single-links">
                  <NavLink
                    exact
                    className="cv-company-single-link"
                    activeClassName="cv-company-single-link-active"
                    to={`/public/companies/${id}/${slug}`}
                  >
                    <HomeRounded /> <span>{t("NASLOVNA")}</span>
                  </NavLink>
                  <NavLink
                    exact
                    className="cv-company-single-link"
                    activeClassName="cv-company-single-link-active"
                    to={`/public/companies/${id}/${slug}/jobs`}
                  >
                    <RecordVoiceOver /> <span>{t("OGLASI")}</span>
                  </NavLink>
                  <NavLink
                    exact
                    className="cv-company-single-link"
                    activeClassName="cv-company-single-link-active"
                    to={`/public/companies/${id}/${slug}/contact`}
                  >
                    <MailRounded /> <span>{t("KONTAKT")}</span>
                  </NavLink>
                </div>
              </div>
            </div>
            {!subroute ? (
              <div className="cv-company-single-info">
                <div className="cv-company-single-part">
                  <CardTitle
                    align="left"
                    title={
                      <>
                        <span className="secondary">{t("Ukratko")}</span>{" "}
                        {"o kompaniji"}
                      </>
                    }
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: tryParsing(company.company_description),
                    }}
                    className="cv-injected-text"
                  ></div>
                </div>
                <div className="cv-company-single-part">
                  <div className="cv-company-single-cover">
                    {company.company_video ? (
                      <div className="cv-company-single-video-holder">
                        <ModalVideo src={company.company_video} />
                      </div>
                    ) : (
                      <img
                        src={
                          company.background_image ||
                          "/assets/images/holder/empty.png"
                        }
                        alt="Background"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {subroute === "jobs" ? (
              <div>
                <CardTitle
                  align="left"
                  postTitle={`${t("Svi oglasi kompanije")} "${
                    company.full_name
                  }"`}
                  title={<span className="secondary">{t("Oglasi")}</span>}
                />
                <div className="cv-company-single-ads">
                  {ads.map((x, y) => (
                    <JobCard
                      companySlug={x.company.slug}
                      slug={x.slug}
                      id={x.id}
                      company={x.company}
                      title={x.title}
                      description={x.short_description}
                      city={x.city}
                      country={x.country}
                      created_at={x.created_at}
                      category={x.type_of_work}
                    />
                  ))}
                  {isLoading ? <Spinner /> : null}
                  {!ads.length && !isLoading ? (
                    <div className="cv-jobs-empty">
                      <div className="cv-jobs-empty-icon">
                        <EmptyJobs />
                      </div>
                      <span>{t("Trenutno nema oglasa")}</span>
                    </div>
                  ) : null}
                </div>
                <div className="cv-pagination-holder cv-pagination-holder-right">
                  <Pagination
                    count={Math.ceil(total / 5)}
                    page={page}
                    setPage={setPage}
                  />
                </div>
              </div>
            ) : null}
            {subroute === "contact" ? (
              <div>
                <div className="cv-company-single-contact-row">
                  <div className="cv-company-single-contact-part">
                    <CardTitle
                      align="left"
                      postTitle={`${t("Kontaktirajte kompaniju ")} "${
                        company.full_name
                      }"`}
                      title={<span className="secondary">{t("Kontakt")}</span>}
                    />
                    <div className="cv-company-single-contact-all-info">
                      <div className="cv-company-single-contact-location">
                        <LocationOn />{" "}
                        <div>
                          <div>{company.address}</div>
                          <div>
                            {company.country.name}, {company.city.name}
                          </div>
                        </div>
                      </div>
                      <div className="cv-company-single-contact-website">
                        <Language />{" "}
                        <a href={company.website}>{company.website}</a>
                      </div>
                      <div className="cv-company-single-contact-others">
                        <div className="cv-company-single-contact-other">
                          <span>{t("PIB") + ":"}</span> {company.pib}
                        </div>
                        {company.pdv ? (
                          <div className="cv-company-single-contact-other">
                            <span>{"PDV" + ":"}</span> {company.pdv}
                          </div>
                        ) : null}
                        {company.number_of_employees_from ? (
                          <div className="cv-company-single-contact-other">
                            <span>{t("Broj zaposlenih") + ":"}</span>{" "}
                            {company.number_of_employees_from} -{" "}
                            {company.number_of_employees_to}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="cv-company-single-contact-part">
                    <div className="cv-company-single-contact-card">
                      <img
                        src="/assets/images/company/contact.png"
                        alt="Contact"
                      />
                      <div className="cv-company-single-contact-content">
                        <CardTitle
                          align="right"
                          postTitle={
                            company.contact_email ? (
                              <Link
                                color="secondary"
                                underline="hover"
                                href={
                                  company.contact_email
                                    ? `mailto:${company.contact_email}`
                                    : ""
                                }
                              >{t`Posaljite poruku poslodavcu putem email-a`}</Link>
                            ) : null
                          }
                          title={
                            <span className="secondary">
                              {t("Kontaktirajte poslodavca")}
                            </span>
                          }
                        />
                        <div className="cv-company-single-contact-actions">
                          {company.facebook ? (
                            <a href={company.facebook} target="_blank">
                              <Facebook />
                            </a>
                          ) : null}
                          {company.instagram ? (
                            <a href={company.instagram} target="_blank">
                              <Instagram />
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Fragment>
        ) : (
          <Spinner />
        )}
      </div>
    </Fragment>
  );
};

export default CompanySingle;
