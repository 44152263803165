import { InputCardWrapper } from './styles';

const InputCard = ({
  children,
  label,
  ...props
}) => {
  return (
    <InputCardWrapper {...props}>
      {label ? <legend className="ic-legend">{label}</legend> : ''}
      {children}
    </InputCardWrapper>
  );
};

export default InputCard;
