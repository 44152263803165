import {
  AddTask,
  Assignment,
  Email,
  ExitToAppRounded,
  HomeRounded,
  Menu as MenuIcon,
  Notifications,
  Work,
} from "@mui/icons-material";
import { IconButton as MuiIconButton } from "@mui/material";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { DashMobileNavigation } from "..";
import { NotificationAPI } from "../../../api/v2";
import { logoSource } from "../../../assets/images";
import { useUserContext } from "../../../context/user";
import { Avatar, IconButton, Button } from "../../ui";
import "./style.scss";
import UserImage from '../../../assets/images/nouser5.png';


const DashNavigation = () => {
  const [open, setOpen] = useState(false);

  const { setUser, conversations, user, logout } = useUserContext();

  const setSeen = (close, seen, id) => {
    if (!seen) {
      NotificationAPI.notificationSetSeen(id, 1);
      setUser({
        ...user,
        notifications: user.notifications.map((x) =>
          x.id === id ? { ...x, seen: 1 } : x
        ),
      });
    }
    close();
  };

  return (
    <Fragment>
      <div className="cv-dash-navigation">
        <Link className="cv-dash-navigation-logo" to="/dashboard">
          <img src={logoSource} alt="CVPRIČA" />
        </Link>
        <MuiIconButton
          className="cv-dash-navigation-hamburger"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </MuiIconButton>
        <div className="cv-dash-navigation-other">
          <div className="cv-dash-navigation-user">
          <div style={{height: 50, width: 50}} className="cv-dashboard-companyprofile-images-profile">
                {user.profile_image ? (
                       <Avatar src={user.profile_image} name={user.full_name} />
                    ) : <img style={{height: 50, width: 50}} src={UserImage}/>}
              </div>
            <div className="cv-dash-navigation-user-name">{user.full_name}</div>
          </div>
          <div className="cv-dash-navigation-actions">
            <IconButton
              count={user.notifications.filter((x) => !x.seen).length}
              className="cv-dash-navigation-action"
              icon={<Notifications />}
              dropdown={{
                title: "Obavještenja",
                content: (close) => (
                  <div className="cv-dash-navigation-notifications-content">
                    <div className="cv-dash-navigation-notifications-list">
                      {user.notifications.map((x) => (
                        <Link
                          onClick={() => setSeen(close, x.seen, x.id)}
                          to={`${
                            x.type === "single_ad"
                              ? `/dashboard/jobs/job/${x.particular_id}`
                              : ""
                          }${
                            x.type === "new_candidate_application"
                              ? `/dashboard/applications/application/${x.particular_id}`
                              : ""
                          }${
                            x.type === "edit_profile_notification"
                              ? "/dashboard/cv-profile/edit"
                              : ""
                          }`}
                          className={`cv-dash-navigation-notification-single ${
                            !x.seen
                              ? "cv-dash-navigation-notification-single-unread"
                              : ""
                          }`}
                        >
                          <div className="cv-dash-navigation-notification-single-left">
                            {x.type === "new_candidate_application" ? (
                              <Assignment />
                            ) : null}
                            {x.type === "single_ad" ? <Work /> : null}
                            {x.type === "edit_profile_notification" ? (
                              <AddTask />
                            ) : null}
                          </div>
                          <div className="cv-dash-navigation-notification-single-right">
                            <div className="cv-dash-navigation-notification-single-title">
                              {x.title}
                            </div>
                            <div className="cv-dash-navigation-notification-single-text">
                              {x.text}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      component={Link}
                      to="/dashboard/notifications"
                      onClick={close}
                    >
                      POGLEDAJ SVE
                    </Button>
                  </div>
                ),
              }}
            />
            <IconButton
              count={conversations.filter(x => x.seen === 0).length}
              className="cv-dash-navigation-action"
              icon={<Email />}
              dropdown={{
                title: "Poruke",
                content: (close) => (
                  <div className="cv-dash-navigation-notifications-content">
                    <div className="cv-dash-navigation-notifications-list">
                      {conversations?.map((x) => {
                        const sender = x.from.id === user.id ? x.to : x.from;
                        return (
                          <Link
                            onClick={close}
                            to={`/dashboard/messages/${sender.id}`}
                            className={`cv-dash-navigation-notification-single ${
                              !x.seen
                                ? "cv-dash-navigation-notification-single-unread"
                                : ""
                            }`}
                          >
                            <div className="cv-dash-navigation-messages-single-left">
                              {sender.profile_image ? (
                                <img src={sender.profile_image} alt="Profile" />
                              ) : null}
                            </div>
                            <div className="cv-dash-navigation-notification-single-right">
                              <div className="cv-dash-navigation-notification-single-title">
                                {sender.full_name}
                                <span>{moment(x.created_at).fromNow()}</span>
                              </div>
                              <div className="cv-dash-navigation-notification-single-text">
                                {x.text}
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                    <Button
                      color="secondary"
                      variant="contained"
                      fullWidth
                      component={Link}
                      to="/dashboard/messages"
                      onClick={close}
                    >
                      POGLEDAJ SVE
                    </Button>
                  </div>
                ),
              }}
            />
            <IconButton
              onClick={logout}
              className="cv-dash-navigation-action"
              icon={<ExitToAppRounded />}
            />
          </div>
        </div>
      </div>
      <div className="cv-dash-navigation-mobile">
        <IconButton
          className="cv-dash-navigation-mobile-action"
          activeClassName="cv-dash-navigation-mobile-action-active"
          icon={<HomeRounded />}
          exact
          component={NavLink}
          to="/dashboard"
        />
        <IconButton
          count={user.notifications.filter((x) => !x.seen).length}
          className="cv-dash-navigation-mobile-action"
          activeClassName="cv-dash-navigation-mobile-action-active"
          icon={<Notifications />}
          component={NavLink}
          to="/dashboard/notifications"
        />
        <IconButton
          count={conversations.filter((x) => !x.seen).length}
          className="cv-dash-navigation-mobile-action"
          activeClassName="cv-dash-navigation-mobile-action-active"
          icon={<Email />}
          component={NavLink}
          to="/dashboard/messages"
        />
      </div>
      <DashMobileNavigation open={open} setOpen={setOpen} />
    </Fragment>
  );
};

export default DashNavigation;
