const Company1 = () => (
  <svg viewBox="0 0 117 118">
    <path
      d="M22.22 117.32C9.99001 117.32 0.100006 107.79 0.100006 95.99C0.100006 84.2 9.99001 74.66 22.22 74.66C34.45 74.66 44.33 84.2 44.33 95.99C44.33 107.79 34.45 117.32 22.22 117.32Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M21.03 44.59C10.56 44.59 2.09 36.42 2.09 26.32C2.09 16.22 10.56 8.04999 21.03 8.04999C31.51 8.04999 39.97 16.22 39.97 26.32C39.97 36.42 31.51 44.59 21.03 44.59Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M85.39 60.97C67.91 60.97 53.79 47.35 53.79 30.49C53.79 13.64 67.91 0.00999451 85.39 0.00999451C102.87 0.00999451 117 13.64 117 30.49C117 47.35 102.87 60.97 85.39 60.97Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M58.95 108.54C31 108.54 8.39999 86.75 8.39999 59.79C8.39999 32.84 31 11.05 58.95 11.05C86.9 11.05 109.5 32.84 109.5 59.79C109.5 86.75 86.9 108.54 58.95 108.54Z"
      fill="white"
    ></path>
    <path
      d="M76.6 14.14C90.66 22.79 100.01 37.96 100.01 55.25C100.01 82.17 77.37 104 49.46 104C43.25 104 37.29 102.92 31.77 100.94C39.62 105.78 48.94 108.57 58.92 108.57C86.82 108.57 109.46 86.74 109.46 59.83C109.5 38.87 95.82 21.04 76.6 14.14Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.95 109.68C30.41 109.68 7.22 87.31 7.22 59.79C7.22 32.28 30.41 9.89999 58.95 9.89999C87.49 9.89999 110.69 32.31 110.69 59.79C110.69 87.28 87.45 109.68 58.95 109.68ZM58.95 12.19C31.74 12.19 9.59 33.55 9.59 59.79C9.59 86.03 31.74 107.4 58.95 107.4C86.16 107.4 108.31 86.03 108.31 59.79C108.31 33.55 86.16 12.19 58.95 12.19Z"
      fill="#21365F"
    ></path>
    <path
      d="M42.34 23.02C41.89 23.02 41.44 22.75 41.26 22.32C41.02 21.75 41.3 21.07 41.89 20.84C47.3 18.65 53.02 17.51 58.92 17.51C64.46 17.51 69.87 18.48 75 20.43C75.59 20.67 75.9 21.34 75.66 21.91C75.42 22.49 74.72 22.79 74.13 22.55C69.28 20.7 64.18 19.79 58.92 19.79C53.33 19.79 47.89 20.84 42.8 22.92C42.66 23.02 42.52 23.02 42.34 23.02Z"
      fill="#21365F"
    ></path>
    <path
      d="M33.66 27.8C33.31 27.8 32.93 27.63 32.72 27.33C32.33 26.83 32.44 26.12 32.96 25.75C34.15 24.91 35.4 24.1 36.69 23.36C37.25 23.02 37.98 23.23 38.33 23.76C38.68 24.3 38.47 25.01 37.91 25.35C36.69 26.02 35.51 26.79 34.39 27.6C34.18 27.73 33.9 27.8 33.66 27.8Z"
      fill="#21365F"
    ></path>
    <path
      d="M58.95 90.27C31 90.27 8.39999 76.65 8.39999 59.79C8.39999 42.94 31 29.32 58.95 29.32C86.9 29.32 109.5 42.94 109.5 59.79C109.5 76.65 86.9 90.27 58.95 90.27Z"
      fill="white"
    ></path>
    <path
      d="M109.5 59.79C109.5 58.52 109.36 57.27 109.12 56.03C100.08 47.95 88.04 43.04 74.75 43.04C50.16 43.04 29.68 60 25.15 82.43C34.11 87.31 45.94 90.27 58.95 90.27C86.86 90.27 109.5 76.62 109.5 59.79Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M58.95 84.15C44.96 84.15 33.66 73.26 33.66 59.79C33.66 46.33 44.96 35.44 58.95 35.44C72.94 35.44 84.24 46.33 84.24 59.79C84.24 73.26 72.94 84.15 58.95 84.15Z"
      fill="white"
    ></path>
    <path
      d="M84.21 59.79C84.21 59.49 84.17 59.16 84.17 58.85C79.64 54.75 73.57 52.19 66.83 52.19C52.88 52.19 41.54 63.09 41.54 76.55C41.54 76.85 41.58 77.19 41.58 77.49C46.11 81.59 52.18 84.15 58.92 84.15C72.9 84.15 84.21 73.25 84.21 59.79Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.95 91.42C30.41 91.42 7.22 77.22 7.22 59.79C7.22 42.37 30.41 28.17 58.95 28.17C87.49 28.17 110.69 42.37 110.69 59.79C110.69 77.22 87.45 91.42 58.95 91.42ZM58.95 30.49C31.74 30.49 9.59 43.65 9.59 59.83C9.59 76.01 31.74 89.16 58.95 89.16C86.16 89.16 108.31 76.01 108.31 59.83C108.31 43.65 86.16 30.49 58.95 30.49Z"
      fill="#21365F"
    ></path>
    <path
      d="M58.95 60.94C58.78 60.94 58.64 60.9 58.46 60.84C58.04 60.67 57.76 60.23 57.76 59.79V35.44C57.76 34.8 58.29 34.29 58.95 34.29C59.61 34.29 60.14 34.8 60.14 35.44V57.2L76.81 42.5C77.3 42.07 78.03 42.1 78.49 42.57C78.94 43.04 78.9 43.75 78.42 44.18L59.75 60.64C59.51 60.84 59.23 60.94 58.95 60.94Z"
      fill="#21365F"
    ></path>
    <path
      d="M45.03 81.29C44.82 81.29 44.58 81.22 44.37 81.09C43.81 80.75 43.67 80.05 44.02 79.51L57.94 59.19C58.25 58.72 58.92 58.55 59.44 58.78L82.18 69.45C82.78 69.72 83.02 70.42 82.74 70.96C82.46 71.54 81.73 71.77 81.17 71.5L59.37 61.27L46.01 80.79C45.8 81.09 45.41 81.29 45.03 81.29Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.95 85.29C44.37 85.29 32.51 73.86 32.51 59.79C32.51 45.73 44.37 34.29 58.95 34.29C73.53 34.29 85.39 45.73 85.39 59.79C85.39 73.86 73.53 85.29 58.95 85.29ZM58.95 36.58C45.66 36.58 34.84 47.01 34.84 59.79C34.84 72.58 45.66 83.01 58.95 83.01C72.24 83.01 83.06 72.58 83.06 59.79C83.06 47.01 72.24 36.58 58.95 36.58Z"
      fill="#21365F"
    ></path>
    <path
      d="M66.83 56.77C67.81 56.77 68.72 56.9 69.62 57.14C68.4 52.56 64.08 49.16 58.95 49.16C52.85 49.16 47.89 53.94 47.89 59.83C47.89 64.77 51.41 68.94 56.16 70.12C55.92 69.25 55.78 68.37 55.78 67.43C55.78 61.54 60.73 56.77 66.83 56.77Z"
      fill="white"
    ></path>
    <path
      d="M70.01 59.79C70.01 58.85 69.87 57.98 69.62 57.1C68.72 56.87 67.81 56.73 66.83 56.73C60.73 56.73 55.78 61.51 55.78 67.4C55.78 68.34 55.92 69.21 56.16 70.09C57.07 70.32 57.97 70.46 58.95 70.46C65.06 70.46 70.01 65.68 70.01 59.79Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.95 71.6C52.18 71.6 46.71 66.32 46.71 59.79C46.71 53.27 52.18 47.99 58.95 47.99C65.72 47.99 71.19 53.27 71.19 59.79C71.19 66.32 65.68 71.6 58.95 71.6ZM58.95 50.27C53.51 50.27 49.08 54.55 49.08 59.79C49.08 65.04 53.51 69.31 58.95 69.31C64.39 69.31 68.82 65.04 68.82 59.79C68.82 54.55 64.39 50.27 58.95 50.27Z"
      fill="#21365F"
    ></path>
    <path
      d="M16.29 21.75C14.53 21.75 13.11 20.38 13.11 18.68C13.11 16.99 14.53 15.62 16.29 15.62C18.04 15.62 19.46 16.99 19.46 18.68C19.46 20.38 18.04 21.75 16.29 21.75Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.29 22.86C13.88 22.86 11.93 20.97 11.93 18.65C11.93 16.33 13.88 14.45 16.29 14.45C18.69 14.45 20.65 16.33 20.65 18.65C20.65 20.97 18.69 22.86 16.29 22.86ZM16.29 16.77C15.2 16.77 14.3 17.61 14.3 18.68C14.3 19.73 15.17 20.6 16.29 20.6C17.37 20.6 18.27 19.76 18.27 18.68C18.27 17.61 17.37 16.77 16.29 16.77Z"
      fill="#21365F"
    ></path>
    <path
      d="M96.84 17.17C95.08 17.17 93.66 15.8 93.66 14.11C93.66 12.42 95.08 11.05 96.84 11.05C98.59 11.05 100.01 12.42 100.01 14.11C100.01 15.8 98.59 17.17 96.84 17.17Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.84 18.28C94.43 18.28 92.48 16.4 92.48 14.08C92.48 11.75 94.43 9.87 96.84 9.87C99.24 9.87 101.2 11.75 101.2 14.08C101.2 16.4 99.24 18.28 96.84 18.28ZM96.84 12.19C95.75 12.19 94.85 13.03 94.85 14.11C94.85 15.15 95.72 16.03 96.84 16.03C97.92 16.03 98.82 15.19 98.82 14.11C98.82 13.07 97.95 12.19 96.84 12.19Z"
      fill="#21365F"
    ></path>
    <path
      d="M106.32 27.84C104.57 27.84 103.15 26.47 103.15 24.77C103.15 23.08 104.57 21.71 106.32 21.71C108.08 21.71 109.5 23.08 109.5 24.77C109.5 26.47 108.08 27.84 106.32 27.84Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.32 28.95C103.92 28.95 101.96 27.06 101.96 24.74C101.96 22.42 103.92 20.54 106.32 20.54C108.73 20.54 110.69 22.42 110.69 24.74C110.69 27.09 108.73 28.95 106.32 28.95ZM106.32 22.86C105.24 22.86 104.34 23.7 104.34 24.77C104.34 25.82 105.21 26.69 106.32 26.69C107.41 26.69 108.31 25.85 108.31 24.77C108.31 23.73 107.41 22.86 106.32 22.86Z"
      fill="#21365F"
    ></path>
    <path
      d="M11.54 99.42C9.79 99.42 8.37 98.05 8.37 96.36C8.37 94.67 9.79 93.3 11.54 93.3C13.3 93.3 14.72 94.67 14.72 96.36C14.72 98.05 13.3 99.42 11.54 99.42Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.54 100.53C9.12999 100.53 7.17999 98.65 7.17999 96.33C7.17999 94.01 9.12999 92.12 11.54 92.12C13.95 92.12 15.9 94.01 15.9 96.33C15.9 98.68 13.95 100.53 11.54 100.53ZM11.54 94.44C10.46 94.44 9.54999 95.29 9.54999 96.36C9.54999 97.4 10.42 98.28 11.54 98.28C12.62 98.28 13.53 97.44 13.53 96.36C13.53 95.32 12.62 94.44 11.54 94.44Z"
      fill="#21365F"
    ></path>
    <path
      d="M24.17 108.57C22.41 108.57 21 107.21 21 105.51C21 103.82 22.41 102.45 24.17 102.45C25.93 102.45 27.34 103.82 27.34 105.51C27.34 107.21 25.93 108.57 24.17 108.57Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.17 109.68C21.76 109.68 19.81 107.8 19.81 105.48C19.81 103.16 21.76 101.27 24.17 101.27C26.58 101.27 28.53 103.16 28.53 105.48C28.53 107.8 26.58 109.68 24.17 109.68ZM24.17 103.6C23.09 103.6 22.18 104.44 22.18 105.51C22.18 106.56 23.05 107.43 24.17 107.43C25.25 107.43 26.16 106.59 26.16 105.51C26.16 104.47 25.29 103.6 24.17 103.6Z"
      fill="#21365F"
    ></path>
    <path
      d="M104.75 100.94C103 100.94 101.58 99.57 101.58 97.88C101.58 96.18 103 94.81 104.75 94.81C106.51 94.81 107.93 96.18 107.93 97.88C107.93 99.57 106.51 100.94 104.75 100.94Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.75 102.08C102.35 102.08 100.39 100.2 100.39 97.88C100.39 95.55 102.35 93.67 104.75 93.67C107.16 93.67 109.12 95.55 109.12 97.88C109.08 100.2 107.13 102.08 104.75 102.08ZM104.75 95.99C103.67 95.99 102.77 96.83 102.77 97.91C102.77 98.95 103.64 99.83 104.75 99.83C105.84 99.83 106.74 98.99 106.74 97.91C106.71 96.83 105.84 95.99 104.75 95.99Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Company1;