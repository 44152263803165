import { AccessTime, Facebook, LinkedIn, LocationOn, SendRounded } from "@mui/icons-material";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { JobAPI } from "../../../api/v2";
import { CardTitle, Hero } from "../../../components/custom";
import { Button } from "../../../components/ui";
import { useLanguageContext } from "../../../context/language";
import { tryParsing } from "../../../utils/misc";
import { setMetaData } from '../../../utils/meta';
import "./style.scss";
import Spinner from "../../../components/custom/spinner";

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="cv-jobsingle-tabpanel">{children}</div>
      )}
    </div>
  );
};

const JobSingle = () => {
  const { id } = useParams();

  const [job, setJob] = useState(null);

  const [tab, setTab] = useState(0);

  const { t } = useLanguageContext();

  const getSingleJob = async () => {
    try{
      const res = await JobAPI.job(id);
      setJob(res);
      setMetaData({
        title: res.full_name,
        description: res.company_description,
      });
    } catch(error) { 
      console.error(error);
    }
  };

  useEffect(() => {
    getSingleJob();
  }, []);

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-jobs.jpg" />

      <div className="cv-container cv-jobsingle-main">
        <div className="cv-jobsingle-title">
          <CardTitle
            postTitle={t("Detaljan prikaz oglasa kompanije")}
            title={
              <>
                <span className="secondary">{t("Detalji")}</span> {"oglasa"}
              </>
            }
          />
          <Button
            className="cv-jobsingle-back"
            variant="contained"
            color="secondary"
            size="large"
            component={Link}
            to="/public/jobs"
          >
            {"NAZAD NA OGLASE"}
          </Button>
          </div>
        </div>
        <div className="cv-container cv-jobsingle-main">
          {job ? (
            <div className="cv-jobsingle-paper">
              <div className="cv-jobsingle-info">
                <div className="cv-jobsingle-info-ad">
                  <CardTitle
                    title={<span className="secondary">{job.title}</span>}
                    postTitle={job.company?.full_name}
                  />
                  <div className="cv-jobsingle-info-row">
                    <LocationOn /> {job.city?.name}, {job.country?.name}
                  </div>
                  <div className="cv-jobsingle-info-row">
                    <AccessTime /> {moment(job.start_date).format("DD.MM.YYYY")}
                    -{moment(job.end_date).format("DD.MM.YYYY")}
                  </div>
                </div>
                <div className="cv-jobsingle-info-logo">
                  <img src={job?.company?.profile_image} alt="Logo" />
                </div>
              </div>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  className="cv-jobsingle-tabs"
                  textColor="secondary"
                  indicatorColor="secondary"
                  value={tab}
                  onChange={(a, b) => setTab(b)}
                  aria-label="basic tabs example"
                >
                  <Tab label="Tekst oglasa" />
                  <Tab label="O poslodavcu" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                {job.description ? (
                  <div
                    className="cv-injected-text"
                    dangerouslySetInnerHTML={{
                      __html: tryParsing(job.description),
                    }}
                  ></div>
                ) : (
                  "/"
                )}
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {job.company?.company_description ? (
                  <div
                    className="cv-jobsearch-description cv-injected-text"
                    dangerouslySetInnerHTML={{
                      __html: tryParsing(job.company.company_description),
                    }}
                  ></div>
                ) : (
                  "/"
                )}
              </TabPanel>
              <div className="cv-jobsearch-actions">
                <div className="cv-jobsearch-actions-share">
                  <span>{t("Podjelite oglas")}</span>
                  <a
                    className="cv-jobsearch-action-single"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  >
                    <Facebook />
                  </a>
                  <a
                    className="cv-jobsearch-action-single"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                  >
                    <LinkedIn />
                  </a>
                </div>
                <Button
                  className="cv-jobsearch-goto"
                  color="primary"
                  variant="contained"
                  size="large"
                  startIcon={<SendRounded />}
                  component={Link}
                  to={`/dashboard/jobs/job/${id}`}
                >
                  {t("KONKURIŠI NA OGLAS")}
                </Button>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>
    </Fragment>
  );
};

export default JobSingle;
