import React from "react";
import { InputWrapper } from "./styles";

const Input = React.forwardRef(({
  endAdornment,
  startAdornment,
  onChange = () => {},
  ...props
}, ref) => {
  return (
    <InputWrapper
      onChange={(a) => onChange(a.target.value)}
      InputProps={{
        endAdornment,
        startAdornment,
      }}
      ref={ref}
      {...props}
    />
  );
});

export default Input;
