import { Check, Close, StorageRounded } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useState } from "react";
import { DashTitle } from "../../components/custom";
import { SimpleCard, Button } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import { useUserContext } from "../../context/user";
import { TabPanel } from "../job-single/public";
import { useEventContext } from "../../context/event";
import "./style.scss";

const Benefits = [
  "Neograničen tekst oglasa",
  "Broj gradova u oglasu",
  "Objava oglasa u državi",
  "Isticanje u rezultatima pretrage",
  "Logo u oglasu i rezultatima pretrage",
  "Vaš link za konkurisanje",
  "Opis u rezultatima pretrage",
  //"Prioritet u postavci oglasa do 24h",
  "Čuvanje nedovršenog/započetog oglasa",
  "Statistika oglasa",
  "Razgledanje kandidata u video formatu",
  "Obavještenja o novim prijavama",
  "Izdvajanje kandidata u posebnu listu",
  //"Čuvanje bilješki o kandidatu",
  "Slanje i primanje direktnih poruka kandidata i uvid u istoriju prepiske",
  "Postavljanje posebnih pitanja na koje kandidat mora odgovoriti u video formatu",
  "Zakazivanje video intervjua",
  "Postavljanje koorporativnog videa",
  "Prezentacija u sekciji za istaknute poslodavce",
];

const Packages = () => {
  const [tab, setTab] = useState(0);

  const [pricing, setPricing] = useState(0);
  const [sPackage, setSPackage] = useState(0);
  const [jobNum, setJobNum] = useState(0);

  const { packages } = useUserContext();

  const { width } = useEventContext();
  const { t } = useLanguageContext();

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Cjenovnik oglasa za poslodavce")}
          subtitle={t(
            "Odaberite oglas po vašoj mjeri. Uporedite vrste oglasa i oglasite se na najposećenijem sajtu za traženje posla na Balkanu."
          )}
        />
        <SimpleCard
          className="cv-dashboard-jobs-new-content cv-dashboard-packages-content-card"
          withContent
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              className="cv-jobsingle-tabs cv-packages-tabs"
              textColor="secondary"
              indicatorColor="secondary"
              value={tab}
              onChange={(a, b) => setTab(b)}
              aria-label="basic tabs example"
            >
              <Tab label={t("CJENOVNIK OGLASA")} />
              <Tab label={t("CJENOVNIK PAKETA")} />
            </Tabs>
          </Box>

          <TabPanel value={tab} index={0}>
            {(() => {
              if (width > 991) {
                return (
                  <div className="cv-dashboard-packages-content cv-dashboard-packages-content-jobs">
                    <div className="cv-dashboard-packages-benefits">
                      <div className="cv-dashboard-packages-cell cv-dashboard-packages-package-title">
                        {t("BENEFITI")}
                      </div>
                      {Benefits.map((x, y) => (
                        <div className="cv-dashboard-packages-cell" key={y}>
                          {x}
                        </div>
                      ))}
                    </div>
                    {packages.map((x, y) => (
                      <SimpleCard
                        className="cv-dashboard-packages-package"
                        key={y}
                      >
                        <div className="cv-dashboard-packages-cell cv-dashboard-packages-package-title">
                          {x.name}
                        </div>
                        {Benefits.map((a, b) => (
                          <div className="cv-dashboard-packages-cell" key={b}>
                            {typeof x.benefits[a] === "boolean" ? (
                              x.benefits[a] ? (
                                <Check color="success" />
                              ) : (
                                <Close color="error" />
                              )
                            ) : (
                              x.benefits[a]
                            )}
                          </div>
                        ))}
                        <div className="cv-dashboard-packages-cell cv-dashboard-packages-package-price">
                          {x.pricing[pricing].price}€
                        </div>
                      </SimpleCard>
                    ))}
                    <div className="cv-dashboard-packages-period">
                      <div className="cv-dashboard-packages-period-title">
                        {t("Izaberite period oglašavanja")}
                      </div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="period"
                          name="row-radio-buttons-group"
                          value={pricing}
                          onChange={(a, b) => setPricing(b)}
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio color="secondary" />}
                            label={t("15 dana")}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio color="secondary" />}
                            label={t("30 dana")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <SimpleCard className="cv-dashboard-packages-register">
                      <div className="cv-dashboard-packages-register-icon">
                        <StorageRounded />
                      </div>
                      <div className="cv-dashboard-packages-register-text">
                        <div className="cv-dashboard-packages-register-title">
                          <span>{"Besplatno"}</span>
                        </div>
                        <div className="cv-dashboard-packages-register-description">
                          <span>
                            {"Besplatno neograničeno korišćenje platforme"}
                          </span>{" "}
                          {t("do 30.06.2022. godine.")}
                        </div>
                      </div>
                    </SimpleCard>
                  </div>
                );
              } else {
                return (
                  <div className="cv-dashboard-packages-content-jobs-mobile">
                    <div className="cv-dashboard-packages-period">
                      <div className="cv-dashboard-packages-period-title">
                        {t("Izaberite period oglašavanja")}
                      </div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="period"
                          name="row-radio-buttons-group"
                          value={pricing}
                          onChange={(a, b) => setPricing(b)}
                          className="cv-dashboard-radio-group"
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio color="secondary" />}
                            label={t("15 dana")}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio color="secondary" />}
                            label={t("30 dana")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    {packages?.map((x, y) => (
                      <SimpleCard
                        className="cv-dashboard-packages-package"
                        key={y}
                      >
                        <div className="cv-dashboard-packages-package-title">
                          {x.name}
                        </div>
                        <div className="cv-dashboard-packages-package-price">
                          {x.pricing[pricing].price}€
                        </div>
                        <div className="cv-dashboard-packages-package-period-text">
                          {!pricing
                            ? t("U periodu od 15 dana od dana aktivacije.")
                            : pricing === "0"
                            ? t("U periodu od 15 dana od dana aktivacije.")
                            : t("U periodu od 30 dana od dana aktivacije.")}
                        </div>
                        {/* <div className="cv-dashboard-packages-choose-package">
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                        >
                          Izaberite
                        </Button>
                      </div> */}
                        <details className="benefits-dropdown">
                          <summary>
                            {"Pogledajte karakteristike"}
                            <svg
                              height="18px"
                              width="18px"
                              viewBox="0 0 320 512"
                            >
                              <path
                                fill="black"
                                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                              ></path>
                            </svg>
                          </summary>
                          {Benefits?.map((a, b) => (
                            <div
                              className="cv-dashboard-packages-cell cv-dashboard-packages-cell-mobile"
                              key={b}
                            >
                              {typeof x.benefits[a] === "boolean" ? (
                                x.benefits[a] ? (
                                  <div className="benefits-group">
                                    <div className="benefit-desc">{a}</div>
                                    <Check color="success" />
                                  </div>
                                ) : (
                                  <div className="benefits-group">
                                    <div className="benefit-desc">{a}</div>
                                    <Close color="error" />
                                  </div>
                                )
                              ) : (
                                <div className="benefits-group">
                                  <div className="benefit-desc">{a}</div>
                                  <div className="benefit-val">
                                    {x.benefits[a]}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </details>
                      </SimpleCard>
                    ))}
                    <SimpleCard className="cv-dashboard-packages-register">
                      <div className="cv-dashboard-packages-register-icon">
                        <StorageRounded />
                      </div>
                      <div className="cv-dashboard-packages-register-text">
                        <div className="cv-dashboard-packages-register-title">
                          <span>{"Besplatno"}</span>
                        </div>
                        <div className="cv-dashboard-packages-register-description">
                          <span>
                            {"Besplatno neograničeno korišćenje platforme"}
                          </span>{" "}
                          {t("do 30.06.2022. godine.")}
                        </div>
                      </div>
                    </SimpleCard>
                  </div>
                );
              }
            })()}
          </TabPanel>

          <TabPanel value={tab} index={1}>
            {(() => {
              if (width > 991) {
                return (
                  <div className="cv-dashboard-packages-content">
                    <div className="cv-dashboard-packages-content-select-package">
                      {t("Izaberite paket") + ":"}
                    </div>
                    <FormControl
                      component="fieldset"
                      className="cv-dashboard-packages-content-select-fieldset"
                    >
                      <RadioGroup
                        row
                        aria-label="period"
                        name="row-radio-buttons-group"
                        value={sPackage}
                        onChange={(a, b) => setSPackage(b)}
                        className="cv-dashboard-packages-content-select-all"
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label="STARTER"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label="BASIC"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="secondary" />}
                          label="PLUS"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio color="secondary" />}
                          label="PRO"
                        />
                        <FormControlLabel
                          value={4}
                          control={<Radio color="secondary" />}
                          label="ENTERPRISE"
                        />
                      </RadioGroup>
                    </FormControl>
                    <div className="cv-dashboard-packages-content-sides">
                      <SimpleCard className="cv-dashboard-packages-content-left">
                        <div className="cv-dashboard-packages-content-left-row">
                          {t("BENEFITI")}
                        </div>
                        {Benefits.map((x, y) => (
                          <div
                            key={y}
                            className="cv-dashboard-packages-content-left-row"
                          >
                            <div className="cv-dashboard-packages-content-left-row-label">
                              {x}
                            </div>
                            <div className="cv-dashboard-packages-content-left-row-value">
                              {typeof packages[sPackage].benefits[x] ===
                              "boolean" ? (
                                packages[sPackage].benefits[x] ? (
                                  <Check color="success" />
                                ) : (
                                  <Close color="error" />
                                )
                              ) : (
                                packages[sPackage].benefits[x]
                              )}
                            </div>
                          </div>
                        ))}
                      </SimpleCard>
                      <div className="cv-dashboard-packages-content-right">
                        <SimpleCard className="cv-dashboard-packages-content-card">
                          <div className="cv-dashboard-packages-period-right">
                            <div className="cv-dashboard-packages-period-title">
                              {t("Izaberite period oglašavanja")}
                            </div>
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                aria-label="period"
                                name="row-radio-buttons-group"
                                value={pricing}
                                onChange={(a, b) => setPricing(b)}
                              >
                                <FormControlLabel
                                  value={0}
                                  control={<Radio color="secondary" />}
                                  label={t("15 dana")}
                                />
                                <FormControlLabel
                                  value={1}
                                  control={<Radio color="secondary" />}
                                  label={t("30 dana")}
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="cv-dashboard-packages-discounts">
                            <div className="cv-dashboard-packages-period-title">
                              {t("Broj oglasa u paketu") + ":"}
                            </div>
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                aria-label="period"
                                name="row-radio-buttons-group"
                                value={jobNum}
                                onChange={(a, b) => setJobNum(b)}
                                className="cv-dashboard-packages-period-formcontrols"
                              >
                                {packages[sPackage].discounts.map((x, y) => (
                                  <FormControlLabel
                                    value={y}
                                    control={<Radio color="secondary" />}
                                    label={`${x.title} (${x.amount}% popusta)`}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="cv-dashboard-packages-period-result">
                            <div className="cv-dashboard-packages-period-result-left">
                              Izabrali ste do{" "}
                              <span>
                                {packages[sPackage].discounts[jobNum].ad_count}{" "}
                                {t("oglasa godišnje") + "("}
                                {
                                  packages[sPackage].discounts[jobNum].amount
                                }{" "}
                                {"%" + t("popusta") + ")" + "iz"}
                                <span>{packages[sPackage].name}</span>
                              </span>
                            </div>
                            <div className="cv-dashboard-packages-period-result-right">
                              Ukupna cijena:
                              <span>
                                {
                                  packages[sPackage].pricing[pricing]
                                    .discount_prices[jobNum].discounted_price
                                }
                                €
                              </span>
                            </div>
                          </div>
                        </SimpleCard>
                        <SimpleCard className="cv-dashboard-packages-register">
                          <div className="cv-dashboard-packages-register-icon">
                            <StorageRounded />
                          </div>
                          <div className="cv-dashboard-packages-register-text">
                            <div className="cv-dashboard-packages-register-title">
                              <span>{"Besplatno"}</span>
                            </div>
                            <div className="cv-dashboard-packages-register-description">
                              <span>
                                {"Besplatno neograničeno korišćenje platforme"}
                              </span>{" "}
                              {t("do 30.06.2022. godine.")}
                            </div>
                          </div>
                        </SimpleCard>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="cv-dashboard-packages-content-jobs-mobile">
                    <div className="cv-dashboard-packages-period">
                      <div className="cv-dashboard-packages-period-title">
                        {t("Izaberite period oglašavanja")}
                      </div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="period"
                          name="row-radio-buttons-group"
                          value={pricing}
                          onChange={(a, b) => setPricing(b)}
                          className="cv-dashboard-radio-group"
                        >
                          <FormControlLabel
                            value={0}
                            control={<Radio color="secondary" />}
                            label={t("15 dana")}
                          />
                          <FormControlLabel
                            value={1}
                            control={<Radio color="secondary" />}
                            label={t("30 dana")}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="cv-dashboard-packages-discounts-mobile">
                      <details className="benefits-dropdown" open>
                        <summary>
                          {t("Broj oglasa u paketu" + ":")}
                          <svg height="18px" width="18px" viewBox="0 0 320 512">
                            <path
                              fill="black"
                              d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                            ></path>
                          </svg>
                        </summary>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            aria-label="period"
                            name="row-radio-buttons-group"
                            value={jobNum}
                            onChange={(a, b) => setJobNum(b)}
                            className="cv-dashboard-packages-period-formcontrols"
                          >
                            {packages[sPackage].discounts?.map((x, y) => (
                              <FormControlLabel
                                value={y}
                                control={<Radio color="secondary" />}
                                label={`${x.title} (${x.amount}% popusta)`}
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </details>
                    </div>

                    {packages?.map((x, y) => (
                      <SimpleCard
                        className="cv-dashboard-packages-package"
                        key={y}
                      >
                        <div className="cv-dashboard-packages-package-title">
                          {x.name}
                        </div>
                        <div className="cv-dashboard-packages-package-price">
                          {
                            x.pricing[pricing].discount_prices[jobNum]
                              .discounted_price
                          }
                          €
                        </div>
                        <div className="cv-dashboard-packages-package-period-text">
                          {!pricing
                            ? t("U periodu od 15 dana od dana aktivacije.")
                            : pricing === "0"
                            ? t("U periodu od 15 dana od dana aktivacije.")
                            : t("U periodu od 30 dana od dana aktivacije.")}
                        </div>
                        {/* <div className="cv-dashboard-packages-choose-package">
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                        >
                          Izaberite
                        </Button>
                      </div> */}
                        <details className="benefits-dropdown">
                          <summary>
                            {t("Pogledajte karakteristike")}
                            <svg
                              height="18px"
                              width="18px"
                              viewBox="0 0 320 512"
                            >
                              <path
                                fill="black"
                                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                              ></path>
                            </svg>
                          </summary>

                          {Benefits?.map((a, b) => (
                            <div
                              className="cv-dashboard-packages-cell cv-dashboard-packages-cell-mobile"
                              key={b}
                            >
                              {typeof x.benefits[a] === "boolean" ? (
                                x.benefits[a] ? (
                                  <div className="benefits-group">
                                    <div className="benefit-desc">{a}</div>
                                    <Check color="success" />
                                  </div>
                                ) : (
                                  <div className="benefits-group">
                                    <div className="benefit-desc">{a}</div>
                                    <Close color="error" />
                                  </div>
                                )
                              ) : (
                                <div className="benefits-group">
                                  <div className="benefit-desc">{a}</div>
                                  <div className="benefit-val">
                                    {x.benefits[a]}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </details>
                      </SimpleCard>
                    ))}
                    <SimpleCard className="cv-dashboard-packages-register">
                      <div className="cv-dashboard-packages-register-icon">
                        <StorageRounded />
                      </div>
                      <div className="cv-dashboard-packages-register-text">
                        <div className="cv-dashboard-packages-register-title">
                          <span>{"Besplatno"}</span>
                        </div>
                        <div className="cv-dashboard-packages-register-description">
                          <span>
                            {"Besplatno neograničeno korišćenje platforme"}
                          </span>{" "}
                          {t("do 30.06.2022. godine.")}
                        </div>
                      </div>
                    </SimpleCard>
                  </div>
                );
              }
            })()}
          </TabPanel>
        </SimpleCard>
      </div>
    </Fragment>
  );
};

export default Packages;
