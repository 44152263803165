import React from 'react';

const EmptyJobs = () => (
  <svg
    version="1.2"
    baseProfile="tiny-ps"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 119 112"
    width="119"
    height="112"
  >
    <g id="Pronađite posao Nema oglasa">
      <g id="nema-oglasa">
        <g id="Group 539">
          <path
            id="Ellipse 113"
            className="shp0"
            fill="#e8ebec"
            d="M26.4 41.09C15.31 41.09 6.35 32.13 6.35 21.05C6.35 9.96 15.31 1 26.4 1C37.49 1 46.45 9.96 46.45 21.05C46.45 32.13 37.49 41.09 26.4 41.09Z"
          ></path>
        </g>
        <g id="Group 540">
          <path
            id="Ellipse 114"
            className="shp0"
            fill="#e8ebec"
            d="M101.12 44.7C91.62 44.7 83.93 37.01 83.93 27.51C83.93 18 91.62 10.32 101.12 10.32C110.63 10.32 118.31 18 118.31 27.51C118.31 37.01 110.63 44.7 101.12 44.7Z"
          ></path>
        </g>
        <g id="Group 541">
          <path
            id="Ellipse 115"
            className="shp0"
            fill="#e8ebec"
            d="M29.01 111.3C13.18 111.3 0.39 98.51 0.39 82.68C0.39 66.85 13.18 54.06 29.01 54.06C44.84 54.06 57.63 66.85 57.63 82.68C57.63 98.51 44.84 111.3 29.01 111.3Z"
          ></path>
        </g>
        <g id="Group 542">
          <path
            id="Path 445"
            className="shp1"
            fill="#ffffff"
            d="M23.46 22.41L69.27 22.41C72.42 22.41 74.99 24.98 74.99 28.13L74.99 85.37C74.99 88.52 72.42 91.09 69.27 91.09L32.07 91.09L17.79 102.52L17.79 28.17C17.74 24.98 20.31 22.41 23.46 22.41Z"
          ></path>
        </g>
        <g id="Group 543">
          <path
            id="Path 446"
            className="shp2"
            fill="#bdc3d8"
            d="M27.77 41.05L69.27 41.05C72.42 41.05 74.99 43.62 74.99 46.77L74.99 85.41C74.99 88.56 72.42 91.13 69.27 91.13L32.07 91.13L24.91 96.84L24.91 43.91C24.91 42.29 26.19 41.05 27.77 41.05Z"
          ></path>
        </g>
        <g id="Group 544">
          <path
            id="Path 447"
            fill-rule="evenodd"
            className="shp3"
            fill="#21365f"
            d="M17.74 103.64C17.58 103.64 17.41 103.59 17.29 103.51C16.92 103.35 16.67 102.97 16.67 102.56L16.67 28.17C16.67 24.44 19.73 21.38 23.46 21.38L69.27 21.38C73 21.38 76.06 24.44 76.06 28.17L76.06 85.41C76.06 89.14 73 92.2 69.27 92.2L32.45 92.2L18.45 103.43C18.24 103.55 17.99 103.64 17.74 103.64ZM23.46 23.49C20.89 23.49 18.82 25.56 18.82 28.13L18.82 100.32L31.37 90.26C31.58 90.09 31.78 90.01 32.03 90.01L69.23 90.01C71.8 90.01 73.87 87.94 73.87 85.37L73.87 28.17C73.87 25.6 71.8 23.53 69.23 23.53L23.46 23.53L23.46 23.49Z"
          ></path>
        </g>
        <g id="Group 545">
          <path
            id="Path 448"
            className="shp1"
            fill="#ffffff"
            d="M34.93 10.98L89.32 10.98C92.46 10.98 95.03 13.55 95.03 16.7L95.03 91.13L80.74 79.7L34.93 79.7C31.78 79.7 29.22 77.13 29.22 73.98L29.22 16.7C29.22 13.55 31.74 10.98 34.93 10.98Z"
          ></path>
        </g>
        <g id="Group 546">
          <path
            id="Path 449"
            className="shp2"
            fill="#bdc3d8"
            d="M52.08 26.72L94.99 26.72L94.99 91.13L80.7 79.7L46.36 79.7L46.36 32.48C46.36 29.29 48.93 26.72 52.08 26.72Z"
          ></path>
        </g>
        <g id="Group 547">
          <path
            id="Path 450"
            fill-rule="evenodd"
            className="shp3"
            fill="#21365f"
            d="M95.03 92.2C94.78 92.2 94.54 92.12 94.37 91.96L80.37 80.73L34.93 80.73C31.21 80.73 28.14 77.67 28.14 73.94L28.14 16.7C28.14 12.97 31.21 9.9 34.93 9.9L89.32 9.9C93.04 9.9 96.11 12.97 96.11 16.7L96.11 91.13C96.11 91.54 95.86 91.91 95.49 92.08C95.32 92.16 95.2 92.2 95.03 92.2ZM34.93 12.06C32.36 12.06 30.29 14.13 30.29 16.7L30.29 73.94C30.29 76.51 32.36 78.58 34.93 78.58L80.74 78.58C80.99 78.58 81.24 78.66 81.4 78.83L93.96 88.89L93.96 16.7C93.96 14.13 91.88 12.06 89.32 12.06L34.93 12.06Z"
          ></path>
        </g>
        <g id="Group 548">
          <path
            id="Path 451"
            className="shp3"
            fill="#21365f"
            d="M65.96 86.45L50.51 86.45C49.93 86.45 49.43 85.95 49.43 85.37C49.43 84.79 49.93 84.29 50.51 84.29L65.96 84.29C66.54 84.29 67.03 84.79 67.03 85.37C67.03 85.99 66.54 86.45 65.96 86.45Z"
          ></path>
        </g>
        <g id="Group 549">
          <path
            id="Path 452"
            className="shp3"
            fill="#21365f"
            d="M44.79 86.45L41.64 86.45C41.06 86.45 40.57 85.95 40.57 85.37C40.57 84.79 41.06 84.29 41.64 84.29L44.79 84.29C45.37 84.29 45.87 84.79 45.87 85.37C45.87 85.99 45.41 86.45 44.79 86.45Z"
          ></path>
        </g>
        <g id="Group 550">
          <path
            id="Path 453"
            className="shp3"
            fill="#21365f"
            d="M23.46 14.92L17.74 14.92C17.16 14.92 16.67 14.42 16.67 13.84C16.67 13.26 17.16 12.76 17.74 12.76L23.46 12.76C24.04 12.76 24.54 13.26 24.54 13.84C24.54 14.42 24.08 14.92 23.46 14.92Z"
          ></path>
        </g>
        <g id="Group 551">
          <path
            id="Path 454"
            className="shp3"
            fill="#21365f"
            d="M20.6 17.77C20.02 17.77 19.52 17.28 19.52 16.7L19.52 10.98C19.52 10.4 20.02 9.9 20.6 9.9C21.18 9.9 21.68 10.4 21.68 10.98L21.68 16.7C21.68 17.28 21.22 17.77 20.6 17.77Z"
          ></path>
        </g>
        <g id="Group 552">
          <path
            id="Path 455"
            className="shp3"
            fill="#21365f"
            d="M72.13 100.78L66.41 100.78C65.83 100.78 65.34 100.28 65.34 99.7C65.34 99.12 65.83 98.62 66.41 98.62L72.13 98.62C72.71 98.62 73.2 99.12 73.2 99.7C73.2 100.28 72.71 100.78 72.13 100.78Z"
          ></path>
        </g>
        <g id="Group 553">
          <path
            id="Path 456"
            className="shp3"
            fill="#21365f"
            d="M69.27 103.64C68.69 103.64 68.19 103.14 68.19 102.56L68.19 96.84C68.19 96.26 68.69 95.77 69.27 95.77C69.85 95.77 70.35 96.26 70.35 96.84L70.35 102.56C70.35 103.14 69.85 103.64 69.27 103.64Z"
          ></path>
        </g>
        <g id="Group 554">
          <path
            id="Path 457"
            className="shp3"
            fill="#21365f"
            d="M86.46 93.61L80.74 93.61C80.16 93.61 79.67 93.12 79.67 92.54C79.67 91.96 80.16 91.46 80.74 91.46L86.46 91.46C87.04 91.46 87.54 91.96 87.54 92.54C87.49 93.16 87.04 93.61 86.46 93.61Z"
          ></path>
        </g>
        <g id="Group 555">
          <path
            id="Path 458"
            className="shp3"
            fill="#21365f"
            d="M83.56 96.47C82.98 96.47 82.48 95.97 82.48 95.39L82.48 89.68C82.48 89.1 82.98 88.6 83.56 88.6C84.14 88.6 84.64 89.1 84.64 89.68L84.64 95.39C84.64 96.01 84.18 96.47 83.56 96.47Z"
          ></path>
        </g>
        <g id="Group 556">
          <path
            id="Path 459"
            className="shp3"
            fill="#21365f"
            d="M107.95 82.18L102.24 82.18C101.66 82.18 101.16 81.68 101.16 81.1C101.16 80.52 101.66 80.03 102.24 80.03L107.95 80.03C108.53 80.03 109.03 80.52 109.03 81.1C109.03 81.68 108.53 82.18 107.95 82.18Z"
          ></path>
        </g>
        <g id="Group 557">
          <path
            id="Path 460"
            className="shp3"
            fill="#21365f"
            d="M105.06 85.04C104.48 85.04 103.98 84.54 103.98 83.96L103.98 78.25C103.98 77.67 104.48 77.17 105.06 77.17C105.64 77.17 106.13 77.67 106.13 78.25L106.13 83.96C106.13 84.54 105.68 85.04 105.06 85.04Z"
          ></path>
        </g>
        <g id="Group 558">
          <path
            id="Path 461"
            className="shp1"
            fill="#ffffff"
            d="M63.55 35.29L100.75 25.27L100.75 65.36L63.55 55.34L63.55 35.29Z"
          ></path>
        </g>
        <g id="Group 559">
          <path
            id="Path 462"
            className="shp2"
            fill="#bdc3d8"
            d="M100.75 25.27L100.75 65.36L74.99 58.41L100.75 25.27Z"
          ></path>
        </g>
        <g id="Group 560">
          <path
            id="Path 463"
            className="shp1"
            fill="#ffffff"
            d="M100.75 25.27L109.32 18.15L109.32 72.53L100.75 65.36L100.75 25.27Z"
          ></path>
        </g>
        <g id="Group 561">
          <path
            id="Path 464"
            className="shp2"
            fill="#bdc3d8"
            d="M109.32 18.15L109.32 72.53L103.65 67.81L109.32 18.15Z"
          ></path>
        </g>
        <g id="Group 562">
          <path
            id="Path 465"
            fill-rule="evenodd"
            className="shp3"
            fill="#21365f"
            d="M100.75 66.44C100.66 66.44 100.54 66.44 100.46 66.4L63.26 56.38C62.81 56.25 62.48 55.84 62.48 55.34L62.48 35.29C62.48 34.8 62.81 34.38 63.26 34.26L100.46 24.24C100.79 24.15 101.12 24.24 101.41 24.4C101.66 24.61 101.83 24.9 101.83 25.27L101.83 65.32C101.83 65.66 101.66 65.99 101.41 66.19C101.2 66.36 101 66.44 100.75 66.44ZM64.63 54.51L99.71 63.96L99.71 26.68L64.63 36.12L64.63 54.51Z"
          ></path>
        </g>
        <g id="Group 563">
          <path
            id="Path 466"
            fill-rule="evenodd"
            className="shp3"
            fill="#21365f"
            d="M109.32 73.61C109.07 73.61 108.82 73.52 108.62 73.36L100.04 66.19C99.8 65.99 99.67 65.7 99.67 65.36L99.67 25.27C99.67 24.94 99.8 24.65 100.04 24.44L108.62 17.28C108.95 17.03 109.41 16.95 109.78 17.11C110.15 17.28 110.4 17.65 110.4 18.06L110.4 72.45C110.4 72.86 110.15 73.24 109.78 73.4C109.65 73.57 109.49 73.61 109.32 73.61ZM101.83 64.87L108.24 70.25L108.24 20.42L101.83 25.81L101.83 64.87Z"
          ></path>
        </g>
        <g id="Group 564">
          <path
            id="Path 467"
            className="shp1"
            fill="#ffffff"
            d="M41.89 35.29L63.55 35.29L63.55 55.34L41.89 55.34C41.19 55.34 40.65 54.8 40.65 54.1L40.65 36.54C40.65 35.87 41.19 35.29 41.89 35.29Z"
          ></path>
        </g>
        <g id="Group 565">
          <path
            id="Path 468"
            className="shp2"
            fill="#bdc3d8"
            d="M57.84 43.91L63.55 43.91L63.55 55.34L50.67 55.34L57.84 43.91Z"
          ></path>
        </g>
        <g id="Group 566">
          <path
            id="Path 469"
            className="shp1"
            fill="#ffffff"
            d="M54.94 55.34L63.51 55.34L63.51 72.53L56.59 72.53C55.68 72.53 54.94 71.79 54.94 70.87L54.94 55.34Z"
          ></path>
        </g>
        <g id="Group 567">
          <path
            id="Path 470"
            className="shp2"
            fill="#bdc3d8"
            d="M59.66 61.06L63.55 61.06L63.55 72.49L59.25 72.49L59.25 61.47C59.25 61.26 59.45 61.06 59.66 61.06Z"
          ></path>
        </g>
        <g id="Group 568">
          <path
            id="Path 471"
            fill-rule="evenodd"
            className="shp3"
            fill="#21365f"
            d="M63.55 56.42L44.96 56.42C42.02 56.42 39.57 54.02 39.57 51.03L39.57 39.6C39.57 36.66 41.97 34.22 44.96 34.22L63.55 34.22C64.13 34.22 64.63 34.71 64.63 35.29L64.63 55.34C64.63 55.92 64.13 56.42 63.55 56.42ZM44.96 36.37C43.18 36.37 41.73 37.82 41.73 39.6L41.73 51.03C41.73 52.82 43.18 54.27 44.96 54.27L62.48 54.27L62.48 36.37L44.96 36.37Z"
          ></path>
        </g>
        <g id="Group 569">
          <path
            id="Path 472"
            fill-rule="evenodd"
            className="shp3"
            fill="#21365f"
            d="M63.55 73.61L60.7 73.61C56.97 73.61 53.9 70.54 53.9 66.81L53.9 55.38C53.9 54.8 54.4 54.31 54.98 54.31L63.55 54.31C64.13 54.31 64.63 54.8 64.63 55.38L64.63 72.57C64.63 73.11 64.13 73.61 63.55 73.61ZM56.02 66.77C56.02 69.34 58.09 71.41 60.65 71.41L62.44 71.41L62.44 56.38L56.02 56.38L56.02 66.77Z"
          ></path>
        </g>
        <g id="Group 570">
          <path
            id="Path 473"
            className="shp3"
            fill="#21365f"
            d="M79.33 22.08C78.75 22.08 78.26 21.59 78.26 21.01L78.26 15.29C78.26 14.71 78.75 14.21 79.33 14.21C79.91 14.21 80.41 14.71 80.41 15.29L80.41 21.01C80.41 21.59 79.91 22.08 79.33 22.08Z"
          ></path>
        </g>
        <g id="Group 571">
          <path
            id="Path 474"
            className="shp3"
            fill="#21365f"
            d="M82.19 19.22L76.48 19.22C75.9 19.22 75.4 18.73 75.4 18.15C75.4 17.57 75.9 17.07 76.48 17.07L82.19 17.07C82.77 17.07 83.27 17.57 83.27 18.15C83.27 18.73 82.77 19.22 82.19 19.22Z"
          ></path>
        </g>
        <g id="Group 572">
          <path
            id="Ellipse 116"
            className="shp3"
            fill="#21365f"
            d="M70.76 19.22C70.17 19.22 69.68 18.74 69.68 18.15C69.68 17.55 70.17 17.07 70.76 17.07C71.36 17.07 71.84 17.55 71.84 18.15C71.84 18.74 71.36 19.22 70.76 19.22Z"
          ></path>
        </g>
        <g id="Group 573">
          <path
            id="Ellipse 117"
            className="shp3"
            fill="#21365f"
            d="M66.45 19.22C65.86 19.22 65.38 18.74 65.38 18.15C65.38 17.55 65.86 17.07 66.45 17.07C67.05 17.07 67.53 17.55 67.53 18.15C67.53 18.74 67.05 19.22 66.45 19.22Z"
          ></path>
        </g>
        <g id="Group 574">
          <path
            id="Ellipse 118"
            className="shp3"
            fill="#21365f"
            d="M59.29 19.22C58.69 19.22 58.21 18.74 58.21 18.15C58.21 17.55 58.69 17.07 59.29 17.07C59.88 17.07 60.36 17.55 60.36 18.15C60.36 18.74 59.88 19.22 59.29 19.22Z"
          ></path>
        </g>
        <g id="Group 575">
          <path
            id="Ellipse 119"
            className="shp3"
            fill="#21365f"
            d="M50.71 19.22C50.12 19.22 49.64 18.74 49.64 18.15C49.64 17.55 50.12 17.07 50.71 17.07C51.31 17.07 51.79 17.55 51.79 18.15C51.79 18.74 51.31 19.22 50.71 19.22Z"
          ></path>
        </g>
        <g id="Group 576">
          <path
            id="Path 475"
            className="shp3"
            fill="#21365f"
            d="M52.08 50.7C51.5 50.7 51 50.21 51 49.63L51 41.05C51 40.47 51.5 39.98 52.08 39.98C52.66 39.98 53.16 40.47 53.16 41.05L53.16 49.63C53.16 50.21 52.7 50.7 52.08 50.7Z"
          ></path>
        </g>
        <g id="Group 577">
          <path
            id="Path 476"
            className="shp3"
            fill="#21365f"
            d="M57.84 50.7C57.26 50.7 56.76 50.21 56.76 49.63L56.76 41.05C56.76 40.47 57.26 39.98 57.84 39.98C58.42 39.98 58.91 40.47 58.91 41.05L58.91 49.63C58.87 50.21 58.42 50.7 57.84 50.7Z"
          ></path>
        </g>
        <g id="Group 578">
          <path
            id="Path 477"
            className="shp3"
            fill="#21365f"
            d="M46.36 50.7C45.79 50.7 45.29 50.21 45.29 49.63L45.29 41.05C45.29 40.47 45.79 39.98 46.36 39.98C46.94 39.98 47.44 40.47 47.44 41.05L47.44 49.63C47.44 50.21 46.94 50.7 46.36 50.7Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default EmptyJobs;