import { Delete, Edit } from "@mui/icons-material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyAPI, AuthAPI } from "../../../api/v2";
import { AdminInputCard } from "../../../components/admin";
import { DashTitle, DataDisplay, ModalVideo, Progress } from "../../../components/custom";
import { SimpleCard, Button, Avatar } from "../../../components/ui";
import { useUserContext } from "../../../context/user";
import { tryParsing } from "../../../utils/misc";
import VerificationCard from "../../../components/custom/verification-card";
import "./style.scss";
import VerificationMobileModal from "../../../components/ui/verification-mobile-modal";
import { useLanguageContext } from "../../../context/language";
import UserImage from '../../../assets/images/nouser5.png';
import NoBGImage from '../../../assets/images/nobg.jpg';

const Profile = () => {
  const [state, setState] = useState(null);
  const [verificationModal, setVerificationModal] = useState(false);

  const { user } = useUserContext();

  const getCandidateData = async () => {
    const company = await CompanyAPI.info();
    setState(company);
  };

  const sendVerification = async () => {
    try{ 
      await AuthAPI.verify(); 
      toast.success(t`Verifikacioni email je uspešno poslat. Proverite vaš email.`);
    } catch(e){
      toast.error(t`Došlo je do greške na serveru.`)
    }
  };

  const sendOtp = async () => {
    if (user.phone) {
      setVerificationModal(true);
    } else {
      toast.error(t`Popunite broj telefona.`)
    }
  };

  useEffect(() => {
    getCandidateData();
  }, []);
  
  const { t } = useLanguageContext();

  const setProfileCompletely = (profile_completely) =>
    setState({ ...state, profile_completely });

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <div className="cv-dashboard-page-header">
          <DashTitle
            title={t("Profil")}
            subtitle={t("Sve informacije o profilu kompanije")}
            actions={[
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Edit />}
                size="large"
                component={Link}
                to="/dashboard/profile/edit"
              >
                {t("IZMIJENI PROFIL")}
              </Button>,
            ]}
          />
        </div>
        <div className="cv-dashboard-profile-verification-wrap">
          {!user.is_email_verified ? (
            <VerificationCard
              title={t("Molimo da verifikujete Vašu email adresu") + ","}
              subtitle={t("kako bi ste mogli konkurisati na oglase") + "."}
              icon="Email"
              onClick={sendVerification}
              duration={60}
              buttonLabel="Verifikuj"
            />
          ) : null}
          {!user.is_phone_verified && user.phone ? (
            <VerificationCard
              title="Molimo da verifikujete Vaš broj telefona,"
              subtitle="kako bi ste mogli konkurisati na oglase."
              icon="Phone"
              onClick={sendOtp}
              duration={60}
              buttonLabel="Verifikuj"
            />
          ) : null}
        </div>
        {state ? (
          <div className="cv-dashboard-companyprofile-main cv-dashboard-order-content">
            <SimpleCard
              className="cv-dashboard-companyprofile-card"
              title={t("PREGLED PROFILA")}
            >
              <div className="cv-dashboard-companyprofile-images">
                <div className="cv-dashboard-companyprofile-images-cover">
                {state.background_image ? (
                      <img src={state.background_image} />
                    ) : <img src={NoBGImage}/>}
                </div>
                <div className="cv-dashboard-companyprofile-images-profile">
                {state.profile_image ? (
                       <Avatar src={state.profile_image} name={state.full_name} />
                    ) : <img src={UserImage}/>}
                 
                </div>
              </div>
              <div className="cv-dashboard-companyprofile-content">
                <div className="cv-dashboard-companyprofile-content-side">
                  <h3 className="cv-dashboard-companyprofile-title cv-mb-20">
                    {state.full_name}
                  </h3>
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Delatnost kompanije")}
                    value={state.company_activity}
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Opis kompanije")}
                    value={
                      <div
                        className="cv-dashboard-companyprofile-font cv-injected-text"
                        dangerouslySetInnerHTML={{
                          __html: tryParsing(state.company_description),
                        }}
                      ></div>
                    }
                  />
                  <SimpleCard className="cv-dashboard-companyprofile-video">
                    <ModalVideo
                      title={t("VIDEO KOMPANIJE")}
                      src={state.company_video}
                    />
                  </SimpleCard>
                </div>
                <div className="cv-dashboard-companyprofile-content-side">
                  {state.company_users.map((x, y) => (
                    <AdminInputCard
                      label="Informacije o kontakt osobi"
                      className="cv-mb-20"
                    >
                      <DataDisplay
                        className="cv-mb-20"
                        label={t("Kontakt osoba")}
                        value={x.contact_person}
                      />
                      <DataDisplay
                        className="cv-mb-20"
                        label={t("Pozicija kontakt osobe")}
                        value={x.contact_person_position}
                      />
                      <DataDisplay
                        className="cv-mb-20"
                        label={t("Kontakt telefon")}
                        value={x.contact_phone}
                        isVerified={user.is_phone_verified}
                      />
                      <DataDisplay
                        label={t("Korisnički email")}
                        value={state.email}
                        isVerified={user.is_email_verified}
                      />
                    </AdminInputCard>
                  ))}
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Kontakt email")}
                    value={state.contact_email}
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Web adresa")}
                    value={
                      <Link
                        className="cv-dashboard-companyprofile-link"
                        to={state.website}
                      >
                        {state.website}
                      </Link>
                    }
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Facebook profil")}
                    value={
                      <Link
                        className="cv-dashboard-companyprofile-link"
                        to={state.facebook}
                      >
                        {state.facebook}
                      </Link>
                    }
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Instagram profil")}
                    value={
                      <Link
                        className="cv-dashboard-companyprofile-link"
                        to={state.instagram}
                      >
                        {state.instagram}
                      </Link>
                    }
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Država")}
                    value={state.country.name}
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Grad")}
                    value={state.city?.name}
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("Adresa kompanije")}
                    value={state.address}
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("PIB")}
                    value={state.pib}
                  />
                  <DataDisplay
                    className="cv-mb-20"
                    label={t("PDV")}
                    value={state.pdv}
                  />
                  <DataDisplay
                    className="cv-dashboard-profile-col-double"
                    label={t("Popunjenost profila")}
                    value={
                      <Progress
                        value={state.profile_completely}
                        className="cv-dashboard-dashboard-progress"
                        circleClassName="cv-dashboard-dashboard-progress-circle"
                        thickness={4}
                      />
                    }
                  />
                </div>
              </div>
            </SimpleCard>
          </div>
        ) : null}
      </div>
      <VerificationMobileModal
        open={verificationModal}
        onClose={() => setVerificationModal(false)}
        phone={user.phone}
        setProfileCompletely={setProfileCompletely}
      />
    </Fragment>
  );
};

export default Profile;
