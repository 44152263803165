import {
  BusinessCenter,
  ChevronRight,
  Dashboard,
  ExitToAppRounded,
  Language,
  Menu,
  Person,
  RecordVoiceOver,
} from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { logoSource } from "../../../assets/images";
import { useLanguageContext } from "../../../context/language";
import { useEventContext } from "../../../context/event";
import "./style.scss";
import { useUserContext } from "../../../context/user";
import { IconButton } from "@mui/material";
import MobileNavigation from "../mobilenavigation";

const Navigation = () => {
  const [languageToggle, setLanguageToggle] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const { t, lang, languages, setLanguage } = useLanguageContext();
  const eventContext = useEventContext();

  const changeLanguage = (x) => {
    setLanguage(x);
    localStorage.setItem("lang", x.code);
    setLanguageToggle(false);
  };

  const navItems = [
    { name: t`Naslovna`, to: `/` },
    {
      name: t`Pretraga poslova`,
      to: `/public/jobs`,
      // items: [
      //   { name: "Pretraga poslova", to: "/public/jobs" },
      //   { name: "Arhiva poslova", to: "/public/jobs/archived" },
      //   { name: "Poslovi putem Email-a", to: "/public/jobs/email" },
      // ],
    },
    { name: t`Poslodavci`, to: `/public/companies` },
    { name: t`Ispričajte svoju cv priču`, to: `/public/cv-story` },
    { name: t`Blog`, to: `/public/blog` },
    { name: t`Kontakt`, to: `/public/contact` },
  ];

  const userContext = useUserContext();

  return (
    <Fragment>
      <div className={`cv-navbar-main`}>
        <div className="cv-navbar-inner cv-container">
          <div className="cv-navbar-top">
            <div className="cv-navbar-top-part">
              <Link
                className="cv-navbar-top-link cv-navbar-top-link-space"
                to="/public/for-companies"
              >
                <BusinessCenter />
                {t`Za poslodavce`}
              </Link>
              {!userContext.user || userContext.user?.role === "company" ? (
                <Link
                  className="cv-navbar-top-link cv-navbar-top-link-space"
                  to="/dashboard/jobs/new"
                >
                  <RecordVoiceOver />
                  {t`Objavite oglas`}
                </Link>
              ) : null}
            </div>
            <div className="cv-navbar-top-part">
              <div className="cv-navbar-top-login">
                {!userContext.user ? (
                  <Fragment>
                    <Person /> {t`Prijavite se`}:
                    <NavLink
                      exact
                      className="cv-navbar-top-link"
                      activeClassName="cv-navbar-top-link-active"
                      to="/public/login/employee"
                    >
                      {t`Kandidat`}
                    </NavLink>
                    /
                    <NavLink
                      exact
                      className="cv-navbar-top-link"
                      activeClassName="cv-navbar-top-link-active"
                      to="/public/login/company"
                    >
                      {t`Poslodavac`}
                    </NavLink>
                  </Fragment>
                ) : (
                  <Fragment>
                    <NavLink
                      exact
                      className="cv-navbar-top-link"
                      activeClassName="cv-navbar-top-link-active"
                      to="/dashboard"
                    >
                      <Dashboard /> {t`Dashboard`}
                    </NavLink>
                    <span
                      exact
                      className="cv-navbar-top-link"
                      activeClassName="cv-navbar-top-link-active"
                      onClick={userContext.logout}
                    >
                      <ExitToAppRounded /> {t`Odjavi se`}
                    </span>
                  </Fragment>
                )}
              </div>
              <div
                className={`cv-navbar-language ${
                  languageToggle ? "cv-navbar-language-toggle" : ""
                }`}
              >
                <div
                  className="cv-navbar-language-toggle-button"
                  onClick={() => setLanguageToggle(!languageToggle)}
                >
                  <Language />
                  <div className="cv-navbar-language-code">{lang.code}</div>
                  <ChevronRight />
                </div>
                <div className="cv-navbar-language-all">
                  {languages
                    .filter((x) => x !== lang)
                    .map((x, y) => (
                      <div
                        className="cv-navbar-language-single"
                        key={y}
                        onClick={() => changeLanguage(x)}
                      >
                        {x.code}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="cv-navbar-bottom">
            <Link className="cv-navbar-bottom-logo" to="/">
              <img src={logoSource} alt="CVPRIČA" />
            </Link>
            <IconButton
              size="large"
              className="cv-navbar-bottom-hamburger"
              onClick={() => setMobileOpen(!mobileOpen)}
            >
              <Menu />
            </IconButton>
            <div className="cv-navbar-bottom-links">
              {navItems.map(({ to, name, items }, y) => (
                <div
                  key={y}
                  className={`cv-navbar-bottom-link-wrapper ${
                    items ? "cv-navbar-bottom-link-wrapper-with-items" : ""
                  }`}
                >
                  <NavLink
                    to={to}
                    exact
                    className="cv-navbar-bottom-link"
                    activeClassName="cv-navbar-bottom-link-active"
                  >
                    <span>{name}</span>
                  </NavLink>
                  {items ? (
                    <div className="cv-navbar-bottom-link-dropdown">
                      {items.map((a, b) => (
                        <Link
                          className="cv-navbar-bottom-link-dropdown-link"
                          to={a.to}
                          key={b}
                        >
                          {a.name}
                        </Link>
                      ))}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <MobileNavigation open={mobileOpen} setOpen={setMobileOpen} />
    </Fragment>
  );
};

export default Navigation;
