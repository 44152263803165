import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button } from "../../ui";
import "./style.scss";
import { useLanguageContext } from "../../../context/language";

const BlogCard = ({
  image,
  type,
  created_at,
  title,
  description,
  to,
  direction,
  fixedRatio,
  className,
  ...props
}) => {

  const { t } = useLanguageContext();

  return (

    <Link
      className={`cv-blogcard-main cv-blogcard-${direction} ${className}`}
      {...props}
      to={to}
    >
      <div
        className={`cv-blogcard-image ${
          fixedRatio !== undefined ? "cv-blogcard-fixedratio" : ""
        }`}
      >
        <img src={image} alt="Blog post" />
        {type === 1 ? (
          <Button
            className="cv-blogcard-type"
            color="primary"
            variant="contained"
            size="small"
          >
            {t`SAVJETI`}
          </Button>
        ) : null}
        {type === 2 ? (
          <Button
            className="cv-blogcard-type"
            color="secondary"
            variant="contained"
            size="small"
          >
            {t`VIJESTI`}
          </Button>
        ) : null}
      </div>
      <div className="cv-blogcard-text">
        <div className="cv-blogcard-created">
          {moment(created_at).format("DD.MM.YYYY")}
        </div>
        <h3 className="cv-blogcard-title">{title}</h3>
        <div className="cv-blogcard-description">{description}</div>

        <p className="cv-blogcard-link">DETALJNIJE</p>
      </div>
    </Link>
  );
};

export default BlogCard;
