import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const defaultValues = {
  width: window.innerWidth,
  scrollTop: window.scrollY,
  clickedElement: null,
  fullscreen: false
};

const EventContext = createContext(defaultValues);

// USING CONTEXT EXPORT
export const useEventContext = () => useContext(EventContext);

// CONTEXT PROVIDER
export const EventContextProvider = ({ children }) => {
  const [state, setState] = useState({
    width: window.innerWidth,
    scrollTop: window.scrollY,
    clickedElement: null,
  });

  // UPDATE REFERENCE
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    //TRACK WIDTH
    const trackWidth = () =>
      setState({ ...stateRef.current, width: window.innerWidth });

    //TRACK SCROLL
    const trackScroll = () =>
      setState({ ...stateRef.current, scrollTop: window.scrollY });

    //TRACK CLICKED ELEMENT
    const trackClickedElement = (e) =>
      setState({ ...stateRef.current, clickedElement: e.target });

    const trackFullscreen = (e) => {
      setState({ ...stateRef.current, fullscreen: document.fullscreenElement ? true : false });
    }

    // ADD LISTENERS
    window.addEventListener("resize", trackWidth);
    window.addEventListener("scroll", trackScroll);
    window.addEventListener("click", trackClickedElement);
    document.addEventListener("fullscreenchange", trackFullscreen)
    return () => {
      // REMOVE LISTENERS
      window.removeEventListener("resize", trackWidth);
      window.removeEventListener("scroll", trackScroll);
      window.removeEventListener("click", trackClickedElement);
    document.removeEventListener("fullscreenchange", trackFullscreen);
    };
  }, []);

  return (
    <EventContext.Provider value={{ ...state }}>
      {children}
    </EventContext.Provider>
  );
};
