import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const padding = (theme, size) => {
  const sizes = {
    small: theme.spacing(1, 2),
    medium: theme.spacing(2, 4),
    large: theme.spacing(3, 6),
  };
  return sizes[size];
};

export const InputWrapper = styled(TextField)(
  ({ theme, color = "primary", size = "medium" }) => ({
    ...theme.typography.text,
    fontFamily: "'Rubik', sans-serif",
    minWidth: 140,
    textTransform: "none",
    "& .MuiInputBase-root": {
      backgroundColor: "#fff",
      borderRadius: 10,
      borderWidth: 2,
      padding: 0,
      "& input": {
        padding: `${padding(theme, size)} !important`,
        minHeight: 45,
        boxSizing: "border-box",
      },
    },
  })
);
