import React from "react";
import { CardTitleWrapper } from "./styles";

const CardTitle = ({
  align,
  title,
  preTitle,
  postTitle,
  baseColor,
  ...props
}) => {
  return (
    <CardTitleWrapper align={align} baseColor={baseColor} {...props}>
      {preTitle ? <p>{preTitle}</p> : null}
      {title ? <h2>{title}</h2> : null}
      {postTitle ? <p>{postTitle}</p> : null}
    </CardTitleWrapper>
  );
};

export default CardTitle;
