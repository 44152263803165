import React, { Fragment, useEffect, useState } from "react";
import { DashTitle, Modal } from "../../../components/custom";
import { SimpleCard, Button } from "../../../components/ui";
import {
  AdminDatepicker,
  AdminInput,
  AdminInputCard,
  AdminSelect,
} from "../../../components/admin";
import "./style.scss";
import { useUserContext } from "../../../context/user";
import RichText from "../../../components/admin/richtext";
import { IconButton, InputAdornment } from "@mui/material";
import { AddCircle, Delete, Save } from "@mui/icons-material";
import { useHistory, useParams } from "react-router";
import { CompanyAPI, JobAPI } from "../../../api/v2";
import { toast } from "react-toastify";
import VerificationWarningModal from "../../../components/ui/verification-warning-modal";
import { useLanguageContext } from "../../../context/language";
import moment from "moment";

const NewJob = () => {
  const [state, setState] = useState({
    title: "",
    country_id: "",
    city_id: "",
    type_of_work_id: "",
    description: "",
    start_date: null,
    end_date: null,
    ads_questions: [],
    short_description: "",
    job_type_id: "",
    work_time_id: "",
    education_level_id: "",
  });

  const { id } = useParams();

  const {
    countries: allCountries,
    cities: allCities,
    addings: { type_of_works, job_types, education_levels, work_times },
    user,
  } = useUserContext();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [typeOfWorks, setTypeOfWorks] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [workTimes, setWorkTimes] = useState([]);
  const [richText, setRichText] = useState('');
  const [emailVerifiedModal, setEmailVerifiedModal] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const loadJobById = async () => {
    const {
      title,
      country_id,
      city_id,
      type_of_work_id,
      description,
      start_date,
      end_date,
      questions,
      short_description,
      job_type_id,
      work_time_id,
      education_level_id,
    } = await JobAPI.job(id);
    setState({
      ...state,
      title,
      country_id,
      city_id,
      type_of_work_id,
      description,
      start_date,
      end_date,
      ads_questions: questions.map((x) => x.text_question),
      short_description,
      job_type_id,
      work_time_id,
      education_level_id,
    });
    setRichText(description)
  };

  useEffect(() => {
    setTypeOfWorks(type_of_works.map((x) => ({ value: x.id, label: x.name })));
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
    setJobTypes(job_types.map((x) => ({ value: x.id, label: x.name })));
    setEducationLevels(
      education_levels.map((x) => ({ value: x.id, label: x.name }))
    );
    setWorkTimes(work_times.map((x) => ({ value: x.id, label: x.name })));

    if (id) {
      loadJobById(id);
    }

    
  }, []);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(state.country_id))
      .map((x) => ({ value: x.id, label: x.name }));
    setCities(filteredCities);
  }, [state.country_id]);

  const addQuestion = () => {
    setState({ ...state, ads_questions: [...state.ads_questions, ""] });
  };

  const removeQuestion = (id) => {
    setState({
      ...state,
      ads_questions: state.ads_questions.filter((x, y) => y !== id),
    });
  };

  const saveQuestion = async (question) => {
    const res = await CompanyAPI.createCompanyQuestion(question);

  };

  const history = useHistory();
  const { t } = useLanguageContext();
  const saveJob = async () => {
    try {
      const { ads_questions, ...values } = state;
      await CompanyAPI.createCompanyAd({
        is_active: 0,
        ...values,
        ads_questions: JSON.stringify(ads_questions),
      });
      history.push("/dashboard/jobs/preparation");
      toast.success(t`Oglas je sačuvan`);
    } catch {
      toast.error(t`Popunite sva obavezna polja`);
    }
  };

  const publishJob = async () => {
    if (!user.is_email_verified || !user.is_phone_verified) {
      setEmailVerifiedModal(true);
      return;
    }
    try {
      const { ads_questions, ...values } = state;
      await CompanyAPI.createCompanyAd({
        is_active: 1,
        ...values,
        ads_questions: JSON.stringify(ads_questions),
      });
      history.push("/dashboard/jobs/posted");
      toast.success(t`Oglas je objavljen`);
    } catch {
      toast.error(t`Popunite sva obavezna polja`);
    }
  };


  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={id ? t("Izmenite oglas") : t("Dodaj novi oglas")}
          subtitle={t("Popunite informacije o oglasu")}
        />
        <SimpleCard
          className="cv-dashboard-jobs-new-content"
          title={t("Informacije oglasa")}
          withContent
        >
          <div className="cv-dashboard-jobs-new-content-inner">
            <div className="cv-dashboard-jobs-new-side">
              <AdminInput
                className="cv-mb-20"
                color="secondary"
                value={state.title}
                onChange={(title) => setState({ ...state, title })}
                label={t("Naslov oglasa")}
                fullWidth
                required
              />
              <AdminInput
                className="cv-mb-20"
                color="secondary"
                value={state.short_description}
                onChange={(short_description) =>
                  setState({ ...state, short_description })
                }
                label={t("Kratak opis")}
                fullWidth
              />
              <AdminSelect
                className="cv-mb-20"
                color="secondary"
                value={state.country_id}
                onChange={(country_id) => setState({ ...state, country_id })}
                label={t("Država u kojoj zapošljavate")}
                fullWidth
                options={countries}
                required
              />
              <AdminSelect
                className="cv-mb-20"
                color="secondary"
                value={state.city_id}
                onChange={(city_id) => setState({ ...state, city_id })}
                label={t("Grad u kom zapošljavate")}
                fullWidth
                options={cities}
                required
              />
              <AdminSelect
                className="cv-mb-20"
                color="secondary"
                value={state.type_of_work_id}
                onChange={(type_of_work_id) =>
                  setState({ ...state, type_of_work_id })
                }
                label={t("Kategorija posla")}
                fullWidth
                options={typeOfWorks}
                required
              />
              <AdminSelect
                className="cv-mb-20"
                color="secondary"
                value={state.job_type_id}
                onChange={(job_type_id) => setState({ ...state, job_type_id })}
                label={t("Tip posla")}
                fullWidth
                options={jobTypes}
                required
              />
              <AdminSelect
                className="cv-mb-20"
                color="secondary"
                value={state.education_level_id}
                onChange={(education_level_id) =>
                  setState({ ...state, education_level_id })
                }
                label={t("Stepen stručne spreme")}
                fullWidth
                options={educationLevels}
                required
              />
              <AdminSelect
                className="cv-mb-20"
                color="secondary"
                value={state.work_time_id}
                onChange={(work_time_id) =>
                  setState({ ...state, work_time_id })
                }
                label={t("Radno vreme")}
                fullWidth
                options={workTimes}
                required
              />
              <AdminDatepicker
                className="cv-mb-20"
                color="secondary"
                value={state.start_date}
                onChange={(start_date) => setState({ ...state, start_date })}
                label={t("Početak trajanja oglasa")}
                fullWidth
                required
                minDate={moment(new Date())}
              />
              <AdminDatepicker
                className="cv-mb-20"
                color="secondary"
                value={state.end_date}
                onChange={(end_date) => setState({ ...state, end_date })}
                label={t("Kraj trajanja oglasa")}
                fullWidth
                minDate={state.start_date}
                required
              />
            </div>
            <div className="cv-dashboard-jobs-new-side">
              <AdminInputCard label={"Opis oglasa" + "*"} className="cv-mb-20">
                <RichText
                  value={richText}
                  setValue={(description) =>
                    setState({ ...state, description })
                  }
                />
              </AdminInputCard>
              <AdminInputCard
                label={t("Postavite pitanja kandidatima")}
                className="cv-mb-20"
              >
                {!state.ads_questions.length ? (
                  <SimpleCard
                    className="cv-dashboard-jobs-questions-empty"
                    withContent
                  >
                    <div className="cv-dashboard-jobs-questions-empty-inner">
                      <h3>{"Nema pitanja"}</h3>
                      <p>{"Dodajte pitanja klikom na dugme"}</p>
                    </div>
                  </SimpleCard>
                ) : (
                  <Fragment>
                    {state.ads_questions.map((x, y) => (
                      <AdminInput
                        className="cv-mb-20"
                        color="secondary"
                        value={state.ads_questions[y]}
                        onChange={(value) =>
                          setState({
                            ...state,
                            ads_questions: state.ads_questions.map((a, b) =>
                              y === b ? value : a
                            ),
                          })
                        }
                        label={`${y + 1}# Pitanje`}
                        fullWidth
                        endAdornment={
                          <InputAdornment>
                            <IconButton onClick={() => saveQuestion(x)}>
                              <Save />
                            </IconButton>
                            <IconButton onClick={() => removeQuestion(y)}>
                              <Delete />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    ))}
                  </Fragment>
                )}
                <div className="cv-dashboard-job-new-legend">
                  <div className="cv-dashboard-job-new-legend-single">
                    <AddCircle /> {"DODAJTE PITANJE"}
                  </div>
                  <div className="cv-dashboard-job-new-legend-single">
                    <Delete /> {"UKLONITE PITANJE"}
                  </div>
                  <div className="cv-dashboard-job-new-legend-single">
                    <Save /> {"SAČUVAJ PITANJE KAO ŠABLON"}
                  </div>
                </div>
                <Button
                  color="secondary"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={addQuestion}
                  startIcon={<AddCircle />}
                >
                  {"DODAJ PITANJA"}
                </Button>
              </AdminInputCard>
            </div>
          </div>
          <div className="cv-dashboard-job-new-actions">
            <div className="cv-dashboard-job-new-actions-left">
              <Button
                className="cv-dashboard-job-new-action"
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={publishJob}
                disabled={disableButtons}
              >
                {"POSTAVI OGLAS"}
              </Button>
              <Button
                className="cv-dashboard-job-new-action"
                color="secondary"
                variant="contained"
                size="large"
                fullWidth
                onClick={saveJob}
                disabled={disableButtons}
              >
                {"SAČUVAJ OGLAS"}
              </Button>
            </div>
            <div className="cv-dashboard-job-new-actions-right">
              <Button
                className="cv-dashboard-job-new-action"
                color="default"
                variant="contained"
                size="large"
                fullWidth
                onClick={() => setCancelModalOpen(true)}
              >
                {"OTKAŽI"}
              </Button>
            </div>
          </div>
        </SimpleCard>
      </div>
      <CancelModal
        open={cancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
      />
      <VerificationWarningModal
        open={emailVerifiedModal}
        onClose={() => setEmailVerifiedModal(false)}
        title={t("Postavljanje oglasa nije moguće")}
        subtitle={t("bez verifikacije e-mail adrese i broja telefona.")}
        to="/dashboard/profile"
      />
    </Fragment>
  );
};

const CancelModal = ({ open, onClose }) => {
  const history = useHistory();

  const cancelCreation = () => {
    history.goBack();
    onClose();
  };

  const { t } = useLanguageContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("OTKAŽI")}
      actions={[
        <Button
          className="cv-dashboard-job-new-action"
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          onClick={cancelCreation}
        >
          {t("DA")}
        </Button>,
        <Button
          className="cv-dashboard-job-new-action"
          color="default"
          variant="contained"
          size="large"
          fullWidth
          onClick={onClose}
        >
          {t("NE")}
        </Button>,
      ]}
      size="medium"
    >
      <div className="cv-modal-content-center">
        {t("Da li želite da prekinete dodavanje novog oglasa?")}
      </div>
    </Modal>
  );
};

export default NewJob;
