import React, { Fragment } from "react";
import { CardTitle, Advice, Card } from "../../components/custom";
import { Button } from "../../components/ui";
import Hero from "../../components/custom/hero";
import { useLanguageContext } from "../../context/language";
import { Link } from "react-router-dom";
import "./style.scss";
import {
  Advice1,
  Advice2,
  Advice3,
  Advice4,
  Advice5,
  Advice6,
} from "../../assets/svg";

const CvStory = () => {
  const { t } = useLanguageContext();

  const advices = [
    {
      title: t`Pripremite svoju priču`,
      description: t`Napravite koncept, izdvojite šta iz svog CV-ja želite da istaknete i kojim redosljedom da prezentujete.`,
      long_description: t`Prije snimanja vaše video CV priče, dobro pripremite šta ćete i na koji način u njoj ispričati. Napravite koncept, izdvojite šta iz svog CV-ja želite da istaknete i kojim redosljedom da prezentujete. Na primjer, ukoliko nemate bogato radno iskustvo, akcenat stavite na obrazovanje ili osobine koje posjedujete, a koje vas mogu istaći pri izboru za traženu poziciju. Neke od najcjenjenijih osobina pri zapošljavanju su komunikativnost, samopouzdanje, spremnost za timski rad i inovacije, profesionalnost, radoznalost, samostalnost itd.`,
      icon: <Advice1 />,
      // icon: "/assets/images/advice-icons/pripremite-svoju-pricu.png",
    },
    {
      title: t`Uvježbajte govor prije snimanja`,
      description: t`Prije snimanja, provježbajte svoj govor naglas i ispred ogledala. Na taj način ćete steći potrebno samopouzdanje.`,
      long_description: t`Kako biste sebe izdvojili kao ozbiljnog kandidata za posao, poželjno je da u snimku vodite računa o načinu govora, dikciji i tonalitetu. Zato, prije snimanja, provježbajte svoj govor naglas i ispred ogledala. Na taj način ćete steći potrebnu sigurnost, samopouzdanje i ubjedljivost. Zamislite da je poslodavac ispred vas i da imate jedinstvenu priliku da ga oduševite. Imajte na umu da ga, u moru drugih kandidata, najmanja greška u vašem govoru i tonu, može odbiti. Ukoliko imate govornu manu, sigurnim i šarmantnim govorom možete je staviti u drugi plan.`,
      icon: <Advice2 />,
      // icon: "/assets/images/advice-icons/uvjezbajte-govor-prije-snimanja.png",
    },
    {
      title: t`Obratite pažnju na mimiku i proksemiku`,
      description: t`Prilikom snimanja, sjedite pristojno, mirno, bez pretjeranog gestikuliranja. Potrebno je da budete ono što jeste, u svojoj najboljoj verziji.`,
      long_description: t`Pored verbalne, obratite pažnju i na neverbalnu komunikaciju. Prednost CV priče je što imate priliku da predstavite sebe prije prvog susreta sa poslodavcem koji kod vas može izazvati tremu i anksioznost. Prilikom snimanja video snimka, sjedite pristojno, bez pucketanja prstiju, prenaglašenog mahanja i prečestog mijenjanja položaja ruku i nogu. Osmijeh nije zabranjen, ali ni tu ne treba pretjerivati. Potrebno je da budete ono što jeste, u svojoj najboljoj verziji.`,
      icon: <Advice3 />,
      // icon: "/assets/images/advice-icons/obratite-paznju-na-mimiku-i-proksemiku.png",
    },
    {
      title: t`Ispratite bonton poslovnog odjevanja`,
      description: t`Poželjno je da izgledate profesionalno i pristojno. Birajte neutralne boje i vodite računa da vam odjeća bude čista i ispeglana.`,
      long_description: t`Imate samo jednu šansu da ostavite dobar prvi utisak, te je od izuzetne važnosti kako ćete se obući prilikom snimanja videa. Za koji god posao želite da aplicirate, treba da izgledate profesionalno i pristojno. Ukoliko ste muškarac, poželjno bi bilo da obučete sako i, u zavisnosti od godišnjeg doba, džemper ili košulju. Birajte neutralne boje koje se lako kombinuju: siva, bijela i teget su uvijek dobar izbor. Kada su žene u pitanju, najbolji izbor jesu bluza i poslovne pantalone ili poslovna suknja. I muškarci i žene bi trebalo da izbjegavaju jarke boje, koje odvlače pažnju i djeluju napadno. Oblačenje treba da istakne vas i vašu ličnost, a ne da svu pažnju poslodavca usmjeri na odijelo. Ukoliko vaša profesija ne zahtijeva formalno oblačenje, možete se odlučiti za „biznis kežual“ stil koji je uvijek najbolji i najsigurniji izbor. Podrazumijeva se da vam je odjeća čista i ispeglana. Takođe, pobrinite se i da vaša kosa, šminka i nokti izgledaju uredno i pristojno.`,
      icon: <Advice4 />,
      // icon: "/assets/images/advice-icons/ispratite-bonton-poslovnog-odjevanja.png",
    },
    {
      title: t`Odaberite adekvatnu lokaciju`,
      description: t`Najbolje je da video priču snimate u dobro osvijetljenom zatvorenom prostoru, bez previše detalja i zvukova koji bi odvlačili pažnju.`,
      long_description: t`Najpogodnija lokacija za snimanje CV priče je zatvoren prostor, kako vas spoljašnji zvukovi, sa ulice ili iz prirode, ne bi ometali. Ipak, bilo da je to vaš dom ili neka druga lokacija, vodite računa da prostor u kom snimate bude dovoljno osvijetljen. Takođe, pazite da u pozadini nema uređaja ili detalja koji će odvlačiti pažnju. Najbolje bi bilo da video snimate za nekim stolom, a da iza vas bude prazan zid. Pobrinite se da prostor u kom snimate bude čist i uredan.`,
      icon: <Advice5 />,
      // icon: "/assets/images/advice-icons/odaberite-adekvatnu-lokaciju.png",
    },
    {
      title: t`Snimite svoju CV priču`,
      description: t`Zahvaljujući širokoj upotrebi naprednih tehnologija, video možete snimiti i sa mobilnog telefona pri čemu je potrebno voditi računa o kvalitetu slike i zvuka.`,
      long_description: t`Za snimanje CV priče birajte kvalitetne uređaje, visoke rezolucije. Ukoliko nemate kameru, dovoljan vam je noviji smartphone s mogućnošću snimanja videa i sa automatskim postavkama. Većina uređaja koji su danas u upotrebi omogućava vam da se snimate veoma lako, iz ruke, poput snimanja „selfija“. Ukoliko vam je lakše, pozicionirajte uređaj na odgovarajuću podlogu, a možete se poslužiti i stativom prilagođenim za držanje kamere ili mobilnog telefona. Ukoliko se vaš glas na snimku ne čuje dovoljno dobro, na tržištu se može naći pregršt pristupačnih mikrofona za pametne telefone ili kamere.`,
      icon: <Advice6 />,
      // icon: "/assets/images/advice-icons/snimite-svoju-cv-pricu.png",
    },
  ];

  return (
    <Fragment>
      <Hero
        img="/assets/images/hero/hero-cvstory.jpg"
        heroStyle={{ objectPosition: "50% 50%" }}
      />
      <div className="cv-container cv-cvstory-main">
        <CardTitle
          align="center"
          title={
            <>
              <span className="secondary">{t("Ispričajte svoju")}</span> {t("CV priču")}
            </>
          }
        />
        <p>
          {t("Često vam se dešava da se prijavljujete na oglase za posao, ali ne dobijate poziv za razgovor? Bez obzira na to koliko je vaš CV bogat, koji je nivo vašeg obrazovanja i koliko je vaše radno iskustvo, sada imate priliku da putem video priče predstavite sebe, svoje znanje i vještine na najbolji način. Zahvaljujući našoj jedinstvenoj platformi,u video snimku možete ispričati zbog čega ste baš vi pravi kandidat za posao za koji aplicirate. Na taj način postajete dostupniji poslodavcima koji putem videa stiču prvi utisak o vama, prije razgovora za posao. Budite korak bliže vašem budućem zaposlenju.")}
        </p>
      </div>
      <Card
        color="secondary"
        className="cv-huge-card-wrapper cv-cvstory-card-advices"
        radius={50}
      >
        <div className="cv-container cv-huge-card-inner cv-advices-wrapper">
          <div className="cv-cvstory-advice-video">
            <video src={t`file/videos/cvstory`} poster={t`cvStoryThumb`} controls></video>
          </div>
          <CardTitle
            baseColor="#fff"
            align="center"
            title={
              <>
                <span className="primary">{t("Savjeti")}</span> {t("za snimanje tvoje priče")}
              </>
            }
          />
          <div className="cv-advices-row">
            {advices.map((x, y) => (
              <Advice
                key={y}
                icon={x.icon}
                number={y + 1}
                title={x.title}
                description={x.description}
                long_description={x.long_description}
              />
            ))}
          </div>
        </div>
      </Card>
      <Card
        image="/assets/images/cvstory/bg.jpg"
        className="cv-cvstory-card-benefits"
        radius={0}
      >
        <div className="cv-container cv-cvstory-card-benefits-inner">
          <div className="cv-cvstory-benefits-text">
            <CardTitle
              align="center"
              title={
                <>
                  <span className="secondary">{t("Benefiti")}</span> {t("platforme")}
                </>
              }
            />
            <p className="cv-cvstory-card-benefits-description">
              {t("CV priča je jedinstvena platforma koja pruža brojne prednosti u odnosu na sajtove za zapošljavanje na koje ste navikli. Kao prvo, možete snimiti CV u video formatu i konkurisati na oglase za posao. Pored toga, znaćete da li je poslodavac primio i pogledao vašu CV priču čime se mogućnost greške pri slanju prijave svodi na minimum. Na našoj platformi imaćete uvid u dokumenta koja ste poslali poslodavcima, a njih možete kontaktirati putem direktne poruke. Interesantne oglase možete sačuvati, a dobijaćete obavještenja o novim oglasima prema svojim parametrima.")}
            </p>
          </div>
          <div className="cv-cvstory-benefits-button-holder">
            <Button
              color="secondary"
              variant="contained"
              component={Link}
              to="/public/login/employee"
              size="large"
            >
              {t("SNIMITE SVOJU CV PRIČU")}
            </Button>
          </div>
        </div>
      </Card>
    </Fragment>
  );
};

export default CvStory;
