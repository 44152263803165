import { Switch } from "@mui/material";
import { styled } from '@mui/system';


export const SwitchWrapper = styled(Switch)(() => ({
  "& .MuiSwitch-track": {
    background: "linear-gradient(to top, #2f2f2f, #7f7f7f)",
  },
  "& .Mui-checked + .MuiSwitch-track": {
    background: "linear-gradient(to top, #04274f, #2897fb)",
    opacity: 0.8,
  },
  "& .Mui-checked .MuiSwitch-thumb": {
    background: "linear-gradient(to top, #04274f, #2897fb)",
  },
  "& .Mui-checked .MuiTouchRipple-root": {
    color: "#2897fb",
  },
}));
