import React from "react";
import { Link } from "react-router-dom";
import { useLanguageContext } from "../../../context/language";
import { Card } from "../../custom";
import { Button } from "../../ui";
import "./style.scss";

const variations = {
  big_vertical: {
    resolution: "325x712",
    image: "/assets/images/jobs/1.jpg",
  },
  small_vertical: {
    resolution: "325x455",
    image: "/assets/images/jobs/2.jpg",
  },
  big_horizontal: {
    resolution: "1015x140",
    image: "/assets/images/jobs/3.jpg",
  },
};

const AdCard = ({ variant = "big_vertical", to="/public/contact", ...props }) => {
  const { image, resolution } = variations[variant];

const { t } = useLanguageContext();

  return (
    <Link to={to} className="cv-adcard-wrapper" {...props}>
      <Card
        image={image}
        className={`cv-adcard-main cv-adcard-variant-${variant}`}
      >
        <Button
          size="small"
          className="cv-adcard-resolution"
          color="primary"
          variant="contained"
        >
          {resolution}px
        </Button>
        {variant === "big_vertical" ? (
          <div className="cv-adcard-big-vertical-main">
            <div className="cv-adcard-big-vertical-text">
              {t("MJESTO ZA")} <span>{t("VAŠU REKLAMU")}</span>
            </div>
            <div className="cv-adcard-big-vertical-email">
              marketing@cvprica.com
            </div>
          </div>
        ) : null}
        {variant === "small_vertical" ? (
          <div className="cv-adcard-small-vertical-main">
            <div className="cv-adcard-small-vertical-text">
              {t("MJESTO ZA")} <span>{t("VAŠU REKLAMU")}</span>
            </div>
            <div className="cv-adcard-small-vertical-email">
              marketing@cvprica.com
            </div>
          </div>
        ) : null}
        {variant === "big_horizontal" ? (
          <div className="cv-adcard-big-horizontal-main">
            <div className="cv-adcard-big-horizontal-text">
              {t("MJESTO ZA")} <span>{t("VAŠU REKLAMU")}</span>
            </div>
            <div className="cv-adcard-big-horizontal-email">
              marketing@cvprica.com
            </div>
          </div>
        ) : null}
      </Card>
    </Link>
  );
};

export default AdCard;
