import React from 'react';
import { CardWrapper } from './styles';

const Card = ({ color = 'default', children, radius = 20, image, ...props}) => {
 return (
   <CardWrapper color={color} radius={radius} image={image} {...props}>
     {children}
   </CardWrapper>
 );   
}

export default Card;