import { LocationOn, RecordVoiceOver, Traffic } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../ui';
import './style.scss';

const scrollTop = () => {
  window.scrollTo({ top: 0 });
};

const CompanyDisplay = ({
  id,
  adsCount,
  background,
  profileImage,
  city,
  country,
  name,
  className,
  address,
  prefix = 'public',
  slug,
  ...props
}) => {
  return (
    <Link
      to={`/${prefix}/companies/${id}/${slug}`}
      className={`cv-companydisplay-main ${className}`}
      {...props}
      onClick={scrollTop}
    >
      <div className="cv-companydisplay-images">
        <div className="cv-companydisplay-background">
          {background ? <img src={background} alt="Profile cover" /> : null}
        </div>
        <div className="cv-companydisplay-profile">
          <img src={profileImage} alt="Profile" />
        </div>
        {adsCount ? (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className="cv-companydisplay-ads-count"
          >
            <span>Aktivnih oglasa:</span><RecordVoiceOver/> {adsCount}
          </Button>
        ) : null}
      </div>
      <div className="cv-companydisplay-text">
        <div className="cv-companydisplay-title">{name}</div>
        <div className="cv-companydisplay-location">
          <LocationOn /> {city.name}, {country.name}
        </div>
        <div className="cv-companydisplay-location">
          <Traffic /> {address || "/"}
        </div>
      </div>
    </Link>
  );
};

export default CompanyDisplay;