import { AccessTime, ListAlt, LocationOn } from "@mui/icons-material";
import { Divider } from "@mui/material";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

const JobCard = ({
  id,
  company,
  title,
  description,
  city,
  country,
  created_at,
  category,
  slug,
  companySlug,
  to = `/public/jobs/${id}/${companySlug}/${slug}`,
}) => {
  return (
    <Link className="cv-jobcard-main" to={to}>
      <div className="cv-jobcard-info">
        <h3>{title}</h3>
        <h4>{company.full_name}</h4>
        <p>
          {description ? (
            description
          ) : (
            <div className="cv-jobcard-desc-empty">
              <Divider />
            </div>
          )}
        </p>
        <div className="cv-jobcard-other">
          <div className="cv-jobcard-single">
            <LocationOn /> {city.name}, {country.name}
          </div>
          <div className="cv-jobcard-single">
            <AccessTime /> {moment(created_at).format("DD.MM.YYYY")}
          </div>
          <div className="cv-jobcard-single">
            <ListAlt /> {category.name}
          </div>
        </div>
      </div>
      {company?.profile_image ? (
        <div className="cv-jobcard-logo">
          <img src={company?.profile_image} alt="Logo" />
        </div>
      ) : null}
    </Link>
  );
};

export default JobCard;
