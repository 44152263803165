import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { LanguageContextProvider } from "./context/language";
import { UserContextProvider } from "./context/user";
import { BrowserRouter } from "react-router-dom";
import { EventContextProvider } from "./context/event";
import PageRouter from "./router";
import Theme from "./theme";
import { ModalContextProvider } from "./context/modal";
import { LocalizationProvider } from "@mui/lab";
import { ToastContainer } from "react-toastify";
import DateAdapter from "@mui/lab/AdapterMoment";
//import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useEffect(() => {
    const lang = localStorage.getItem('lang');
    if (!lang) {
      localStorage.setItem('lang', 'ME');
    }
  }, []);
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <BrowserRouter>
        <ThemeProvider theme={Theme}>
          <EventContextProvider>
            <UserContextProvider>
              <LanguageContextProvider>
                <ModalContextProvider>
                  <PageRouter />
                  <ToastContainer theme="colored" />
                </ModalContextProvider>
              </LanguageContextProvider>
            </UserContextProvider>
          </EventContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </LocalizationProvider>
  );
};

export default App;
