export const toFormData = (data, name) => {
  const formData = new FormData();
  Object.keys(data).forEach((x) => {
    if (![undefined, null, ""].includes(data[x])) {
      formData.append(x, data[x], name);
    }
  });
  return formData;
};

export const toQueryParams = (data) => {
  const queryParams = Object.keys(data)
    .map((x) =>
      data[x]
        ? `${x}=${
            data[x].constructor.name === "Array" ? `[${data[x]}]` : data[x]
          }`
        : null
    )
    .filter((x) => x !== null)
    .join("&");
  return queryParams;
};

export const blobToFile = (theBlob, fileName) => {
  const myFile = new File([theBlob], fileName, {
    type: theBlob.type,
  });
  return myFile;
};
