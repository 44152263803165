import { styled } from "@mui/system";
import { Pagination } from "@mui/material";

export const PaginationWrapper = styled(Pagination)(({ theme }) => ({
  display: "block",
  width: "auto",
  "& li:not(:last-child)": {
    marginRight: 10,
  },
  "& .MuiButtonBase-root": {
    fontSize: 16,
    padding: 0,
    width: 40,
    height: 40,
    margin: 0,
    borderRadius: 10,
    border: "solid 1px",
    borderColor: "#00000020",
    display: "flex",
    "&.MuiPaginationItem-page": {
      color: "#6b6b6b",
      "&.Mui-selected": {
        fontWeight: 600,
        color: "#2897fb",
      },
    },
    "& .MuiSvgIcon-root": {
      width: 32,
      height: 32,
    },
  },
}));
