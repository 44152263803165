import React from "react";
import { Button } from "../../ui";
import moment from "moment";
import { Link } from "react-router-dom";
import "./style.scss";
import { RecordVoiceOver } from "@mui/icons-material";
import { useLanguageContext } from "../../../context/language";

const CompanyCard = ({
  image,
  name,
  adsCount,
  createdAt,
  latestAd,
  className,
  slug,
  id,
  ...props
}) => {
  const { t } = useLanguageContext();

  return (
    <div className={`cv-companycard-main ${className}`} {...props}>
      <div
        className="cv-companycard-inner"
      >
        <div className="cv-companycard-front">
          <div className="cv-companycard-image">
            <img src={image} alt={name} />
          </div>
          <div className="cv-companycard-text">
            <span>{name}</span>
            {adsCount
              ? `${t(["Ukupan broj oglasa"])}: ${adsCount}`
              : t`Trenutno nema oglasa`}
          </div>
        </div>
        <div className="cv-companycard-back">
          <div className="cv-companycard-text">
            <span>{name}</span>
            {adsCount
              ? `${t(["Ukupan broj oglasa"])}: ${adsCount}`
              : t`Trenutno nema oglasa`}
          </div>
          {latestAd ? (
            <div className="cv-companycard-ad">
              <div className="cv-companycard-ad-info">
                {latestAd.title}
                <span>{moment(createdAt).format("DD.MM.YYYY")}</span>
              </div>
              <Button
                component={Link}
                className="cv-companycard-button"
                size="large"
                color="primary"
                variant="contained"
                to={`/public/jobs/${latestAd.id}/${slug}/${latestAd.slug}`}
              >
                {t`DETALJNIJE`}
              </Button>
            </div>
          ) : (
            <div className="cv-companycard-ad">
              <div className="cv-companycard-ad-info cv-companycard-ad-info-empty">
                <RecordVoiceOver />
                <span>{t`Ova kompanija trenutno nema oglasa`}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
