import React, { useState, Fragment, useEffect } from "react";
import Hero from "../../components/custom/hero";
import { useLanguageContext } from "../../context/language";
import { useParams, Link } from "react-router-dom";
import { useUserContext } from "../../context/user";
import { CardTitle, Modal } from "../../components/custom";
import { Input, Button, Select } from "../../components/ui";
import { Checkbox } from "@mui/material";
import {
  Check,
  CheckCircleOutlined,
  Facebook,
  Google,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import "./style.scss";
import { AdminInput } from "../../components/admin";
import { AuthAPI } from "../../api/v2";
import { toast } from "react-toastify";
import { isEmail, isEmpty } from "validator";
import { gapi, loadAuth2 } from "gapi-script";
import { googleClientId } from "../../constants/project";

const getRememberInfo = () => {
  try {
    return JSON.parse(localStorage.getItem("remember_info"));
  } catch {
    return null;
  }
};

const setRememberInfo = (x) => {
  // localStorage.setItem("remember_info", JSON.stringify(x));
};

const removeRememberInfo = () => {
  // localStorage.removeItem("remember_info");
};

const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [registerState, setRegisterState] = useState({
    full_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    country_id: "",
    language_id: "",
  });
  const [checked, setChecked] = useState(getRememberInfo() ? true : false);
  const [remember, setRemember] = useState(getRememberInfo());
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [message, setMessage] = useState("");
  const [rMessage, setRMessage] = useState("");
  const [tab, setTab] = useState(0);

  const [open, setOpen] = useState(false);

  const { role } = useParams();
  const { t } = useLanguageContext();
  const {
    login,
    countries: allCountries,
    ui_languages: allLanguages,
    setUser,
  } = useUserContext();

  useEffect(() => {
    if (remember) {
      setState({
        ...state,
        ...remember,
      });
    }
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
    setLanguages(allLanguages.map((x) => ({ value: x.id, label: x.name })));
    (async () => {
      gapi.load("auth2", () => {
        gapi.auth2
          .init({
            client_id: googleClientId,
          })
          .then((auth2) => {}
          );
 
      });
    })();
  }, []);

  const loginWithGoogle = async () => {
    gapi.load("auth2", async () => {
      try {
        const googleAuth = gapi.auth2.getAuthInstance();
        const googleRes = await googleAuth.signIn();
        const { id_token: token } = googleRes.getAuthResponse();
        const res = await AuthAPI.googleAuth({ token, role });
        setUser(res);
      } catch (e) {
        toast.error("Korisnik ne postoji");
      }
    });
  };

  const processRegister = async () => {
    try {
      if (
        [
          registerState.full_name,
          registerState.password,
          registerState.password_confirmation,
          registerState.email,
        ].filter((x) => x.trim() === "").length +
        [registerState.country_id, registerState.language_id].filter((x) =>
          ["", 0].includes(x)
        ).length
      ) {
        return setRMessage("Popunite sva polja");
      } else if (!isEmail(registerState.email)) {
        return setRMessage("Nije validan email format");
      } else if (
        registerState.password !== registerState.password_confirmation
      ) {
        return setRMessage("Lozinke se ne poklapaju");
      }
      await AuthAPI.register({ ...registerState, role });
      setTab(0);
      setRegisterState({
        full_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        country_id: "",
        language_id: "",
      });
      toast.success(t`Uspešno ste se registrovali`);
    } catch (e) {
      console.error(e);
      return setRMessage(t`Došlo je do greške`);
    }
  };

  const processLogin = async () => {
    try {
      if ([state.email, state.password].filter((x) => x.trim() === "").length) {
        return setMessage(t`Popunite sva polja`);
      }
      await login(state);
      if (checked) {
        setRememberInfo(state);
      } else {
        removeRememberInfo();
      }
      return setMessage(
        t`Uspešno ste se se ulogovali, uskoro ćete biti redirektovani`
      );
    } catch (e) {
      return setMessage(t`Neispravno korisničko ime ili lozinka`);
    }
  };

  const privilegues = [
    t`Predstavite sebe u video formi`,
    t`Pretražujete poslove po državama, gradovima i kategorijama`,
    t`Sačuvate interesantne oglase`,
    t`Konkurišete na oglas za posao u više država i gradova`,
    t`Znate da li je poslodavac primio i pregledao Vašu prijavu`,
    t`Ostvarite direktnu komunikaciju sa poslodavcem putem poruka platforme`,
    t`Budete obavješteni o novim oglasima i porukama poslodavca`,
    t`Imate uvid u istoriju svojih prijava sa svim detaljima`,
  ];

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-login.jpg" />
      <div className="cv-container cv-login-main">
        <CardTitle
          title={
            <>
              <span className="secondary">Prijavite se</span> kao{" "}
              {role === "company" ? "poslodavac" : "kandidat"}
            </>
          }
        />
        <div className="cv-login-cards">
          <div className="cv-login-card cv-login-card-tab-wrapper">
            <div className="cv-login-card-tabs">
              <div
                className={`cv-login-card-tab ${
                  tab === 0 ? "cv-login-card-tab-active" : ""
                }`}
                onClick={() => setTab(0)}
              >
                {t("Prijava")}
              </div>
              <div
                className={`cv-login-card-tab ${
                  tab === 1 ? "cv-login-card-tab-active" : ""
                }`}
                onClick={() => setTab(1)}
              >
                {t("Registracija")}
              </div>
            </div>
            {tab === 0 ? (
              <Fragment>
                <div className="cv-login-social">
                  <p className="cv-login-form-helper">
                    {t("PRIJAVITE SE PREKO EMAIL ADRESE")}
                  </p>
                  {/*<div className="cv-login-form-social-buttons">
                    <Button
                      color="error"
                      variant="contained"
                      size="large"
                      fullWidth
                      className="cv-login-form-social-button"
                      startIcon={<Google />}
                      onClick={loginWithGoogle}
                    >
                      Sign in with Google
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      fullWidth
                      className="cv-login-form-social-button cv-disabled"
                      startIcon={<Facebook />}
                    >
                      Sign in with Facebook
                    </Button>
                  </div>*/}
                </div>
                <div className="cv-login-form">
                  {/* <p className="cv-login-form-helper">
                    {t("ILI PREKO VAŠE EMAIL ADRESE")}
                  </p> */}
                  <Input
                    fullWidth
                    className="cv-login-input"
                    type="email"
                    value={state.email}
                    placeholder="Email adresa"
                    onChange={(email) => setState({ ...state, email })}
                  />
                  <Input
                    fullWidth
                    className="cv-login-input"
                    type="password"
                    value={state.password}
                    placeholder="Lozinka"
                    onChange={(password) => setState({ ...state, password })}
                  />
                  <Button
                    className="cv-login-button"
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={processLogin}
                  >
                    {t("PRIJAVITE SE")}
                  </Button>
                  <div className="cv-login-message">{message}</div>
                </div>
                <div className="cv-login-actions">
                  <label className="cv-login-remember">
                    <Checkbox
                      onChange={(a, b) => setChecked(b)}
                      color="secondary"
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<RadioButtonChecked />}
                      checked={checked}
                      size="small"
                    />
                    {"Zapamti me"}
                  </label>
                  <div className="cv-login-forgot">
                    {t("Zaboravili ste lozinku") + "?"}{" "}
                    <span onClick={() => setOpen(true)}>
                      {"Zatražite novu" + "."}
                    </span>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <div className="cv-login-social">
                  <p className="cv-login-form-helper">
                    {t("REGISTRUJTE SE PREKO E-MAIL ADRESE")}
                  </p>
                  {/* <div className="cv-login-form-social-buttons">
                    <Button
                      color="error"
                      variant="contained"
                      size="large"
                      fullWidth
                      className="cv-login-form-social-button cv-disabled"
                      startIcon={<Google />}
                    >
                      Sign in with Google
                    </Button>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="large"
                      fullWidth
                      className="cv-login-form-social-button cv-disabled"
                      startIcon={<Facebook />}
                    >
                      Sign in with Facebook
                    </Button>
                  </div> */}
                </div>
                <div className="cv-login-form">
                  {/* <p className="cv-login-form-helper">
                    {t("ILI PREKO VAŠE EMAIL ADRESE")}
                  </p> */}
                  {role !== 'company' ? (
                    <Input
                      fullWidth
                      className="cv-login-input"
                      type="email"
                      value={registerState.full_name}
                      placeholder={t("Ime i prezime")}
                      onChange={(full_name) =>
                        setRegisterState({ ...registerState, full_name })
                      }
                    />
                  ) : (
                    <Input
                      fullWidth
                      className="cv-login-input"
                      type="email"
                      value={registerState.full_name}
                      placeholder={t("Naziv kompanije")}
                      onChange={(full_name) =>
                        setRegisterState({ ...registerState, full_name })
                      }
                    />
                  )}
                  <Input
                    fullWidth
                    className="cv-login-input"
                    type="email"
                    value={registerState.email}
                    placeholder={t("Email adresa")}
                    onChange={(email) =>
                      setRegisterState({ ...registerState, email })
                    }
                  />
                  <Input
                    fullWidth
                    className="cv-login-input"
                    type="password"
                    value={registerState.password}
                    placeholder={t("Lozinka")}
                    onChange={(password) =>
                      setRegisterState({ ...registerState, password })
                    }
                  />
                  <Input
                    fullWidth
                    className="cv-login-input"
                    type="password"
                    value={registerState.password_confirmation}
                    placeholder={t("Ponovite lozinku")}
                    onChange={(password_confirmation) =>
                      setRegisterState({
                        ...registerState,
                        password_confirmation,
                      })
                    }
                  />
                  <Select
                    fullWidth
                    className="cv-login-input"
                    type="password"
                    value={registerState.country_id}
                    placeholder={t("Odaberite državu")}
                    onChange={(country_id) =>
                      setRegisterState({ ...registerState, country_id })
                    }
                    options={countries}
                  />
                  <Select
                    fullWidth
                    className="cv-login-input"
                    type="password"
                    value={registerState.language_id}
                    placeholder={t("Odaberite jezik")}
                    onChange={(language_id) =>
                      setRegisterState({ ...registerState, language_id })
                    }
                    options={languages}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size="large"
                    onClick={processRegister}
                    className="cv-login-button"
                  >
                    {t("REGISTRUJ SE")}
                  </Button>
                  <div className="cv-login-message">{rMessage}</div>
                </div>
              </Fragment>
            )}
          </div>
          <div className="cv-login-card cv-login-card-desc">
            {role === "employee" ? (
              <div className="cv-login-list">
                <div className="cv-login-list-title">
                  {t("KAO REGISTROVAN KORISNIK MOŽETE DA") + ":"}
                </div>
                {privilegues.map((x, y) => (
                  <div key={y} className="cv-login-list-item">
                    <CheckCircleOutlined /> {x}
                  </div>
                ))}
              </div>
            ) : (
              <div className="cv-login-packages">
                <img src="/assets/images/login/1.jpg" alt="Package" />
                <div className="cv-login-packages-content">
                  <CardTitle
                    postTitle={
                      t("Odaberite oglas ili paket po vašoj mjeri") + "."
                    }
                    title={
                      <>
                        <span className="secondary">
                          {t("Cijenovnik oglasa i paketa")}
                        </span>{" "}
                        {t("za poslodavce")}
                      </>
                    }
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/public/for-companies"
                    fullWidth
                  >
                    {t("DETALJNIJE")}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ResetModal open={open} onClose={() => setOpen(false)} />
    </Fragment>
  );
};

const ResetModal = ({ open, onClose }) => {
  const [state, setState] = useState({
    email: "",
  });

  const resetPass = async () => {
    try {
      if (isEmpty(state.email)) {
        return toast.error(t`Popunite polje za email adresu`);
      } else if (!isEmail(state.email)) {
        return toast.error(t`Neispravan email format`);
      }
      await AuthAPI.resetPassword(state);
      toast.success(t`Informacije o izmeni lozinke su vam poslate na email`);
      onClose();
    } catch {
      toast.error(t`Korisnik sa datom email adresom ne postoji u bazi`);
    }
  };
  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("ZABORAVLJENA LOZINKA")}
      actions={
        <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          onClick={resetPass}
        >
          {t("POŠALJITE ZAHTJEV")}
        </Button>
      }
      size="small"
    >
      <div className="cv-profile-modal-text">
        {t(
          "Unesite Vašu email adresu kako bi mogli da Vam pošaljemo novu lozinku za Vaš nalog"
        ) + "."}
      </div>
      <div>
        <AdminInput
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={state.email}
          onChange={(email) => setState({ ...state, email })}
          label={t("Email adresa")}
        />
      </div>
    </Modal>
  );
};

export default Login;
