import { styled } from "@mui/system";
import { Badge, CircularProgress, IconButton, Menu } from "@mui/material";
import { gradient } from "../../../utils/style";

export const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: "0px 0px 4px 2px #00000020",
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));

export const BadgeWrapper = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    background: gradient(180, theme.palette.primary.mainGradient),
  },
}));

export const CircularProgressWrapper = styled(CircularProgress)({
  width: "24px !important",
  height: "24px !important",
});

export const MenuWrapper = styled(Menu)({
  "& .MuiPaper-root": {
    borderRadius: 15,
  },
});
