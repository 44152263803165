import React, { Fragment } from "react";
import "./style.scss";

const DashTitle = ({ title, subtitle, actions }) => {
  return (
    <Fragment>
      <div className="cv-dashtitle-main">
        <div className="cv-dashtitle-left">
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>
      {actions ? <div className="cv-dashtitle-actions">
        { actions }
      </div> : null}
    </Fragment>
  );
};

export default DashTitle;
