import { Autocomplete as MuiAutocomplete, Chip } from "@mui/material";
import { useState } from "react";
import { AdminInput } from "../";

const AutocompleteMultiSelect = ({
  options = [],
  label,
  color = "primary",
  value,
  onChange,
  disabled,
  tags,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <MuiAutocomplete
      value={value}
      onChange={(a, b) => onChange(b)}
      inputValue={inputValue}
      onInputChange={(a, b) => setInputValue(b)}
      disabled={disabled}
      options={options}
      classes={{ paper: "cv-paper-radius" }}
      multiple
      renderTags={
        tags
          ? (value, getTagProps) =>
              value.map((x, y) => (
                <Chip
                  variant="outlined"
                  label={x.label}
                  {...getTagProps({ y })}
                />
              ))
          : null
      }
      {...props}
      renderInput={(params) => (
        <AdminInput color={color} {...params} label={label} />
      )}
    />
  );
};

export default AutocompleteMultiSelect;
