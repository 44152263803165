import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AuthAPI, ChatAPI, GlobalAPI } from "../../api/v2";
import axios from "axios";

const defaultValues = {
  user: null,
  addings: null,
  token: localStorage.getItem("token"),
  countries: null,
  fetched: false,
  packages: null,
  ui_languages: null,
};

const UserContext = createContext(defaultValues);

var notificationsInterval = null;
var notificationsIntervalTime = 300000; // 5 min

// USING CONTEXT EXPORT
export const useUserContext = () => useContext(UserContext);

// CONTEXT PROVIDER
export const UserContextProvider = ({ children }) => {
  const [state, setState] = useState({
    user: null,
    addings: null,
    countries: null,
    cities: null,
    token: localStorage.getItem("token"),
    fetched: false,
    packages: null,
    ui_languages: null,
    conversations: null,
  });

  const setSeenConversation = (senderId) => {
    setState({
      ...state,
      conversations: state.conversations.map((x) => {
        const sender = x.from.id === state.user.id ? x.to : x.from;
        return sender.id === parseInt(senderId) ? { ...x, seen: 1 } : x;
      }),
    });
  };

  // REFERENCE
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    (async () => {
      const addings = state.addings || (await GlobalAPI.commonData());
      const countries = state.countries || (await GlobalAPI.countries());
      const cities = state.cities || (await GlobalAPI.cities());
      const packages = state.packages || (await GlobalAPI.packages());
      const ui_languages =
        state.ui_languages || (await GlobalAPI.uiLanguages());

      if (state.token) {
        try {
          localStorage.setItem("token", state.token);
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${state.token}`;
          const user = await GlobalAPI.user();
          const { data: conversations } = await ChatAPI.conversations({
            limit: 5,
            offset: 0,
          });
          if (notificationsInterval) {
            clearInterval(notificationsInterval);
          }
          notificationsInterval = setInterval(async () => {
            const { data: conversations } = await ChatAPI.conversations({
              limit: 5,
              offset: 0,
            });
            setState({
              ...stateRef.current,
              conversations,
            });
          }, notificationsIntervalTime);
          setState({
            ...stateRef.current,
            addings,
            user,
            countries,
            cities,
            packages,
            ui_languages,
            conversations,
            fetched: true,
          });
        } catch {
          if (notificationsInterval) {
            clearInterval(notificationsInterval);
          }
          setState({
            ...stateRef.current,
            addings,
            countries,
            cities,
            packages,
            ui_languages,
            fetched: true,
            token: null,
          });
        }
      } else {
        localStorage.removeItem("token");
        axios.defaults.headers.common["Authorization"] = null;
          if (notificationsInterval) {
            clearInterval(notificationsInterval);
          }
        setState({
          ...stateRef.current,
          addings,
          countries,
          cities,
          ui_languages,
          packages,
          user: null,
          conversations: null,
          fetched: true,
        });
      }
    })();
  }, [state.token]);

  const setToken = (token) => {
    setState({ ...stateRef.current, token });
  };

  const login = async (data) => {
    try {
      const { token } = await AuthAPI.login(data);
      setToken(token);
    } catch (e) {
      throw e;
    }
  };

  const logout = async () => {
    await AuthAPI.logout();
    setToken(null);
  };

  const setUser = (user) => {
    setState({ ...stateRef.current, user });
  };

  const updateProfileCompleted = async () => {
    const res = await GlobalAPI.profileCompleted();
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        login,
        logout,
        setUser,
        updateProfileCompleted,
        setSeenConversation,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
