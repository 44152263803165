import { Add, Delete, EditOutlined } from "@mui/icons-material";
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Image, Button, Avatar } from "../../ui";
import "./style.scss";
import UserImage from '../../../assets/images/nouser5.png';
const UploadImage = ({ src, className, onUpload, onDelete, name }) => {
  const [uplading, setUploading] = useState(false);

  const onChange = async (e) => {
    if (onUpload) {
      setUploading(true);
      await onUpload(e.target.files);
      setUploading(false);
    }
  };

  const fileRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpload = () => {
    fileRef.current?.click();
    handleClose();
  };

  const handleDelete = async () => {
    if (onDelete) {
      setUploading(true);
      await onDelete();
      setUploading(false);
    }
    handleClose();
  };

  return (
    <div className={`cv-uploadimage-main ${className}`}>
      <div className="cv-uploadimage-image-holder">
        {/* <Image src={src} /> */}
        {src ? (
          <Image src={src} />
        ) : (
          <img src={UserImage} />
        )}
        {uplading ? (
          <div className="cv-uploadimage-image-uploading">
            <CircularProgress color="secondary" size={60} />
          </div>
        ) : null}
      </div>
      <input type="file" hidden onChange={onChange} ref={fileRef} />
      <div className="cv-uploadimage-image-button-holder">
        <Button
          className="cv-uploadimage-image-button"
          variant="contained"
          color="secondary"
          onClick={handleClick}
        >
          <EditOutlined />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleUpload}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>{src ? "Izmeni fotografiju" : "Dodaj fotografiju"}</ListItemText>
          </MenuItem>
           
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>Obriši fotografiju</ListItemText>
            </MenuItem>
          
        </Menu>
      </div>
    </div>
  );
};

export default UploadImage;
