import { createTheme } from "@mui/material";
import {
  createBreakpoints,
  createPalette,
  createSpacing,
  createTypography,
  createZIndexes,
} from "./theming";

const Theme = createTheme({
  palette: createPalette(),
  typography: createTypography(),
  zIndex: createZIndexes(),
  spacing: createSpacing(),
  breakpoints: createBreakpoints(),
});

export default Theme;
