import React from 'react';

const MobileWarningIcon = ({ className }) => (
    <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="394 103 10 15">
        <svg width="10" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0.313 0 9.375 15" fill="#ff663d" x="394" y="103">
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="15" viewBox="0.667 0.333 8.958 14.333">
            <path paint-order="stroke fill markers" fill-rule="evenodd" d="M8.281.333h-6.27C1.268.333.666.935.666 1.677v11.646c0 .742.602 1.343 1.343 1.343h6.271c.742 0 1.344-.601 1.344-1.343V1.677C9.625.935 9.023.333 8.28.333zM5.146 13.771a.895.895 0 1 1 0-1.792.895.895 0 1 1 0 1.792zm3.135-3.024a.337.337 0 0 1-.336.336H2.346a.337.337 0 0 1-.336-.336V2.013c0-.185.151-.336.336-.336h5.6c.184 0 .335.151.335.336v8.734z" />
            </svg>
        </svg>
        <svg width="3" height="8" xmlns="http://www.w3.org/2000/svg" viewBox="0.7 0 2.5 8" fill="#ff663d" x="398" y="105">
            <svg xmlns="http://www.w3.org/2000/svg" width="3" height="8" viewBox="0.124 0.998 2.044 6.54">
            <path paint-order="stroke fill markers" fill-rule="evenodd" d="M2.168 6.516c0 .563-.46 1.022-1.022 1.022-.563 0-1.022-.46-1.022-1.022 0-.563.46-1.022 1.022-1.022s1.022.46 1.022 1.022zM.24 1.32l.174 3.474a.307.307 0 0 0 .305.291h.848a.307.307 0 0 0 .305-.29l.174-3.475a.306.306 0 0 0-.305-.322H.546a.306.306 0 0 0-.305.322z" />
            </svg>
        </svg>
    </svg>
);

export default MobileWarningIcon;
