import { styled } from "@mui/system";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const padding = (theme, size) => {
  const sizes = {
    small: theme.spacing(1, 2),
    medium: theme.spacing(2, 4),
    large: theme.spacing(3, 6),
  };
  return sizes[size];
};

export const FormControlWrapper = styled(FormControl)(
  ({ theme, color = "primary", size = "medium" }) => ({
    ...theme.typography.text,
    fontFamily: "'Rubik', sans-serif",
    minWidth: 140,
    textTransform: "none",
    "& .MuiInputBase-root": {
      backgroundColor: "#fff",
      borderRadius: 10,
      borderWidth: 2,
      "& select, & .MuiSelect-select": {
        padding: padding(theme, size),
        paddingRight: 40,
        minHeight: 45,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center"
      },
    },
  })
);

export const InputLabelWrapper = styled(InputLabel)({});

export const SelectWrapper = styled(Select)(({ value }) => ({
  color: !value ? "#00000060" : "#000",
}));

export const OptionWrapper = styled(MenuItem)(({ value }) => ({
  color: "#000",
  "&:first-child": {
    color: !value ? "#00000060" : "#000",
  },
}));