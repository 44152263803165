import {
  BusinessCenter,
  CheckCircle,
  Close,
  CloudUpload,
  Description,
  GroupAdd,
  PeopleAlt,
  RecordVoiceOver,
  SendRounded,
  SentimentDissatisfied,
  Tune,
  Videocam,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Alert, Collapse, IconButton } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AuthAPI, CandidateAPI, CompanyAPI } from "../../api/v2";
import {
  AdminAutocomplete,
  AdminAutocompleteSelect,
  AdminInput,
  AdminSelect,
} from "../../components/admin";
import {
  DashTitle,
  Progress,
  Statistic,
  Modal,
  ModalVideo,
  Swipe,
} from "../../components/custom";
import { SimpleCard, Button, Input, Select } from "../../components/ui";
import { useModalContext } from "../../context/modal";
import { useUserContext } from "../../context/user";
import { removeFalsy, tryParsing } from "../../utils/misc";
import VideoRecorder from "react-video-recorder";
import "./style.scss";
import { blobToFile } from "../../utils/converter";
import { toast } from "react-toastify";
import { useLanguageContext } from "../../context/language";

const Dashboard = () => {
  const [state, setState] = useState({
    ads: [],
    year: new Date().getFullYear(),
    percent: 0,
    video: null,
    documents_count: 0,
    applications_count: 0,
    statistic: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    applications: [],
    candidates_eminent_count: 0,
    new_candidates: [],
    ads_count: 0,
    ads_applications_count: 0,
    ads_applications_eminent_count: 0,
    last_ad_applications_count: 0,
    active_ads: [],
    isVideoUploading: false,
  });
  const [searchState, setSearchState] = useState(
    tryParsing(localStorage.getItem("dashboardFilters")) || {}
  );
  const [modalState, setModalState] = useState(
    tryParsing(localStorage.getItem("dashboardFilters")) || {
      country_id: "",
      city_id: "",
      type_of_work_id: "",
      company_name: "",
    }
  );

  useEffect(() => {
    localStorage.setItem("dashboardFilters", JSON.stringify(searchState));

    // INIT
    setCountry(
      allCountries
        .map((x) => ({ value: x.id, label: x.name }))
        .find((x) => x.value === searchState.country_id) || null
    );
    setCity(
      allCities
        .map((x) => ({ value: x.id, label: x.name }))
        .find((x) => x.value === searchState.city_id) || null
    );
    setCategory(
      type_of_works
        .map((x) => ({ value: x.id, label: x.name }))
        .find((x) => x.value === searchState.type_of_work_id) || null
    );
    setCompany(searchState.company_name || null);
  }, [searchState]);

  const [verification, setVerification] = useState(false);
  const [open, setOpen] = useState(false);
  const [recorderOpen, setRecorderOpen] = useState(false);

  const getCandidateData = async (params) => {
    const { ads_for_me, user } = await CandidateAPI.dashboard(
      state.year,
      removeFalsy(params)
    );
    setState({
      ...state,
      ads: ads_for_me,
      percent: user.profile_completely,
      video: user.cv_video?.video || null,
      documents_count: user.documents_count,
      applications_count: user.applications_count,
      statistic: user.applications_statistics_per_mouth,
      applications: user.applications,
    });
  };

  const { t, languages, setLanguage } = useLanguageContext();

  const getCompanyData = async (params) => {
    const { company, new_candidates } = await CompanyAPI.dashboard(state.year, {
      ...params,
      is_active: 1,
    });
    setState({
      ...state,
      new_candidates,
      percent: company.profile_completely,
      candidates_eminent_count: company.candidates_eminent_count,
      statistic: company.applications_statistics_per_mouth,
      ads_count: company.ads_count,
      ads_applications_count: company.ads_applications_count,
      ads_applications_eminent_count: company.ads_applications_eminent_count,
      last_ad_applications_count: company.last_ad_applications_count,
      active_ads: company.active_ads,
      // video: company.cv_video?.video,
      // documents_count: company.documents_count,
      // applications_count: company.applications_count,
      // statistic: company.applications_statistics_per_mouth,
      // applications: company.applications,
    });
  };

  const {
    user,
    setUser,
    countries: allCountries,
    cities: allCities,
    addings: { type_of_works },
  } = useUserContext();

  useEffect(() => {
    if (user.role === "employee") {
      getCandidateData(searchState);
    } else if (user.role === "company") {
      getCompanyData(searchState);
    }
  }, [searchState]);

  // -----------------------------------------------------------------------------------

  const resetFilters = () => {
    setOpen(false);
    setModalState({
      country_id: "",
      city_id: "",
      type_of_work_id: "",
      company_name: "",
    });
    setSearchState({
      ...searchState,
      country_id: "",
      city_id: "",
      type_of_work_id: "",
      company_name: "",
    });
  };

  const applyFilters = () => {
    setSearchState({ ...modalState });
    setOpen(false);
  };

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [typeOfWorks, setTypeOfWorks] = useState([]);
  const [companies, setCompanies] = useState([]);

  const getCompanies = async () => {
    const res = await CompanyAPI.companies({ only_with_ads: 0 });
    setCompanies(res.map((x) => ({ value: x.id, label: x.full_name })));
  };

  useEffect(() => {
    if (user.ui_language) {
      const selectedLang = languages.find(
        (lang) => lang.id === parseInt(user.ui_language.id)
      );
      if (selectedLang) {
        localStorage.setItem("lang", selectedLang.code);
        setLanguage(selectedLang);
      } else {
        setLanguage(languages.find((lang) => lang.id === 1));
        localStorage.setItem(
          "lang",
          languages.find((lang) => lang.id === 1).code
        );
      }
    }
  }, [user]);

  useEffect(() => {
    setTypeOfWorks(type_of_works.map((x) => ({ value: x.id, label: x.name })));
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
    getCompanies();
  }, []);
  const uploadVideo = async (e) => {
    setState({ ...state, isVideoUploading: true });
    try {
      const { cv_video } = await CandidateAPI.updateProfileVideo(
        e.target.files[0]
      );
      setState({ ...state, video: cv_video.video, isVideoUploading: false });
      setUser({ ...user, cv_video });
    } catch (error) {
      console.error(error);
    }
  };

  const uploadRef = useRef();

  const [infoOpen, setInfoOpen] = useState(
    !user.is_email_verified || !user.is_phone_verified
  );

  const sendVerification = async () => {
    await AuthAPI.verify();
  };

  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [category, setCategory] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(modalState.country_id))
      .map((x) => ({
        value: x.id,
        label: x.name,
      }));
    setCities(filteredCities);
  }, [modalState.country_id]);

  const updateCountry = (x) => {
    setCountry(x);
    setCity(null);
    setModalState({ ...modalState, country_id: x?.value, city_id: "" });
  };

  const updateCity = (x) => {
    setCity(x);
    setModalState({ ...modalState, city_id: x?.value });
  };

  const updateCategory = (x) => {
    setCategory(x);
    setModalState({ ...modalState, type_of_work_id: x?.value });
  };

  const updateCompany = (company_name) => {
    setCompany(company_name);
    setModalState({ ...modalState, company_name });
  };

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Dashboard")}
          subtitle={`${"Dobrodošli"} ${user.full_name}!`}
        />

        {/* <Collapse in={infoOpen} className="cv-alert">
          <Alert
            className={`cv-alert-inner ${infoOpen ? "cv-alert-expand" : ""}`}
            severity="info"
            variant="filled"
          // action={
          //   <IconButton
          //     aria-label="close"
          //     color="inherit"
          //     size="small"
          //     onClick={() => {
          //       setInfoOpen(false);
          //     }}
          //   >
          //     <Close fontSize="inherit" />
          //   </IconButton>
          // }
          >
            {!user.is_email_verified ? <div className="cv-alert-description">
              {t("Potrebna je verifikacija vaše email adrese! Kliknite")}{" "}
              <span onClick={sendVerification}>ovde</span> kako biste poslali
              verifikacioni email.
            </div> : null}
            {!user.is_phone_verified ? <div className="cv-alert-description">
              {t("Potrebna je verifikacija vašeg broja mobilnog telefona! Kliknite")}{" "}
              <span onClick={() => setVerification(true)}>{t("ovde")}</span> {t("kako biste poslali kod na vaš broj") + '.'}
            </div> : null}
          </Alert>
        </Collapse> */}
        <div className="cv-dashboard-dashboard-main">
          <SimpleCard
            className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-card-doubled cv-dashboard-dashboard-card-disable-height-mobile"
            title={
              user.role === "employee"
                ? t("NOVI OGLASI ZA MENE")
                : t("AKTUELNI OGLASI")
            }
            actions={
              user.role === "employee" ? (
                <div
                  className="cv-dashboard-dashboard-card-title-icon"
                  onClick={() => setOpen(true)}
                >
                  <Tune />
                </div>
              ) : null
            }
          >
            <div className="cv-dashboard-dashboard-card-content">
              <div className="cv-dashboard-dashboard-card-content-inner">
                {user.role === "employee"
                  ? state.ads.map((x, y) => (
                      <Link
                        to={`/dashboard/jobs/job/${x.id}`}
                        key={x.id}
                        className="cv-dashboard-dashboard-card-ad"
                      >
                        <div className="cv-dashboard-dashboard-card-ad-image">
                          <img src={x.company.profile_image} alt="Company" />
                        </div>
                        <div className="cv-dashboard-dashboard-card-ad-text">
                          <span className="cv-dashboard-dashboard-card-ad-text-title">
                            {x.title}
                          </span>
                          <div className="cv-dashboard-dashboard-card-ad-date">
                            {t("Kreirano")}{" "}
                            {moment(x.created_at).format("DD.MM.YYYY")}
                          </div>
                          <span className="cv-dashboard-dashboard-card-ad-text-locaton">
                            {x.city.name}, {x.country.name_short}
                          </span>
                        </div>
                      </Link>
                    ))
                  : state.active_ads.map((x, y) => (
                      <Link
                        to={`/dashboard/jobs/job/${x.id}`}
                        key={x.id}
                        className="cv-dashboard-dashboard-card-ad"
                      >
                        <div className="cv-dashboard-dashboard-card-ad-image">
                          <img src={x.company.profile_image} alt="Company" />
                        </div>
                        <div className="cv-dashboard-dashboard-card-ad-text">
                          <span className="cv-dashboard-dashboard-card-ad-text-title">
                            {x.title}
                          </span>
                          <div className="cv-dashboard-dashboard-card-ad-date">
                            {t("Kreirano")}{" "}
                            {moment(x.created_at).format("DD.MM.YYYY")}
                          </div>
                          <span className="cv-dashboard-dashboard-card-ad-text-locaton">
                            {x.company.city.name},{" "}
                            {x.company.country.name_short}
                          </span>
                        </div>
                      </Link>
                    ))}

                {user.role === "employee" && !state.ads.length ? (
                  <div className="cv-dashboard-no-results">
                    <BusinessCenter />
                    <span>{t("Nema rezultata")}</span>
                  </div>
                ) : null}
                {user.role === "company" && !state.active_ads.length ? (
                  <div className="cv-dashboard-no-results">
                    <BusinessCenter />
                    <span>{t("Nema rezultata")}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </SimpleCard>
          {user.role === "employee" ? (
            <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-videocard">
              {state.video || state.isVideoUploading ? (
                <ModalVideo
                  isUploading={state.isVideoUploading}
                  src={state.video}
                />
              ) : (
                <img
                  className="cv-dashboard-dashboard-videocard-placeholder"
                  src="/assets/images/holder/empty.png"
                />
              )}
              <input
                type="file"
                ref={uploadRef}
                hidden
                onChange={uploadVideo}
              />
              <IconButton
                className="cv-dashboard-dashboard-videocard-action-left"
                onClick={() => uploadRef.current.click()}
              >
                <CloudUpload />
              </IconButton>
              <IconButton
                className="cv-dashboard-dashboard-videocard-action-right"
                onClick={() => setRecorderOpen(true)}
              >
                <Videocam />
              </IconButton>
            </SimpleCard>
          ) : (
            <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-data">
              <div className="cv-dashboard-dashboard-icon">
                <SendRounded />
              </div>
              <div className="cv-dashboard-dashboard-card-info">
                <div className="cv-dashboard-dashboard-description">
                  {t("Broj prijava za poslednji postavljen oglas")}
                </div>
                <span>{state.last_ad_applications_count}</span>
                <div className="cv-dashboard-dashboard-description">
                  {t("Ukupan broj prijava") + ":"}{" "}
                  {state.ads_applications_count}
                </div>
              </div>
            </SimpleCard>
          )}
          {user.role === "employee" ? (
            <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-data">
              <div className="cv-dashboard-dashboard-icon">
                <Description />
              </div>
              <div className="cv-dashboard-dashboard-card-info">
                <div className="cv-dashboard-dashboard-description">
                  {t("Moja CV dokumenta")}
                </div>
                <span>{state.documents_count}</span>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="cv-dashboard-dashboard-card-button"
                  size="large"
                  component={Link}
                  to="/dashboard/cv-profile"
                >
                  {t("POGLEDAJ")}
                </Button>
              </div>
            </SimpleCard>
          ) : (
            <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-data">
              <div className="cv-dashboard-dashboard-icon">
                <PeopleAlt />
              </div>
              <div className="cv-dashboard-dashboard-card-info">
                <div className="cv-dashboard-dashboard-description">
                  {t("Broj izdvojenih kandidata")}
                </div>
                <span>{state.candidates_eminent_count}</span>
                {state.candidates_eminent_count !== 0 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className="cv-dashboard-dashboard-card-button"
                    size="large"
                    component={Link}
                    to="/dashboard/jobs/candidates"
                  >
                    {t("POGLEDAJTE LISTU")}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={`cv-dashboard-dashboard-card-button ${
                      !state.selected_applications_count ? "cv-disabled" : ""
                    }`}
                    size="large"
                    component={Link}
                    to="/dashboard/jobs/candidates"
                  >
                    {t("POGLEDAJTE LISTU")}
                  </Button>
                )}
              </div>
            </SimpleCard>
          )}
          <SimpleCard
            className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-card-doubled cv-dashboard-dashboard-card-statistics"
            title={
              user.role === "employee"
                ? t("Moja statistika prijava")
                : t("Statistika oglasa")
            }
            actions={
              <Select
                disableDefault
                color="secondary"
                placeholder="Godina"
                value={state.year}
                onChange={(year) => setState({ ...state, year })}
                options={[
                  {
                    label: new Date().getFullYear(),
                    value: new Date().getFullYear(),
                  },
                ]}
              />
            }
          >
            <div className="cv-dashboard-dashboard-card-content">
              <Statistic data={state.statistic} />
            </div>
            <div className="cv-dashboard-dashboard-card-statistics-swipe">
              <Swipe />
            </div>
          </SimpleCard>
          {user.role === "employee" ? (
            <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-data">
              <div className="cv-dashboard-dashboard-icon">
                <RecordVoiceOver />
              </div>
              <div className="cv-dashboard-dashboard-card-info">
                <div className="cv-dashboard-dashboard-description">
                  {t("Moj ukupan broj prijava na oglase")}
                </div>
                <span>{state.applications_count}</span>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  className="cv-dashboard-dashboard-card-button"
                  size="large"
                  component={Link}
                  to="/dashboard/applications/done"
                >
                  {t("SVE PRIJAVE")}
                </Button>
              </div>
            </SimpleCard>
          ) : (
            <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-data">
              <div className="cv-dashboard-dashboard-icon">
                <RecordVoiceOver />
              </div>
              <div className="cv-dashboard-dashboard-card-info">
                <div className="cv-dashboard-dashboard-description">
                  {t("Ukupan broj oglasa")}
                </div>
                <span>{state.ads_count}</span>
                {state.ads_count !== 0 ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className="cv-dashboard-dashboard-card-button"
                    size="large"
                    component={Link}
                    to="/dashboard/jobs/posted"
                  >
                    {t("POGLEDAJTE LISTU")}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    className={`cv-dashboard-dashboard-card-button ${
                      !state.selected_applications_count ? "cv-disabled" : ""
                    }`}
                    size="large"
                    component={Link}
                    to="/dashboard/jobs/posted"
                  >
                    {t("POGLEDAJTE LISTU")}
                  </Button>
                )}
              </div>
            </SimpleCard>
          )}
          <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-data">
            <div className="cv-dashboard-dashboard-icon">
              <CheckCircle />
            </div>
            <div className="cv-dashboard-dashboard-card-info cv-dashboard-dashboard-card-info-flex">
              <div className="cv-dashboard-dashboard-description">
                {t("Popunjenost profila")}
              </div>
              <Progress
                value={state.percent}
                className="cv-dashboard-dashboard-progress"
                circleClassName="cv-dashboard-dashboard-progress-circle"
                thickness={4}
              />
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                className="cv-dashboard-dashboard-card-button"
                size="large"
                component={Link}
                to={
                  user.role === "employee"
                    ? "/dashboard/cv-profile/edit"
                    : "/dashboard/profile/edit"
                }
              >
                {t("IZMIJENI")}
              </Button>
            </div>
          </SimpleCard>
          {/* <SimpleCard className="cv-dashboard-dashboard-card cv-dashboard-dashboard-card-full-width cv-dashboard-dashboard-card-empty cv-dashboard-dashboard-card-status">
            <div className="cv-dashboard-dashboard-card-status-title">
              {user.role === "employee" ? (
                <Fragment>
                  <SendRounded />
                  <h3>MOJ STATUS PRIJAVA</h3>
                  <p>Poslednje prijave</p>
                </Fragment>
              ) : (
                <Fragment>
                  <GroupAdd />
                  <h3>NOVO REGISTROVANI KANDIDATI</h3>
                  <p>Najnoviji registrovani kandidati</p>
                </Fragment>
              )}
            </div>
            <div className="cv-dashboard-dashboard-card-status-list">
              {user.role === "employee" ? (
                <table className="cv-dashboard-dashboard-card-status-list-table">
                  <thead>
                    <tr>
                      <th>KOMPANIJA</th>
                      <th>POZICIJA</th>
                      <th>DATUM</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.applications.map((x, y) => (
                      <tr key={y}>
                        <td>
                          <Link to={`/dashboard/jobs/job/${x.ad_id}`}>
                            {x.ad_company_full_name}
                          </Link>
                        </td>
                        <td>{x.ad_title}</td>
                        <td>{moment(x.created_at).format("DD.MM.YYYY")}</td>
                        <td>{x.seen ? <Visibility /> : <VisibilityOff />}</td>
                      </tr>
                    ))}
                    {state.applications.length !== 0 ? "" : <p className="no-ads-txt">Trentno nema prijava</p>}
                  </tbody>
                </table>
              ) : (
                <table className="cv-dashboard-dashboard-card-status-list-table">
                  <thead>
                    <tr>
                      <th>IME I PREZIME</th>
                      <th>LOKACIJA</th>
                      <th>OBRAZOVANJE</th>
                      <th>PROFIL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.new_candidates.map((x, y) => (
                      <tr key={y}>
                        <td>{x.full_name}</td>
                        <td>
                          {[x.city?.name, x.country?.name]
                            .filter((x) => x)
                            .join(", ")}
                        </td>
                        <td>{x.educations.map((x) => x.course).join(", ")}</td>
                        <td>
                          <Link to={`/dashboard/employee/${x.id}`}>
                            POGLEDAJ
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {user.role === "employee" ? (
              state.applications?.length ? (
                <div className="cv-dashboard-dashboard-card-status-list-mobile">
                  <Swipe
                    options={state.applications.map((x, y) => (
                      <div className="cv-dashboard-dashboard-status-swipe">
                        <div className="cv-dashboard-dashboard-status-swipe-single">
                          <span>Kompanija</span>
                          {x.full_name || "/"}
                        </div>
                        <div className="cv-dashboard-dashboard-status-swipe-single">
                          <span>Pozicija</span>
                          {x.ad_title}
                        </div>
                        <div className="cv-dashboard-dashboard-status-swipe-single">
                          <span>Datum</span>
                          {moment(x.created_at).format("DD.MM.YYYY")}
                        </div>
                        <div className="cv-dashboard-dashboard-status-swipe-single">
                          <span>Status</span>
                          {x.seen ? <Visibility /> : <VisibilityOff />}
                        </div>
                        <div className="cv-dashboard-dashboard-status-swipe-single">
                          <Button
                            to={`/dashboard/jobs/job/${x.id}`}
                            component={Link}
                            color="secondary"
                            variant="contained"
                            fullWidth
                          >
                            POGLEDAJ
                          </Button>
                        </div>
                      </div>
                    ))}
                  />
                </div>
              ) : null
            ) : state.new_candidates?.length ? (
              <div className="cv-dashboard-dashboard-card-status-list-mobile">
                <Swipe
                  options={state.new_candidates.map((x, y) => (
                    <div className="cv-dashboard-dashboard-status-swipe">
                      <div className="cv-dashboard-dashboard-status-swipe-single">
                        <span>Ime i prezime</span>
                        {x.full_name || "/"}
                      </div>
                      <div className="cv-dashboard-dashboard-status-swipe-single">
                        <span>Lokacija</span>
                        {[x.city?.name, x.country?.name]
                          .filter((x) => x)
                          .join(", ") || "/"}
                      </div>
                      <div className="cv-dashboard-dashboard-status-swipe-single">
                        <span>Obrazovanje</span>
                        {x.educations?.map((x) => x.course).join(", ") || "/"}
                      </div>
                      <div className="cv-dashboard-dashboard-status-swipe-single">
                        <Button
                          to={`/dashboard/employee/${x.id}`}
                          component={Link}
                          color="secondary"
                          variant="contained"
                          fullWidth
                        >
                          POGLEDAJ
                        </Button>
                      </div>
                    </div>
                  ))}
                />
              </div>
            ) : null}
          </SimpleCard> */}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="FILTERI"
        actions={
          <div className="cv-modal-buttons-group">
            <Button
              color="secondary"
              variant="contained"
              size="large"
              fullWidth
              onClick={applyFilters}
            >
              {t("POTVRDI")}
            </Button>
            <Button
              color="secondary"
              variant="text"
              size="large"
              fullWidth
              onClick={resetFilters}
            >
              {t("RESETUJ FILTERE")}
            </Button>
          </div>
        }
        size="small"
      >
        <div key={modalState}>
          <AdminAutocompleteSelect
            className="cv-mb-10"
            color="secondary"
            variant="outlined"
            value={country}
            onChange={updateCountry}
            options={countries}
            label={t("Država")}
          />
          <AdminAutocompleteSelect
            className="cv-mb-10"
            color="secondary"
            variant="outlined"
            value={city}
            onChange={updateCity}
            options={cities}
            label={t("Grad")}
          />
          <AdminAutocompleteSelect
            className="cv-mb-10"
            color="secondary"
            variant="outlined"
            value={category}
            onChange={updateCategory}
            options={typeOfWorks}
            label={t("Kategorija posla")}
          />
          <AdminAutocomplete
            color="secondary"
            variant="outlined"
            value={company}
            onChange={updateCompany}
            label={t("Naziv kompanije")}
            options={companies}
          />
        </div>
      </Modal>
      <RecordModal
        open={recorderOpen}
        onClose={() => setRecorderOpen(false)}
        state={state}
        setState={setState}
      />
      <VerificationModal
        open={verification}
        onClose={() => setVerification(false)}
      />
    </Fragment>
  );
};
const VerificationModal = ({ open, onClose }) => {
  const { t } = useLanguageContext();
  const [pin, setPin] = useState(["", "", "", "", "", ""]);

  const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const processVerification = async () => {
    try {
      await AuthAPI.confirmOtp(pin.join(""));
      toast.success(t`Broj telefona je uspešno verifikovan`);
      onClose();
    } catch {
      toast.error(t`Pin koji ste uneli nije ispravan`);
    }
  };

  const changeNumber = (v, number) => {
    setPin(pin.map((a, b) => (number === b ? v.slice(-1) : a)));
    if (number < 5 && v) {
      refs[number + 1].current.querySelector("input")?.focus();
    }
  };

  const requestNewCode = async () => {
    try {
      await AuthAPI.sendOtp();
      toast.success(t`Kod je uspešno poslat na broj vašeg telefona`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  useEffect(() => {
    if (open) {
      requestNewCode();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("VERIFIKACIJA")}
      size="small"
      actions={[
        <Button
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
          className={pin.filter((x) => x.trim() === "").length}
          onClick={processVerification}
        >
          {t("VERIFIKUJ")}
        </Button>,
        <Button
          variant="contained"
          color="default"
          onClick={onClose}
          size="large"
          fullWidth
        >
          {t("OTKAŽI")}
        </Button>,
      ]}
    >
      <div className="cv-dashboard-verification-modal-content">
        <div className="cv-dashboard-verification-modal-content-row">
          {t("Kod je poslat na vaš broj telefona")}
        </div>
        <div className="cv-dashboard-verification-modal-content-row cv-dashboard-pin">
          {[0, 1, 2, 3, 4, 5].map((x) => (
            <Input
              className="cv-dashboard-pin-single"
              ref={refs[x]}
              value={pin[x]}
              onChange={(v) => changeNumber(v, x)}
            />
          ))}
        </div>
        <div className="cv-dashboard-verification-modal-content-row">
          {t("Ukoliko niste dobili kod, kliknite")}{" "}
          <span onClick={requestNewCode}>{t("ovde")}</span> {t("za nov")}
        </div>
      </div>
    </Modal>
  );
};

const RecordModal = ({ open, onClose, setState, state }) => {
  const [videoBlob, setVideoBlob] = useState(null);
  const { t } = useLanguageContext();
  const { user, setUser } = useUserContext();

  const saveRecording = async () => {
    const file = blobToFile(videoBlob, "video.webm");
    const { cv_video } = await CandidateAPI.updateProfileVideo(file);
    setUser({ ...user, cv_video });
    setState({ ...state, video: cv_video.video });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("SNIMI VIDEO CV")}
      size="medium"
      actions={[
        <Button
          variant="contained"
          color="secondary"
          onClick={saveRecording}
          size="large"
          fullWidth
          className={!videoBlob ? "cv-disabled" : null}
        >
          {"SAČUVAJ"}
        </Button>,
        <Button
          variant="contained"
          color="default"
          onClick={onClose}
          size="large"
          fullWidth
        >
          {"OTKAŽI"}
        </Button>,
      ]}
    >
      <div className="cv-dashboard-cvvideo-modal-content">
        <VideoRecorder
          mimeType="video/webm"
          isOnlyInitially={true}
          showReplayControls={true}
          videoControlsList="nodownload"
          disablepictureinpicture
          onRecordingComplete={(videoBlob) => {
            setVideoBlob(videoBlob);
          }}
        />
      </div>
    </Modal>
  );
};

export default Dashboard;
