import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ME, RS, HR, BA, SL, MK, EN } from "../../translations";

const languages = [
  { id: 1, name: "crnogorski", code: "ME", object: ME },
  { id: 2, name: "srpski", code: "RS", object: RS },
  { id: 3, name: "bosanski", code: "BA", object: BA },
  { id: 4, name: "hrvatski", code: "HR", object: HR },
  // { id: 5, name: "Slovenija", code: "SL", object: SL },
  // { id: 6, name: "Makedonija", code: "MK", object: MK },
  // { id: 7, name: "Engleski", code: "EN", object: EN },
];

const defaultValues = {
  languages,
  lang: localStorage.getItem("lang") || languages.find(() => true),
};

const LanguageContext = createContext(defaultValues);

// USING CONTEXT EXPORT
export const useLanguageContext = () => useContext(LanguageContext);

// CONTEXT PROVIDER
export const LanguageContextProvider = ({ children }) => {
  const [state, setState] = useState({
    languages,
    lang:
      languages.find((x) => x.code === localStorage.getItem("lang")) ??
      languages.find(() => true),
  });

  // REFERENCE
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const t = (words, ...variables) => {
    if (Array.isArray(words)) {
      var translated = words.reduce(
        (accumulator, word, index) =>
          accumulator +
          (state.lang?.object[word] || word) +
          (index < words.length - 1 ? variables[index] : ""),
        ""
      );
      return translated;
    } else {
      return state.lang?.object?.[words] || words;
    }
  };

  const setLanguage = (lang) => {
    setState({ ...stateRef.current, lang });
  };

  const changeLanguage = () => {
    const langId = state.languages.findIndex((x) => x === state.lang);
    if (langId === state.languages.length - 1) {
      setState({ ...stateRef.current, lang: state.languages[0] });
    } else {
      setState({ ...stateRef.current, lang: state.languages[langId + 1] });
    }
  };

  return (
    <LanguageContext.Provider
      value={{ ...state, t, changeLanguage, setLanguage, languages }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
