import React from "react";

import { Modal } from '../../custom';
import { Button } from '../index';

const LeavePageModal = ({ open, onClose, onLeave = () => { } }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="OTKAŽI"
      actions={[
        <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          onClick={onLeave}
          className="cv-dashboard-employee-edit-button"
        >
          DA
        </Button>,
        <Button
          color="default"
          variant="contained"
          size="large"
          fullWidth
          onClick={onClose}
          className="cv-dashboard-employee-edit-button"
        >
          NE
        </Button>,
      ]}
      size="medium"
    >
      <div className="cv-profile-modal-text">
        Da li ste sigurni da želite da otkažete izmene?
      </div>
    </Modal>
  );
};

export default LeavePageModal;