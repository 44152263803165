import React from 'react';

const WarningIcon = ({ className }) => (
    <svg className={className} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" fill="#ff663d">
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0.791 1 34.542 30.704">
            <path paint-order="stroke fill markers" fill-rule="evenodd" d="M34.944 27.387c1.107 1.918-.282 4.317-2.493 4.317H3.673c-2.215 0-3.598-2.402-2.493-4.317L15.57 2.438c1.106-1.92 3.88-1.916 4.986 0l14.388 24.949zm-16.882-5.158a2.759 2.759 0 1 0 0 5.517 2.759 2.759 0 0 0 0-5.517zm-2.619-9.916l.445 8.156a.72.72 0 0 0 .719.68h2.911a.72.72 0 0 0 .718-.68l.445-8.156a.72.72 0 0 0-.718-.759h-3.801a.72.72 0 0 0-.719.76z"/>
        </svg>
    </svg>
);

export default WarningIcon;