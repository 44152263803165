import React, { Fragment, useEffect, useState } from "react";
import {
  Advice,
  BlogCard,
  Card,
  CardTitle,
  CompanyCard,
  Hero,
} from "../../components/custom";
import { Button, Select } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import { useEventContext } from "../../context/event";
import { Search } from "./features";
import { Link, useHistory } from "react-router-dom";
import {
  Advice1,
  Advice2,
  Advice3,
  Advice4,
  Advice5,
  Advice6,
} from "../../assets/svg";
import "./style.scss";
import { CompanyAPI } from "../../api/v2";
import { BlogPosts } from "../../constants/static";
import Spinner from "../../components/custom/spinner";

const Home = () => {
  const [filter, setFilter] = useState("");
  const [companies, setCompanies] = useState([]);
  const [searchCount, setSearchCount] = useState({
    companies: 0,
    workingPositions: 0,
  });

  const { t } = useLanguageContext();
  const { width } = useEventContext();
  const [isLoadingEminentCompanies, setIsLoadingEminentCompanies] = useState(false);

  const getEminentCompanies = async () => {
    try{
      setIsLoadingEminentCompanies(true);
      const { data, meta } = await CompanyAPI.publicCompanySearch({
        limit: 8,
        is_eminent: 1,
      });
      setCompanies(data);
      setSearchCount({
        companies: meta.total,
        workingPositions: data
          .map((x) => x.active_ads_count)
          .reduce((x, y) => x + y, 0),
      });
    } catch( error ) {
      console.error(error);
    } finally{
      setIsLoadingEminentCompanies(false);
    }
  };

  useEffect(() => {
    getEminentCompanies();
  }, []);

  const advices = [
    {
      title: t`Pripremite svoju priču`,
      description: t`Napravite koncept, izdvojite šta iz svog CV-ja želite da istaknete i kojim redosljedom da prezentujete.`,
      long_description: t`Prije snimanja vaše video CV priče, dobro pripremite šta ćete i na koji način u njoj ispričati. Napravite koncept, izdvojite šta iz svog CV-ja želite da istaknete i kojim redosljedom da prezentujete. Na primjer, ukoliko nemate bogato radno iskustvo, akcenat stavite na obrazovanje ili osobine koje posjedujete, a koje vas mogu istaći pri izboru za traženu poziciju. Neke od najcjenjenijih osobina pri zapošljavanju su komunikativnost, samopouzdanje, spremnost za timski rad i inovacije, profesionalnost, radoznalost, samostalnost itd.`,
      icon: <Advice1 />,
      // icon: "/assets/images/advice-icons/pripremite-svoju-pricu.png",
    },
    {
      title: t`Uvježbajte govor prije snimanja`,
      description: t`Prije snimanja, provježbajte svoj govor naglas i ispred ogledala. Na taj način ćete steći potrebno samopouzdanje.`,
      long_description: t`Kako biste sebe izdvojili kao ozbiljnog kandidata za posao, poželjno je da u snimku vodite računa o načinu govora, dikciji i tonalitetu. Zato, prije snimanja, provježbajte svoj govor naglas i ispred ogledala. Na taj način ćete steći potrebnu sigurnost, samopouzdanje i ubjedljivost. Zamislite da je poslodavac ispred vas i da imate jedinstvenu priliku da ga oduševite. Imajte na umu da ga, u moru drugih kandidata, najmanja greška u vašem govoru i tonu, može odbiti. Ukoliko imate govornu manu, sigurnim i šarmantnim govorom možete je staviti u drugi plan.`,
      icon: <Advice2 />,
      // icon: "/assets/images/advice-icons/uvjezbajte-govor-prije-snimanja.png",
    },
    {
      title: t`Obratite pažnju na mimiku i proksemiku`,
      description: t`Prilikom snimanja, sjedite pristojno, mirno, bez pretjeranog gestikuliranja. Potrebno je da budete ono što jeste, u svojoj najboljoj verziji.`,
      long_description: t`Pored verbalne, obratite pažnju i na neverbalnu komunikaciju. Prednost CV priče je što imate priliku da predstavite sebe prije prvog susreta sa poslodavcem koji kod vas može izazvati tremu i anksioznost. Prilikom snimanja video snimka, sjedite pristojno, bez pucketanja prstiju, prenaglašenog mahanja i prečestog mijenjanja položaja ruku i nogu. Osmijeh nije zabranjen, ali ni tu ne treba pretjerivati. Potrebno je da budete ono što jeste, u svojoj najboljoj verziji.`,
      icon: <Advice3 />,
      // icon: "/assets/images/advice-icons/obratite-paznju-na-mimiku-i-proksemiku.png",
    },
    {
      title: t`Ispratite bonton poslovnog odjevanja`,
      description: t`Poželjno je da izgledate profesionalno i pristojno. Birajte neutralne boje i vodite računa da vam odjeća bude čista i ispeglana.`,
      long_description: t`Imate samo jednu šansu da ostavite dobar prvi utisak, te je od izuzetne važnosti kako ćete se obući prilikom snimanja videa. Za koji god posao želite da aplicirate, treba da izgledate profesionalno i pristojno. Ukoliko ste muškarac, poželjno bi bilo da obučete sako i, u zavisnosti od godišnjeg doba, džemper ili košulju. Birajte neutralne boje koje se lako kombinuju: siva, bijela i teget su uvijek dobar izbor. Kada su žene u pitanju, najbolji izbor jesu bluza i poslovne pantalone ili poslovna suknja. I muškarci i žene bi trebalo da izbjegavaju jarke boje, koje odvlače pažnju i djeluju napadno. Oblačenje treba da istakne vas i vašu ličnost, a ne da svu pažnju poslodavca usmjeri na odijelo. Ukoliko vaša profesija ne zahtijeva formalno oblačenje, možete se odlučiti za „biznis kežual“ stil koji je uvijek najbolji i najsigurniji izbor. Podrazumijeva se da vam je odjeća čista i ispeglana. Takođe, pobrinite se i da vaša kosa, šminka i nokti izgledaju uredno i pristojno.`,
      icon: <Advice4 />,
      // icon: "/assets/images/advice-icons/ispratite-bonton-poslovnog-odjevanja.png",
    },
    {
      title: t`Odaberite adekvatnu lokaciju`,
      description: t`Najbolje je da video priču snimate u dobro osvijetljenom zatvorenom prostoru, bez previše detalja i zvukova koji bi odvlačili pažnju.`,
      long_description: t`Najpogodnija lokacija za snimanje CV priče je zatvoren prostor, kako vas spoljašnji zvukovi, sa ulice ili iz prirode, ne bi ometali. Ipak, bilo da je to vaš dom ili neka druga lokacija, vodite računa da prostor u kom snimate bude dovoljno osvijetljen. Takođe, pazite da u pozadini nema uređaja ili detalja koji će odvlačiti pažnju. Najbolje bi bilo da video snimate za nekim stolom, a da iza vas bude prazan zid. Pobrinite se da prostor u kom snimate bude čist i uredan.`,
      icon: <Advice5 />,
      // icon: "/assets/images/advice-icons/odaberite-adekvatnu-lokaciju.png",
    },
    {
      title: t`Snimite svoju CV priču`,
      description: t`Zahvaljujući širokoj upotrebi naprednih tehnologija, video možete snimiti i sa mobilnog telefona pri čemu je potrebno voditi računa o kvalitetu slike i zvuka.`,
      long_description: t`Za snimanje CV priče birajte kvalitetne uređaje, visoke rezolucije. Ukoliko nemate kameru, dovoljan vam je noviji smartphone s mogućnošću snimanja videa i sa automatskim postavkama. Većina uređaja koji su danas u upotrebi omogućava vam da se snimate veoma lako, iz ruke, poput snimanja „selfija“. Ukoliko vam je lakše, pozicionirajte uređaj na odgovarajuću podlogu, a možete se poslužiti i stativom prilagođenim za držanje kamere ili mobilnog telefona. Ukoliko se vaš glas na snimku ne čuje dovoljno dobro, na tržištu se može naći pregršt pristupačnih mikrofona za pametne telefone ili kamere.`,
      icon: <Advice6 />,
      // icon: "/assets/images/advice-icons/snimite-svoju-cv-pricu.png",
    },
  ];

  const history = useHistory();

  const applyFilter = (filter) => {
    history.push(`/public/jobs?created_before=${filter}`);
  };

  return (
    <Fragment>
      <Hero
        video={t`file/videos/hero`}
        img={t`file/image/hero`}
        data={{
          title: [
            {
              text: t`TVOJA VIDEO`,
              class: "cv-color-white cv-fw-200",
              tag: "h2",
            },
            {
              text: t`BIOGRAFIJA`,
              class: "cv-color-secondary cv-fw-400",
              tag: "h1",
            },
          ],
          description: [
            t`Tvoj CV u video formi`,
            t`Jedinstvena prilika da ostaviš utisak`,
            t`Zaposli se`,
          ],
          actions: [
            <div className="custom-button">
              <Button
                component={Link}
                color="primary"
                variant="contained"
                size="large"
                to="/public/cv-story"
              >
                {t`Ispričajte svoju cv priču`}
              </Button>
              ,
            </div>,
          ],
        }}
      />
      <div>
        <Card
          color="default"
          image="/assets/images/card/white1.jpg"
          radius="0px 0px 50px 50px"
          style={{
            marginTop: -50,
            position: "relative",
            padding: 0,
          }}
        >
          <div className="cv-container cv-page-content">
            <Search searchCount={searchCount} />
            <div className="cv-home-search-filter">
              <div className="cv-home-search-filter-history"></div>
              <Select
                className="cv-home-search-filter-form"
                value={filter}
                onChange={(filter) => applyFilter(filter)}
                placeholder={t`Najnoviji oglasi`}
                options={[
                  { value: "0", label: t`Postavljeni danas` },
                  { value: "3", label: t`Postavljeni prije 3 dana` },
                  { value: "7", label: t`Postavljeni prije 7 dana` },
                  { value: "10", label: t`Postavljeni prije 10 dana` },
                ]}
              />
            </div>
            <div className="cv-home-companies">
              <CardTitle
                align="center"
                preTitle={t`POSLODAVCI`}
                title={
                  <>
                    <span className="secondary">{t`Istaknuti`}</span>{" "}
                    {t`poslodavci`}
                  </>
                }
              />
              {isLoadingEminentCompanies ? (
                <Spinner />
              ) : (
                <div className="cv-home-companies-eminent">
                  {companies.map((x, y) => (
                    <CompanyCard
                      id={x.id}
                      image={x.profile_image}
                      name={x.full_name}
                      adsCount={x.active_ads_count}
                      latestAd={x.latest_active_ad}
                      className="cv-home-companies-eminent-company"
                      createdAt={x.created_at}
                      slug={x.slug}
                      key={y}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Card>
        <Card
          color="secondary"
          radius={0}
          className="cv-huge-card-wrapper cv-advices-card"
        >
          <div className="cv-container cv-huge-card-inner cv-advices-wrapper">
            <CardTitle
              baseColor="#fff"
              align="center"
              title={
                <>
                  <span className="primary">{t`Savjeti`}</span>{" "}
                  {t`za snimanje tvoje priče`}
                </>
              }
            />
            <div className="cv-advices-row">
              {advices.map((x, y) => (
                <Advice
                  key={y}
                  icon={x.icon}
                  number={y + 1}
                  title={x.title}
                  description={x.description}
                  long_description={x.long_description}
                />
              ))}
            </div>
            <div className="cv-advices-wrapper-actions">
              <Button
                color="primary"
                variant="contained"
                size="large"
                component={Link}
                to="/public/cv-story"
              >
                {t`SNIMITE SVOJU CV PRIČU`}
              </Button>
            </div>
          </div>
        </Card>
        <Card
          color="default"
          image="/assets/images/card/white2.jpg"
          radius="50px 50px 0px 0px"
          style={{
            marginBottom: -50,
            padding: "0px 0px 100px",
            position: "relative",
          }}
        >
          <div className="cv-container cv-page-content">
            <div className="cv-home-blog-wrapper">
              <CardTitle
                className="cv-home-blog-card-title"
                preTitle={t`BLOG`}
                title={
                  <>
                    <span className="secondary">{t`Najnovije`}</span>{" "}
                    {t`sa bloga`}
                  </>
                }
              />
              <Button
                color="primary"
                variant="contained"
                size="large"
                component={Link}
                to="/public/blog"
              >
                {t`POGLEDAJTE SVE`}
              </Button>
              <div className="cv-home-blog-all">
                <BlogCard
                  direction={
                    width > 900
                      ? "vertical"
                      : width <= 600
                      ? "vertical"
                      : "horizontal"
                  }
                  type={1}
                  created_at={BlogPosts.advices[0].date}
                  image={BlogPosts.advices[0].image}
                  title={BlogPosts.advices[0].title}
                  description={BlogPosts.advices[0].short_description}
                  to={BlogPosts.advices[0].link}
                />
                <BlogCard
                  type={2}
                  direction={width <= 600 ? "vertical" : "horizontal"}
                  created_at={BlogPosts.news[1].date}
                  image={BlogPosts.news[1].image}
                  title={BlogPosts.news[1].title}
                  description={BlogPosts.news[1].short_description}
                  to={BlogPosts.news[1].link}
                />
                <BlogCard
                  type={2}
                  direction={width <= 600 ? "vertical" : "horizontal"}
                  created_at={BlogPosts.news[2].date}
                  image={BlogPosts.news[2].image}
                  title={BlogPosts.news[2].title}
                  description={BlogPosts.news[2].short_description}
                  to={BlogPosts.news[2].link}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

export default Home;
