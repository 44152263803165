import React from 'react';
import { statusObject } from '../../../constants/selects';
import './style.scss';

//   1: "Aktivan",
//   2: "Neaktivan",
//   3: "Arhiviran",
//   4: "Neobjavljen",
//   5: "Nacrt",
//   6: "Istekao",
  
const Status = ({status = 1}) => {
    return <div className={`cv-status-main cv-status-${status}`}>
        { statusObject[status] }
    </div>
}

export default Status;