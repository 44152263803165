import { ArrowBack, Delete, Edit, Visibility } from "@mui/icons-material";
import { Checkbox, IconButton } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { CandidateAPI, AuthAPI } from "../../../api/v2";
import EmailWarningIcon from "../../../assets/svg/icons/EmailWarningIcon";
import MobileWarningIcon from "../../../assets/svg/icons/MobileWarningIcon";
import { useLanguageContext } from "../../../context/language";
import {
  DashTitle,
  DataDisplay,
  UploadImage,
  Modal,
  Progress,
} from "../../../components/custom";
import VerificationCard from "../../../components/custom/verification-card";
import { SimpleCard, Button } from "../../../components/ui";
import VerificationMobileModal from "../../../components/ui/verification-mobile-modal";
import { useUserContext } from "../../../context/user";
import jsFileDownload from "js-file-download";
import mime from "mime-types";
import UserImage from '../../../assets/images/nouser5.png';
import { v4 as uuidv4 } from "uuid";
import "./style.scss";
import {
  AdminDatepicker,
  AdminInput,
  AdminSelect,
} from "../../../components/admin";

const Profile = () => {
  const [state, setState] = useState({});
  const [open, setOpen] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);

  const [educationOpen, setEducationOpen] = useState(false);
  const [workExpOpen, setWorkExpOpen] = useState(false);
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [computerOpen, setComputerOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [drivingOpen, setDrivingOpen] = useState(false);

  const getCandidateData = async () => {
    const candidate = await CandidateAPI.candidateInfo();
    setState(candidate);
  };

  useEffect(() => {
    getCandidateData();
  }, []);

  const deactivateProfile = () => {
    setOpen(false);
  };

  const { user, setUser } = useUserContext();

  const uploadProfileImage = async (files) => {
    try {
      const { profile_image } = await CandidateAPI.updateProfileImage(files[0]);
      setState({ ...state, profile_image });
      setUser({ ...user, profile_image });
      toast.success(t`Profilna slika je uspešno promenjena`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  const sendVerification = async () => {
    try {
      await AuthAPI.verify();
      toast.success(
        t`Verifikacioni email je uspešno poslat. Proverite vaš email.`
      );
    } catch (e) {
      toast.error(t`Došlo je do greške na serveru.`);
    }
  };

  const { t } = useLanguageContext();

  const sendOtp = async () => {
    if (user.phone) {
      setVerificationModal(true);
    } else {
      toast.error(t`Popunite broj telefona.`);
    }
  };

  const downloadVideo = async () => {
    const res = await CandidateAPI.downloadCvVideo();
    const contentType = res.headers["content-type"];
    const extension = mime.extension(contentType);
    jsFileDownload(res.data, `${uuidv4()}.${extension}`);
  };

  const downloadDocument = async () => {
    const res = await CandidateAPI.downloadCvDocument();
    const contentType = res.headers["content-type"];
    const extension = mime.extension(contentType);
    jsFileDownload(res.data, `${uuidv4()}.${extension}`);
  };
  const setProfileCompletely = (profile_completely) =>
    setState({ ...state, profile_completely });

   // Convert file to blob
  async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }
  // End convert

  const deleteProfileImage = async () => {
    try{
      const image = await getFileFromUrl(UserImage);
      const { profile_image } = await CandidateAPI.updateProfileImage(image);
      setState({ ...state, profile_image });
      setUser({ ...user, profile_image });
      toast.success(t`Profilna slika je uspešno izbrisana`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  }

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <div className="cv-dashboard-page-header">
          <DashTitle
            title={t("Moj CV profil")}
            subtitle={t(
              "Popunite sve informacije prije konkurisanja na oglase"
            )}
            actions={[
              <Button
                color="error"
                variant="contained"
                startIcon={<Delete />}
                size="large"
                onClick={() => setOpen(true)}
              >
                {t("DEAKTIVIRAJ PROFIL")}
              </Button>,
            ]}
          />
        </div>
        <div className="cv-dashboard-profile-verification-wrap">
          {!user.is_email_verified ? (
            <VerificationCard
              title={t("Molimo da verifikujete Vašu email adresu") + ","}
              subtitle={t("kako bi ste mogli konkurisati na oglase") + "."}
              icon="Email"
              onClick={sendVerification}
              duration={60}
              buttonLabel={t("Verifikuj")}
            />
          ) : null}
          {!user.is_phone_verified && user.phone ? (
            <VerificationCard
              title={t("Molimo da verifikujete Vaš broj telefona" + ",")}
              subtitle={t("kako bi ste mogli konkurisati na oglase" + ".")}
              icon="Phone"
              onClick={sendOtp}
              duration={60}
              buttonLabel={t("Verifikuj")}
            />
          ) : null}
        </div>
        <div className="cv-dashboard-profile-main cv-dashboard-order-content">
          <SimpleCard
            className="cv-dashboard-profile-card"
            title={t("Kontakt informacije")}
            withContent
          >
            <div className="cv-dashboard-profile-grid">
              <DataDisplay
                className="cv-dashboard-profile-col-double"
                label={t("Ime i prezime")}
                value={state.full_name}
                required
              />
              <DataDisplay label="Pol" value={state.gender?.name} required />
              <DataDisplay
                label={t("Datum rođenja")}
                value={
                  state.birth_year
                    ? moment(state.birth_year).format("DD.MM.YYYY.")
                    : "/"
                }
                required
              />
              <DataDisplay
                label={t("Nivo obrazovanja")}
                value={state.education_level?.name}
                required
              />
              <DataDisplay
                label={t("Email")}
                value={state.email}
                // isWarning={!user.is_email_verified}
                isVerified={user.is_email_verified}
                required
              />
              <DataDisplay
                label={t("Država")}
                value={state.country?.name}
                required
              />
              <DataDisplay label="Grad" value={state.city?.name} required />
              <DataDisplay
                className="cv-dashboard-profile-col-double"
                label={t("Ulica i broj prebivališta")}
                value={state.address}
                required
              />
              <DataDisplay
                label={t("Kontakt telefon")}
                value={state.phone}
                // isWarning={!user.is_phone_verified}
                isVerified={user.is_phone_verified}
                required
              />
              <DataDisplay
                label={t("Poštanski broj")}
                value={state.zip_code}
                required
              />
              <DataDisplay
                className="cv-dashboard-profile-col-double"
                label={t("Popunjenost profila")}
                value={
                  <Progress
                    value={state.profile_completely}
                    className="cv-dashboard-dashboard-progress"
                    circleClassName="cv-dashboard-dashboard-progress-circle"
                    thickness={4}
                  />
                }
                required
              />
            </div>
          </SimpleCard>
          <SimpleCard
            className="cv-dashboard-profile-card"
            title={t("Moja biografija")}
            withContent
          >
            <UploadImage
              src={state.profile_image}
              name={state.full_name}
              className="cv-dashboard-profile-upload"
              onUpload={uploadProfileImage}
              onDelete={deleteProfileImage}
            />
            <div className="cv-dashboard-profile-grid">
              <DataDisplay
                className="cv-dashboard-profile-col-double"
                label={t("Obrazovanje")}
                value={state.educations?.map((x) => x.title).join(", ")}
                required
                action={
                  state.educations?.length ? (
                    <IconButton onClick={() => setEducationOpen(true)}>
                      <Visibility />
                    </IconButton>
                  ) : null
                }
              />
              <DataDisplay
                label={t("Radno iskustvo")}
                value={
                  state.work_experiences?.length
                    ? state.work_experiences
                        ?.map((x) => x.company_name)
                        .join(", ")
                    : t("Bez radnog iskustva")
                }
                action={
                  state.work_experiences?.length ? (
                    <IconButton onClick={() => setWorkExpOpen(true)}>
                      <Visibility />
                    </IconButton>
                  ) : null
                }
              />
              <DataDisplay
                label={t("Strani jezici")}
                value={state.languages?.map((x) => x.languages_name).join(", ")}
                action={
                  state.languages?.length ? (
                    <IconButton onClick={() => setLanguagesOpen(true)}>
                      <Visibility />
                    </IconButton>
                  ) : null
                }
              />
              <DataDisplay
                className="cv-dashboard-profile-col-double"
                label={t("Rad na računaru")}
                value={state.computer_skills
                  ?.map((x) => x.computer_skill_name.name)
                  .join(", ")}
                action={
                  state.computer_skills?.length ? (
                    <IconButton onClick={() => setComputerOpen(true)}>
                      <Visibility />
                    </IconButton>
                  ) : null
                }
              />
              <DataDisplay
                label="CV dokument"
                className="cv-dashboard-profile-col-double cv-dashboard-profile-col-double-doc"
                value={
                  state.cv_document?.document_name ? (
                    <IconButton
                      className="cv-dashboard-profile-link"
                      onClick={downloadDocument}
                    >
                      {/* {state.cv_document.document_name} */}
                      <svg height="24px" width="24px" viewBox="0 0 384 512">
                        <path
                          fill="#353535"
                          d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                        ></path>
                      </svg>
                    </IconButton>
                  ) : (
                    "/"
                  )
                }
              />
              <DataDisplay
                label="CV video"
                className="cv-dashboard-profile-col-double cv-dashboard-profile-col-double-video"
                value={
                  state.cv_video?.video ? (
                    <IconButton
                      className="cv-dashboard-profile-link"
                      onClick={downloadVideo}
                    >
                      <svg height="24px" width="24px" viewBox="0 0 640 512">
                        <path
                          fill="#353535"
                          d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"
                        ></path>
                      </svg>
                      {/* {state.cv_video.video} */}
                    </IconButton>
                  ) : (
                    "/"
                  )
                }
              />
              <DataDisplay
                label={t("Ukratko o meni")}
                value={state.aditional_info}
                action={
                  state.aditional_info ? (
                    <IconButton onClick={() => setAboutOpen(true)}>
                      <Visibility />
                    </IconButton>
                  ) : null
                }
              />
              <DataDisplay
                label={t("Vozačka dozvola")}
                value={state.driver_licences
                  ?.map((x) => x.drivers_licence_category.name)
                  .join(", ")}
                action={
                  state.driver_licences?.length ? (
                    <IconButton onClick={() => setDrivingOpen(true)}>
                      <Visibility />
                    </IconButton>
                  ) : null
                }
              />
              <Button
                color="secondary"
                variant="contained"
                size="large"
                className="cv-dashboard-profile-button-right cv-dashboard-profile-button-right-video-cv"
                component={Link}
                to="/dashboard/cv-profile/video"
              >
                VIDEO CV
              </Button>
            </div>
          </SimpleCard>
          <Button
                color="default"
                variant="contained"
                size="large"
                startIcon={<Edit />}
                className="cv-dashboard-profile-button-right cv-dashboard-profile-button-custom"
                component={Link}
                to="/dashboard/cv-profile/edit"
              >
                {t("IZMJENI")}
              </Button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={t("DEAKTIVIRAJ")}
        actions={[
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={deactivateProfile}
          >
            DA
          </Button>,
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setOpen(false)}
          >
            NE
          </Button>,
        ]}
        size="medium"
      >
        <div className="cv-profile-modal-text">
          {"Da li ste sigurni da želite deaktivirati nalog" + "?"}
        </div>
        <div className="cv-profile-modal-text-error">
          {t(
            "Pažnja: nakon potvrde deaktivacije, nalog će biti obrisan u roku od 30 dana. U slučaju da opet pristupite prije isteka roka, nalog će biti ponovo aktiviran."
          )}
        </div>
      </Modal>
      <VerificationMobileModal
        open={verificationModal}
        onClose={() => setVerificationModal(false)}
        setProfileCompletely={setProfileCompletely}
      />
      <EducationsModal
        edu={state.educations}
        open={educationOpen}
        onClose={() => setEducationOpen(false)}
        state={state}
        setState={setState}
      />
      <WorkExpModal
        workexps={state.work_experiences}
        open={workExpOpen}
        onClose={() => setWorkExpOpen(false)}
        state={state}
        setState={setState}
      />
      <LanguagesModal
        langs={state.languages}
        open={languagesOpen}
        onClose={() => setLanguagesOpen(false)}
        state={state}
        setState={setState}
      />
      <ComputerSkillsModal
        skills={state.computer_skills}
        open={computerOpen}
        onClose={() => setComputerOpen(false)}
        state={state}
        setState={setState}
      />
      <OtherModal
        desc={state.aditional_info}
        open={aboutOpen}
        onClose={() => setAboutOpen(false)}
        state={state}
        setState={setState}
      />
      <DrivingLicenceModal
        licence={state.driver_licences}
        open={drivingOpen}
        onClose={() => setDrivingOpen(false)}
        state={state}
        setState={setState}
      />
    </Fragment>
  );
};

const EducationsModal = ({ edu, open, onClose, ...parent }) => {
  const [education, setEducation] = useState(null);

  const { addings } = useUserContext();

  const [educationLevels, setEducationLevels] = useState([]);
  const [educationAreas, setEducationAreas] = useState([]);

  useEffect(() => {
    setEducationLevels(
      addings.education_levels.map((x) => ({ value: x.id, label: x.name }))
    );
    setEducationAreas(
      addings.education_areas.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const { t } = useLanguageContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t`OBRAZOVANJE`}
      size="medium"
      actions={
        education ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setEducation(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!education ? (
          <div className="cv-dashboard-profile-preview-main">
            {edu?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Obrazovanje")} ${y + 1}`}
                value={x.title}
                onClick={() => setEducation(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Nivo obrazovanja`}
              value={
                educationLevels.find(
                  (x) => x.value === education.education_level_id
                )?.label
              }
            />
            <DataDisplay
              label={t`Oblast obrazovanja`}
              value={
                educationAreas.find(
                  (x) => x.value === education.education_area_id
                )?.label
              }
            />
            <DataDisplay label={t`Stečeno zvanje`} value={education.title} />
            <DataDisplay
              label={t`Naziv institucije`}
              value={education.institution}
            />
            <DataDisplay label={t`Grad`} value={education.city} />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Smjer`}
              value={education.course}
            />
            <DataDisplay
              label={t`Datum upisa`}
              value={moment(education.start_date).format("DD.MM.YYYY")}
            />
            <DataDisplay
              label={t`Datum diplomiranja`}
              value={
                education.ongoing
                  ? t`Još uvjek u toku`
                  : moment(education.end_date).format("DD.MM.YYYY")
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const WorkExpModal = ({ workexps, open, onClose, ...parent }) => {
  const [workExp, setWorkExp] = useState(null);

  const { addings } = useUserContext();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(
      addings.type_of_works.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="RADNO ISKUSTVO"
      size="medium"
      actions={
        workExp ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setWorkExp(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!workExp ? (
          <div className="cv-dashboard-profile-preview-main">
            {workexps?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Kompanija")} ${y + 1}`}
                value={x.company_name}
                onClick={() => setWorkExp(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay label={t`Kompanija`} value={workExp.company_name} />
            <DataDisplay label={t`Lokacija`} value={workExp.location} />
            <DataDisplay label={t`Radna pozicija`} value={workExp.position} />
            <DataDisplay
              label={t`Kategorija posla`}
              value={
                categories.find((x) => x.value === workExp.type_of_work_id)
                  ?.label
              }
            />
            <DataDisplay
              label={t`Datum zaposlenja`}
              value={moment(workExp.start_date).format("DD.MM.YYYY")}
            />
            <DataDisplay
              label={t`Datum prestanka radnog odnosa`}
              value={
                workExp.ongoing
                  ? t`Još uvjek zaposlen`
                  : moment(workExp.end_date).format("DD.MM.YYYY")
              }
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Opis pozicije`}
              value={workExp.description}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const LanguagesModal = ({ langs, open, onClose, ...parent }) => {
  const [language, setLanguage] = useState(null);

  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("STRANI JEZICI")}
      size="medium"
      actions={
        language ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setLanguage(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!language ? (
          <div className="cv-dashboard-profile-preview-main">
            {langs?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Jezik")} ${y + 1}`}
                value={x.languages_name}
                onClick={() => setLanguage(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Jezik`}
              value={language.languages_name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Čitanje`}
              value={language.language_reads_name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Pisanje`}
              value={language.language_writes_name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Govor`}
              value={language.language_speaks_name}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const ComputerSkillsModal = ({ skills, open, onClose, ...parent }) => {
  const [computerSkill, setComputerSkill] = useState(null);

  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="RAD NA RAČUNARU"
      size="medium"
      actions={
        computerSkill ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setComputerSkill(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!computerSkill ? (
          <div className="cv-dashboard-profile-preview-main">
            {skills?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Vještina")} ${y + 1}`}
                value={x.computer_skill_name.name}
                onClick={() => setComputerSkill(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Vještina`}
              value={computerSkill.computer_skill_name.name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Nivo znanja`}
              value={computerSkill.computer_skill_knowledge_level.name}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const OtherModal = ({ desc, open, onClose, ...parent }) => {
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("OSTALO")}
        size="medium"
        actions={
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {"ZATVORI"}
          </Button>
        }
      >
        <div className="cv-dashboard-profile-preview-main ">
          <DataDisplay
            label={t`Ukratko o meni`}
            value={
              <div className="cv-dashboard-companyprofile-normal">{desc}</div>
            }
          />
        </div>
      </Modal>
    </Fragment>
  );
};

const DrivingLicenceModal = ({ licence, open, onClose, ...parent }) => {

  const { addings } = useUserContext();

  const [drivingCategories, setDrivingCategories] = useState([]);

  useEffect(() => {
    setDrivingCategories(
      addings.drivers_licence_categories.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
  }, []);

  const { t } = useLanguageContext();

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("VOZAČKA DOZVOLA")}
        size="medium"
        actions={
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {"ZATVORI"}
          </Button>
        }
      >
        <div className="cv-dashboard-profile-preview-main cv-grid-2">
          <DataDisplay label={t`Vozačka dozvola`} value={licence ? licence[0]?.drivers_licence_category.name : null} />
          <DataDisplay
            label={t`Sopstveno vozilo`}
            value={licence ? (licence[0]?.own_vehicle ? 'Da' : 'Ne') : null}
          />
          <DataDisplay
            className="cv-grid-col-2"
            label={t`Dodatne informacije`}
            value={
              <div className="cv-dashboard-companyprofile-normal">
                {licence ? licence[0]?.additional_info : "/"}
              </div>
            }
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default Profile;
