import React, { Fragment, useState } from "react";
import Hero from "../../components/custom/hero";
import { CardTitle, Card, Advice } from "../../components/custom";
import { Button, SimpleCard } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import {
  BusinessCenter,
  Check,
  Close,
  StorageRounded,
} from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from "@mui/material";
import { Box } from "@mui/system";
import { useUserContext } from "../../context/user";
import { TabPanel } from "../job-single/public";
import { Link } from "react-router-dom";
import "./style.scss";
import { Company1, Company2, Company3, Company4 } from "../../assets/svg";
import { useEventContext } from "../../context/event";

const ForCompanies = () => {
  const [tab, setTab] = useState(0);

  const [pricing, setPricing] = useState(0);
  const [sPackage, setSPackage] = useState(0);
  const [jobNum, setJobNum] = useState(0);

  const { packages } = useUserContext();

  const { t } = useLanguageContext();

  const Benefits = [
    t`Neograničen tekst oglasa`,
    t`Broj gradova u oglasu`,
    t`Objava oglasa u državi`,
    t`Isticanje u rezultatima pretrage`,
    t`Logo u oglasu i rezultatima pretrage`,
    t`Vaš link za konkurisanje`,
    t`Opis u rezultatima pretrage`,
    //t`Prioritet u postavci oglasa do 24h`,
    t`Čuvanje nedovršenog/započetog oglasa`,
    t`Statistika oglasa`,
    t`Razgledanje kandidata u video formatu`,
    t`Obavještenja o novim prijavama`,
    t`Izdvajanje kandidata u posebnu listu`,
    //t`Čuvanje bilješki o kandidatu`,
    t`Slanje i primanje direktnih poruka kandidata i uvid u istoriju prepiske`,
    t`Postavljanje posebnih pitanja na koje kandidat mora odgovoriti u video formatu`,
    t`Zakazivanje video intervjua`,
    t`Postavljanje koorporativnog videa`,
    t`Prezentacija u sekciji za istaknute poslodavce`,
  ];

  const { width } = useEventContext();

  const advices = [
    {
      title: t`Bolja slika o kandidatima`,
      description: t`Zahvaljujući jedinstvenom konceptu na sajtu CV priča, poslodavac stiče kompletniju sliku o kandidatima koji imaju mogućnost da CV snime u video formatu.`,
      long_description: t`Zahvaljujući jedinstvenom konceptu na sajtu CV priča, poslodavac stiče kompletniju sliku o kandidatima koji imaju mogućnost da CV snime u video formatu.`,
      icon: <Company1 />,
    },
    {
      title: t`Brži proces selekcije`,
      description: t`Video CV priča u kom kandidati prezentuju svoje radno iskustvo, vještine i znanje, može zamijeniti prvi razgovor za posao, a mogućnost postavljanja direktnih poruka na koje kandidati odgovaraju u video formatu, može zamijeniti i drugi krug intervjuisanja.`,
      long_description: t`Video CV priča u kom kandidati prezentuju svoje radno iskustvo, vještine i znanje, može zamijeniti prvi razgovor za posao, a mogućnost postavljanja direktnih poruka na koje kandidati odgovaraju u video formatu, može zamijeniti i drugi krug intervjuisanja.`,
      icon: <Company2 />,
    },
    {
      title: t`Oglasi za više gradova i država`,
      description: t`Ova platforma omogućava da se oglasi postavljaju u više gradova i država, što vam omogućava brzo pronalaženje radnika, bez obzira na njihovo mjesto prebivališta.`,
      long_description: t`Ova platforma omogućava da se oglasi postavljaju u više gradova i država, što vam omogućava brzo pronalaženje radnika, bez obzira na njihovo mjesto prebivališta.`,
      icon: <Company3 />,
    },
    {
      title: t`Izbor kandidata bilo kad i bilo gdje`,
      description: t`Izuzetna preglednost platforme pruža vam mogućnost da veoma lako postavljate oglase i vršite selekciju kandidata, kako sa računara, tako i sa mobilnih uređaja.`,
      long_description: t`Izuzetna preglednost platforme pruža vam mogućnost da veoma lako postavljate oglase i vršite selekciju kandidata, kako sa računara, tako i sa mobilnih uređaja.`,
      icon: <Company4 />,
    },
  ];
  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-for-companies.jpg" />
      <Card
        className="cv-for-companies-benefits-card"
        image="/assets/images/card/for-companies.jpg"
      >
        <div className="cv-container cv-for-companies-benefits-card-inner">
          <div className="cv-for-companies-benefits-card-content">
            <CardTitle
              title={
                <>
                  <span className="secondary">
                    <BusinessCenter className="cv-for-companies-benefits-title-icon" />
                    {t("Benefiti")}
                  </span>{" "}
                  {("platforme")}
                </>
              }
            />
            <div className="cv-for-companies-benefits-description">
              <div className="cv-for-companies-benefits-description-single">
                {t("Brojne benefite na platformi CV priča pripremili smo i za poslodavce. Zahvaljujući naprednim postavkama, do radnika možete doći brzo i lako, a naš sajt je i odlična prilika da na pravi način istaknete i prezentujete svoje poslovanje.")}
              </div>
              <div className="cv-for-companies-benefits-description-single">
                {t("Direktno predstavljanje kandidata kroz video CV smanjiće vrijeme intervjuisanja u realnom vremenu i zamijeniti prvi krug intervjuisanja. Takođe, mogućnost postavljanja dodatnih direktnih pitanja na koje kandidati odgovaraju u video formi, može zamijeniti drugi krug intervjuisanja. Opcija zakazivanja video intervjua pruža uštedu vremena i troškova organizovanja razgovora uživo i predstavlja prednost u uslovima kada je intervju licem u lice onemogućen.")}
              </div>
              <div className="cv-for-companies-benefits-description-single">
                {t("Oglase možete postavljati u više različitih država i gradova, što predstavlja jedinstvenu priliku u regionu. Pritom, obezbijeđen je prioritet u postavci oglasa, odnosno od momenta objavljivanja, oglas će biti istaknut na početnoj strani narednih 14h. Stranica je jednako pregledna na računarima i na telefonima tako da selekciju kandidata možete vršiti bilo kada i bilo gdje, čak i sa mobilnih uređaja.")}
              </div>
              <div className="cv-for-companies-benefits-description-single">
                {t("U okviru platforme poslodavcima je omogućeno kreiranje svoje stranice poput pravog veb sajta, na kom će biti istaknute osnovne informacije o firmi, naslovna fotografija i logo, lista postavljenih oglasa, blog, galerija i kontakt. Još jedna od prednosti našeg sajta je što nudi mogućnost da, osim u tekstualnoj formi, firmu možete predstaviti putem fotografije ili video snimka.")}
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card
        color="secondary"
        radius={0}
        className="cv-huge-card-wrapper cv-advices-card cv-for-companies-benefits-card-advices"
      >
        <div className="cv-container cv-huge-card-inner cv-advices-wrapper">
          <CardTitle
            baseColor="#fff"
            align="center"
            title={
              <>
                <span className="primary">{t`Benefiti`}</span>{" "}
                {t`za poslodavce`}
              </>
            }
          />
          <div className="cv-advices-row cv-job-advices-row">
            {advices?.map((x, y) => (
              <Advice
                key={y}
                icon={x.icon}
                number={y + 1}
                title={x.title}
                description={x.description}
                long_description={x.long_description}
              />
            ))}
          </div>
        </div>
      </Card>
      <div className="cv-container cv-for-companies-main">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
          className="box-title-tabs"
        >
          <CardTitle
            postTitle={t("Odaberite oglas po vašoj mjeri. Uporedite vrste oglasa i oglasite se na najposećenijem sajtu za traženje posla na Balkanu") + ".."}
            title={
              <>
                <span className="secondary">{t("Cjenovnik oglasa")} </span>
                {t("za poslodavce")}
              </>
            }
          />
          <Tabs
            className="cv-jobsingle-tabs cv-packages-tabs"
            textColor="secondary"
            indicatorColor="secondary"
            value={tab}
            onChange={(a, b) => setTab(b)}
            aria-label="basic tabs example"
          >
            <Tab label={t("CJENOVNIK OGLASA")} />
            <Tab label={t("CJENOVNIK PAKETA")} />
          </Tabs>
        </Box>

        <TabPanel value={tab} index={0}>

          {(() => {
            if (width > 991) {
              return (

                <div className="cv-dashboard-packages-content cv-dashboard-packages-content-jobs">
                  <div className="cv-dashboard-packages-benefits">
                    <div className="cv-dashboard-packages-cell cv-dashboard-packages-package-title">
                      {t("BENEFITI")}
                    </div>
                    {Benefits?.map((x, y) => (
                      <div className="cv-dashboard-packages-cell" key={y}>
                        {x}
                      </div>
                    ))}
                  </div>
                  {packages?.map((x, y) => (
                    <SimpleCard className="cv-dashboard-packages-package" key={y}>
                      <div className="cv-dashboard-packages-cell cv-dashboard-packages-package-title">
                        {x.name}
                      </div>
                      {Benefits?.map((a, b) => (
                        <div className="cv-dashboard-packages-cell" key={b}>
                          {typeof x.benefits[a] === "boolean" ? (
                            x.benefits[a] ? (
                              <Check color="success" />
                            ) : (
                              <Close color="error" />
                            )
                          ) : (
                            x.benefits[a]
                          )}
                        </div>
                      ))}
                      <div className="cv-dashboard-packages-cell cv-dashboard-packages-package-price">
                        {x.pricing[pricing].price}€
                      </div>
                    </SimpleCard>
                  ))}
                  <div className="cv-dashboard-packages-period">
                    <div className="cv-dashboard-packages-period-title">
                      {t("Izaberite period oglašavanja")}
                    </div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="period"
                        name="row-radio-buttons-group"
                        value={pricing}
                        onChange={(a, b) => setPricing(b)}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label={t("15 dana")}
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label={t("30 dana")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <SimpleCard className="cv-dashboard-packages-register">
                    <div className="cv-dashboard-packages-register-icon">
                      <StorageRounded />
                    </div>
                    <div className="cv-dashboard-packages-register-text">
                      <div className="cv-dashboard-packages-register-title">
                        <span>{("Besplatno")}</span> {("uz registraciju")}
                      </div>
                      <div className="cv-dashboard-packages-register-description">
                        <span>{("Besplatno neograničeno korišćenje platforme")}</span> {t("do 30.06.2022. godine.")}
                      </div>
                    </div>
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="cv-dashboard-packages-register-button"
                      component={Link}
                      to="/public/login/company"
                    >
                      {t("REGISTRUJTE SE")}
                    </Button>
                  </SimpleCard>
                </div>

              )
            } else {
              return (

                <div className="cv-dashboard-packages-content-jobs-mobile">
                  <div className="cv-dashboard-packages-period">
                    <div className="cv-dashboard-packages-period-title">
                      {t("Izaberite period oglašavanja")}
                    </div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="period"
                        name="row-radio-buttons-group"
                        value={pricing}
                        onChange={(a, b) => setPricing(b)}
                        className="cv-dashboard-radio-group"
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label={t("15 dana")}
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label={t("30 dana")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {packages?.map((x, y) => (
                    <SimpleCard className="cv-dashboard-packages-package" key={y}>
                      <div className="cv-dashboard-packages-package-title">
                        {x.name}
                      </div>
                      <div className="cv-dashboard-packages-package-price">
                        {x.pricing[pricing].price}€
                      </div>
                      <div className="cv-dashboard-packages-package-period-text">
                        {!pricing ? (
                          t("U periodu od 15 dana od dana aktivacije.")
                        ) : (
                          pricing === "0" ? (
                            t("U periodu od 15 dana od dana aktivacije.")
                          ) : (
                            t("U periodu od 30 dana od dana aktivacije.")
                          )
                        )}
                      </div>
                      {/* <div className="cv-dashboard-packages-choose-package">
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                        >
                          Izaberite
                        </Button>
                      </div> */}
                      <details className="benefits-dropdown">
                        <summary>
                          {t("Pogledajte karakteristike")}
                          <svg height="18px" width="18px" viewBox="0 0 320 512"><path fill="black" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
                        </summary>
                        {Benefits?.map((a, b) => (
                          <div className="cv-dashboard-packages-cell cv-dashboard-packages-cell-mobile" key={b}>
                            {typeof x.benefits[a] === "boolean" ? (
                              x.benefits[a] ? (
                                <div className="benefits-group">
                                  <div className="benefit-desc">
                                    {a}
                                  </div>
                                  <Check color="success" />
                                </div>
                              ) : (
                                <div className="benefits-group">
                                  <div className="benefit-desc">
                                    {a}
                                  </div>
                                  < Close color="error" />
                                </div>
                              )
                            ) : (
                              <div className="benefits-group">
                                <div className="benefit-desc">
                                  {a}
                                </div>
                                <div className="benefit-val">
                                  {x.benefits[a]}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                      </details>
                    </SimpleCard>
                  ))}

                  <SimpleCard className="cv-dashboard-packages-register">
                    <div className="cv-dashboard-packages-register-text">
                      <div className="cv-dashboard-packages-register-title">
                        <span>{t("Besplatno")}</span> {("uz registraciju")}
                      </div>
                      <div className="cv-dashboard-packages-register-description">
                        <span>{t("Besplatno neograničeno korišćenje platforme")}</span><br /> {t("do 30.06.2022.godine.")}
                      </div>
                    </div>
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="cv-dashboard-packages-register-button"
                      component={Link}
                      to="/public/login/company"
                    >
                      {t("REGISTRUJTE SE")}
                    </Button>
                  </SimpleCard>
                </div>

              )
            }
          })()}

        </TabPanel>

        <TabPanel value={tab} index={1}>

          {(() => {
            if (width > 991) {
              return (

                <div className="cv-dashboard-packages-content">
                  <div className="cv-dashboard-packages-content-select-package">
                    {t("Izaberite paket") + ':'}
                  </div>
                  <FormControl
                    component="fieldset"
                    className="cv-dashboard-packages-content-select-fieldset"
                  >
                    <RadioGroup
                      row
                      aria-label="period"
                      name="row-radio-buttons-group"
                      value={sPackage}
                      onChange={(a, b) => setSPackage(b)}
                      className="cv-dashboard-packages-content-select-all"
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio color="secondary" />}
                        label="STARTER"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio color="secondary" />}
                        label="BASIC"
                      />
                      <FormControlLabel
                        value={2}
                        control={<Radio color="secondary" />}
                        label="PLUS"
                      />
                      <FormControlLabel
                        value={3}
                        control={<Radio color="secondary" />}
                        label="PRO"
                      />
                      <FormControlLabel
                        value={4}
                        control={<Radio color="secondary" />}
                        label="ENTERPRISE"
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className="cv-dashboard-packages-content-sides">
                    <SimpleCard className="cv-dashboard-packages-content-left">
                      <div className="cv-dashboard-packages-content-left-row">
                        {t("BENEFITI")}
                      </div>
                      {Benefits?.map((x, y) => (
                        <div
                          key={y}
                          className="cv-dashboard-packages-content-left-row"
                        >
                          <div className="cv-dashboard-packages-content-left-row-label">
                            {x}
                          </div>
                          <div className="cv-dashboard-packages-content-left-row-value">
                            {typeof packages[sPackage].benefits[x] === "boolean" ? (
                              packages[sPackage].benefits[x] ? (
                                <Check color="success" />
                              ) : (
                                <Close color="error" />
                              )
                            ) : (
                              packages[sPackage].benefits[x]
                            )}
                          </div>
                        </div>
                      ))}
                    </SimpleCard>
                    <div className="cv-dashboard-packages-content-right">
                      <SimpleCard className="cv-dashboard-packages-content-card">
                        <div className="cv-dashboard-packages-period-right">
                          <div className="cv-dashboard-packages-period-title">
                            {t("Izaberite period oglašavanja")}
                          </div>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="period"
                              name="row-radio-buttons-group"
                              value={pricing}
                              onChange={(a, b) => setPricing(b)}
                            >
                              <FormControlLabel
                                value={0}
                                control={<Radio color="secondary" />}
                                label={t("15 dana")}
                              />
                              <FormControlLabel
                                value={1}
                                control={<Radio color="secondary" />}
                                label={t("30 dana")}
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="cv-dashboard-packages-discounts">
                          <div className="cv-dashboard-packages-period-title">
                            {t("Broj oglasa u paketu:")}
                          </div>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              aria-label="period"
                              name="row-radio-buttons-group"
                              value={jobNum}
                              onChange={(a, b) => setJobNum(b)}
                              className="cv-dashboard-packages-period-formcontrols"
                            >
                              {packages[sPackage].discounts?.map((x, y) => (
                                <FormControlLabel
                                  value={y}
                                  control={<Radio color="secondary" />}
                                  label={`${x.title} (${x.amount}% popusta)`}
                                />
                              ))}
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <div className="cv-dashboard-packages-period-result">
                          <div className="cv-dashboard-packages-period-result-left">
                            {t("Izabrali ste do")}{" "}
                            <span>
                              {packages[sPackage].discounts[jobNum].ad_count} {t("oglasa godišnje")}
                              ({packages[sPackage].discounts[jobNum].amount} {'%' + t("popusta") + ")" + t("iz")}
                              <span>{packages[sPackage].name}</span>
                            </span>
                          </div>
                          <div className="cv-dashboard-packages-period-result-right">
                            {t("Ukupna cijena") + ':'}
                            <span>
                              {
                                packages[sPackage].pricing[pricing].discount_prices[
                                  jobNum
                                ].discounted_price
                              }
                              €
                            </span>
                          </div>
                        </div>
                      </SimpleCard>
                      <SimpleCard className="cv-dashboard-packages-register">
                        <div className="cv-dashboard-packages-register-icon">
                          <StorageRounded />
                        </div>
                        <div className="cv-dashboard-packages-register-text">
                          <div className="cv-dashboard-packages-register-title">
                            <span>{t("Besplatno")}</span> {t("uz registraciju")}
                          </div>
                          <div className="cv-dashboard-packages-register-description">
                            <span>{t("Besplatno neograničeno korišćenje platforme")}</span>{" "}
                            {t("do 30.06.2022. godine.")}
                          </div>
                        </div>
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                          className="cv-dashboard-packages-register-button"
                          component={Link}
                          to="/public/login/company"
                        >
                          {("REGISTRUJTE SE")}
                        </Button>
                      </SimpleCard>
                    </div>
                  </div>
                </div>

              )
            } else {
              return (


                <div className="cv-dashboard-packages-content-jobs-mobile">
                  <div className="cv-dashboard-packages-period">
                    <div className="cv-dashboard-packages-period-title">
                      {t("Izaberite period oglašavanja")}
                    </div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="period"
                        name="row-radio-buttons-group"
                        value={pricing}
                        onChange={(a, b) => setPricing(b)}
                        className="cv-dashboard-radio-group"
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio color="secondary" />}
                          label="15 dana"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio color="secondary" />}
                          label="30 dana"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className="cv-dashboard-packages-discounts-mobile">
                    <details className="benefits-dropdown" open>
                      <summary>
                        {t("Broj oglasa u paketu:")}
                        <svg height="18px" width="18px" viewBox="0 0 320 512"><path fill="black" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
                      </summary>
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          aria-label="period"
                          name="row-radio-buttons-group"
                          value={jobNum}
                          onChange={(a, b) => setJobNum(b)}
                          className="cv-dashboard-packages-period-formcontrols"
                        >
                          {packages[sPackage].discounts?.map((x, y) => (
                            <FormControlLabel
                              value={y}
                              control={<Radio color="secondary" />}
                              label={`${x.title} (${x.amount}% popusta)`}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </details>
                  </div>

                  {packages?.map((x, y) => (
                    <SimpleCard className="cv-dashboard-packages-package" key={y}>
                      <div className="cv-dashboard-packages-package-title">
                        {x.name}
                      </div>
                      <div className="cv-dashboard-packages-package-price">
                        {x.pricing[pricing].discount_prices[
                          jobNum
                        ].discounted_price
                        }€
                      </div>
                      <div className="cv-dashboard-packages-package-period-text">
                        {!pricing ? (
                          t("U periodu od 15 dana od dana aktivacije.")
                        ) : (
                          pricing === "0" ? (
                            t("U periodu od 15 dana od dana aktivacije.")
                          ) : (
                            t("U periodu od 30 dana od dana aktivacije.")
                          )
                        )}
                      </div>
                      {/* <div className="cv-dashboard-packages-choose-package">
                        <Button
                          color="secondary"
                          size="large"
                          variant="contained"
                        >
                          Izaberite
                        </Button>
                      </div> */}
                      <details className="benefits-dropdown">
                        <summary>
                          {("Pogledajte karakteristike")}
                          <svg height="18px" width="18px" viewBox="0 0 320 512"><path fill="black" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"></path></svg>
                        </summary>

                        {Benefits?.map((a, b) => (
                          <div className="cv-dashboard-packages-cell cv-dashboard-packages-cell-mobile" key={b}>
                            {typeof x.benefits[a] === "boolean" ? (
                              x.benefits[a] ? (
                                <div className="benefits-group">
                                  <div className="benefit-desc">
                                    {a}
                                  </div>
                                  <Check color="success" />
                                </div>
                              ) : (
                                <div className="benefits-group">
                                  <div className="benefit-desc">
                                    {a}
                                  </div>
                                  < Close color="error" />
                                </div>
                              )
                            ) : (
                              <div className="benefits-group">
                                <div className="benefit-desc">
                                  {a}
                                </div>
                                <div className="benefit-val">
                                  {x.benefits[a]}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}

                      </details>
                    </SimpleCard>
                  ))}

                  <SimpleCard className="cv-dashboard-packages-register">
                    <div className="cv-dashboard-packages-register-text">
                      <div className="cv-dashboard-packages-register-title">
                        <span>{t("Besplatno")}</span> {t("uz registraciju")}
                      </div>
                      <div className="cv-dashboard-packages-register-description">
                        <span>{t("Besplatno neograničeno korišćenje platforme")}</span><br /> {t("do 30.06.2022.godine.")}
                      </div>
                    </div>
                    <Button
                      color="secondary"
                      size="large"
                      variant="contained"
                      className="cv-dashboard-packages-register-button"
                      component={Link}
                      to="/public/login/company"
                    >
                      {("REGISTRUJTE SE")}
                    </Button>
                  </SimpleCard>
                </div>

              )
            }
          })()}

        </TabPanel>
      </div >
    </Fragment >
  );
};

export default ForCompanies;
