import { useState } from 'react';
import { SwitchWrapper } from './styles';

const Switch = ({ checked, setChecked }) => {
  const [isChecked, setIsChecked] = useState(checked || false);

  const handleClick = () => {
    setIsChecked(!isChecked);
    if (setChecked) setChecked(!isChecked);
  };

  return <SwitchWrapper color="default" checked={isChecked} onClick={handleClick} />;
};

export default Switch;
