import React from 'react';
import { logoSource } from '../../assets/images';
import './style.scss';

const Loader = () => (
  <div className="cv-prica-loader-wrapper">
    <div className="cv-prica-loader-logo">
      <img src={logoSource} alt="CVPRIČA" />
    </div>
    <div className="cv-prica-loader-text">
      Molimo sačekajte
    </div>
    <div className="cv-prica-loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);

export default Loader;