import React, { useEffect, useState } from "react";
import {
  TableBodyWrapper,
  TableCellWrapper,
  TableHeadWrapper,
  TableRowWrapper,
  TableWrapper,
  TableSortLabelWrapper,
  TableSelectWrapper,
  EmptyTableWrapper,
} from "./styles";
import Spinner from "../spinner";

import { SentimentVeryDissatisfied } from "@mui/icons-material";
// import { CircularProgress } from "@mui/material";

const headItems = [{ key: "id", id: 1, name: "ID" }];

const Table = ({
  head = headItems,
  body = [],
  sort,
  className,
  onRequestSort = () => { },
  onSelection = () => { },
  disableSelection,
  loading,
  ...props
}) => {
  const [globalSelect, setGlobalSelect] = useState(false);
  const [selects, setSelects] = useState(body.map((x) => false));

  const createSortHandler = (key) => (e) => {
    if (onRequestSort) onRequestSort(e, key);
  };

  const handleSelect = (id) => {
    const newSelectValues = selects.map((x, y) => (y === id ? !x : x));
    setSelects(newSelectValues);
    setGlobalSelect(
      !newSelectValues.filter((x) => x === false).length ? true : false
    );
  };

  const handleGlobalSelect = () => {
    setSelects(body.map(() => (globalSelect ? false : true)));
    setGlobalSelect(!globalSelect);
  };

  const handleSelection = () => {
    const selectedFields = body.filter((x, y) => selects[y] === true);
    if (onSelection) onSelection(selectedFields);
  };

  const renderEmptyBody = () => {
    if (!body.length || loading) {
      if (loading) {
        return (
          <EmptyTableWrapper>
            <Spinner />
          </EmptyTableWrapper>
        );
      } else {
        return (
          <EmptyTableWrapper>
            <SentimentVeryDissatisfied />
            <h3>Nema rezultata</h3>
            <p>Izgleda da ova tabela nema rezultata!</p>
          </EmptyTableWrapper>
        );
      }
    }
    return null;
  }

  useEffect(() => {
    handleSelection();
  }, [selects]);

  useEffect(() => {
    setSelects(body.map((x) => false));
    setGlobalSelect(false);
  }, [body]);

  return (
    <div
      style={{
        overflowX: "auto",
        overflowY: "hidden",
        width: "100%",
      }}
      className={className}
      {...props}
    >
      <TableWrapper>
        <TableHeadWrapper>
          <TableRowWrapper>
            {disableSelection ? null : <TableCellWrapper center>
              <TableSelectWrapper
                selected={globalSelect}
                onClick={handleGlobalSelect}
              />
            </TableCellWrapper>}
            {head.map((x) => (
              <TableCellWrapper key={x.id} center={x.center}>
                {sort && x.sort ? (
                  <TableSortLabelWrapper
                    active={sort.order_by === x.sort}
                    onClick={createSortHandler(x.sort)}
                    direction={
                      sort.order_by === x.sort ? sort.order_dir : "asc"
                    }
                  >
                    {x.name}
                  </TableSortLabelWrapper>
                ) : (
                  x.name
                )}
              </TableCellWrapper>
            ))}
          </TableRowWrapper>
        </TableHeadWrapper>
        <TableBodyWrapper>
          {!loading && body.map((x, y) => (
            <TableRowWrapper key={y}>
              {disableSelection ? null : <TableCellWrapper center>
                <TableSelectWrapper
                  selected={selects[y]}
                  onClick={() => handleSelect(y)}
                />
              </TableCellWrapper>}
              {head.map((a) => (
                <TableCellWrapper key={a.id} center={a.center}>
                  {x[a.key]}
                </TableCellWrapper>
              ))}
            </TableRowWrapper>
          ))}
        </TableBodyWrapper>
      </TableWrapper>
      {renderEmptyBody()}
    </div>
  );
};

export default Table;
