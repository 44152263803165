import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../components/ui";
import "./style.scss";

const Verify = () => {
  return (
    <div className="cv-verify-main">
      <h3 className="cv-verify-title">Verifikacija</h3>
      <p className="cv-verify-description">
        Uspešno ste verifikovali vašu email adresu
      </p>
      <div className="cv-verify-buttons">
        <Button variant="contained" color="secondary" size="large" component={Link} to="/dashboard">
          NAZAD NA POČETNU
        </Button>
      </div>
    </div>
  );
};

export default Verify;
