import { Search } from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";
import { CompanyAPI } from "../../../api/v2";
import { CardTitle, CompanyDisplay } from "../../../components/custom";
import Hero from "../../../components/custom/hero";
import Spinner from "../../../components/custom/spinner";
import { Pagination, Input } from "../../../components/ui";
import { useLanguageContext } from "../../../context/language";
import "./style.scss";

const Companies = () => {
  const { t } = useLanguageContext();
  const [companies, setCompanies] = useState([]);
  const [state, setState] = useState({
    term: "",
    limit: 8,
    offset: 0,
  });
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const [search, setSearch] = useState("")
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setState({...state, search});
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const getCompanies = async () => {
    try{
      setIsLoadingCompanies(true);
      const { data } = await CompanyAPI.publicCompanySearch({
        ...state,
        only_with_ads: 0
      });
      setCompanies(data);
    }catch(error){
      console.error(error);
    }
    finally{
      setIsLoadingCompanies(false);
    }
  };

  useEffect(() => {
    getCompanies();
  }, [state]);

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-companies.jpg" />
      <div className="cv-container cv-companies-main">
        <div className="cv-companies-head">
          <CardTitle
            align="left"
            postTitle={t("Pretražite profile poslodavaca i saznajte uslove rada i sve druge važne informacije o kompanijama koje vas zanimaju.")}
            title={
              <>
                <span className="secondary">{t("Upoznajte")}</span> {t("poslodavce")}
              </>
            }
          />
          <Input
            value={search}
            onChange={(term) => setSearch(term)}
            placeholder={t("Pronađite poslodavca")}
            className="cv-companies-filter-search"
            InputProps={{ endAdornment: <Search /> }}
          />
        </div>
        {isLoadingCompanies ? (
          <Spinner />
        ) : (
        <div className="cv-companies-all">
          {companies.map((x) => (
            <CompanyDisplay
              slug={x.slug}
              id={x.id}
              key={x.id}
              adsCount={x.active_ads_count}
              background={x.background_image ? (x.background_image ) : ("/assets/images/card/gray_simple.jpeg")}
              profileImage={x.profile_image}
              city={x.city}
              country={x.country}
              name={x.full_name}
              address={x.address}
              className="cv-companies-all-single"
            />
          ))}
        </div>
        )}
        <div className="cv-pagination-holder cv-pagination-holder-right">
          <Pagination count={1} page={1} setPage={() => { }} />
        </div>
      </div>
    </Fragment>
  );
};

export default Companies;
