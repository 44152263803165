export function setMetaDataBySelector(selector, value) {
  const metaTag = document.querySelector(selector);
  if (metaTag) {
    metaTag.setAttribute('content', value);
  } else {
    const meta = document.createElement('meta');
    let attrName = selector.match(/\[(.*?)=/)[0];
    attrName = attrName.substr(1, attrName.length - 2);
    let attrValue = selector.match(/"(.*?)"/)[0];
    attrValue = attrValue.substr(1, attrValue.length - 2);
    meta.setAttribute(attrName, attrValue);
    meta.setAttribute('content', value);
    document.head.append(meta);
  }
}

export function setTitle(text) {
  document.title = text;
  setMetaDataBySelector('meta[property="og:title"]', text);
  setMetaDataBySelector('meta[name="twitter:title"]', text);
}

export function setDescription(text) {
  setMetaDataBySelector('meta[name="description"]', text);
  setMetaDataBySelector('meta[property="og:description"]', text);
  setMetaDataBySelector('meta[name="twitter:description"]', text);
}

export function setMetaData(metaData) {
  if (metaData.title) {
    setTitle(metaData.title);
  }
  if (metaData.description) {
    setDescription(metaData.description);
  }
}
  