import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { JobAPI } from "../../../../api/v2";
import { Card } from "../../../../components/custom";
import {
  Autocomplete,
  Button,
  Input,
  MultiSelect,
} from "../../../../components/ui";
import { Select, AutocompleteSelect } from "../../../../components/ui";
import { useLanguageContext } from "../../../../context/language";
import { useUserContext } from "../../../../context/user";
import { toQueryParams } from "../../../../utils/converter";
import "./style.scss";

const Search = ({ searchCount }) => {
  const [state, setState] = useState({
    term: "",
    country_id: "",
    city_id: "",
    types_of_work: [],
  });
  const [search, setSearch] = useState("")
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setState({...state, term: search});
    }, 800)

    return () => clearTimeout(delayDebounceFn)
  }, [search])


  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [typeOfWorks, setTypeOfWorks] = useState([]);

  const history = useHistory();
  const { t } = useLanguageContext();

  const {
    countries: allCountries,
    cities: allCities,
    addings: { type_of_works },
  } = useUserContext();

  const [counts, setCounts] = useState({
    positions: 0,
    companies: 0,
    locations: [],
    types_of_work: [],
    cities: []
  });

  const getCounts = async () => {
    const { totals, locations, types_of_work, cities: rCities } =
      await JobAPI.jobSearchAllFilter();
    setCounts({
      positions: totals.ads,
      companies: totals.companies,
      locations,
      types_of_work,
      cities: rCities,
    });
  };

  useEffect(() => {
    getCounts();
  }, []);

  useEffect(() => {
    setTypeOfWorks(
      type_of_works.map((x) => {
        const a = counts.types_of_work.find((y) => y.type_of_work_id === x.id);
        return {
          value: x.id,
          label: `${x.name} (${a?.active_ads_count || "0"})`,
        };
      })
    );
  }, [counts.types_of_work]);

  useEffect(() => {
    setCountries(
      allCountries.map((x) => {
        const a = counts.locations.find((y) => y.country_id === x.id);
        return {
          value: x.id,
          label: `${x.name} (${a?.active_ads_count || "0"})`,
        };
      })
    );
  }, [counts.locations]);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(state.country_id))
      .map((x) => {
        const a = counts.cities.find((y) => y.city_id === x.id);
        return {
          value: x.id,
          label: `${x.name} (${a?.active_ads_count || "0"})`,
        };
      });
    setCities(filteredCities);
  }, [state.country_id, counts.cities]);

  const proccessSearch = () => {
    const queryParams = toQueryParams(state);
    history.push(`/public/jobs?${queryParams}`);
  };

  useEffect(() => {
    const foundCity = cities.find((x) => x.value === state.city_id);
    setCity(foundCity);
  }, [cities, state.country_id]);

  useEffect(() => {
    const foundCountry = countries.find((x) => x.value === state.country_id);
    setCountry(foundCountry);
  }, [countries]);

  const updateCity = (x) => {
    setCity(x);
    setState({ ...state, city_id: x?.value });
  };

  const updateCountry = (x) => {
    setCountry(x);
    setCity(null);
    setState({ ...state, country_id: x?.value, city_id: "" });
  };

  const [terms, setTerms] = useState([]);
  useEffect(() => {
    (async () => {
      if (state.term) {
        const { ads, companies } = await JobAPI.jobSearchTermFilter(state.term);
        setTerms([
          ...ads.map((x) => ({ value: x, label: x, category: "Poslovi" })),
          ...companies.map((x) => ({
            value: x,
            label: x,
            category: "Kompanije",
          })),
        ]);
      } else {
        setTerms([]);
      }
    })();
  }, [state.term]);

  return (
    <Card className="cv-home-search" color="secondary">
      <div className="cv-home-search-text">
        <span>
          {t("Trenutno otvorenih")} {counts.positions} {t("radnih mjesta")}
        </span>{" "}
        {t("za")} {counts.companies} {t("kompanija")}
      </div>
      <div className="cv-home-search-form">
        <Autocomplete
          size="large"
          className="cv-home-search-part"
          placeholder={t`Pozicija, poslodavac ili ključna riječ`}
          value={search}
          onChange={(term) => setSearch(term)}
          groupBy={(x) => x.category}
          options={terms}
        />
        <AutocompleteSelect
          id="country"
          size="large"
          className="cv-home-search-part"
          placeholder={t`Država`}
          options={countries}
          value={country}
          onChange={updateCountry}
        />
        <AutocompleteSelect
          id="city"
          size="large"
          className="cv-home-search-part"
          placeholder={t`Grad`}
          disabled={!state.country_id}
          options={cities}
          value={city}
          onChange={updateCity}
        />
        <MultiSelect
          id="job_category"
          size="large"
          className="cv-home-search-part"
          placeholder={t`Kategorija posla`}
          options={typeOfWorks}
          value={state.types_of_work}
          onChange={(types_of_work) => setState({ ...state, types_of_work })}
        />
        <Button
          className="cv-home-search-part"
          color="primary"
          variant="contained"
          size="large"
          onClick={proccessSearch}
        >
          {t`PRETRAŽITE`}
        </Button>
      </div>
    </Card>
  );
};

export default Search;
