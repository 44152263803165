import { Attachment, Email, SendRounded } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { ChatAPI } from "../../api/v2";
import { DashTitle } from "../../components/custom";
import Spinner from "../../components/custom/spinner";
import { SimpleCard, Button } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import { useUserContext } from "../../context/user";
import "./style.scss";

const ChatBubble = ({ type, text, date, from, to, user_id, created_by }) => {
  const user = created_by === from.id ? from : to;

  const { user: me } = useUserContext();

  return (
    <div
      className={`cv-dashboard-chat-bubble-main ${
        user.id !== me.id ? "cv-dashboard-chat-bubble-incomming" : ""
      }`}
    >
      <div className="cv-dashboard-chat-bubble-image">
        <img src={user.profile_image} />
      </div>
      <div className="cv-dashboard-chat-bubble-text">
        {/* <div className="cv-dashboard-chat-bubble-text-name">
          {user.full_name}
        </div> */}
        <span>{text}</span>
        <div className="cv-dashboard-chat-bubble-date">
          {moment(date).fromNow()}
        </div>
      </div>
    </div>
  );
};

const Messages = () => {
  const [text, setText] = useState("");
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
  });
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { setSeenConversation, user } = useUserContext();

  const getMessages = async (x) => {
    setLoading(true);
    const { data: conversationsData, meta } = await ChatAPI.conversations(
      state
    );
    if (id) {
      await ChatAPI.conversationSetSeen(id);
      setSeenConversation(id);
    }
    setConversations(conversationsData);
    setTotal(meta.total);

    if (x) {
      const { data: messagesData } = await ChatAPI.conversation(x, {
        limit: 10,
        offset: 0,
      });
      setMessages(messagesData);
    }
    setLoading(false);
  };

  const sendMessage = async () => {
    const msg = await ChatAPI.sendMessage(id, { text });
    setMessages([msg, ...messages]);
    setText("");
  };

  useEffect(() => {
    getMessages(id);
  }, [id]);
  const { t } = useLanguageContext();

  const renderMessageBody = () => {
    if (id) {
      if (loading) {
        return (
          <div className="cv-dashboard-messages-direct cv-dashboard-messages-direct-holder">
            <Spinner />
          </div>
        );
      } else {
        return (
          <div className="cv-dashboard-messages-direct">
            <div className="cv-dashboard-messages-direct-body">
              {messages.map((x) => (
                <div className="cv-dashboard-messages-message">
                  <ChatBubble
                    type={x.type}
                    text={x.text}
                    from={x.from}
                    to={x.to}
                    user_id={x.user_id}
                    date={x.created_at}
                    created_by={x.created_by}
                  />
                </div>
              ))}
            </div>
            <div className="cv-dashboard-messages-direct-form">
              <div className="cv-dashboard-messages-direct-form-inner">
                {/* <IconButton>
                  <Attachment />
                </IconButton> */}
                <input
                  type="text"
                  placeholder={t("Odgovor na poruku") + "..."}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  className={`cv-dashboard-messages-direct-form-button ${
                    !text.trim() ? "cv-disabled" : ""
                  }`}
                  onClick={sendMessage}
                >
                  <SendRounded />
                </Button>
              </div>
            </div>
          </div>
        );
      }
    }
    return (
      <div className="cv-dashboard-messages-direct cv-dashboard-messages-direct-holder">
        <div className="cv-dashboard-messages-direct-holder-icon">
          <Email />
        </div>
        <h3>{t("Poruke")}</h3>
        <p>{t("Zapocnite konverzaciju klikom na korisnika sa leve strane")}</p>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle title="Poruke" subtitle="Pogledajte svoje poruke" />
        <SimpleCard className="cv-dashboard-messages-content" title="PORUKE">
          <div
            className={`cv-dashboard-messages-content-inner ${
              id ? "cv-dashboard-messages-has-id" : ""
            }`}
          >
            <div className="cv-dashboard-messages-contacts">
              {conversations.map((x) => {
                const sender = x.from.id === user.id ? x.to : x.from;

                return (
                  <Link
                    to={`/dashboard/messages/${sender.id}`}
                    className={`cv-dashboard-messages-contact-single ${
                      id === String(sender.id)
                        ? "cv-dashboard-messages-contact-single-active"
                        : ""
                    }`}
                  >
                    <div className="cv-dashboard-messages-contact-single-img">
                      {sender.profile_image ? (
                        <img
                          src={sender.profile_image}
                          alt={sender.full_name}
                        />
                      ) : null}
                    </div>
                    <div className="cv-dashboard-messages-contact-single-info">
                      <div className="cv-dashboard-messages-contact-single-info-title">
                        {sender.full_name}
                      </div>
                      <div className="cv-dashboard-messages-contact-single-info-text">
                        {x.text}
                      </div>
                      <div className="cv-dashboard-messages-contact-single-info-date">
                        {moment(x.created_at).format("DD.MM.YYYY [u] HH:mm")}
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
            {renderMessageBody()}
          </div>
        </SimpleCard>
      </div>
    </Fragment>
  );
};

export default Messages;
