import {
  Email,
  FindInPage,
  Search,
  Tune,
  Videocam,
  Visibility,
  Add,
  Remove,
} from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyAPI, ChatAPI } from "../../../api/v2";
import { AdminAutocompleteSelect, AdminInput, AdminSelect } from "../../../components/admin";
import {
  DashTitle,
  Status,
  Table,
  Modal,
  DisplayCard,
} from "../../../components/custom";
import { Button, Pagination, Select, Input } from "../../../components/ui";
import { statuses } from "../../../constants/selects";
import { useEventContext } from "../../../context/event";
import { useLanguageContext } from "../../../context/language";
import { useUserContext } from "../../../context/user";
import { removeFalsy } from "../../../utils/misc";
import "./style.scss";

const tableHead = [
  { key: "full_name", id: 1, name: "Ime i prezime" },
  { key: "title", id: 2, name: "Naslov oglasa" },
  { key: "education", id: 3, name: "Obrazovanje" },
  { key: "location", id: 4, name: "Lokacija" },
  { key: "date", id: 5, name: "D. Prijave" },
  { key: "document", id: 7, name: "Dokument" },
  { key: "video", id: 8, name: "Video" },
  { key: "msg", id: 9, name: "Poruka" },
];

const ApplicationsList = (props) => {
  const [state, setState] = useState({
    limit: 5,
    offset: 0,
  });
  const [search, setSearch] = useState("")

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setState({...state, search});
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [search])
  const [term, setTerm] = useState("");
  const [selection, setSelection] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [apps, setApps] = useState([]);
  const [sort, setSort] = useState({
    order_by: "id",
    order_dir: "asc",
  });

  const { id } = useParams();
  const { t } = useLanguageContext();
  const history = useHistory();
  const { user } = useUserContext();

  const isSelectedCandidatesPage = history.location.pathname.includes('/dashboard/jobs/candidates');

  const [messageOpen, setMessageOpen] = useState(0);
  const [videoOpen, setVideoOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState({
    city_id: "",
    type_of_work_id: "",
    status_id: "",
  });
  const [loading, setLoading] = useState(false);
  const requestSort = () => {};

  const getAllCandidates = async () => {
    setLoading(true);
    let data, meta;
    if (isSelectedCandidatesPage) {
      const response = await CompanyAPI.selectedApplicationsList(
        removeFalsy({
          ...state,
          ...modalState,
          term,
        })
      );
      data = response.data;
      meta = response.meta;
    } else {
      const response = await CompanyAPI.companyAdApplicationsList(
        id,
        removeFalsy({
          ...state,
          ...modalState,
          term,
        })
      );
      data = response.data;
      meta = response.meta;
    }
    setTotal(meta.total);
    const formattedApps = data.map(
      ({ id, ad, created_at, education, user, viewed, status, ...x }) => ({
        ...x,
        id,
        full_name: (
          <Link
            href="javascript:void(0)"
            color="secondary"
            underline="hover"
            onClick={() => history.push(`/dashboard/applications/application/${id}`)}
          >{x.full_name}</Link>
        ),
        image: user.profile_image,
        to: `/dashboard/applications/application/${id}`,
        date: moment(created_at).format("DD.MM.YYYY"),
        title: ad.title,
        education: user.educations.map((x) => x.title).join(", "),
        location: [user.city?.name, user.country?.name]
          .filter((x) => x)
          .join(", "),
        document: (
          <IconButton
            className={`cv-dashboard-profile-link ${!user.cv_document ? "cv-disabled" : ""}`}
            href={user.cv_document?.document_link}
            download
            rel="noreferrer"
            target="_blank"
          >
            <FindInPage />
          </IconButton>
        ),
        video: (
          <IconButton
            className={!x.cv_video ? "cv-disabled" : ""}
            onClick={() => setVideoOpen(x.cv_video)}
          >
            <Videocam size="large" />
          </IconButton>
        ),
        msg: (
          <IconButton onClick={() => setMessageOpen(x.user_id)}>
            <Email size="large" />
          </IconButton>
        ),
      })
    );
    setApps(formattedApps);
    setLoading(false);
  };

  const addSelectedCandidates = async () => {
    setLoading(true);
    const ids = selection.map((app) => app.id);
    await CompanyAPI.companyAdSelectApplications(id, ids);
    setLoading(false);
  };

  const removeSelectedCandidates = async () => {
    const ids = selection.map((app) => app.id);
    await CompanyAPI.companyAdDeselectApplications(ids);
    getAllCandidates();
  }

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getAllCandidates();
    }, 300);

    return () => {
      clearTimeout(searchTimeout);
    }
  }, [term]);

  useEffect(() => {
    getAllCandidates();
  }, [state, modalState]);

  const handlePage = (x) => {
    setPage(x);
    setState({ ...state, offset: (x - 1) * state.limit });
  };

  const { width } = useEventContext();

  return (
    <Fragment>
      <div className={t("cv-dashboard-page-scrollable")}>
        <DashTitle
          title={
            isSelectedCandidatesPage ? "Izdvojeni kandidati" : "Lista kandidata"
          }
          subtitle={
            isSelectedCandidatesPage
              ? "Lista svih izdvojenih kandidata"
              : "Svi kandidati koji su konkurisali za posao"
          }
          actions={[
            isSelectedCandidatesPage ? (
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Remove />}
                size="large"
                className={!selection.length ? "cv-disabled" : ""}
                onClick={removeSelectedCandidates}
              >
                UKLONI KANDIDATE ({selection.length})
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="contained"
                startIcon={<Add />}
                size="large"
                className={!selection.length ? "cv-disabled" : ""}
                onClick={addSelectedCandidates}
              >
                IZDVOJI KANDIDATE ({selection.length})
              </Button>
            ),
            <Button
              color="default"
              variant="contained"
              startIcon={<Email />}
              size="large"
              className={!selection.length ? "cv-disabled" : ""}
              onClick={() => {
                const to_user_ids = selection.map((app) => app.user_id);
                setMessageOpen(to_user_ids);
              }}
            >
              GRUPNA PORUKA ({selection.length})
            </Button>,
          ]}
        />
        <div className="cv-dashboard-jobs-all-main cv-dashboard-order-content">
          <div className="cv-dashboard-filters-main">
            <Select
              disableDefault
              className="cv-dashboard-filters-limit"
              placeholder={t("Prikaži")}
              value={state.limit}
              onChange={(limit) => setState({ ...state, limit })}
              options={[
                { value: 5, label: t("Prikaži") + " 5" },
                { value: 10, label: t("Prikaži") + " 10" },
                { value: 25, label: t("Prikaži") + " 25" },
                { value: 50, label: t("Prikaži") + " 50" },
              ]}
            />
            <div className="cv-dashboard-filter-with-search">
              <Input
                className="cv-dashboard-filters-search"
                placeholder={t("Pronađi konkurs") + "..."}
                value={search}
                onChange={(term) => setSearch(term)}
                endAdornment={<Search />}
              />
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className="cv-dashboard-filter-button"
                disabled
                onClick={() => setModalOpen(true)}
              >
                <Tune />
              </Button>
            </div>
          </div>
          {width >= 900 ? (
            <Table
              head={tableHead}
              body={apps}
              sort={sort}
              onRequestSort={requestSort}
              onSelection={setSelection}
              loading={loading}
            />
          ) : (
            <div className="cv-dashboard-resposnive-table">
              {apps.map((x, y) => (
                <DisplayCard
                  to={x.to}
                  key={y}
                  title={x.full_name}
                  image={x.image}
                  headActions={x.msg}
                  infos={[
                    { title: t("Obrazovanje"), value: x.education },
                    { title: t("Lokacija"), value: x.location },
                    { title: t("Datum prijave"), value: x.date },
                  ]}
                  bodyActions={[x.video]}
                />
              ))}
            </div>
          )}
          <div className="cv-dashboard-pagination-holder">
            <Pagination
              page={page}
              setPage={handlePage}
              count={Math.ceil(total / state.limit)}
            />
          </div>
        </div>
      </div>
      <VideoModal open={videoOpen} onClose={() => setVideoOpen(null)} />

      <MessageModal
        open={messageOpen}
        onClose={() => {
          setMessageOpen(0);
        }}
        userId={user.id}
      />
      <FilterModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        modalState={modalState}
        setModalState={setModalState}
      />
    </Fragment>
  );
};

const VideoModal = ({ open, onClose }) => {
  const { t } = useLanguageContext();
  return(
    <Modal
      open={open}
      onClose={onClose}
      title={t("CV VIDEO")}
      actions={[
        <Button
          color="default"
          variant="contained"
          size="large"
          fullWidth
          onClick={onClose}
        >
          {t("ZATVORI")}
        </Button>,
      ]}
      size="large"
    >
      <video
        className="cv-modalvideo-video-preview"
        src={open}
        controls
        autoPlay
      ></video>
    </Modal>
  );
};

const FilterModal = ({ open, onClose, modalState, setModalState }) => {
  const [state, setState] = useState(modalState);

  useEffect(() => {
    setState(modalState);
  }, [modalState]);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [typeOfWorks, setTypeOfWorks] = useState([]);

  const {
    cities: allCities,
    countries: allCountries,
    addings: { type_of_works },
  } = useUserContext();

  useEffect(() => {
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
    setTypeOfWorks(type_of_works.map((x) => ({ value: x.id, label: x.name })));
    setCities(allCities.map((x) => ({ value: x.id, label: x.name })));
  }, []);

  const applyFilters = () => {
    setModalState(state);
    onClose();
  };

  const resetFilters = () => {
    setModalState({
      country_id: "",
      city_id: "",
      type_of_work_id: "",
    });
    onClose();
  };

  const { t } = useLanguageContext();

  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(state.country_id))
      .map((x) => ({
        value: x.id,
        label: x.name,
      }));
    setCities(filteredCities);
  }, [state.country_id]);

  const updateCountry = (x) => {
    setCountry(x);
    setCity(null);
    setState({ ...state, country_id: x?.value, city_id: "" });
  };

  const updateCity = (x) => {
    setCity(x);
    setState({ ...state, city_id: x?.value });
  };

  const updateCategory = (x) => {
    setCategory(x);
    setState({ ...state, type_of_work_id: x?.value });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("FILTERI")}
      actions={
        <div className="cv-modal-buttons-group">
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={applyFilters}
          >
            {t("POTVRDI")}
          </Button>
          <Button
            color="secondary"
            variant="text"
            size="large"
            fullWidth
            onClick={resetFilters}
          >
            {t("RESETUJ FILTERE")}
          </Button>
        </div>
      }
      size="small"
    >
      <div>
        <AdminAutocompleteSelect
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={country}
          onChange={updateCountry}
          options={countries}
          label={t("Država")}
        />
        <AdminAutocompleteSelect
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={city}
          onChange={updateCity}
          options={cities}
          label={t("Grad")}
        />
        <AdminAutocompleteSelect
          className="cv-mb-10"
          color="secondary"
          variant="outlined"
          value={category}
          onChange={updateCategory}
          options={typeOfWorks}
          label={t("Kategorija posla")}
        />
      </div>
    </Modal>
  );
};

const MessageModal = ({ open, onClose, userId  }) => {
  const {t} = useLanguageContext();
  const [state, setState] = useState({
    title: "",
    text: "",
  });

  const clearMessage = () => {
    setState({
      title: "",
      text: ""
    });
  }

  const sendMessage = async () => {
    await ChatAPI.sendMessage(open, state, userId);
    toast.success(t`Poruka uspešno poslata.`);
    clearMessage();
    onClose();
  }

  const sendGroupMessage = async () => {
    await ChatAPI.sendMessages(open, state, userId);
    toast.success(t`Poruka uspešno poslata.`);
    clearMessage();
    onClose();
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("POŠALJITE PORUKU")}
      actions={
        <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          onClick={open.length > 1 ? sendGroupMessage : sendMessage}
        >
          {t("POŠALJITE")}
        </Button>
      }
      size="medium"
    >
      <AdminInput
        className="cv-mb-20"
        label={t("Naslov poruke")}
        value={state.title}
        onChange={(title) => setState({ ...state, title })}
        color="secondary"
      />
      <AdminInput
        label={t("Tekst poruke")}
        value={state.text}
        onChange={(text) => setState({ ...state, text })}
        minRows={5}
        maxRows={10}
        multiline
        color="secondary"
      />
    </Modal>
  );
};

export default ApplicationsList;
