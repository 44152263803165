const Advice4 = () => (
  <svg viewBox="0 0 105 109">
    <path
      d="M104.12 76.12C104.12 87.8 94.63 97.28 82.95 97.28C71.26 97.28 61.78 87.8 61.78 76.12C61.78 64.43 71.26 54.95 82.95 54.95C94.63 54.95 104.12 64.43 104.12 76.12Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M31.36 36.37C21.33 36.37 13.22 28.26 13.22 18.23C13.22 8.21 21.33 0.100006 31.36 0.100006C41.38 0.100006 49.49 8.21 49.49 18.23C49.49 28.26 41.38 36.37 31.36 36.37Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M30.72 109C14 109 0.480011 95.49 0.480011 78.77C0.480011 62.05 14 48.53 30.72 48.53C47.44 48.53 60.95 62.05 60.95 78.77C60.95 95.49 47.44 109 30.72 109Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M99.58 55.27C99.58 28.55 77.94 6.89999 51.21 6.89999C24.52 6.89999 2.85001 28.55 2.85001 55.27C2.85001 81.96 24.49 103.64 51.21 103.64C77.94 103.64 99.58 81.99 99.58 55.27Z"
      fill="white"
    ></path>
    <path
      d="M99.58 55.27C99.58 43.55 95.43 32.82 88.5 24.46C82.63 21.94 76.18 20.53 69.38 20.53C42.69 20.53 21.01 42.18 21.01 68.9C21.01 80.62 25.16 91.34 32.09 99.71C37.96 102.23 44.41 103.64 51.21 103.64C77.94 103.64 99.58 81.99 99.58 55.27Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M80.75 68.29L69.41 66.35C68.65 66.22 67.94 65.99 67.24 65.71H35.19C34.52 65.99 33.78 66.22 33.02 66.35L21.68 68.29C15.3 69.38 10.64 74.81 10.41 81.26C19 94.7 34.04 103.64 51.18 103.64C68.33 103.64 83.36 94.7 91.95 81.26C91.79 74.84 87.1 69.41 80.75 68.29Z"
      fill="white"
    ></path>
    <path
      d="M91.98 81.26C91.95 79.91 91.7 78.61 91.25 77.36C82.02 72.86 66.03 72.6 49.2 77.58C37.23 81.13 27.14 86.59 20.47 92.62C28.83 99.52 39.53 103.67 51.18 103.67C68.36 103.64 83.43 94.7 91.98 81.26Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.21 104.75C34.23 104.75 18.65 96.2 9.49001 81.83C9.36001 81.64 9.29001 81.42 9.29001 81.19C9.52001 74.23 14.66 68.36 21.49 67.18L32.82 65.23C33.46 65.1 34.1 64.91 34.71 64.65C34.87 64.59 35 64.56 35.16 64.56H67.24C67.4 64.56 67.56 64.59 67.69 64.65C68.29 64.91 68.93 65.1 69.57 65.23L80.91 67.18C87.74 68.36 92.88 74.26 93.1 81.19C93.1 81.42 93.04 81.64 92.91 81.83C83.81 96.2 68.2 104.75 51.21 104.75ZM11.59 80.94C20.34 94.44 35.16 102.49 51.21 102.49C67.31 102.49 82.09 94.44 90.83 80.94C90.48 75.22 86.21 70.4 80.52 69.41L69.19 67.46C68.42 67.34 67.69 67.11 66.99 66.83H35.38C34.68 67.11 33.94 67.34 33.18 67.46L21.84 69.41C16.22 70.4 11.94 75.22 11.59 80.94Z"
      fill="#21365F"
    ></path>
    <path
      d="M62.13 59.61L60.98 55.08H41.41L40.26 59.61C39.56 62.36 37.65 64.56 35.16 65.68L51.21 72.06L67.24 65.68C64.75 64.56 62.83 62.36 62.13 59.61Z"
      fill="white"
    ></path>
    <path
      d="M67.27 65.68C64.75 64.56 62.83 62.32 62.16 59.61L61.75 58.01H48.44L47.19 62.9C46.55 65.39 44.99 67.46 42.91 68.77L51.21 72.09L67.27 65.68Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.21 73.21C51.09 73.21 50.93 73.18 50.8 73.15L34.74 66.73C34.32 66.57 34.04 66.15 34.04 65.71C34.04 65.26 34.29 64.85 34.71 64.65C36.94 63.66 38.57 61.72 39.18 59.32L40.33 54.79C40.45 54.28 40.9 53.93 41.41 53.93H61.02C61.53 53.93 61.97 54.28 62.1 54.79L63.25 59.32C63.86 61.72 65.49 63.63 67.72 64.65C68.13 64.85 68.39 65.26 68.39 65.71C68.39 66.15 68.1 66.57 67.69 66.73L51.66 73.15C51.5 73.18 51.37 73.21 51.21 73.21ZM37.71 65.48L51.21 70.88L64.69 65.48C62.9 64.11 61.59 62.16 61.02 59.9L60.09 56.23H42.27L41.35 59.9C40.81 62.13 39.53 64.11 37.71 65.48Z"
      fill="#21365F"
    ></path>
    <path
      d="M70.59 35.98C70.59 49.01 61.91 59.58 51.21 59.58C40.52 59.58 31.83 49.01 31.83 35.98C31.83 22.96 40.52 16.06 51.21 16.06C61.91 16.06 70.59 22.96 70.59 35.98Z"
      fill="white"
    ></path>
    <path
      d="M70.59 35.98C70.59 25.16 64.62 18.62 56.45 16.7C48.72 18.84 43.1 25.35 43.1 35.79C43.1 46.55 49.04 55.62 57.12 58.46C64.94 55.4 70.59 46.49 70.59 35.98Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.21 60.73C39.91 60.73 30.72 49.65 30.72 36.02C30.72 23.44 38.95 14.98 51.21 14.98C63.47 14.98 71.71 23.44 71.71 36.02C71.71 49.62 62.52 60.73 51.21 60.73ZM51.21 17.21C40.14 17.21 32.98 24.59 32.98 35.98C32.98 48.37 41.16 58.43 51.21 58.43C61.27 58.43 69.44 48.34 69.44 35.98C69.44 24.59 62.29 17.21 51.21 17.21Z"
      fill="#21365F"
    ></path>
    <path
      d="M76.08 97.89C75.45 97.89 74.97 97.38 74.97 96.77V82.72C74.97 82.09 75.48 81.61 76.08 81.61C76.72 81.61 77.2 82.12 77.2 82.72V96.77C77.2 97.38 76.69 97.89 76.08 97.89Z"
      fill="#21365F"
    ></path>
    <path
      d="M26.34 97.89C25.7 97.89 25.23 97.38 25.23 96.77V82.72C25.23 82.09 25.74 81.61 26.34 81.61C26.98 81.61 27.46 82.12 27.46 82.72V96.77C27.49 97.38 26.98 97.89 26.34 97.89Z"
      fill="#21365F"
    ></path>
    <path
      d="M51.21 72.09L39.21 77.65L35.19 65.68L51.21 72.09Z"
      fill="white"
    ></path>
    <path
      d="M51.21 72.09L63.28 77.68L67.27 65.68L51.21 72.09Z"
      fill="white"
    ></path>
    <path
      d="M40.65 68.07L43.23 75.76L50.99 72.19L40.65 68.07Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M56.71 74.49L56.55 74.55L63.28 77.68L65.61 70.62L56.32 74.33L56.71 74.49Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.21 78.77C39.05 78.77 38.89 78.73 38.76 78.67C38.48 78.54 38.25 78.29 38.12 78L34.1 66.03C33.97 65.61 34.07 65.13 34.39 64.85C34.71 64.53 35.19 64.46 35.6 64.62L51.66 71.04C52.08 71.2 52.36 71.61 52.36 72.06C52.36 72.51 52.11 72.92 51.69 73.11L39.66 78.67C39.53 78.73 39.37 78.77 39.21 78.77ZM37.04 67.66L39.88 76.08L48.37 72.16L37.04 67.66Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.28 78.8C63.12 78.8 62.96 78.77 62.8 78.7L50.73 73.11C50.32 72.92 50.06 72.51 50.06 72.06C50.06 71.61 50.35 71.2 50.77 71.04L66.83 64.62C67.24 64.46 67.69 64.56 68.04 64.85C68.36 65.13 68.49 65.61 68.33 66.03L64.34 78C64.24 78.32 64.02 78.54 63.7 78.67C63.57 78.77 63.44 78.8 63.28 78.8ZM54.09 72.16L62.61 76.08L65.42 67.62L54.09 72.16Z"
      fill="#21365F"
    ></path>
    <path
      d="M55.01 81L57.02 74.77L51.21 72.09L45.44 74.77L47.41 81H55.01Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.01 82.15H47.38C46.9 82.15 46.46 81.83 46.3 81.35L44.32 75.09C44.16 74.55 44.41 73.98 44.92 73.72L50.73 71.04C51.02 70.91 51.37 70.91 51.69 71.04L57.47 73.72C57.98 73.94 58.24 74.55 58.08 75.09L56.1 81.35C55.97 81.83 55.52 82.15 55.01 82.15ZM48.24 79.88H54.22L55.65 75.38L51.25 73.34L46.84 75.38L48.24 79.88Z"
      fill="#21365F"
    ></path>
    <path
      d="M47.41 81L43.2 102.93C45.82 103.38 48.5 103.6 51.25 103.6C53.99 103.6 56.67 103.35 59.29 102.93L55.08 81H47.41Z"
      fill="white"
    ></path>
    <path
      d="M59.26 102.93L55.04 81H54.31L49.97 103.57C50.38 103.57 50.83 103.6 51.25 103.6C53.96 103.64 56.64 103.38 59.26 102.93Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.21 104.75C48.5 104.75 45.72 104.53 42.98 104.05C42.69 103.99 42.4 103.83 42.24 103.57C42.08 103.32 41.99 103 42.05 102.71L46.27 80.78C46.36 80.23 46.84 79.85 47.38 79.85H55.01C55.56 79.85 56.03 80.23 56.13 80.78L60.34 102.71C60.41 103 60.34 103.32 60.15 103.57C59.99 103.83 59.71 103.99 59.42 104.05C56.71 104.53 53.93 104.75 51.21 104.75ZM44.51 102.01C49.01 102.65 53.42 102.65 57.92 102.01L54.09 82.15H48.34L44.51 102.01Z"
      fill="#21365F"
    ></path>
    <path
      d="M43.74 28.23C43.74 28.23 54.82 37.8 69.38 28.26C66.63 20.28 59.55 16.06 51.25 16.06C42.21 16.06 34.64 20.98 32.51 30.33C33.08 30.84 37.96 34.23 43.74 28.23Z"
      fill="white"
    ></path>
    <path
      d="M43.74 28.23C43.74 28.23 54.82 37.8 69.38 28.26C69.16 27.59 68.9 26.98 68.61 26.37C65.07 22.58 59.96 20.6 54.25 20.6C46.3 20.6 39.5 24.43 36.5 31.71C38.48 31.8 41 31.07 43.74 28.23Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.35 33.59C50.19 33.59 45.63 30.94 43.84 29.7C37.65 35.44 32.38 31.71 31.77 31.26C31.42 30.97 31.26 30.53 31.36 30.11C33.5 20.76 41.09 14.94 51.21 14.94C60.47 14.94 67.66 19.8 70.43 27.91C70.59 28.39 70.4 28.93 69.99 29.22C64.97 32.5 60.31 33.59 56.35 33.59ZM43.74 27.08C44 27.08 44.25 27.17 44.48 27.36C44.89 27.72 54.76 35.92 67.98 27.81C65.33 21.17 59.13 17.21 51.21 17.21C42.37 17.21 35.89 21.94 33.75 29.85C35.22 30.68 38.7 31.8 42.91 27.43C43.14 27.2 43.42 27.08 43.74 27.08Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.21 104.75C23.92 104.75 1.73001 82.56 1.73001 55.27C1.73001 27.97 23.92 5.78 51.21 5.78C78.51 5.78 100.7 27.97 100.7 55.27C100.7 82.56 78.51 104.75 51.21 104.75ZM51.21 8.05C25.16 8.05 3.99001 29.25 3.99001 55.27C3.99001 81.32 25.19 102.49 51.21 102.49C77.27 102.49 98.43 81.29 98.43 55.27C98.43 29.22 77.27 8.05 51.21 8.05Z"
      fill="#21365F"
    ></path>
    <path
      d="M74.71 57.82H72.6C71.97 57.82 71.49 57.31 71.49 56.7C71.49 56.07 72 55.59 72.6 55.59H74.71C75.35 55.59 75.83 56.1 75.83 56.7C75.83 57.31 75.32 57.82 74.71 57.82Z"
      fill="#21365F"
    ></path>
    <path
      d="M81.67 57.82H79.56C78.93 57.82 78.45 57.31 78.45 56.7C78.45 56.07 78.96 55.59 79.56 55.59H81.67C82.31 55.59 82.79 56.1 82.79 56.7C82.82 57.31 82.31 57.82 81.67 57.82Z"
      fill="#21365F"
    ></path>
    <path
      d="M77.14 62.36C76.5 62.36 76.02 61.84 76.02 61.24V59.13C76.02 58.49 76.53 58.01 77.14 58.01C77.78 58.01 78.26 58.52 78.26 59.13V61.21C78.29 61.84 77.78 62.36 77.14 62.36Z"
      fill="#21365F"
    ></path>
    <path
      d="M77.14 55.36C76.5 55.36 76.02 54.85 76.02 54.25V52.14C76.02 51.5 76.53 51.02 77.14 51.02C77.78 51.02 78.26 51.53 78.26 52.14V54.25C78.29 54.85 77.78 55.36 77.14 55.36Z"
      fill="#21365F"
    ></path>
    <path
      d="M82.63 40.07H80.52C79.88 40.07 79.41 39.56 79.41 38.95C79.41 38.32 79.92 37.84 80.52 37.84H82.63C83.27 37.84 83.75 38.35 83.75 38.95C83.78 39.56 83.27 40.07 82.63 40.07Z"
      fill="#21365F"
    ></path>
    <path
      d="M89.62 40.07H87.51C86.88 40.07 86.4 39.56 86.4 38.95C86.4 38.32 86.91 37.84 87.51 37.84H89.62C90.26 37.84 90.74 38.35 90.74 38.95C90.74 39.56 90.23 40.07 89.62 40.07Z"
      fill="#21365F"
    ></path>
    <path
      d="M85.09 44.6C84.45 44.6 83.97 44.09 83.97 43.49V41.41C83.97 40.77 84.48 40.29 85.09 40.29C85.73 40.29 86.21 40.81 86.21 41.41V43.49C86.21 44.09 85.69 44.6 85.09 44.6Z"
      fill="#21365F"
    ></path>
    <path
      d="M85.09 37.61C84.45 37.61 83.97 37.1 83.97 36.5V34.39C83.97 33.75 84.48 33.27 85.09 33.27C85.73 33.27 86.21 33.78 86.21 34.39V36.5C86.21 37.1 85.69 37.61 85.09 37.61Z"
      fill="#21365F"
    ></path>
    <path
      d="M11.98 50.06H9.87C9.23 50.06 8.75 49.55 8.75 48.95C8.75 48.31 9.26 47.83 9.87 47.83H11.98C12.61 47.83 13.09 48.34 13.09 48.95C13.13 49.55 12.61 50.06 11.98 50.06Z"
      fill="#21365F"
    ></path>
    <path
      d="M18.97 50.06H16.86C16.22 50.06 15.74 49.55 15.74 48.95C15.74 48.31 16.25 47.83 16.86 47.83H18.97C19.61 47.83 20.09 48.34 20.09 48.95C20.09 49.55 19.58 50.06 18.97 50.06Z"
      fill="#21365F"
    ></path>
    <path
      d="M14.43 54.6C13.8 54.6 13.32 54.09 13.32 53.48V51.4C13.32 50.77 13.83 50.29 14.43 50.29C15.07 50.29 15.55 50.8 15.55 51.4V53.48C15.55 54.09 15.04 54.6 14.43 54.6Z"
      fill="#21365F"
    ></path>
    <path
      d="M14.43 47.64C13.8 47.64 13.32 47.13 13.32 46.52V44.41C13.32 43.77 13.83 43.3 14.43 43.3C15.07 43.3 15.55 43.81 15.55 44.41V46.52C15.55 47.13 15.04 47.64 14.43 47.64Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Advice4;