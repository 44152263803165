import React, { useEffect, useState } from "react";
import { CompanyAPI } from "../../../api/v2";
import { CompanyDisplay, DashTitle } from "../../../components/custom";
import { Pagination, Input } from "../../../components/ui";
import { Search } from "@mui/icons-material";
import "./style.scss";
import { useLanguageContext } from "../../../context/language";
import Spinner from "../../../components/custom/spinner";

const DashboardCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [state, setState] = useState({
    term: "",
    limit: 8,
    offset: 0,
  });
  const [search, setSearch] = useState("")
  
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setState({...state, search});
    }, 400)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false);
  const { t } = useLanguageContext();

  const getCompanies = async () => {
    try {
      setIsLoadingCompanies(true);
      const { data, meta } = await CompanyAPI.publicCompanySearch(state);
      setCompanies(data);
      setTotal(meta.total);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCompanies(false);
    }
  };

  const handlePage = (x) => {
    setPage(x);
    setState({ ...state, offset: (x - 1) * state.limit });
  };

  useEffect(() => {
    getCompanies();
  }, [state]);

  return (
    <div className="cv-dashboard-page-scrollable">
      <DashTitle
        title={t("Upoznajte poslodavce")}
        subtitle={t(
          "Pretražite profile poslodavaca i saznajte uslove rada i sve druge važne informacije o kompanijama koje vas zanimaju."
        )}
      />
      <Input
        value={search}
        onChange={(term) => setSearch(term)}
        placeholder={t("Pronađite poslodavca")}
        className="cv-companies-filter-search"
        InputProps={{ endAdornment: <Search /> }}
      />
      <div className="cv-companies-all cv-dashboard-companies-all">
        {isLoadingCompanies ? (
          <div className="cv-spinner-holder">
            <Spinner />
          </div>
        ) : (
          companies.map((x) => (
            <CompanyDisplay
              slug={x.slug}
              prefix="dashboard"
              id={x.id}
              key={x.id}
              adsCount={x.active_ads_count}
              background={x.background_image ? (x.background_image ) : ("/assets/images/card/gray_simple.jpeg")}
              profileImage={x.profile_image}
              city={x.city}
              country={x.country}
              name={x.full_name}
              address={x.address}
              className="cv-companies-all-single"
            />
          ))
        )}
      </div>
      <div className="cv-dashboard-pagination-holder">
        <Pagination
          page={page}
          setPage={handlePage}
          count={Math.ceil(total / state.limit)}
        />
      </div>
    </div>
  );
};

export default DashboardCompanies;
