import { styled } from '@mui/system';

export const CardTitleWrapper = styled("div")(({ theme, align, baseColor = '#353535' }) => ({
  textAlign: align,
  color: baseColor,
  padding: '20px 0px',
  "& p": {
    fontSize: 15,
    fontWeight: 300,
    marginBottom: 15,
  },
  "& h2": {
    fontSize: 40,
    fontWeight: 400,
    "& span.primary": {
      fontWeight: 500,
      color: theme.palette.primary.main,
    },
    "& span.secondary": {
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 32
    }
  },
}));