import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLanguageContext } from "../../../context/language";
import { BusinessCenter, Close, Dashboard, ExitToApp, PersonRounded, RecordVoiceOver, SendRounded, Settings, StorageRounded } from "@mui/icons-material";
import { logoSource } from "../../../assets/images";
import { useHistory, NavLink } from "react-router-dom";
import { useUserContext } from "../../../context/user";
import { NavDropdownLink } from "../sidebar";
import './style.scss';


const DashMobileNavigation = ({ open, setOpen }) => {

  const { location } = useHistory();

  const [toggle, setToggle] = useState(location.pathname);

  const { user, logout } = useUserContext();

  const onClose = () => {
    setOpen(false);
  };

  const { t } = useLanguageContext();

  const items = [
    { name: t`Dashboard`, icon: <Dashboard />, to: "/dashboard" },
    {
      name: t`Moj CV profil`,
      icon: <PersonRounded />,
      to: "/dashboard/cv-profile",
      role: "employee",
    },
    {
      name: t`Profil`,
      icon: <PersonRounded />,
      to: "/dashboard/profile",
      role: "company",
    },
    {
      name: t`Oglasi`,
      icon: <RecordVoiceOver />,
      to: "/dashboard/jobs",
      items: [
        { name: t`Svi oglasi`, to: "/dashboard/jobs/all", role: "employee" },
        {
          name: t`Omiljeni oglasi`,
          to: "/dashboard/jobs/favorites",
          role: "employee",
        },
        {
          name: t`Dodaj novi oglas`,
          to: "/dashboard/jobs/new",
          role: "company",
        },
        {
          name: t`Postavljeni oglasi`,
          to: "/dashboard/jobs/posted",
          role: "company",
        },
        {
          name: t`Oglasi u pripremi`,
          to: "/dashboard/jobs/preparation",
          role: "company",
        },
        {
          name: t`Arhiva oglasa`,
          to: "/dashboard/jobs/archived",
          role: "company",
        },
      ],
    },
    {
      name: t`Paketi`,
      icon: <StorageRounded />,
      to: "/dashboard/packages",
      role: "company",
    },
    {
      name: t`Moji konkursi`,
      icon: <SendRounded />,
      to: "/dashboard/applications",
      role: "employee",
      items: [
        { name: t`Izvršene prijave`, to: "/dashboard/applications/done" },
        {
          name: t`Prijave u pripremi`,
          to: "/dashboard/applications/preparation",
        },
      ],
    },
    {
      name: t`Kompanije`,
      icon: <BusinessCenter />,
      to: "/dashboard/companies",
      role: "employee",
    },
    { name: t`Podešavanja`, icon: <Settings />, to: "/dashboard/settings" },
  ];

  const locationChange = (x) => {
      setToggle(x);
      setOpen(false);
  }

  return (
    <Drawer
      className="cv-dash-mobilenavigation-wrapper"
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <div className="cv-mobilenavigation-main">
        <div className="cv-mobilenavigation-top">
          <div className="cv-mobilenavigation-logo">
            <img src={logoSource} alt="CVPRIČA" />
          </div>
          <IconButton
            className="cv-mobilenavigation-close"
            size="large"
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </div>
        <div className="cv-dash-sidebar-drawer">
          {items.map((x, y) =>
            !x.role || x.role === user.role ? (
              x.items ? (
                <NavDropdownLink
                  icon={x.icon}
                  title={x.name}
                  toggle={toggle}
                  setToggle={setToggle}
                  items={x.items}
                  to={x.to}
                  locationChange={locationChange}
                />
              ) : (
                <NavLink
                  className="cv-dash-sidebar-item"
                  activeClassName="cv-dash-sidebar-item-active"
                  exact
                  to={x.to}
                  onClick={() => locationChange(x.to)}
                >
                  {x.icon} {x.name}
                </NavLink>
              )
            ) : null
          )}
          <div
            className="cv-dash-sidebar-item"
            activeClassName="cv-dash-sidebar-item-active"
            onClick={logout}
          >
            <ExitToApp/> Odjavi se
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default DashMobileNavigation;
