const createPalette = () => ({
  primary: {
    light: "#feb04b",
    main: "#ffa837",
    dark: "#ff9a18",
    lightGradient: ["#feb04b", "#ef6239"],
    mainGradient: ["#ffa837", "#ee5124"],
    darkGradient: ["#ff9a18", "#e44011"],
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#3da1fb",
    main: "#2897fb",
    dark: "#0b89fa",
    lightGradient: ["#3da1fb", "#063b77"],
    mainGradient: ["#2897fb", "#04274f"],
    darkGradient: ["#0b89fa", "#032347"],
    contrastText: "#ffffff",
  },
  default: {
    dark: "#c6c6c6",
    main: "#dcdcdc",
    light: "#dfdfdf",
    darkGradient: ["#c6c6c6", "#9d9d9d"],
    mainGradient: ["#dcdcdc", "#afafaf"],
    lightGradient: ["#dfdfdf", "#b7b7b7"],
    contrastText: "#000000",
  },
  error: {
    dark: "#c62828",
    main: "#d32f2f",
    light: "#ef5350",
    darkGradient: ["#c62828", "#900000"],
    mainGradient: ["#d32f2f", "#a10000"],
    lightGradient: ["#ef5350", "#c30000"],
    contrastText: "#ffffff",
  },
  warning: {
    dark: "#e65100",
    main: "#ED6C02",
    light: "#ff9800",
    darkGradient: ["#e65100", "#ff903b"],
    mainGradient: ["#ED6C02", "#ffa45e"],
    lightGradient: ["#ff9800", "#fead6e"],
    contrastText: "#ffffff",
  },
  success: {
    dark: "#1b5e20",
    main: "#2e7d32",
    light: "#4caf50",
    darkGradient: ["#1b5e20", "#50be1b"],
    mainGradient: ["#2e7d32", "#59d41f"],
    lightGradient: ["#4caf50", "#66e02d"],
    contrastText: "#ffffff",
  },
  info: {
    dark: "#01579b",
    main: "#0288d1",
    light: "#03a9f4",
    darkGradient: ["#01579b", "#13c8d5"],
    mainGradient: ["#0288d1", "#19dcea"],
    lightGradient: ["#03a9f4", "#30dfec"],
    contrastText: "#ffffff",
  },
});

export default createPalette;
