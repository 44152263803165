import { KeyboardBackspace, Settings, Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { CandidateAPI, CompanyAPI } from "../../api/v2";
import {
  DashTitle,
  DataDisplay,
  ModalVideo,
  Modal,
} from "../../components/custom";
import { SimpleCard, Button } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import { useUserContext } from "../../context/user";
import { tryParsing } from "../../utils/misc";
import "./style.scss";

const ApplicationSingle = () => {
  const [application, setApplication] = useState(null);

  const { id } = useParams();

  const { user } = useUserContext();

  const { t } = useLanguageContext();
  const { role } = user;

  const [educationOpen, setEducationOpen] = useState(false);
  const [workExpOpen, setWorkExpOpen] = useState(false);
  const [languagesOpen, setLanguagesOpen] = useState(false);
  const [computerOpen, setComputerOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [drivingOpen, setDrivingOpen] = useState(false);

  const getApplication = async (x) => {
    if (x) {
      const { computer_skills, education, experience, languages, ...other } =
        role === 'employee' ? await CandidateAPI.applicationShow(x) : await CompanyAPI.applicationShow(x);

      setApplication({
        computer_skills: tryParsing(computer_skills),
        education: tryParsing(education),
        experience: tryParsing(experience),
        languages: tryParsing(languages),
        ...other,
      });
    } else {
      setApplication({
        computer_skills: user.computer_skills,
        education: user.educations,
        experience: user.experience,
        languages: user.languages,
        cv_video: user.cv_video.video,
        email: user.contact_email,
        user,
      });
    }
  };

  useEffect(() => {
    getApplication(id);
  }, [id]);

  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={role === "employee" ? "Pregled profila" : "Pregled profila"}
          subtitle="Detaljan pregled prijave kandidata"
        />
        <SimpleCard
          className="cv-dashboard-application-single-content"
          title={" "}
          withContent
          actions={
            <Button
              className="cv-dashboard-application-single-content-action"
              variant="contained"
              color="default"
              startIcon={<KeyboardBackspace />}
              onClick={goBack}
            >
              <span className="cv-hide-mobile">{t("NAZAD")}</span>
            </Button>
          }
        >
          {application ? (
            <Fragment>
              <div className="cv-dashboard-application-single-profile">
                <div className="cv-dashboard-application-single-profile-image">
                  {application.user.profile_image ? (
                    <img src={application.user.profile_image} alt="Profile" />
                  ) : null}
                </div>
                <div className="cv-dashboard-application-single-profile-data">
                  <h3>{application.user.full_name}</h3>
                  <p>
                    {moment(application.user.birth_year).format("DD.MM.YYYY")}
                  </p>
                  {role === "employee" ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      startIcon={<Settings />}
                      component={Link}
                      to="/dashboard/cv-profile/edit"
                    >
                      {t("IZMJENI PROFIL")}
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="cv-dashboard-application-single-other">
                <div className="cv-dashboard-application-single-other-part">
                  <DataDisplay
                    label={t("Pol")}
                    value={application.user.gender?.name}
                  />
                  <DataDisplay
                    label={t("Kontakt email")}
                    value={application.email}
                  />
                  <DataDisplay
                    className="cv-dashboard-profile-col-double"
                    label={t("Obrazovanje")}
                    value={application.user.educations
                      ?.map((x) => x.title)
                      .join(", ")}
                    action={
                      application.user.educations?.length ? (
                        <IconButton onClick={() => setEducationOpen(true)}>
                          <Visibility />
                        </IconButton>
                      ) : null
                    }
                  />
                  <DataDisplay
                    label={t("Država")}
                    value={application.user.country?.name}
                  />
                  <DataDisplay
                    label={t("Grad")}
                    value={application.user.city?.name}
                  />
                  <DataDisplay
                    label={t("Ulica i broj prebivališta")}
                    value={application.user.address}
                  />
                  <DataDisplay
                    label={t("Kontakt telefon")}
                    value={application.user.phone}
                  />
                  <DataDisplay
                    className="cv-dashboard-profile-col-double"
                    label={t("Rad na računaru")}
                    value={application.user.computer_skills
                      ?.map((x) => x.computer_skill_name.name)
                      .join(", ")}
                    action={
                      application.user.computer_skills?.length ? (
                        <IconButton onClick={() => setComputerOpen(true)}>
                          <Visibility />
                        </IconButton>
                      ) : null
                    }
                  />
                  <DataDisplay
                    label={t("Strani jezici")}
                    value={application.user.languages
                      ?.map((x) => x.languages_name)
                      .join(", ")}
                    action={
                      application.user.languages?.length ? (
                        <IconButton onClick={() => setLanguagesOpen(true)}>
                          <Visibility />
                        </IconButton>
                      ) : null
                    }
                  />
                  <DataDisplay
                    label={t("CV dokument")}
                    value={
                      application.user.cv_document?.document_name ? (
                        <a
                          className="cv-dashboard-profile-link"
                          href={application.user.cv_document?.document_link}
                          rel="noreferrer"
                          target="_blank"
                          download
                        >
                          {application.user.cv_document?.document_link}
                        </a>
                      ) : (
                        "/"
                      )
                    }
                  />
                  <DataDisplay
                    label={t("Radno iskustvo")}
                    value={
                      application.user.work_experiences?.length
                        ? application.user.work_experiences
                            ?.map((x) => x.company_name)
                            .join(", ")
                        : t("Bez radnog iskustva")
                    }
                    action={
                      application.user.work_experiences?.length ? (
                        <IconButton onClick={() => setWorkExpOpen(true)}>
                          <Visibility />
                        </IconButton>
                      ) : null
                    }
                  />
                  <DataDisplay
                    label={t("Vozačka dozvola")}
                    value={application.user.driver_licences
                      ?.map((x) => x.drivers_licence_category.name)
                      .join(", ")}
                    action={
                      application.user.driver_licences?.length ? (
                        <IconButton onClick={() => setDrivingOpen(true)}>
                          <Visibility />
                        </IconButton>
                      ) : null
                    }
                  />
                </div>
                <div className="cv-dashboard-application-single-other-part">
                  <div className="cv-dashboard-application-single-video-main cv-dashboard-profile-col-double">
                    <span>{t("CV video biografija")}</span>
                    <div className="cv-dashboard-application-single-video-holder">
                      <ModalVideo src={application.cv_video} />
                    </div>
                  </div>
                  <DataDisplay
                    className="cv-dashboard-profile-col-double"
                    label={t("Ukratko o meni")}
                    value={application.user.aditional_info}
                    action={
                      application.user.aditional_info ? (
                        <IconButton onClick={() => setAboutOpen(true)}>
                          <Visibility />
                        </IconButton>
                      ) : null
                    }
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
        </SimpleCard>
      </div>
      {application ? (
        <Fragment>
          <EducationsModal
            edu={application.user.educations}
            open={educationOpen}
            onClose={() => setEducationOpen(false)}
          />
          <WorkExpModal
            workexps={application.user.work_experiences}
            open={workExpOpen}
            onClose={() => setWorkExpOpen(false)}
          />
          <LanguagesModal
            langs={application.user.languages}
            open={languagesOpen}
            onClose={() => setLanguagesOpen(false)}
          />
          <ComputerSkillsModal
            skills={application.user.computer_skills}
            open={computerOpen}
            onClose={() => setComputerOpen(false)}
          />
          <OtherModal
            desc={application.user.aditional_info}
            open={aboutOpen}
            onClose={() => setAboutOpen(false)}
          />
          <DrivingLicenceModal
            licence={application.user.driver_licences}
            open={drivingOpen}
            onClose={() => setDrivingOpen(false)}
          />
        </Fragment>
      ) : null}
    </Fragment>
  );
};


const EducationsModal = ({ edu, open, onClose, ...parent }) => {
  const [education, setEducation] = useState(null);

  const { addings } = useUserContext();

  const [educationLevels, setEducationLevels] = useState([]);
  const [educationAreas, setEducationAreas] = useState([]);

  useEffect(() => {
    setEducationLevels(
      addings.education_levels.map((x) => ({ value: x.id, label: x.name }))
    );
    setEducationAreas(
      addings.education_areas.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const { t } = useLanguageContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t`OBRAZOVANJE`}
      size="medium"
      actions={
        education ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setEducation(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!education ? (
          <div className="cv-dashboard-profile-preview-main">
            {edu?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Obrazovanje")} ${y + 1}`}
                value={x.title}
                onClick={() => setEducation(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Nivo obrazovanja`}
              value={
                educationLevels.find(
                  (x) => x.value === education.education_level_id
                )?.label
              }
            />
            <DataDisplay
              label={t`Oblast obrazovanja`}
              value={
                educationAreas.find(
                  (x) => x.value === education.education_area_id
                )?.label
              }
            />
            <DataDisplay label={t`Stečeno zvanje`} value={education.title} />
            <DataDisplay
              label={t`Naziv institucije`}
              value={education.institution}
            />
            <DataDisplay label={t`Grad`} value={education.city} />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Smjer`}
              value={education.course}
            />
            <DataDisplay
              label={t`Datum upisa`}
              value={moment(education.start_date).format("DD.MM.YYYY")}
            />
            <DataDisplay
              label={t`Datum diplomiranja`}
              value={
                education.ongoing
                  ? t`Još uvjek u toku`
                  : moment(education.end_date).format("DD.MM.YYYY")
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const WorkExpModal = ({ workexps, open, onClose, ...parent }) => {
  const [workExp, setWorkExp] = useState(null);

  const { addings } = useUserContext();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setCategories(
      addings.type_of_works.map((x) => ({ value: x.id, label: x.name }))
    );
  }, []);

  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="RADNO ISKUSTVO"
      size="medium"
      actions={
        workExp ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setWorkExp(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!workExp ? (
          <div className="cv-dashboard-profile-preview-main">
            {workexps?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Kompanija")} ${y + 1}`}
                value={x.company_name}
                onClick={() => setWorkExp(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay label={t`Kompanija`} value={workExp.company_name} />
            <DataDisplay label={t`Lokacija`} value={workExp.location} />
            <DataDisplay label={t`Radna pozicija`} value={workExp.position} />
            <DataDisplay
              label={t`Kategorija posla`}
              value={
                categories.find((x) => x.value === workExp.job_category_id)
                  ?.label
              }
            />
            <DataDisplay
              label={t`Datum zaposlenja`}
              value={moment(workExp.start_date).format("DD.MM.YYYY")}
            />
            <DataDisplay
              label={t`Datum prestanka radnog odnosa`}
              value={
                workExp.ongoing
                  ? t`Još uvjek zaposlen`
                  : moment(workExp.end_date).format("DD.MM.YYYY")
              }
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Opis pozicije`}
              value={workExp.description}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const LanguagesModal = ({ langs, open, onClose, ...parent }) => {
  const [language, setLanguage] = useState(null);

  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("STRANI JEZICI")}
      size="medium"
      actions={
        language ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setLanguage(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!language ? (
          <div className="cv-dashboard-profile-preview-main">
            {langs?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Jezik")} ${y + 1}`}
                value={x.languages_name}
                onClick={() => setLanguage(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Jezik`}
              value={language.languages_name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Čitanje`}
              value={language.language_reads_name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Pisanje`}
              value={language.language_writes_name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Govor`}
              value={language.language_speaks_name}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const ComputerSkillsModal = ({ skills, open, onClose, ...parent }) => {
  const [computerSkill, setComputerSkill] = useState(null);

  const { t } = useLanguageContext();
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="RAD NA RAČUNARU"
      size="medium"
      actions={
        computerSkill ? (
          <Button
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            onClick={() => setComputerSkill(null)}
          >
            {t`NAZAD`}
          </Button>
        ) : (
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t`ZATVORI`}
          </Button>
        )
      }
    >
      <div className="cv-dashboard-profile-edit-modal-main">
        {!computerSkill ? (
          <div className="cv-dashboard-profile-preview-main">
            {skills?.map((x, y) => (
              <DataDisplay
                key={x.id}
                className="cv-dashboard-profile-edit-single"
                label={`${t("Vještina")} ${y + 1}`}
                value={x.computer_skill_name.name}
                onClick={() => setComputerSkill(x)}
              />
            ))}
          </div>
        ) : (
          <div className="cv-dashboard-profile-preview-main cv-grid-2">
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Vještina`}
              value={computerSkill.computer_skill_name.name}
            />
            <DataDisplay
              className="cv-grid-col-2"
              label={t`Nivo znanja`}
              value={computerSkill.computer_skill_knowledge_level.name}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const OtherModal = ({ desc, open, onClose, ...parent }) => {
  const { t } = useLanguageContext();
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("OSTALO")}
        size="medium"
        actions={
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {"ZATVORI"}
          </Button>
        }
      >
        <div className="cv-dashboard-profile-preview-main ">
          <DataDisplay
            label={t`Ukratko o meni`}
            value={
              <div className="cv-dashboard-companyprofile-normal">{desc}</div>
            }
          />
        </div>
      </Modal>
    </Fragment>
  );
};

const DrivingLicenceModal = ({ licence, open, onClose, ...parent }) => {
  const { addings } = useUserContext();

  const [drivingCategories, setDrivingCategories] = useState([]);

  useEffect(() => {
    setDrivingCategories(
      addings.drivers_licence_categories.map((x) => ({
        value: x.id,
        label: x.name,
      }))
    );
  }, []);

  const { t } = useLanguageContext();

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title={t("VOZAČKA DOZVOLA")}
        size="medium"
        actions={
          <Button
            color="default"
            variant="contained"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {"ZATVORI"}
          </Button>
        }
      >
        <div className="cv-dashboard-profile-preview-main cv-grid-2">
          <DataDisplay
            label={t`Vozačka dozvola`}
            value={licence ? licence[0]?.drivers_licence_category.name : null}
          />
          <DataDisplay
            label={t`Sopstveno vozilo`}
            value={licence ? (licence[0]?.own_vehicle ? "Da" : "Ne") : null}
          />
          <DataDisplay
            className="cv-grid-col-2"
            label={t`Dodatne informacije`}
            value={
              <div className="cv-dashboard-companyprofile-normal">
                {licence ? licence[0]?.additional_info : "/"}
              </div>
            }
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default ApplicationSingle;
