import React, { useEffect, useState } from 'react';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';

const editorLabels = {
  // Generic
  'generic.add': 'Add',
  'generic.cancel': 'Cancel',

  // BlockType
  'components.controls.blocktype.h1': 'Title',
  'components.controls.blocktype.h2': 'Title',
  'components.controls.blocktype.h3': 'Sub Title',
  'components.controls.blocktype.h4': 'Title',
  'components.controls.blocktype.h5': 'Title',
  'components.controls.blocktype.h6': 'Title',
  'components.controls.blocktype.blockquote': 'Blockquote',
  'components.controls.blocktype.code': 'Code',
  'components.controls.blocktype.blocktype': 'Block Type',
  'components.controls.blocktype.normal': 'Text',

  // Color Picker
  'components.controls.colorpicker.colorpicker': 'Color Picker',
  'components.controls.colorpicker.text': 'Text',
  'components.controls.colorpicker.background': 'Highlight',

  // Embedded
  'components.controls.embedded.embedded': 'Embedded',
  'components.controls.embedded.embeddedlink': 'Embedded Link',
  'components.controls.embedded.enterlink': 'Enter link',

  // Emoji
  'components.controls.emoji.emoji': 'Emoji',

  // FontFamily
  'components.controls.fontfamily.fontfamily': 'Font',

  // FontSize
  'components.controls.fontsize.fontsize': 'Font Size',

  // History
  'components.controls.history.history': 'History',
  'components.controls.history.undo': 'Undo',
  'components.controls.history.redo': 'Redo',

  // Image
  'components.controls.image.image': 'Image',
  'components.controls.image.fileUpload': 'File Upload',
  'components.controls.image.byURL': 'URL',
  'components.controls.image.dropFileText': 'Drop the file or click to upload',

  // Inline
  'components.controls.inline.bold': 'Bold',
  'components.controls.inline.italic': 'Italic',
  'components.controls.inline.underline': 'Underline',
  'components.controls.inline.strikethrough': 'Strikethrough',
  'components.controls.inline.monospace': 'Monospace',
  'components.controls.inline.superscript': 'Superscript',
  'components.controls.inline.subscript': 'Subscript',

  // Link
  'components.controls.link.linkTitle': 'Link Title',
  'components.controls.link.linkTarget': 'Link Target',
  'components.controls.link.linkTargetOption': 'Open link in new window',
  'components.controls.link.link': 'Link',
  'components.controls.link.unlink': 'Unlink',

  // List
  'components.controls.list.list': 'List',
  'components.controls.list.unordered': 'Unordered',
  'components.controls.list.ordered': 'Ordered',
  'components.controls.list.indent': 'Indent',
  'components.controls.list.outdent': 'Outdent',

  // Remove
  'components.controls.remove.remove': 'Remove',

  // TextAlign
  'components.controls.textalign.textalign': 'Text Align',
  'components.controls.textalign.left': 'Left',
  'components.controls.textalign.center': 'Center',
  'components.controls.textalign.right': 'Right',
  'components.controls.textalign.justify': 'Justify'
};

const getParsed = (value) => {
  try {
    const r = JSON.parse(value);
    return r;
  } catch(e) {
    return value;
  }
}

const RichText = ({ value, setValue, viewOnly }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = e => {
    if (!viewOnly) {
      setEditorState(e);
    }
  };

  useEffect(() => {
    if (value) {
      const contentBlock = htmlToDraft(getParsed(value));
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const state = EditorState.createWithContent(contentState);
        setEditorState(state);
      }
    }
  }, [value]);

  useEffect(() => {
    if (!viewOnly) {
      //const html = convertToHTML(editorState.getCurrentContent());
      //setValue(html);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const hashConfig = {
        trigger: '#',
        separator: ' '
      };
      const html = JSON.stringify(draftToHtml(rawContentState, hashConfig));
      setValue(html);
    }
  }, [editorState]);

  return (
    <div className="cv-richtext-main">
      <Editor
        readOnly={viewOnly}
        toolbarHidden={viewOnly}
        editorState={editorState}
        editorClassName="cv-dashboard-companyprofile-font cv-injected-text"
        editorStyle={{ fontSize: 16, fontFamily: "'Rubik', sans-serif" }}
        onEditorStateChange={onEditorStateChange}
        localization={{ locale: "en", translations: editorLabels }}
        toolbar={{
          options: ["inline", "blockType", "list", "textAlign", "colorPicker"],
          blockType: {
            inDropdown: true,
            options: ["H1", "H3", "Normal", "Blockquote"],
            displayNames: [
              { label: "Normal", displayName: "Normal", style: "header-one" },
              { label: "H1", displayName: "H1", style: "unstyled" },
              { label: "H3", displayName: "H3", style: "unstyled" },
              {
                label: "Blockquote",
                displayName: "Blockquote",
                style: "blockquote",
              },
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
          fontSize: {
            default: 16,
          },
        }}
      />
    </div>
  );
};

export default RichText;
