import React from "react";
import WarningIcon from "../../../assets/svg/icons/warning-icon";
import { CheckCircleIcon } from "../../../assets/svg";
import "./style.scss";

const DataDisplay = ({ label, fullWidth, className, action, value = "/", required, isWarning, isVerified, ...props }) => {

  const renderIcon = () => {
    if (isVerified !== undefined) {
      if (isVerified) {
        return (
          <span className="cv-data-display-icon"><CheckCircleIcon /></span>
        );
      } else {
        return (
          <span className="cv-data-display-icon"><WarningIcon /></span>
        );
      }
    }
    return null;
  }

  return (
    <div className={`cv-data-display-main ${className}`} {...props}>
      <div className="cv-data-display-label">
        {label}
        {required ? <span>*</span> : null}
      </div>
      <div className={`cv-data-display-value ${fullWidth ? "cv-data-display-value-full-width" : ""}`}>
        <span style={isWarning  || (isVerified !== undefined && !isVerified) ? {color: '#ee5124'} : null}>{value}</span>
        {renderIcon()}
        {action ? action : null}
      </div>
    </div>
  );
};

export default DataDisplay;
