import { Phone } from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { forwardRef, Fragment, useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { AdminInput } from "..";
import Flags from "country-flag-icons/react/3x2";
import "./style.scss";

const prefixes = [
  {
    label: "Bosna i Hercegovina",
    prefix: "+387",
    icon: <Flags.BA />,
  },
  { label: "Hrvatska", prefix: "+385", icon: <Flags.HR /> },
  { label: "Crna Gora", prefix: "+382", icon: <Flags.ME /> },
  { label: "Srbija", prefix: "+381", icon: <Flags.RS /> },
];

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="## ### ######"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const PhoneInput = ({ value: defaultValue, onChange, ...props }) => {
  const [value, setValue] = useState("");
  const [prefix, setPrefix] = useState(prefixes[2]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue.slice(4));
      setPrefix(prefixes.find((x) => x.prefix === defaultValue.slice(0, 4)));
    }
  }, [defaultValue]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const handlePrefix = (x) => {
    setPrefix(x);
    onChange(`${x.prefix}${value.replace(/ /g, "")}`);
    onClose();
  };

  const handleValue = (x) => {
    setValue(x);
    onChange(`${prefix.prefix}${x.replace(/ /g, "")}`);
  };

  return (
    <div className="cv-phoneinput-main">
      <AdminInput
        {...props}
        value={value}
        onChange={(x) => handleValue(x)}
        inputComponent={TextMaskCustom}
        startAdornment={
          <Fragment>
            <IconButton onClick={handleClick}>
              {prefix ? (
                <div className="cv-phoneinput-icon">{prefix.icon}</div>
              ) : (
                <Phone />
              )}
            </IconButton>
            <span className="cv-phoneinput-prefix">
              {prefix ? prefix.prefix : "+0"}
            </span>
          </Fragment>
        }
      />
      <Menu
        className="cv-phoneinput-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {prefixes.map((x, y) => (
          <MenuItem key={y} onClick={() => handlePrefix(x)}>
            <ListItemIcon>
              <div className="cv-phoneinput-icon">{x.icon}</div>
            </ListItemIcon>
            <Typography variant="inherit">
              ({x.prefix}) {x.label}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PhoneInput;
