const Company3 = () => (
  <svg viewBox="0 0 122 123">
    <path
      d="M99.46 51.14C86.99 51.14 76.91 41.06 76.91 28.6C76.91 16.13 86.99 6.05 99.46 6.05C111.92 6.05 122 16.13 122 28.6C122 41.06 111.92 51.14 99.46 51.14Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M44.05 122.4C33.37 122.4 24.73 113.76 24.73 103.08C24.73 92.4 33.37 83.76 44.05 83.76C54.73 83.76 63.37 92.4 63.37 103.08C63.37 113.76 54.73 122.4 44.05 122.4Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M32.22 64.8C14.4 64.8 0 50.4 0 32.58C0 14.76 14.4 0.360001 32.22 0.360001C50.04 0.360001 64.44 14.76 64.44 32.58C64.44 50.4 50.04 64.8 32.22 64.8Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M31.31 37.04H12.47C10.67 37.04 9.23999 38.48 9.23999 40.27V46.72C9.23999 46.72 22.66 44.17 26.41 56.12C28.28 62.14 27.08 69.26 25.81 75.88L42.82 79.34L31.31 37.04Z"
      fill="white"
    ></path>
    <path
      d="M42.82 79.3L31.31 37.04H25.45L34.77 71.26L26.89 69.66C26.61 71.73 26.21 73.85 25.81 75.88L42.82 79.3Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.82 80.5C42.74 80.5 42.66 80.5 42.58 80.46L25.57 76.99C24.93 76.87 24.49 76.24 24.61 75.6C25.97 68.71 27 62.02 25.25 56.44C21.87 45.69 9.95999 47.76 9.43999 47.88C9.07999 47.96 8.71999 47.84 8.43999 47.64C8.15999 47.4 8.00999 47.08 8.00999 46.72V40.27C8.00999 37.84 9.99999 35.85 12.43 35.85H31.27C31.82 35.85 32.3 36.21 32.42 36.72L43.93 78.98C44.05 79.38 43.93 79.82 43.65 80.14C43.49 80.38 43.18 80.5 42.82 80.5ZM27.24 74.88L41.14 77.71L30.39 38.24H12.47C11.35 38.24 10.43 39.15 10.43 40.27V45.37C14.18 45.09 24.3 45.33 27.56 55.76C29.39 61.54 28.52 68.11 27.24 74.88Z"
      fill="#21365F"
    ></path>
    <path
      d="M89.3 88.74V67.35V37.04H31.31L42.82 79.3L89.3 88.74Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M78.98 79.9C80.97 80.3 82.85 78.78 82.85 76.75V59.31V37.04H31.31L40.87 72.17L78.98 79.9Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.3 89.94C89.22 89.94 89.14 89.94 89.06 89.9L42.58 80.46C42.14 80.38 41.78 80.02 41.66 79.58L30.15 37.32C30.07 36.96 30.11 36.56 30.35 36.29C30.59 35.97 30.95 35.81 31.31 35.81H89.3C89.98 35.81 90.49 36.36 90.49 37V88.7C90.49 89.06 90.33 89.42 90.06 89.62C89.86 89.86 89.58 89.94 89.3 89.94ZM43.81 78.27L88.1 87.27V38.28H32.9L43.81 78.27Z"
      fill="#21365F"
    ></path>
    <path
      d="M25.81 75.84C23.5 87.79 20.95 98.02 36.01 96.63C40.19 96.23 43.97 96.11 47.44 96.19L42.82 79.3L25.81 75.84Z"
      fill="white"
    ></path>
    <path
      d="M42.82 79.3L36.17 77.95L38.56 86.71C38.95 88.22 37.84 89.74 36.29 89.74C33.7 89.78 30.95 89.94 28 90.18C26.61 90.3 25.37 90.34 24.26 90.25C24.93 94.72 27.92 97.35 36.05 96.59C40.23 96.19 44.01 96.07 47.48 96.15L42.82 79.3Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.46 97.98C29.55 97.98 26.81 97.03 25.05 95.11C21.59 91.29 22.9 84.64 24.38 76.91L24.61 75.64C24.69 75.32 24.85 75.04 25.13 74.88C25.41 74.72 25.73 74.64 26.05 74.68L43.06 78.15C43.49 78.23 43.85 78.58 43.97 79.02L48.59 95.91C48.71 96.27 48.63 96.67 48.39 96.99C48.15 97.31 47.8 97.46 47.4 97.46C43.81 97.38 40.03 97.54 36.13 97.9C35.21 97.94 34.29 97.98 33.46 97.98ZM26.77 77.27V77.35C25.45 84.12 24.18 90.53 26.84 93.48C28.4 95.23 31.39 95.87 35.93 95.43C39.35 95.11 42.66 95 45.88 94.96L41.9 80.34L26.77 77.27Z"
      fill="#21365F"
    ></path>
    <path
      d="M89.3 107.9H109.09C110.89 107.9 112.32 106.47 112.32 104.67V93.4L89.3 88.74V107.9Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M89.3 103.08H102.68C104.47 103.08 105.91 101.65 105.91 99.85V92.09L89.3 88.7V103.08Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.09 109.14H89.3C88.62 109.14 88.1 108.58 88.1 107.94V88.78C88.1 88.42 88.26 88.06 88.54 87.87C88.82 87.63 89.18 87.55 89.54 87.63L112.56 92.29C113.12 92.41 113.52 92.88 113.52 93.48V104.75C113.56 107.14 111.56 109.14 109.09 109.14ZM90.49 106.7H109.09C110.21 106.7 111.13 105.79 111.13 104.67V94.4L90.49 90.22V106.7Z"
      fill="#21365F"
    ></path>
    <path
      d="M109.09 37.04H89.3V67.35H112.32V40.23C112.32 38.48 110.89 37.04 109.09 37.04Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M105.91 37.04H89.3V60.94H102.68C104.47 60.94 105.91 59.51 105.91 57.71V37.04Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.32 68.59H89.3C88.62 68.59 88.1 68.03 88.1 67.39V37.08C88.1 36.4 88.66 35.89 89.3 35.89H109.09C111.52 35.89 113.52 37.88 113.52 40.31V67.43C113.56 68.03 113 68.59 112.32 68.59ZM90.49 66.16H111.13V40.27C111.13 39.15 110.21 38.24 109.09 38.24H90.49V66.16Z"
      fill="#21365F"
    ></path>
    <path d="M89.3 88.74L112.32 93.4V67.35H89.3V88.74Z" fill="#BDC3D8"></path>
    <path
      d="M89.3 81.97L102.05 84.56C104.04 84.96 105.91 83.44 105.91 81.41V67.35H89.3V81.97Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.32 94.64C112.24 94.64 112.16 94.64 112.08 94.6L89.06 89.9C88.5 89.78 88.1 89.3 88.1 88.7V67.35C88.1 66.68 88.66 66.16 89.3 66.16H112.32C113 66.16 113.52 66.72 113.52 67.35V93.4C113.52 93.76 113.36 94.12 113.08 94.32C112.88 94.52 112.6 94.64 112.32 94.64ZM90.49 87.75L111.13 91.93V68.59H90.49V87.75Z"
      fill="#21365F"
    ></path>
    <path
      d="M36.05 96.63C12.63 98.78 31.74 72.93 26.45 56.08C22.7 44.13 9.28 46.68 9.28 46.68V104.67C9.28 106.47 10.71 107.9 12.51 107.9H79.34C79.3 107.9 70.86 93.4 36.05 96.63Z"
      fill="white"
    ></path>
    <path
      d="M21.59 60.94C26.89 77.79 7.76999 103.64 31.19 101.49C52.22 99.54 63.61 104.04 69.34 107.94H79.3C79.3 107.94 70.86 93.44 36.01 96.67C12.59 98.82 31.7 72.97 26.41 56.12C22.66 44.17 9.23999 46.72 9.23999 46.72V51.42C13.5 51.78 19.32 53.69 21.59 60.94Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.3 109.13H12.47C10.04 109.13 8.04999 107.14 8.04999 104.71V46.72C8.04999 46.12 8.43999 45.65 9.03999 45.53C9.19999 45.49 23.58 42.94 27.6 55.76C29.71 62.45 28.16 70.38 26.77 77.39C25.45 84.16 24.18 90.57 26.84 93.48C28.4 95.23 31.39 95.87 35.93 95.43C71.1 92.17 80.02 106.67 80.38 107.3C80.58 107.66 80.62 108.14 80.38 108.5C80.14 108.9 79.74 109.13 79.3 109.13ZM10.48 47.76V104.67C10.48 105.79 11.39 106.7 12.51 106.7H76.75C73.05 103.08 62.05 95.43 36.17 97.82C30.83 98.34 27.2 97.42 25.09 95.07C21.63 91.25 22.94 84.6 24.42 76.87C25.73 70.14 27.2 62.53 25.29 56.44C22.42 47.36 13.46 47.44 10.48 47.76Z"
      fill="#21365F"
    ></path>
    <path
      d="M42.82 79.3L47.44 96.19C72.77 96.67 79.3 107.9 79.3 107.9H89.3V88.74L42.82 79.3Z"
      fill="white"
    ></path>
    <path
      d="M82.85 87.43V96.63C82.85 98.42 81.41 99.85 79.62 99.85H67.99C76.59 103.28 79.3 107.9 79.3 107.9H89.3V88.74L82.85 87.43Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.3 109.13H79.3C78.86 109.13 78.47 108.9 78.27 108.54C78.19 108.42 71.61 97.9 47.4 97.42C46.88 97.42 46.4 97.07 46.24 96.55L41.66 79.62C41.54 79.22 41.66 78.78 41.94 78.47C42.22 78.15 42.66 78.03 43.06 78.11L89.54 87.55C90.1 87.67 90.49 88.14 90.49 88.74V107.9C90.49 108.58 89.98 109.13 89.3 109.13ZM79.94 106.7H88.06V89.74L44.49 80.9L48.35 95.03C70.3 95.67 78.19 104.32 79.94 106.7Z"
      fill="#21365F"
    ></path>
    <path
      d="M90.73 13.42C79.26 1.95 60.7 1.95 49.23 13.42C37.76 24.89 37.76 43.46 49.23 54.93L69.98 75.68L90.73 54.93C102.2 43.49 102.2 24.89 90.73 13.42Z"
      fill="white"
    ></path>
    <path
      d="M90.73 13.42C89.62 12.31 88.46 11.35 87.23 10.48C95.59 21.95 94.64 38.12 84.28 48.47L63.53 69.26L69.98 75.72L90.73 54.97C102.2 43.49 102.2 24.89 90.73 13.42Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.98 76.91C69.66 76.91 69.34 76.79 69.14 76.55L48.39 55.8C36.48 43.89 36.48 24.5 48.39 12.59C60.3 0.680001 79.7 0.680001 91.61 12.59C97.38 18.36 100.57 26.05 100.57 34.21C100.57 42.38 97.38 50.07 91.61 55.84L70.86 76.59C70.58 76.79 70.3 76.91 69.98 76.91ZM69.98 6.05C62.77 6.05 55.56 8.8 50.07 14.3C39.07 25.29 39.07 43.14 50.07 54.09L69.98 74L89.9 54.09C95.19 48.79 98.14 41.7 98.14 34.17C98.14 26.65 95.19 19.6 89.9 14.26C84.4 8.8 77.19 6.05 69.98 6.05Z"
      fill="#21365F"
    ></path>
    <path
      d="M69.98 50.27C61.08 50.27 53.89 43.07 53.89 34.17C53.89 25.28 61.08 18.08 69.98 18.08C78.88 18.08 86.07 25.28 86.07 34.17C86.07 43.07 78.88 50.27 69.98 50.27Z"
      fill="white"
    ></path>
    <path
      d="M80.74 22.23C81.05 23.46 81.21 24.77 81.21 26.13C81.21 35.01 74 42.22 65.12 42.22C60.98 42.22 57.2 40.63 54.33 38.04C56.08 45.05 62.37 50.27 69.94 50.27C78.82 50.27 86.03 43.06 86.03 34.17C86.07 29.43 84 25.17 80.74 22.23Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.98 51.5C60.42 51.5 52.66 43.73 52.66 34.17C52.66 24.61 60.42 16.85 69.98 16.85C79.54 16.85 87.31 24.61 87.31 34.17C87.27 43.73 79.5 51.5 69.98 51.5ZM69.98 19.28C61.78 19.28 55.08 25.97 55.08 34.17C55.08 42.38 61.78 49.07 69.98 49.07C78.19 49.07 84.88 42.38 84.88 34.17C84.88 25.97 78.19 19.28 69.98 19.28Z"
      fill="#21365F"
    ></path>
    <path
      d="M109.09 20.51H102.64C101.97 20.51 101.45 19.95 101.45 19.32C101.45 18.64 102 18.12 102.64 18.12H109.09C109.77 18.12 110.29 18.68 110.29 19.32C110.33 19.99 109.77 20.51 109.09 20.51Z"
      fill="#21365F"
    ></path>
    <path
      d="M105.91 23.74C105.23 23.74 104.71 23.18 104.71 22.54V16.09C104.71 15.41 105.27 14.9 105.91 14.9C106.58 14.9 107.1 15.45 107.1 16.09V22.54C107.1 23.22 106.55 23.74 105.91 23.74Z"
      fill="#21365F"
    ></path>
    <path
      d="M39.83 14.1H33.38C32.7 14.1 32.18 13.54 32.18 12.91C32.18 12.23 32.74 11.71 33.38 11.71H39.83C40.51 11.71 41.03 12.27 41.03 12.91C41.06 13.54 40.51 14.1 39.83 14.1Z"
      fill="#21365F"
    ></path>
    <path
      d="M36.64 17.33C35.97 17.33 35.45 16.77 35.45 16.13V9.68C35.45 9 36.01 8.48 36.64 8.48C37.32 8.48 37.84 9.04 37.84 9.68V16.13C37.84 16.77 37.28 17.33 36.64 17.33Z"
      fill="#21365F"
    ></path>
    <path
      d="M20.51 26.96H14.06C13.38 26.96 12.86 26.41 12.86 25.77C12.86 25.09 13.42 24.58 14.06 24.58H20.51C21.19 24.58 21.71 25.13 21.71 25.77C21.71 26.45 21.19 26.96 20.51 26.96Z"
      fill="#21365F"
    ></path>
    <path
      d="M17.29 30.19C16.61 30.19 16.09 29.63 16.09 29V22.54C16.09 21.87 16.65 21.35 17.29 21.35C17.96 21.35 18.48 21.91 18.48 22.54V29C18.52 29.67 17.96 30.19 17.29 30.19Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Company3;