const Advice3 = () => (
  <svg viewBox="0 0 99 109">
    <path
      d="M54.49 108.93C43.51 108.93 34.64 100.05 34.64 89.07C34.64 78.09 43.51 69.21 54.49 69.21C65.47 69.21 74.35 78.09 74.35 89.07C74.35 100.05 65.47 108.93 54.49 108.93Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M17.69 86.23C8.28001 86.23 0.670013 78.63 0.670013 69.21C0.670013 59.8 8.28001 52.19 17.69 52.19C27.1 52.19 34.71 59.8 34.71 69.21C34.71 78.63 27.1 86.23 17.69 86.23Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M70.2 56.8C54.49 56.8 41.8 44.11 41.8 28.4C41.8 12.7 54.49 0 70.2 0C85.91 0 98.6 12.7 98.6 28.4C98.6 44.11 85.91 56.8 70.2 56.8Z"
      fill="#E8EBEC"
    ></path>
    <path
      d="M18.18 83.61H9.04001C6.31001 83.61 4.07001 85.84 4.07001 88.57V93.15C4.07001 95.88 6.31001 98.11 9.04001 98.11H18.18V83.61Z"
      fill="white"
    ></path>
    <path
      d="M28.15 67.97H17.4C15.49 67.97 13.96 69.53 13.96 71.41V79.07C13.96 80.99 15.52 82.51 17.4 82.51H28.15V67.97Z"
      fill="white"
    ></path>
    <path
      d="M92.93 67.44H17.69C19.57 71.77 19.57 78.75 17.69 83.04H92.93C89.13 78.72 89.13 71.73 92.93 67.44Z"
      fill="white"
    ></path>
    <path
      d="M90.09 75.24H31.16C26.84 75.24 23.36 78.75 23.36 83.04H92.93C91.05 80.88 90.09 78.08 90.09 75.24Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M83 83.04H9.18002C11.06 87.37 11.06 94.35 9.18002 98.64H83C79.21 94.35 79.21 87.37 83 83.04Z"
      fill="white"
    ></path>
    <path
      d="M80.16 90.84H36.87C32.54 90.84 29.07 94.35 29.07 98.64H83C81.09 96.52 80.16 93.68 80.16 90.84Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.93 84.11H17.69C17.33 84.11 16.98 83.93 16.8 83.61C16.59 83.33 16.59 82.94 16.73 82.62C18.5 78.54 18.5 71.94 16.73 67.87C16.59 67.55 16.62 67.16 16.8 66.87C16.98 66.59 17.33 66.38 17.69 66.38H92.93C93.36 66.38 93.71 66.62 93.89 67.01C94.06 67.4 93.99 67.83 93.71 68.15C90.27 72.09 90.27 78.43 93.71 82.37C93.99 82.69 94.06 83.11 93.89 83.5C93.71 83.86 93.36 84.11 92.93 84.11ZM19.21 81.98H90.84C88.43 77.94 88.43 72.55 90.84 68.5H19.21C20.49 72.55 20.49 77.94 19.21 81.98Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83 99.74H9.18001C8.82001 99.74 8.47001 99.57 8.29001 99.25C8.08001 98.96 8.08001 98.57 8.22001 98.25C9.99001 94.18 9.99001 87.58 8.22001 83.5C8.08001 83.18 8.11001 82.79 8.29001 82.51C8.50001 82.23 8.82001 82.01 9.18001 82.01H83C83.43 82.01 83.78 82.26 83.96 82.65C84.14 83.04 84.06 83.47 83.78 83.79C80.34 87.72 80.34 94.07 83.78 98C84.06 98.32 84.14 98.75 83.96 99.14C83.78 99.49 83.39 99.74 83 99.74ZM10.7 97.61H80.91C78.5 93.57 78.5 88.18 80.91 84.14H10.7C11.98 88.18 11.98 93.54 10.7 97.61Z"
      fill="#21365F"
    ></path>
    <path
      d="M84.42 99.74H9.18C5.45 99.74 2.44 96.73 2.44 93.01V88.75C2.44 85.03 5.45 82.01 9.18 82.01H84.42C85.02 82.01 85.48 82.47 85.48 83.08C85.48 83.68 85.02 84.14 84.42 84.14H9.18C6.62 84.14 4.57 86.2 4.57 88.75V93.01C4.57 95.56 6.62 97.61 9.18 97.61H84.42C85.02 97.61 85.48 98.08 85.48 98.68C85.48 99.25 84.99 99.74 84.42 99.74Z"
      fill="#21365F"
    ></path>
    <path
      d="M94.35 84.11H17.69C14.74 84.11 12.37 81.73 12.37 78.79V71.7C12.37 68.75 14.74 66.38 17.69 66.38H94.35C94.95 66.38 95.41 66.84 95.41 67.44C95.41 68.04 94.95 68.5 94.35 68.5H17.69C15.91 68.5 14.5 69.92 14.5 71.7V78.79C14.5 80.56 15.91 81.98 17.69 81.98H94.35C94.95 81.98 95.41 82.44 95.41 83.04C95.41 83.64 94.91 84.11 94.35 84.11Z"
      fill="#21365F"
    ></path>
    <path
      d="M81.8 78.79H76.65C76.05 78.79 75.59 78.33 75.59 77.72C75.59 77.12 76.05 76.66 76.65 76.66H81.8C82.4 76.66 82.86 77.12 82.86 77.72C82.86 78.33 82.4 78.79 81.8 78.79Z"
      fill="#21365F"
    ></path>
    <path
      d="M70.98 78.79H45.42C44.81 78.79 44.35 78.33 44.35 77.72C44.35 77.12 44.81 76.66 45.42 76.66H70.98C71.58 76.66 72.04 77.12 72.04 77.72C72.04 78.33 71.58 78.79 70.98 78.79Z"
      fill="#21365F"
    ></path>
    <path
      d="M56.27 73.82H31.23C30.63 73.82 30.17 73.36 30.17 72.76C30.17 72.16 30.63 71.7 31.23 71.7H56.27C56.87 71.7 57.33 72.16 57.33 72.76C57.33 73.33 56.83 73.82 56.27 73.82Z"
      fill="#21365F"
    ></path>
    <path
      d="M71.87 94.03H41.16C40.56 94.03 40.1 93.57 40.1 92.97C40.1 92.37 40.56 91.91 41.16 91.91H71.87C72.47 91.91 72.93 92.37 72.93 92.97C72.93 93.57 72.47 94.03 71.87 94.03Z"
      fill="#21365F"
    ></path>
    <path
      d="M53.43 89.07H49.64C49.03 89.07 48.57 88.61 48.57 88.01C48.57 87.4 49.03 86.94 49.64 86.94H53.43C54.03 86.94 54.49 87.4 54.49 88.01C54.49 88.61 54 89.07 53.43 89.07Z"
      fill="#21365F"
    ></path>
    <path
      d="M43.96 89.07H25.56C24.96 89.07 24.5 88.61 24.5 88.01C24.5 87.4 24.96 86.94 25.56 86.94H43.96C44.56 86.94 45.03 87.4 45.03 88.01C45.03 88.61 44.53 89.07 43.96 89.07Z"
      fill="#21365F"
    ></path>
    <path
      d="M54.6 7.79999C41.27 7.79999 30.45 18.62 30.45 31.95C30.45 51.56 46.34 67.44 65.95 67.44H78.71V31.95C78.75 18.62 67.93 7.79999 54.6 7.79999Z"
      fill="white"
    ></path>
    <path
      d="M70.27 13.62C68.22 12.98 66.05 12.66 63.82 12.66C51.69 12.66 41.83 22.52 41.83 34.64C41.83 52.51 56.3 66.98 74.17 66.98H78.75V31.95C78.75 24.61 75.45 18.05 70.27 13.62Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.75 68.5H65.98C45.84 68.5 29.42 52.12 29.42 31.95C29.42 18.05 40.74 6.73999 54.63 6.73999C68.53 6.73999 79.85 18.05 79.85 31.95V67.44C79.81 68.01 79.31 68.5 78.75 68.5ZM54.6 8.85999C41.87 8.85999 31.52 19.22 31.52 31.95C31.52 50.92 46.94 66.38 65.95 66.38H77.65V31.95C77.68 19.22 67.33 8.85999 54.6 8.85999Z"
      fill="#21365F"
    ></path>
    <path
      d="M78.75 33.37H58.89V47.55C58.89 58.54 67.79 67.4 78.75 67.4V33.37Z"
      fill="white"
    ></path>
    <path
      d="M68.78 33.37V47.55C68.78 58.54 73.22 67.4 78.71 67.4V33.33H68.78V33.37Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.75 68.5C67.19 68.5 57.83 59.11 57.83 47.58V33.4C57.83 32.8 58.29 32.34 58.89 32.34H78.75C79.35 32.34 79.81 32.8 79.81 33.4V67.48C79.81 68.01 79.31 68.5 78.75 68.5ZM59.92 34.43V47.55C59.92 57.55 67.79 65.77 77.65 66.34V34.43H59.92Z"
      fill="#21365F"
    ></path>
    <path
      d="M61.12 38.4L54.6 44L48.08 38.4V27.69H61.12V38.4Z"
      fill="white"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.6 45.07C54.35 45.07 54.1 45 53.89 44.82L47.37 39.22C47.12 39 47.01 38.72 47.01 38.4V27.69C47.01 27.09 47.47 26.63 48.08 26.63H61.12C61.73 26.63 62.19 27.09 62.19 27.69V38.4C62.19 38.72 62.05 39 61.83 39.22L55.31 44.82C55.1 45 54.85 45.07 54.6 45.07ZM54.6 42.58L60.06 37.9V28.72H49.14V37.9L54.6 42.58Z"
      fill="#21365F"
    ></path>
    <path
      d="M41.13 41.17C33.67 41.17 27.65 35.14 27.65 27.69C27.65 20.24 33.67 14.22 41.13 14.22C48.58 14.22 54.6 20.24 54.6 27.69C54.6 35.14 48.58 41.17 41.13 41.17Z"
      fill="white"
    ></path>
    <path
      d="M68.07 41.17C60.62 41.17 54.6 35.14 54.6 27.69C54.6 20.24 60.62 14.22 68.07 14.22C75.52 14.22 81.55 20.24 81.55 27.69C81.55 35.14 75.52 41.17 68.07 41.17Z"
      fill="white"
    ></path>
    <path
      d="M54.6 27.69C54.6 26.17 54.32 24.68 53.85 23.33C51.8 22.05 49.39 21.31 46.8 21.31C39.35 21.31 33.32 27.34 33.32 34.78C33.32 36.31 33.61 37.8 34.07 39.15C36.13 40.42 38.54 41.17 41.13 41.17C48.57 41.17 54.6 35.14 54.6 27.69Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M81.58 27.69C81.58 26.17 81.3 24.68 80.84 23.33C78.78 22.05 76.37 21.31 73.78 21.31C66.34 21.31 60.31 27.34 60.31 34.78C60.31 36.31 60.59 37.8 61.05 39.15C63.11 40.42 65.52 41.17 68.11 41.17C75.52 41.17 81.58 35.14 81.58 27.69Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.13 42.23C33.11 42.23 26.59 35.71 26.59 27.69C26.59 19.68 33.11 13.15 41.13 13.15C49.14 13.15 55.66 19.68 55.66 27.69C55.66 35.71 49.14 42.23 41.13 42.23ZM41.13 15.28C34.28 15.28 28.71 20.85 28.71 27.69C28.71 34.54 34.28 40.1 41.13 40.1C47.97 40.1 53.54 34.54 53.54 27.69C53.54 20.85 47.97 15.28 41.13 15.28Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.07 42.23C60.06 42.23 53.54 35.71 53.54 27.69C53.54 19.68 60.06 13.15 68.07 13.15C76.09 13.15 82.61 19.68 82.61 27.69C82.65 35.71 76.12 42.23 68.07 42.23ZM68.07 15.28C61.23 15.28 55.66 20.85 55.66 27.69C55.66 34.54 61.23 40.1 68.07 40.1C74.92 40.1 80.48 34.54 80.48 27.69C80.48 20.85 74.92 15.28 68.07 15.28Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.13 42.23C33.11 42.23 26.59 35.71 26.59 27.69C26.59 19.68 33.11 13.15 41.13 13.15C49.14 13.15 55.66 19.68 55.66 27.69C55.66 35.71 49.14 42.23 41.13 42.23ZM41.13 15.28C34.28 15.28 28.71 20.85 28.71 27.69C28.71 34.54 34.28 40.1 41.13 40.1C47.97 40.1 53.54 34.54 53.54 27.69C53.54 20.85 47.97 15.28 41.13 15.28Z"
      fill="#21365F"
    ></path>
    <path
      d="M41.13 32.66C38.38 32.66 36.16 30.44 36.16 27.69C36.16 24.95 38.38 22.73 41.13 22.73C43.87 22.73 46.09 24.95 46.09 27.69C46.09 30.44 43.87 32.66 41.13 32.66Z"
      fill="white"
    ></path>
    <path
      d="M68.07 32.66C65.33 32.66 63.11 30.44 63.11 27.69C63.11 24.95 65.33 22.73 68.07 22.73C70.82 22.73 73.04 24.95 73.04 27.69C73.04 30.44 70.82 32.66 68.07 32.66Z"
      fill="white"
    ></path>
    <path
      d="M46.09 27.69C46.09 26.95 45.91 26.24 45.59 25.6C45.52 25.6 45.45 25.56 45.38 25.56C42.65 25.56 40.42 27.8 40.42 30.53C40.42 31.27 40.59 31.98 40.91 32.62C40.98 32.62 41.05 32.66 41.13 32.66C43.86 32.66 46.09 30.42 46.09 27.69Z"
      fill="#BDC3D8"
    ></path>
    <path
      d="M73.04 27.69C73.04 26.95 72.86 26.24 72.54 25.6C72.47 25.6 72.4 25.56 72.33 25.56C69.6 25.56 67.36 27.8 67.36 30.53C67.36 31.27 67.54 31.98 67.86 32.62C67.93 32.62 68 32.66 68.07 32.66C70.84 32.66 73.04 30.42 73.04 27.69Z"
      fill="#BDC3D8"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.13 33.72C37.79 33.72 35.1 31.03 35.1 27.69C35.1 24.36 37.79 21.66 41.13 21.66C44.46 21.66 47.15 24.36 47.15 27.69C47.15 31.03 44.42 33.72 41.13 33.72ZM41.13 23.79C38.96 23.79 37.22 25.53 37.22 27.69C37.22 29.85 38.96 31.59 41.13 31.59C43.29 31.59 45.03 29.85 45.03 27.69C45.03 25.53 43.25 23.79 41.13 23.79Z"
      fill="#21365F"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M68.07 33.72C64.74 33.72 62.05 31.03 62.05 27.69C62.05 24.36 64.74 21.66 68.07 21.66C71.41 21.66 74.1 24.36 74.1 27.69C74.1 31.03 71.41 33.72 68.07 33.72ZM68.07 23.79C65.91 23.79 64.17 25.53 64.17 27.69C64.17 29.85 65.91 31.59 68.07 31.59C70.24 31.59 71.97 29.85 71.97 27.69C71.97 25.53 70.24 23.79 68.07 23.79Z"
      fill="#21365F"
    ></path>
    <path
      d="M38.29 15.28C37.69 15.28 37.22 14.82 37.22 14.22C37.22 11.28 34.85 8.89999 31.91 8.89999C31.3 8.89999 30.84 8.43999 30.84 7.83999C30.84 7.22999 31.3 6.76999 31.91 6.76999C36.02 6.76999 39.35 10.11 39.35 14.22C39.35 14.79 38.86 15.28 38.29 15.28Z"
      fill="#21365F"
    ></path>
    <path
      d="M71.62 15.28C71.02 15.28 70.56 14.82 70.56 14.22C70.56 10.11 73.89 6.76999 78 6.76999C78.6 6.76999 79.06 7.22999 79.06 7.83999C79.06 8.43999 78.6 8.89999 78 8.89999C75.06 8.89999 72.68 11.28 72.68 14.22C72.72 14.79 72.22 15.28 71.62 15.28Z"
      fill="#21365F"
    ></path>
    <path
      d="M23.36 58.58H17.69C17.08 58.58 16.62 58.11 16.62 57.51C16.62 56.91 17.08 56.45 17.69 56.45H23.36C23.96 56.45 24.42 56.91 24.42 57.51C24.42 58.08 23.96 58.58 23.36 58.58Z"
      fill="#21365F"
    ></path>
    <path
      d="M20.52 61.41C19.92 61.41 19.46 60.95 19.46 60.35V54.68C19.46 54.07 19.92 53.61 20.52 53.61C21.13 53.61 21.59 54.07 21.59 54.68V60.35C21.59 60.92 21.13 61.41 20.52 61.41Z"
      fill="#21365F"
    ></path>
    <path
      d="M20.52 50.03C19.94 50.03 19.46 49.56 19.46 48.97C19.46 48.38 19.94 47.9 20.52 47.9C21.11 47.9 21.59 48.38 21.59 48.97C21.59 49.56 21.11 50.03 20.52 50.03Z"
      fill="#21365F"
    ></path>
    <path
      d="M20.52 45.78C19.94 45.78 19.46 45.3 19.46 44.71C19.46 44.12 19.94 43.65 20.52 43.65C21.11 43.65 21.59 44.12 21.59 44.71C21.59 45.3 21.11 45.78 20.52 45.78Z"
      fill="#21365F"
    ></path>
    <path
      d="M20.52 40.1C19.94 40.1 19.46 39.63 19.46 39.04C19.46 38.45 19.94 37.97 20.52 37.97C21.11 37.97 21.59 38.45 21.59 39.04C21.59 39.63 21.11 40.1 20.52 40.1Z"
      fill="#21365F"
    ></path>
    <path
      d="M20.52 31.59C19.94 31.59 19.46 31.12 19.46 30.53C19.46 29.94 19.94 29.46 20.52 29.46C21.11 29.46 21.59 29.94 21.59 30.53C21.59 31.12 21.11 31.59 20.52 31.59Z"
      fill="#21365F"
    ></path>
    <path
      d="M94.35 33.37H88.67C88.07 33.37 87.61 32.9 87.61 32.3C87.61 31.7 88.07 31.24 88.67 31.24H94.35C94.95 31.24 95.41 31.7 95.41 32.3C95.41 32.87 94.91 33.37 94.35 33.37Z"
      fill="#21365F"
    ></path>
    <path
      d="M91.51 36.2C90.91 36.2 90.45 35.74 90.45 35.14V29.46C90.45 28.86 90.91 28.4 91.51 28.4C92.11 28.4 92.57 28.86 92.57 29.46V35.14C92.57 35.71 92.08 36.2 91.51 36.2Z"
      fill="#21365F"
    ></path>
    <path
      d="M91.51 41.88C90.92 41.88 90.45 41.4 90.45 40.81C90.45 40.22 90.92 39.75 91.51 39.75C92.1 39.75 92.57 40.22 92.57 40.81C92.57 41.4 92.1 41.88 91.51 41.88Z"
      fill="#21365F"
    ></path>
    <path
      d="M91.51 46.13C90.92 46.13 90.45 45.65 90.45 45.07C90.45 44.48 90.92 44 91.51 44C92.1 44 92.57 44.48 92.57 45.07C92.57 45.65 92.1 46.13 91.51 46.13Z"
      fill="#21365F"
    ></path>
    <path
      d="M91.51 51.8C90.92 51.8 90.45 51.33 90.45 50.74C90.45 50.15 90.92 49.68 91.51 49.68C92.1 49.68 92.57 50.15 92.57 50.74C92.57 51.33 92.1 51.8 91.51 51.8Z"
      fill="#21365F"
    ></path>
    <path
      d="M91.51 60.35C90.92 60.35 90.45 59.87 90.45 59.28C90.45 58.7 90.92 58.22 91.51 58.22C92.1 58.22 92.57 58.7 92.57 59.28C92.57 59.87 92.1 60.35 91.51 60.35Z"
      fill="#21365F"
    ></path>
    <path
      d="M9.18 44.36H3.5C2.9 44.36 2.44 43.9 2.44 43.29C2.44 42.69 2.9 42.23 3.5 42.23H9.18C9.78 42.23 10.24 42.69 10.24 43.29C10.24 43.9 9.75 44.36 9.18 44.36Z"
      fill="#21365F"
    ></path>
    <path
      d="M6.34 47.19C5.74 47.19 5.28 46.73 5.28 46.13V40.46C5.28 39.85 5.74 39.39 6.34 39.39C6.94 39.39 7.41 39.85 7.41 40.46V46.13C7.41 46.73 6.91 47.19 6.34 47.19Z"
      fill="#21365F"
    ></path>
    <path
      d="M16.27 15.96H10.6C9.99 15.96 9.53 15.49 9.53 14.89C9.53 14.29 9.99 13.83 10.6 13.83H16.27C16.87 13.83 17.33 14.29 17.33 14.89C17.33 15.49 16.87 15.96 16.27 15.96Z"
      fill="#21365F"
    ></path>
    <path
      d="M13.43 18.79C12.83 18.79 12.37 18.33 12.37 17.73V12.06C12.37 11.45 12.83 10.99 13.43 10.99C14.04 10.99 14.5 11.45 14.5 12.06V17.73C14.5 18.33 14.04 18.79 13.43 18.79Z"
      fill="#21365F"
    ></path>
    <path
      d="M88.67 15.96H83C82.4 15.96 81.94 15.49 81.94 14.89C81.94 14.29 82.4 13.83 83 13.83H88.67C89.28 13.83 89.74 14.29 89.74 14.89C89.74 15.49 89.28 15.96 88.67 15.96Z"
      fill="#21365F"
    ></path>
    <path
      d="M85.84 18.79C85.23 18.79 84.77 18.33 84.77 17.73V12.06C84.77 11.45 85.23 10.99 85.84 10.99C86.44 10.99 86.9 11.45 86.9 12.06V17.73C86.9 18.33 86.4 18.79 85.84 18.79Z"
      fill="#21365F"
    ></path>
  </svg>
);

export default Advice3;