import { Search } from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { BlogCard, CardTitle } from "../../components/custom";
import Hero from "../../components/custom/hero";
import { Pagination, Button, Input } from "../../components/ui";
import { useLanguageContext } from "../../context/language";
import "./style.scss";
import { EmptyTableWrapper } from "../../components/custom/table/styles";
import { SentimentVeryDissatisfied } from "@mui/icons-material";

const Blog = () => {
  const [state, setState] = useState({
    temp: "",
    posts: [],
  });

  const { t, lang } = useLanguageContext();
  const { type } = useParams();

  const BlogPosts = t("BlogPosts");


  useEffect(() => {
    if (type) {
      setState({ ...state, posts: BlogPosts[type] });
    } else {
      setState({ ...state, posts: [...BlogPosts.advices, ...BlogPosts.news] });
    }
  }, [type, lang]);

  return (
    <Fragment>
      <Hero img="/assets/images/hero/hero-blog.jpg" />
      <div className="cv-container cv-blog-main">
        <CardTitle
          title={
            <>
              <span className="secondary">{t("Blog")}</span> {t("i vijesti")}
            </>
          }
        />
        {state && state.posts && <div className="cv-blog-filter">
          <div className="cv-blog-filter-categories">
            <Button
              color="secondary"
              variant="contained"
              size="large"
              component={Link}
              to="/public/blog/news"
            >
              {t("VIJESTI")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              component={Link}
              to="/public/blog/advices"
            >
              {t("SAVJETI")}
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={() => setState({ ...state, type: 1 })}
              size="large"
              component={Link}
              to="/public/blog"
            >
              {t("SVE")}
            </Button>
          </div>
          <Input
            value={state.temp}
            onChange={(temp) => setState({ ...state, temp })}
            placeholder={t("Pronađite blog")}
            className="cv-blog-filter-search"
            InputProps={{ endAdornment: <Search /> }}
          />
        </div>}
        {state && state.posts && state.posts.length > 0 ? (<div className="cv-blog-posts">
          {state.posts
            .filter(
              (x) =>
                x.title.toLowerCase().includes(state.temp.toLowerCase()) ||
                x.short_description
                  .toLowerCase()
                  .includes(state.temp.toLowerCase())
            )
            .map((x, y) => (
              <BlogCard
                key={y}
                fixedRatio
                type={x.category}
                direction="vertical"
                created_at={x.date}
                image={x.image}
                title={x.title}
                description={x.short_description}
                to={x.link}
              />
            ))}
        </div>) : <div style={{ alignSelf: "center" }}>
          <EmptyTableWrapper>
            <SentimentVeryDissatisfied />
            <h3>Nema rezultata</h3>
            <p>Izgleda da ovaj blog ne postoji!</p>
          </EmptyTableWrapper>
        </div>}
        <div className="cv-pagination-holder cv-pagination-holder-right">
          <Pagination count={1} page={1} setPage={() => { }} />
        </div>
      </div>
    </Fragment>
  );
};

export default Blog;
