import React from "react";
import { useHistory } from "react-router-dom";
import { SimpleCard } from "../../ui";
import "./style.scss";

const DisplayCard = ({ to, image, title, headActions, infos, bodyActions }) => {
  const history = useHistory();

  const changeLocation = () => {
    if (to) {
      history.push(to);
    }
  };

  return (
    <SimpleCard className="cv-displaycard-main" onClick={changeLocation}>
      <div className="cv-displaycard-head">
        <div className="cv-displaycard-head-content">
          {image ? (
            <div className="cv-displaycard-head-content-image">
              <img src={image} alt="Profile" />
            </div>
          ) : null}
          {title}
        </div>
        <div
          className="cv-displaycard-head-action"
          onClick={(e) => e.stopPropagation()}
        >
          {headActions}
        </div>
      </div>
      <div className="cv-displaycard-body">
        <div className="cv-displaycard-infos">
          {infos.map((x, y) => (
            <div className="cv-displaycard-info" key={y}>
              {x.title ? <span>{x.title}: </span> : null}
              {x.value}
            </div>
          ))}
        </div>
        {bodyActions ? (
          <div className="cv-displaycard-body-actions">
            {bodyActions.map((x, y) => (
              <div
                className="cv-displaycard-body-action"
                key={y}
                onClick={(e) => e.stopPropagation()}
              >
                {x}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </SimpleCard>
  );
};

export default DisplayCard;
