import { Autocomplete as MuiAutocomplete } from "@mui/material";
import { AdminInput } from "../";

const Autocomplete = ({ options = [], label, color = "primary", value, onChange, ...params }) => {

  return (
    <MuiAutocomplete
      value={value}
      inputValue={value}
      onInputChange={(a, b) => onChange(b)}
      options={options}
      classes={{ paper: "cv-paper-radius" }}
      {...params}
      renderInput={(params) => (
        <AdminInput color={color} {...params} label={label} />
      )}
    />
  );
};

export default Autocomplete;
