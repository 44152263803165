import { CloudUpload, Delete, Edit, Save, Close, EditOutlined, Add } from "@mui/icons-material";
import { Alert, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { CompanyAPI } from "../../../api/v2";
import {
  AdminInput,
  AdminInputCard,
  AdminPhoneInput,
  AdminSelect,
} from "../../../components/admin";
import PhoneInput from "react-phone-input-2";
import RichText from "../../../components/admin/richtext";
import {
  DashTitle,
  DataDisplay,
  ModalVideo,
  Progress,
  UploadImage,
} from "../../../components/custom";
import { SimpleCard, Button } from "../../../components/ui";
import LeavePageModal from "../../../components/ui/leave-page-modal";
import { useLanguageContext } from "../../../context/language";
import UserImage from '../../../assets/images/nouser5.png';
import NoBGImage from '../../../assets/images/nobg.jpg';
import { useUserContext } from "../../../context/user";
import "./style.scss";
import "react-phone-input-2/lib/material.css";
import { removeFalsy } from "../../../utils/misc";

const ProfileEdit = () => {
  const [state, setState] = useState({
    full_name: "",
    company_description: "",
    company_video: "",
    country_id: "",
    city_id: "",
    background_image: "",
    profile_image: "",
    company_activity: "",
    contact_email: "",
    company_user: {
      contact_person: "",
      contact_person_position: "",
      contact_phone: "",
      contact_mail: "",
    },
    email: "",
    number_of_employees_from: "",
    number_of_employees_to: "",
    website: "",
    facebook: "",
    instagram: "",
    address: "",
    pib: "",
    pdv: "",
  });
  console.log("test", state?.company_user?.contact_phone)

  const [errors, setErrors] = useState({
    email: false,
    contact_phone: false,
    full_name: false,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [richText, setRichText] = useState("");
  const { t } = useLanguageContext();

  const {
    countries: allCountries,
    cities: allCities,
    user,
    setUser,
  } = useUserContext();

  const getCompanyData = async () => {
    const company = await CompanyAPI.info();
    const cUser = company.company_users.find((x) => true) || {};
    setState({
      ...state,
      full_name: company.full_name,
      company_description: company.company_description,
      company_video: company.company_video,
      country_id: company.country.id,
      city_id: company.city && company.city.id,
      background_image: company.background_image,
      profile_image: company.profile_image,
      company_activity: company.company_activity,
      contact_email: company.contact_email,
      company_user: {
        ...state.company_user,
        contact_mail: cUser.contact_mail,
        contact_person: cUser.contact_person,
        contact_person_position: cUser.contact_person_position,
        contact_phone: cUser.contact_phone,
      },
      email: company.email,
      number_of_employees_from: company.number_of_employees_from,
      number_of_employees_to: company.number_of_employees_to,
      website: company.website,
      facebook: company.facebook,
      instagram: company.instagram,
      address: company.address,
      pib: company.pib,
      pdv: company.pdv,
    });
    setRichText(company.company_description);
  };
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getCompanyData();
    setCountries(allCountries.map((x) => ({ value: x.id, label: x.name })));
  }, []);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [promptOpen, setPromptOpen] = useState(false);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(state.country_id))
      .map((x) => ({ value: x.id, label: x.name }));
    setCities(filteredCities);
  }, [state.country_id]);

  const getPercent = () => {
    const len = [
      state.full_name,
      state.company_description,
      state.company_activity,
      state.profile_image,
      state.background_image,
      state.company_user.contact_phone,
      state.email,
      state.country_id,
      state.city_id,
      state.address,
      state.pib,
      user.is_email_verified ? true : null,
      user.is_phone_verified ? true : null,
    ];

    const fLen = len.filter((x) =>
      Array.isArray(x) ? x.length > 0 : ![null, undefined, ""].includes(x)
    ).length;
    return Math.round((fLen / len.length) * 100);
  };

  const saveChanges = async () => {
    if (state.full_name.trim() === "") {
      return toast.error(t`Naziv kompanije polje je obavezno`)
    }
    if (state && !state.company_activity || state && state.company_activity && state.company_activity === null) {
      return toast.error(t`Delatnost kompanije polje je obavezno`)
    }
    if (state && !state.company_user.contact_person || state && state.company_user.contact_person && state.company_user.contact_person === null) {
      return toast.error(t`Kontakt osoba polje je obavezno`)
    }
    if (state && !state.company_user.contact_person_position || state && state.company_user.contact_person_position && state.company_user.contact_person_position === null) {
      return toast.error(t`Pozicija kontakt osobe polje je obavezno`)
    }
    if (state && !state.company_user.contact_phone || state && state.company_user.contact_phone && state.company_user.contact_phone === null) {
      return toast.error(t`Broj telefona polje je obavezno`)
    }
    if (state && !state.email || state && state.email && state.email === null) {
      return toast.error(t`Korisnički Email polje je obavezno`)
    }
    if (state && !state.contact_email || state && state.contact_email && state.contact_email === null) {
      return toast.error(t`Kontakt email polje je obavezno`)
    }
    if (state && !state.country_id || state && state.country_id && state.country_id === null) {
      return toast.error(t`Država polje je obavezno`)
    }
    if (state && !state.city_id || state && state.city_id && state.city_id === null) {
      return toast.error(t`Grad polje je obavezno`)
    }
    if (state.address.trim() === "") {
      return toast.error(t`Fizička adresa polje je obavezno`)
    }
    if (state && !state.pib || state && state.pib && state.pib === null) {
      return toast.error(t`PIB je obavezno`)
    }
    if (state && !state.pdv || state && state.pdv && state.pdv === null) {
      return toast.error(t`PDV polje je obavezno`)
    }
    try {
      const { company_user, ...values } = state;
      const res = await CompanyAPI.update(
        removeFalsy(
          {
            ...values,
            ...company_user,
            contact_phone:
              company_user.contact_phone.charAt(0) === "+"
                ? company_user.contact_phone
                : `+${company_user.contact_phone}`,
          },
          [null, undefined]
        )
      );
      if (values.email !== user.email) {
        setUser({ ...user, ...res, is_email_verified: 0 });
        history.push("/dashboard/profile");
        return toast.success(t`Izmjene su uspešno sačuvane`);
      } else {
        setUser({ ...user, ...res });
        history.push("/dashboard/profile");
        return toast.success(t`Izmjene su uspešno sačuvane`);
      }
    } catch (e) {
      if (e.response.data.message.includes("Phone must be unique")) {
        toast.error(t`Broj telefona već postoji`);
      } else {
        toast.error(t`Broj telefona polje je obavezno`);
      }
    }
  };

  const backgroundImageRef = useRef();
  const companyVideoRef = useRef();

  const uploadProfileImage = async (files) => {
    try {
      const file = files[0];
      const { profile_image } = await CompanyAPI.updateProfileImage(file);
      setUser({ ...user, profile_image });
      setState({ ...state, profile_image });
      toast.success(t`Profilna slika je uspešno promenjena`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  };

  const uploadBackgroundImage = async (e) => {
    try {
      const file = e.target.files[0];
      const { background_image } = await CompanyAPI.updateBackgroundImage(file);
      setUser({ ...user, background_image });
      setState({ ...state, background_image });
      toast.success(t`Pozadina profila je uspešno promenjena`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
    handleClose();
  };

  const uploadCompanyVideo = async (e) => {
    try {
      setIsUploadingVideo(true);
      const file = e.target.files[0];
      const { company_video } = await CompanyAPI.updateProfileVideo(file);
      setUser({ ...user, company_video });
      setState({ ...state, company_video });
      toast.success(t`Video kompanije je uspešno promenjen`);
    } catch {
      toast.error(t`Došlo je do greške`);
    } finally {
      setIsUploadingVideo(false);
    }
  };
  // Convert file to blob
  async function getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }
  // End convert

  const deleteProfileImage = async () => {
    try {
      const image = await getFileFromUrl(UserImage);
      const { profile_image } = await CompanyAPI.updateProfileImage(image);
      setState({ ...state, profile_image });
      setUser({ ...user, profile_image });
      toast.success(t`Profilna slika je uspešno izbrisana`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
  }
  const deleteBackgroundImage = async () => {
    try {
      const image = await getFileFromUrl(NoBGImage);
      const { background_image } = await CompanyAPI.updateBackgroundImage(image);
      setState({ ...state, background_image });
      setUser({ ...user, background_image });
      toast.success(t`Pozadina profila je uspešno izbrisana`);
    } catch {
      toast.error(t`Došlo je do greške`);
    }
    handleClose();
  }

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Profil")}
          subtitle={t("Sve informacije o profilu kompanije")}
          actions={[
            <Button
              variant="contained"
              color="default"
              startIcon={<Close />}
              size="large"
              onClick={() => setPromptOpen(true)}
            >
              {t("OTKAŽITE")}
            </Button>,
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Save />}
              size="large"
              onClick={saveChanges}
            >
              {t("SAČUVAJ IZMJENE")}
            </Button>,
          ]}
        />
        {state ? (
          <div className="cv-dashboard-companyprofile-main cv-dashboard-order-content">
            <SimpleCard
              className="cv-dashboard-companyprofile-card"
              title={t("PREGLED PROFILA")}
            >
              <div className="cv-dashboard-companyprofile-images">
                <div className="cv-dashboard-companyprofile-images-cover-wrapper">
                  <div className="cv-dashboard-companyprofile-images-cover">
                    {state.background_image ? (
                      <img src={state.background_image} />
                    ) : <img src={NoBGImage} />}
                    <input
                      type="file"
                      ref={backgroundImageRef}
                      onChange={uploadBackgroundImage}
                      hidden
                    />
                  </div>
                  {/* <IconButton
                    className="cv-dashboard-companyprofile-cover-upload"
                    onClick={() => backgroundImageRef.current.click()}
                  >
                    <CloudUpload />
                  </IconButton> */}
                  {/* <Button
                    className="cv-dashboard-companyprofile-cover-upload"
                    variant="contained"
                    color="secondary"
                    onClick={() => backgroundImageRef.current?.click()}
                  >    
                  <EditOutlined />
                  </Button> */}
                  <Button
                    className="cv-dashboard-companyprofile-cover-upload"
                    variant="contained"
                    color="secondary"
                    onClick={handleClick}
                  >
                    <EditOutlined />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => backgroundImageRef.current?.click()}>
                      <ListItemIcon>
                        <Add />
                      </ListItemIcon>
                      <ListItemText>Izmeni fotografiju</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={deleteBackgroundImage}>
                      <ListItemIcon>
                        <Delete />
                      </ListItemIcon>
                      <ListItemText>Obriši fotografiju</ListItemText>
                    </MenuItem>
                  </Menu>

                </div>
                <UploadImage
                  src={state.profile_image}
                  onUpload={uploadProfileImage}
                  onDelete={deleteProfileImage}
                />
              </div>
              {!state.profile_image || !state.background_image ? (
                <div className="cv-dashboard-profile-alert">
                  <Alert severity="info">
                    Kako bi dodatno kompletirali profil potrebno je dodati
                    sledeća polja:{" "}
                    <span className="cv-dashboard-profile-alert-bold">
                      {[
                        !state.profile_image ? "profilnu sliku" : null,
                        !state.background_image ? "naslovnu sliku" : null,
                      ]
                        .filter((x) => x !== null)
                        .join(", ")}
                    </span>
                  </Alert>
                </div>
              ) : null}
              <div className="cv-dashboard-companyprofile-content">
                <div className="cv-dashboard-companyprofile-content-side">
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Naziv kompanije")}
                    value={state.full_name}
                    onChange={(full_name) => setState({ ...state, full_name })}
                    error={state && !state.full_name || state && state.full_name && state.full_name === null}
                    required
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label="Delatnost kompanije"
                    required
                    value={state.company_activity}
                    onChange={(company_activity) =>
                      setState({ ...state, company_activity })
                    }
                    error={state && !state.company_activity || state && state.company_activity && state.company_activity === null}
                  />
                  <AdminInputCard
                    className="cv-mb-20"
                    label={t("Opis kompanije") + " *"}
                  >
                    <RichText
                      value={richText}
                      setValue={(company_description) =>
                        setState({ ...state, company_description })
                      }
                    />
                  </AdminInputCard>
                  <div className="cv-dashboard-companyprofile-custom-label">
                    <span>{t("Video kompanije")}</span>
                    <input
                      type="file"
                      ref={companyVideoRef}
                      onChange={uploadCompanyVideo}
                      hidden
                    />
                    <IconButton onClick={() => companyVideoRef.current.click()}>
                      <CloudUpload />
                    </IconButton>
                  </div>
                  <SimpleCard className="cv-dashboard-companyprofile-video cv-mb-20">
                    <ModalVideo
                      isUploading={isUploadingVideo}
                      title={t("VIDEO KOMPANIJE")}
                      src={state.company_video}
                    />
                  </SimpleCard>
                  <div className="cv-dashboard-companyprofile-employees-wrap">
                    <p>Broj zaposlenih:</p>
                    <div>
                      <AdminInput
                        label="Od"
                        color="secondary"
                        className="cv-dashboard-companyprofile-employees-data"
                        type="number"
                        value={state.number_of_employees_from}
                        min={0}
                        onChange={(number_of_employees_from) => {
                          setState({ ...state, number_of_employees_from });
                        }}
                      />
                      <span>-</span>
                      <AdminInput
                        label="Do"
                        color="secondary"
                        className="cv-dashboard-companyprofile-employees-data"
                        type="number"
                        value={state.number_of_employees_to}
                        min={state.number_of_employees_from}
                        onChange={(number_of_employees_to) => {
                          setState({ ...state, number_of_employees_to });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="cv-dashboard-companyprofile-content-side">
                  <AdminInputCard
                    label={t("Informacije o kontakt osobi")}
                    className="cv-mb-20"
                  >
                    <AdminInput
                      color="secondary"
                      className="cv-mb-20"
                      label={t("Kontakt osoba")}
                      value={state.company_user.contact_person}
                      onChange={(contact_person) =>
                        setState({
                          ...state,
                          company_user: {
                            ...state.company_user,
                            contact_person,
                          },
                        })
                      }
                      error={state && !state.company_user.contact_person || state && state.company_user.contact_person && state.company_user.contact_person === null}
                    />
                    <AdminInput
                      color="secondary"
                      className="cv-mb-20"
                      label={t("Pozicija kontakt osobe")}
                      value={state.company_user.contact_person_position}
                      onChange={(contact_person_position) =>
                        setState({
                          ...state,
                          company_user: {
                            ...state.company_user,
                            contact_person_position,
                          },
                        })
                      }
                      error={state && !state.company_user.contact_person_position || state && state.company_user.contact_person_position && state.company_user.contact_person_position === null}
                    />
                    <AdminPhoneInput
                      className="cv-mb-20"
                      label={t("Broj telefona")}
                      value={state.company_user.contact_phone}
                      onChange={(contact_phone) =>
                        setState({
                          ...state,
                          company_user: {
                            ...state.company_user,
                            contact_phone,
                          },
                        })
                      }
                      error={state && state.company_user.contact_phone && state.company_user.contact_phone == '+387' || state && state.company_user.contact_phone && state.company_user.contact_phone == '+385' || state && state.company_user.contact_phone && state.company_user.contact_phone == '+382' || state && state.company_user.contact_phone && state.company_user.contact_phone == '+381' || !state.company_user.contact_phone}
                      color="secondary"
                      variant="outlined"
                      placeholder="00-000-0000"
                      required
                    />
                    {/* <PhoneInput
                      value={state.company_user.contact_phone}
                      containerClass="cv-mb-20"
                      specialLabel="Broj telefona"
                      required
                      onChange={(contact_phone) =>
                        setState({
                          ...state,
                          company_user: {
                            ...state.company_user,
                            contact_phone,
                          },
                        })
                      }
                      isValid={!errors.contact_phone}
                      onBlur={() =>
                        state.company_user?.contact_phone
                          ? setErrors({ ...errors, contact_phone: false })
                          : setErrors({ ...errors, contact_phone: true })
                      }
                      regions={"europe"}
                      country={"me"}
                      priority={{ rs: 0 }}
                      preferredCountries={["me", "rs", "hr", "ba", "si"]}
                      placeholder="382 12 345 6789"
                    /> */}
                    {/* <AdminInput
                      color="secondary"
                      className="cv-mb-20"
                      label={t("Kontakt telefon")}
                      value={state.company_user.contact_phone}
                      onChange={(contact_phone) =>
                        setState({
                          ...state,
                          company_user: { ...state.company_user, contact_phone },
                        })
                      }
                    /> */}
                    <AdminInput
                      color="secondary"
                      label={t("Korisnički Email")}
                      value={state.email}
                      required
                      onChange={(email) =>
                        setState({
                          ...state,
                          email,
                        })
                      }
                      error={state && !state.email || state && state.email && state.email === null}
                    />
                  </AdminInputCard>

                  <AdminInput
                    className="cv-mb-20"
                    color="secondary"
                    label={t("Kontakt email")}
                    value={state.contact_email}
                    onChange={(contact_email) =>
                      setState({
                        ...state,
                        contact_email,
                      })
                    }
                    error={state && !state.contact_email || state && state.contact_email && state.contact_email === null}
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Web adresa")}
                    placeholder="https://..."
                    value={state.website}
                    onChange={(website) => setState({ ...state, website })}
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Facebook profil")}
                    placeholder="https://..."
                    value={state.facebook}
                    onChange={(facebook) => setState({ ...state, facebook })}
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Instagram profil")}
                    placeholder="https://..."
                    value={state.instagram}
                    onChange={(instagram) => setState({ ...state, instagram })}
                  />
                  <AdminSelect
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Država")}
                    value={state.country_id}
                    required
                    onChange={(country_id) =>
                      setState({ ...state, country_id })
                    }
                    options={countries}
                    error={state && !state.country_id || state && state.country_id && state.country_id === null}
                  />
                  <AdminSelect
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Grad")}
                    required
                    value={state.city_id}
                    onChange={(city_id) => setState({ ...state, city_id })}
                    options={cities}
                    error={state && !state.city_id || state && state.city_id && state.city_id === null}
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("Adresa kompanije")}
                    value={state.address}
                    required
                    onChange={(address) => setState({ ...state, address })}
                    error={state && !state.address || state && state.address && state.address === null}
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("PIB")}
                    value={state.pib}
                    required
                    onChange={(pib) => setState({ ...state, pib })}
                    error={state && !state.pib || state && state.pib && state.pib === null}
                  />
                  <AdminInput
                    color="secondary"
                    className="cv-mb-20"
                    label={t("PDV")}
                    value={state.pdv}
                    onChange={(pdv) => setState({ ...state, pdv })}
                    error={state && !state.pdv || state && state.pdv && state.pdv === null}
                  />
                  <DataDisplay
                    className="cv-dashboard-profile-col-double"
                    label={t("Popunjenost profila")}
                    value={
                      <Progress
                        value={getPercent()}
                        className="cv-dashboard-dashboard-progress"
                        circleClassName="cv-dashboard-dashboard-progress-circle"
                        thickness={4}
                      />
                    }
                  />
                </div>
              </div>
            </SimpleCard>
          </div>
        ) : null}
      </div>
      <LeavePageModal
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
        onLeave={() => history.goBack()}
      />
    </Fragment>
  );
};

export default ProfileEdit;
