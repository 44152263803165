import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import React, { Fragment, useEffect, useState } from "react";
import { CompanyAPI, JobAPI } from "../../../../../api/v2";
import { Card, CardTitle } from "../../../../../components/custom";
import {
  Input,
  Select,
  MultiSelect,
  Button,
  AutocompleteSelect,
  Autocomplete,
} from "../../../../../components/ui";
import { useLanguageContext } from "../../../../../context/language";
import { useUserContext } from "../../../../../context/user";
import "./style.scss";
import { AdminAutocompleteSelect } from "../../../../../components/admin";

const Search = ({ values, setValues, onFilter, detailed, setDetailed }) => {
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [typeOfWorks, setTypeOfWorks] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [workTimes, setWorkTimes] = useState([]);
  const [search, setSearch] = useState("")
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setValues({ ...values, search });
    }, 400)
    return () => clearTimeout(delayDebounceFn)
  }, [search])
  const { t } = useLanguageContext();
  const {
    countries: allCountries,
    cities: allCities,
    addings: { type_of_works, work_times, job_types },
  } = useUserContext();

  const [counts, setCounts] = useState({
    positions: 0,
    companies: 0,
    locations: [],
    cities: [],
    types_of_work: [],
    companiesArray: [],
  });

  const getCounts = async () => {
    const {
      companies: companiesArray,
      totals,
      locations,
      types_of_work,
      cities: rCities,
    } = await JobAPI.jobSearchAllFilter();
    setCounts({
      positions: totals.ads,
      companies: totals.companies,
      locations,
      cities: rCities,
      types_of_work,
      companiesArray,
    });
  };



  useEffect(() => {
    setCompanies(
      counts.companiesArray.map((x) => ({
        value: x.company_id,
        label: `${x.full_name}`,
      }))
    );
  }, [counts.companiesArray]);

  useEffect(() => {
    const filteredCities = allCities
      .filter((x) => x.country_id === parseInt(values.country_id))
      .map((x) => {
        const a = counts.cities.find((y) => y.city_id === x.id);
        return {
          value: x.id,
          label: `${x.name} (${a?.active_ads_count || "0"})`,
        };
      });
    setCities(filteredCities);
  }, [values.country_id, counts.cities]);

  useEffect(() => {
    setTypeOfWorks(
      type_of_works.map((x) => {
        const a = counts.types_of_work.find((y) => y.type_of_work_id === x.id);
        return {
          value: x.id,
          label: `${x.name} (${a?.active_ads_count || "0"})`,
        };
      })
    );
  }, [counts.types_of_work]);

  useEffect(() => {
    setCountries(
      allCountries.map((x) => {
        const a = counts.locations.find((y) => y.country_id === x.id);
        return {
          value: x.id,
          label: `${x.name} (${a?.active_ads_count || "0"})`,
        };
      })
    );
  }, [counts.locations]);

  useEffect(() => {
    setJobTypes(job_types.map((x) => ({ value: x.id, label: x.name })));
    setWorkTimes(work_times.map((x) => ({ value: x.id, label: x.name })));
    getCounts();
  }, []);

  useEffect(() => {
    const foundCity = cities.find((x) => x.value === values.city_id);
    setCity(foundCity);
  }, [cities, values.country_id]);

  useEffect(() => {
    const foundCountry = countries.find((x) => x.value === values.country_id);
    setCountry(foundCountry);
  }, [countries]);

  const updateCity = (x) => {
    setCity(x);
    setValues({ ...values, city_id: x?.value });
  };

  const updateCountry = (x) => {
    setCountry(x);
    setCity(null);
    setValues({ ...values, country_id: x?.value, city_id: "" });
  };

  const [terms, setTerms] = useState([]);
  useEffect(() => {
    (async () => {
      if (values.term) {
        const { ads, companies } = await JobAPI.jobSearchTermFilter(
          values.term
        );
        setTerms([
          ...ads.map((x) => ({ value: x, label: x, category: "Poslovi" })),
          ...companies.map((x) => ({
            value: x,
            label: x,
            category: "Kompanije",
          })),
        ]);
      } else {
        setTerms([]);
      }
    })();
  }, [values.term]);

  return (
    <Card color="secondary" className="cv-jobsearch-main">
      <CardTitle
        title={
          <>
            <span className="primary">{t("Pretraga")}</span> {t("poslova")}
          </>
        }
        baseColor="#ffffff"
      />

      <div className="cv-jobsearch-text">
        <div className="cv-jobsearch-text-info">
          <span>
            {t`Trenutno otvorenih`} {counts.positions} {t`radnih mjesta`}
          </span>{" "}
          {t`za`} {counts.companies} {t`kompanija`}
        </div>
        <div
          className="cv-jobsearch-detailed"
          onClick={() => setDetailed(!detailed)}
        >
          {detailed ? <ArrowDropUp /> : <ArrowDropDown />}
          {t("Napredna pretraga")}
        </div>
      </div>
      <div
        className={`cv-jobsearch-filter ${detailed ? "cv-jobsearch-filter-detailed" : ""
          }`}
      >
        <Autocomplete
          size="large"
          className="cv-home-search-part"
          placeholder={t`Pozicija, poslodavac ili ključna riječ`}
          value={search}
          onChange={(term) => setSearch(term)}
          groupBy={(x) => x.category}
          options={terms}
        />
        <AutocompleteSelect
          allowEmpty
          id="country"
          size="large"
          placeholder={t("Država")}
          options={countries}
          value={country}
          onChange={updateCountry}
        />
        <AutocompleteSelect
          id="city"
          size="large"
          className="cv-home-search-part"
          placeholder={!values.country_id ? t`Odaberite državu` : t`Grad`}
          disabled={!values.country_id}
          options={cities}
          value={city}
          onChange={updateCity}
        />
        <MultiSelect
          id="job_category"
          size="large"
          placeholder={t("Kategorija posla")}
          options={typeOfWorks}
          value={values.types_of_work}
          onChange={(types_of_work) => setValues({ ...values, types_of_work })}
        />
        {detailed ? (
          <Fragment>
            <MultiSelect
              id="company"
              size="large"
              placeholder={t("Poslodavac")}
              options={companies}
              value={values.companies}
              onChange={(companies) => setValues({ ...values, companies })}
            />
            <Select
              allowEmpty
              id="work_time"
              size="large"
              placeholder={t("Radno vrijeme")}
              options={workTimes}
              value={values.work_time_id}
              onChange={(work_time_id) =>
                setValues({ ...values, work_time_id })
              }
            />
            <Select
              allowEmpty
              id="job_type"
              size="large"
              placeholder={t("Tip posla")}
              options={jobTypes}
              value={values.job_type_id}
              onChange={(job_type_id) => setValues({ ...values, job_type_id })}
            />
          </Fragment>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={onFilter}
        >
          {t("PRETRAŽITE")}
        </Button>
      </div>
    </Card>
  );
};

export default Search;
