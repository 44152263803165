import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { gradient } from "../../../utils/style";

const padding = (theme, size) => {
  const sizes = {
    small: theme.spacing(1, 6),
    medium: theme.spacing(2, 8),
    large: theme.spacing(3, 10),
  };
  return sizes[size];
};

export const ButtonWrapper = styled(Button)(
  ({ theme, color = "primary", size = "medium" }) => ({
    ...theme.typography.button,
    borderRadius: 10,
    borderWidth: 2,
    fontFamily: "'Rubik', sans-serif",
    minWidth: 140,
    textTransform: "none",
    padding: padding(theme, size),
    "&:hover": {
      borderWidth: 2,
    },
    "&.MuiButton-contained": {
      textShadow: "0px 0px 2px #00000050",
      svg: {
        filter: "drop-shadow(0px 0px 1px #00000050)",
      },
      background: gradient(180, theme.palette[color].mainGradient),
      "&:hover": {
        background: gradient(180, theme.palette[color].darkGradient),
      },
    },
    "&.MuiButton-outlined": {
      color: `${theme.palette[color].mainGradient[0]}`,
      borderColor: theme.palette[color].mainGradient[0],
      background: gradient(
        180,
        theme.palette[color].mainGradient.map((x) => `${x}20`)
      ),
      "&:hover": {
        color: `${theme.palette[color].darkGradient[0]}`,
        borderColor: theme.palette[color].darkGradient[0],
        background: gradient(
          180,
          theme.palette[color].darkGradient.map((x) => `${x}20`)
        ),
      },
    },
    "&.MuiButton-text": {
      color: `${theme.palette[color].mainGradient[0]}`,
      "&:hover": {
        color: `${theme.palette[color].darkGradient[0]}`,
        background: `${theme.palette[color].mainGradient[0]}20`,
      },
    },
  })
);



const MyButton = styled(Button)(({ theme }) => ({
  widht: 200,
  backgroundColor: theme.palette.primary.main
}))