import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CandidateAPI } from "../../api/v2";
import { DashTitle, DataDisplay } from "../../components/custom";
import { SimpleCard } from "../../components/ui";
import "./style.scss";
import { useLanguageContext } from "../../context/language";

const EmployeeSingle = () => {

  const [candidate, setCandidate] = useState(null);

  const { id } = useParams();

  const getCandidate = async (id) => {
    const res = await CandidateAPI.candidateInfo(id);
    setCandidate(res);
  }

  useEffect(() => {
    getCandidate(id);
  },[id])
  const { t } = useLanguageContext();

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle title={t("Kandidat")} subtitle={t("Detaljan profil kandidata")} />
        <SimpleCard
          className="cv-dashboard-employee-preview-content"
          title={t("KANDIDAT")}
          withContent
        >
          {candidate ? (
            <div className="cv-dashboard-employee-preview-content-inner">
              <div className="cv-dashboard-employee-preview-side">
                <DataDisplay label={t("Pol")} value={candidate.gender.name} />
                <DataDisplay label={t("Email")} value={candidate.email} />
                <DataDisplay
                  label={t("Obrazovanje")}
                  value={candidate.educations.map((x) => x.title).join(", ")}
                />
                <DataDisplay label={t("Država")} value={candidate.country.name} />
                <DataDisplay label={t("Grad")} value={candidate.city.name} />
                <DataDisplay
                  label={t("Ulica i broj prebivališta")}
                  value={candidate.address}
                />
                <DataDisplay label="Kontakt telefon" value={candidate.phone} />
                <DataDisplay
                  label={t("Rad na racunaru")}
                  value={candidate.computer_skills
                    .map((x) => x.computer_skill_name.name)
                    .join(", ")}
                    
                />
                <DataDisplay
                  label={t("Strani jezici")}
                  value={candidate.languages
                    .map((x) => x.languages_name)
                    .join(", ")}
                    
                />
                <DataDisplay
                  label={t("CV dokument")}
                  value={candidate.cv_document?.document_name}
                />
                <DataDisplay
                  label={t("Radno iskustvo")}
                  value={candidate.work_experiences
                    .map((x) => x.company_name)
                    .join(", ")}
                    
                  action={
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  }
                />
              </div>
              <div className="cv-dashboard-employee-preview-side"></div>
            </div>
          ) : null}
        </SimpleCard>
      </div>
    </Fragment>
  );
};

export default EmployeeSingle;
