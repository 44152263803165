import {
  CloudUpload,
  Delete,
  PeopleAlt,
  Save,
  SendRounded,
  Videocam,
} from "@mui/icons-material";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router";
import { CandidateAPI, JobAPI } from "../../../api/v2";
import { jobs } from "../../../api/v2/job";
import {
  DashTitle,
  Modal,
  ModalVideo,
  Status,
} from "../../../components/custom";
import { SimpleCard, Button } from "../../../components/ui";
import { tryParsing } from "../../../utils/misc";
import VideoRecorder from "react-video-recorder";
import "./style.scss";
import { blobToFile } from "../../../utils/converter";
import { useUserContext } from "../../../context/user";
import { Link } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useLanguageContext } from "../../../context/language";
import Spinner from "../../../components/custom/spinner";
import { EmptyTableWrapper } from "../../../components/custom/table/styles";
import { SentimentVeryDissatisfied } from "@mui/icons-material";

const JobSingleCandidate = () => {
  const [job, setJob] = useState(null);
  const [recordModal, setRecordModal] = useState(0);
  const [applicationModal, setApplicationModal] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const [showWrongFormat, setShowWrongFormat] = useState(false);
  const [myApplication, setMyApplication] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const { id } = useParams();
  const history = useHistory();
  const [isloadingJobs, setIsLoadingJobs] = useState(false);

  const getJob = async (x) => {
    console.log("test1")
    setIsLoadingJobs(true);
    const { questions, ...res } = await JobAPI.job(x).catch(err => {
      setIsLoadingJobs(false);
      return {quest: null, res: null,}
    });
    if (res.application_id) {
      const application_res = await CandidateAPI.applicationShow(
        res.application_id
      ).catch(err => {
        setIsLoadingJobs(false);
      });
      setMyApplication(application_res);
      setJob({
        ...res,
        //questions
        questions: questions.map((x, y) => ({
          ...x,
          answer_video: application_res.answers[y]?.video_answer
            ? {
              url: application_res.answers[y]?.video_answer?.path,
              uploaded: application_res.answers[y]?.video_answer?.id || null,
            }
            : null,
        })),
      });
    } else {
      if (questions) {
        setJob({ ...res, questions });
      }
    }
    setIsLoadingJobs(false);
    console.log("test2")

  };

  const { user } = useUserContext();

  useEffect(() => {
    getJob(id);
  }, [id]);

  const removeAnswer = async (a) => {
    const answerId = job.questions.find(x => x.id === a).answer_video.uploaded;
    await JobAPI.deleteQuestionVideo(answerId);
    setJob({
      ...job,
      questions: job.questions.map((x) =>
        x.id === a ? { ...x, answer_video: null } : x
      ),
    });
    setUploadId(null);
    uploadRef.current.value = null;
    setHasChanges(true);
  };

  const saveJob = async () => {
    const { id: application_id } = await CandidateAPI.applicationStore({
      applied: 0,
      ad_id: id,
      answers: job.questions.map((x) => ({
        ad_question_id: x.id,
        video_id: x.answer_video?.uploaded || null,
      })),
    });
    setHasChanges(false);
    setJob({ ...job, application_id });
    history.push(`/dashboard/applications/preparation`);
  };

  const uploadRef = useRef();
  const { t } = useLanguageContext();
  const [uploadId, setUploadId] = useState(null);
  const answerQuestion = (x) => {
    setUploadId(x);
    uploadRef.current.click();
  };

  const uploadVideo = async (e) => {
    const file = e.target.files[0];
    e.target.value = "";
    if (file.type.includes("video")) {
      const uId = uploadId;
      setJob({
        ...jobRef.current,
        questions: jobRef.current.questions.map((x, y) =>
          y === uId
            ? {
              ...x,
              answer_video: {
                file,
                url: URL.createObjectURL(file),
                uploaded: null,
              },
            }
            : x
        ),
      });
      const { id, path } = await JobAPI.uploadQuestionVideo(file);
      setJob({
        ...jobRef.current,
        questions: jobRef.current.questions.map((x, y) =>
          y === uId
            ? {
              ...x,
              answer_video: {
                file,
                url: path,
                uploaded: id,
              },
            }
            : x
        ),
      });
      setHasChanges(true);
    } else {
      toast.error(t`Izabrani format nije podržan`);
    }
  };

  useEffect(() => {
    if (job?.questions) {
      const { questions } = job;
      for (let i = 0; i < questions.length; i++) {
        if (!questions[i].answer_video) {
          setEnableBtn(false);
          return;
        }
      }
      setEnableBtn(!!job.is_active);
    }
  }, [job]);

  const jobRef = useRef(job);
  useEffect(() => {
    jobRef.current = job;
  }, [job]);

  return (
    <Fragment>
      <div className="cv-dashboard-page-scrollable">
        <DashTitle
          title={t("Detalji oglasa")}
          subtitle={t("Sve informacije o oglasu kompanije")}
        />
        <SimpleCard
          className="cv-dashboard-job-single-candidate-main"
          title={job?.title ? job.title : t("OGLAS SE UČITAVA")}
          actions={job?.status ? <Status status={job.status} /> : null}
          withContent
        >
          {!isloadingJobs ? (
            job ? (
              <Fragment>
                <div className="cv-dashboard-job-single-candidate-company">
                  <div className="cv-dashboard-job-single-candidate-company-image">
                    {job.company?.profile_image ? (
                      <img src={job.company.profile_image} />
                    ) : null}
                  </div>
                  <div className="cv-dashboard-job-single-candidate-company-data">
                    <div className="cv-dashboard-job-single-candidate-company-data-single">
                      <span>{t("Naziv kompanije") + ":"} </span>
                      {job.company?.full_name}
                    </div>
                    <div className="cv-dashboard-job-single-candidate-company-data-single">
                      <span>{t("Tip posla") + ":"} </span>
                      {job.type_of_work?.name}
                    </div>
                    <div className="cv-dashboard-job-single-candidate-company-data-single">
                      <span>{t("Lokacija") + ":"} </span>
                      {job.city?.name}, {job.country?.name}
                    </div>
                    <div className="cv-dashboard-job-single-candidate-company-data-single">
                      <span>{t("Datum trajanja") + ":"} </span>
                      {moment(job.start_date).format("DD.MM.YYYY")} -{" "}
                      {moment(job.end_date).format("DD.MM.YYYY")}
                    </div>
                    <div className="cv-dashboard-job-single-candidate-company-data-single">
                      <span>{t("Broj prijavljenih kandidata") + ":"} </span>
                      {job.number_of_applications}
                    </div>
                  </div>
                </div>
                <div className="cv-dashboard-job-single-candidate-description">
                  <div className="cv-dashboard-job-single-candidate-title">
                    {t("Tekst oglasa") + ":"}
                  </div>
                  <div
                    className="cv-injected-text"
                    dangerouslySetInnerHTML={{
                      __html: tryParsing(job.description),
                    }}
                  ></div>
                </div>
                <div className="cv-dashboard-job-single-candidate-questions">
                  <div className="cv-dashboard-job-single-candidate-title">
                    {t("Pitanja za kandidata") + ":"}
                  </div>
                  <div>
                    <input
                      type="file"
                      hidden
                      onChange={uploadVideo}
                      ref={uploadRef}
                      accept="video/*"
                    />
                    {job.questions?.map((x, y) => (
                      <div className="cv-dashboard-job-single-candidate-question">
                        <div className="cv-dashboard-job-single-candidate-question-text">
                          <span>{y + 1}#</span> {x.text_question}
                        </div>
                        {/* {job.application_id !== null && myApplication ? (
                        <div
                          className={`cv-dashboard-job-single-candidate-question-video`}
                        >
                          {myApplication.answers[y] ? (
                            <ModalVideo
                              src={myApplication.answers[y]?.video_answer?.path}
                            />
                          ) : null}
                        </div>
                      ) : null} */}
                        {user.role === "employee" ? (
                          <div className="cv-dashboard-job-single-candidate-question-actions">
                            {!x.answer_video ? (
                              <Fragment>
                                <Button
                                  className="cv-dashboard-job-single-candidate-question-action"
                                  size="small"
                                  color="secondary"
                                  variant="text"
                                  startIcon={<Videocam />}
                                  onClick={() => setRecordModal(x.id)}
                                >
                                  {t("SNIMITE ODGOVOR")}
                                </Button>
                                <Button
                                  className="cv-dashboard-job-single-candidate-question-action"
                                  size="small"
                                  color="secondary"
                                  variant="text"
                                  startIcon={<CloudUpload />}
                                  onClick={() => answerQuestion(y)}
                                >
                                  {t("OKAČITE SNIMAK")}
                                </Button>
                              </Fragment>
                            ) : (
                              <div className="cv-dashboard-job-single-candidate-question-action-delete">
                                <div
                                  className={`cv-dashboard-job-single-candidate-question-video ${!x.answer_video.uploaded ? "cv-disabled" : ""
                                    }`}
                                >
                                  <ModalVideo src={x.answer_video.url} />
                                </div>
                                {!x.answer_video.uploaded ? (
                                  <LinearProgress />
                                ) : null}
                                <Button
                                  className="cv-dashboard-job-single-candidate-question-action"
                                  size="small"
                                  color="error"
                                  variant="text"
                                  startIcon={<Delete />}
                                  onClick={() => removeAnswer(x.id)}
                                >
                                  {"OBRIŠITE ODGOVOR"}
                                </Button>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    ))}
                    {!job.questions.length ? (
                      <div>{t("Nema dodatnih pitanja za kandidata") + "!"}</div>
                    ) : null}
                  </div>
                </div>
                <div className="cv-dashboard-job-single-candidate-buttons">
                  {user.role === "employee" ? (
                    <Fragment>
                      {!job.applied && hasChanges ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          startIcon={<Save />}
                          onClick={saveJob}
                        >
                          {t("SAČUVAJ PRIJAVU")}
                        </Button>
                      ) : null}
                      {!job.application_id || !job.applied ? (
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          startIcon={<SendRounded />}
                          onClick={() => setApplicationModal(true)}
                          className={
                            job.status === 6 ||
                              job.questions.filter((x) => !x.answer_video).length
                              ? "cv-disabled"
                              : ""
                          }
                        >
                          {t("KONKURIŠI NA OGLAS")}
                        </Button>
                      ) : null}
                      {job.applied && job.application_id ? (
                        <Button
                          color="primary"
                          variant="contained"
                          size="large"
                          startIcon={<SendRounded />}
                          component={Link}
                          to={`/dashboard/applications/application/${job.application_id}`}
                        >
                          {t("POGLEDAJ PRIJAVU")}
                        </Button>
                      ) : null}
                    </Fragment>
                  ) : (
                    <Fragment>
                      {job.company.id === user.id ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          startIcon={<PeopleAlt />}
                          component={Link}
                          to={`/dashboard/jobs/job/${id}/applications`}
                        >
                          {t("LISTA KANDIDATA")}
                        </Button>
                      ) : null}
                    </Fragment>
                  )}
                  {/* {user.role === "employee" ? (
                  job.application_id == null ? (
                    <Fragment>
                      {job.questions.length &&
                      job.questions.filter((x) => x.answer_video).length ? (
                        <Button
                          color="secondary"
                          variant="contained"
                          size="large"
                          startIcon={<Save />}
                          onClick={saveJob}
                        >
                          {t("SAČUVAJ PRIJAVU")}
                        </Button>
                      ) : null}
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        startIcon={<SendRounded />}
                        onClick={() => setApplicationModal(true)}
                        className={
                          job.status === 6 ||
                          job.questions.filter((x) => !x.answer_video).length
                            ? "cv-disabled"
                            : ""
                        }
                      >
                        {t("KONKURIŠI NA OGLAS")}
                      </Button>
                    </Fragment>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      startIcon={<SendRounded />}
                      component={Link}
                      to={`/dashboard/applications/application/${job.application_id}`}
                    >
                      {t("POGLEDAJ PRIJAVU")}
                    </Button>
                  )
                ) : (
                  <Fragment>
                    {job.company.id === user.id ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        startIcon={<PeopleAlt />}
                        component={Link}
                        to={`/dashboard/jobs/job/${id}/applications`}
                      >
                        {t("LISTA KANDIDATA")}
                      </Button>
                    ) : null}
                  </Fragment>
                )} */}
                </div>
              </Fragment>
            ) : (
              <div className="cv-spinner-holder">
                <EmptyTableWrapper>
                  <SentimentVeryDissatisfied />
                  <h3>Nema rezultata</h3>
                  <p>Izgleda da ovaj oglas ne postoji!</p>
                </EmptyTableWrapper>
              </div>
            )
          ) : (
            <Spinner />
          )}
        </SimpleCard>
      </div>
      <RecordModal
        open={recordModal}
        onClose={() => setRecordModal(0)}
        setJob={setJob}
        job={jobRef}
        setHasChanges={setHasChanges}
      />
      <ApplicationModal
        open={applicationModal}
        onClose={() => setApplicationModal(false)}
        job={job}
        setJob={setJob}
      />
    </Fragment>
  );
};

const ApplicationModal = ({ open, onClose, ...parent }) => {
  const { id } = useParams();

  const history = useHistory();

  const { t } = useLanguageContext();

  const sendApplication = async () => {
    try {
      await CandidateAPI.applicationStore({
        ad_id: id,
        applied: 1,
        answers: parent.job.questions.map((x) => ({
          ad_question_id: x.id,
          video_id: x.answer_video?.uploaded,
        })),
      });
      parent.setJob({
        ...parent.job,
        applied: 1,
      });
      history.push("/dashboard/jobs/all");
      toast.success(t`Prijava uspešno poslata`);
    } catch (error) {
      if (error.response.data.message.includes("Must be a verified user")) {
        return toast.error("Profil nije verifikovan");
      }
      toast.error(t`Prijava nije poslata`);
    } finally {
      onClose();
    }
  };

  const { user } = useUserContext();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("KONKURIŠI NA OGLAS")}
      size="medium"
      warning={user.profile_completely !== 100}
    >
      <div className="cv-dashboard-jobs-apply">
        {user.profile_completely !== 100 ? (
          <div className="cv-dashboard-jobs-apply-warning">
            {t(
              "Vaš profil nije maskimalno popunjen! Da li želite poslati prijavu"
            ) + "?"}
          </div>
        ) : null}
        <Button
          color="default"
          variant="contained"
          size="large"
          fullWidth
          component={Link}
          to="/dashboard/cv-profile/edit"
          className="cv-mb-10"
        >
          {t("DOPUNI PROFIL")}
        </Button>

        {user.profile_completely === 100 ? <Button
          color="secondary"
          variant="contained"
          size="large"
          fullWidth
          className="cv-mb-10"
          onClick={sendApplication}
        >
          {t("PRIJAVI SE")}
        </Button> : null}

        <Button
          color="secondary"
          variant="text"
          size="large"
          fullWidth
          onClick={onClose}
        >
          {t("ODUSTANI")}
        </Button>
      </div>
    </Modal>
  );
};

const RecordModal = ({ open, onClose, ...parent }) => {
  const [videoBlob, setVideoBlob] = useState(null);

  const { t } = useLanguageContext();

  const saveRecording = async () => {
    const file = blobToFile(videoBlob, "video.webm");
    parent.setJob({
      ...parent.job.current,
      questions: parent.job.current.questions.map((x, y) =>
        x.id === open
          ? {
            ...x,
            answer_video: {
              file,
              url: URL.createObjectURL(file),
              uploaded: null,
            },
          }
          : x
      ),
    });
    onClose();
    const { id, path } = await JobAPI.uploadQuestionVideo(file);
    parent.setJob({
      ...parent.job.current,
      questions: parent.job.current.questions.map((x, y) =>
        x.id === open
          ? {
            ...x,
            answer_video: {
              file,
              url: path,
              uploaded: id,
            },
          }
          : x
      ),
    });
    parent.setHasChanges(true);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("SNIMI VIDEO CV")}
      size="medium"
      actions={[
        <Button
          variant="contained"
          color="secondary"
          onClick={saveRecording}
          size="large"
          fullWidth
          className={!videoBlob ? "cv-disabled" : null}
        >
          {t("SAČUVAJ")}
        </Button>,
        <Button
          variant="contained"
          color="default"
          onClick={onClose}
          size="large"
          fullWidth
        >
          {t("OTKAŽI")}
        </Button>,
      ]}
    >
      <div className="cv-dashboard-cvvideo-modal-content">
        <VideoRecorder
          mimeType="video/webm"
          isOnlyInitially={true}
          showReplayControls={true}
          videoControlsList="nodownload"
          disablepictureinpicture
          onRecordingComplete={(videoBlob) => {
            setVideoBlob(videoBlob);
          }}
        />
      </div>
    </Modal>
  );
};

export default JobSingleCandidate;
